import React from "react";
import ErrorPage from './ErrorPage'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
      }

    render() {
        return this.state.hasError ?
            <ErrorPage {...this.props}/> :
            this.props.children
    }
}
