import * as actionTypes from "./actionTypes";
import * as constant from "../../shared/constant/constant";

export const createAndTrackErrorMessage = (message, field) => {
    return {
        type: actionTypes.CREATE_AND_TRACK_TOAST_MESSAGE,
        payload: {
            from: constant.ERROR_FROM_API,
            key: field,
            message,
            messageType: constant.MESSAGE_TYPE_ERROR
        }
    };
};

export const createAndTrackSuccessMessage = (message) => {
    return {
        type: actionTypes.CREATE_AND_TRACK_TOAST_MESSAGE,
        payload: {
            message,
            messageType: constant.MESSAGE_TYPE_SUCCESS
        }
    };
};
