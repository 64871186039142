//Data tags for onesignal
export const TAG_LOAN_CREATED_DATE = "loan_created_date";
export const TAG_DATE_SINCE_LOAN_ORIGINATED = "date_since_loan_originated";
export const TAG_NEXT_PAYMENT_DATE = "next_payment_date";
export const TAG_PAID_OFF_DATE = "paid_off_date";
export const TAG_LOAN_STATUS = "loan_status";
export const TAG_LOAN_TYPE = "loan_type";
export const TAG_BANK_VERIFICATION_COMPLETED = "bank_verification_completed";
export const TAG_ACCOUNT_OWNERSHIP_COMPLETED = "account_ownership_completed";
export const TAG_ACCOUNT_NUMBER_COMPLETED = "account_number_completed";
export const TAG_ESIGN_COMPLETED = "esign_completed";
export const TAG_EMAIL_VERIFICATION_COMPLETED = "email_verification_completed";
export const TAG_IDENTITY_VERIFICATION_COMPLETED = "identity_verification_completed";
export const TAG_PAYMENT_INFO_COLLECTED = "payment_info_collected";
export const TAG_APPLICATION_COMPLETED = "application_completed";
export const TAG_APPLICATION_STARTED = "is_application_started";
export const TAG_FIRST_NAME = "first_name";
export const TAG_LAST_NAME = "last_name";
export const TAG_ORGANIZATION_ID = "organization_id";
export const TAG_IS_ELIGIBLE_FOR_DRAW = "is_eligible_for_draw";
export const TAG_LAST_DRAW_DATE = "last_draw_date";
export const TAG_EXISTING_USER = "isExistingUser";
export const TAG_NEXT_PAYMENT_DATE_ISO = "next_payment_date_ISO";
export const IS_KYC = "is_kyc_completed";
export const SPOUSE_INFO_COLLECTED="spouse_info_collected";
export const IS_LOGIN="is_login";
export const TAG_REASON_FOR_WITHDRAW = "reason_for_withdraw";
export const TAG_DATE_SINCE_WITHDRAW = "date_since_withdraw";
export const TAG_IS_USER_CREATED_PASSWORD = "is_password_created";
export const TAG_IS_PERSONAL_INFO_SUBMITTED = "is_personal_info_submitted";
export const TAG_USER_ID = "user_id";
export const TAG_CUSTOMER_ID = "customer_id";
