import webapi from "../../axios/webapi";

import * as route from "../../shared/constant/route";
import * as utility from "../../shared/utility/utility";
import {getAPIErrorMessage} from "../../shared/utility/utility";
import {put, select} from "redux-saga/effects";
import * as errorActions from "../action/ErrorAction";
import * as field from "../../shared/constant/field";
import * as constant from "../../shared/constant/constant";
import styledToast from "../../components/widgets/toast";
import * as authActions from "../action/AuthAction";
import {push} from 'connected-react-router';

export function* trackToastMessage(action) {
    const state = yield select();
    let toastMessage = action.payload.message;
    let messageType = action.payload.messageType;
    let key = action.payload.key;

    if (messageType === constant.MESSAGE_TYPE_ERROR) {
        yield put(errorActions.createApiError(toastMessage, key));
        toastMessage = getAPIErrorMessage(toastMessage);
    } else {
        styledToast.success(toastMessage, {autoClose: 5000});
    }

    let email = state[field.REDUCER_NODE_AUTH][field.USERAUTH][field.EMAIL];

    if (utility.isEmptyOrNullString(email) || !utility.isToastPopupErrorMessage(utility.getAPIResponseErrorMessage(action.payload.message))) {
        return;
    }

    let userId = state[field.REDUCER_NODE_AUTH][field.USERID];
    let baseLoanId = state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.BASE_LOAN_ID];
    let organizationId = utility.getOrganizationId();
    try {
        yield webapi({
            method: "post",
            url: route.WEB_API_TRACKING_NOTIFICATION,
            data: {
                toastMessage,
                messageType,
                page: window.location.pathname,
                email,
                userId,
                baseLoanId,
                organizationId
            }
        });
    } catch (err) {
        if(err.response && err.response.status === 401) {
            yield put(authActions.logoutAction(true));
            yield put(push(route.LOGIN));
        }
    }
}
