import {push} from "connected-react-router";
import {
    startAsyncValidation,
    stopAsyncValidation,
    setSubmitSucceeded,
    setSubmitFailed,
    stopSubmit,
    initialize,
    untouch
} from "redux-form";
import { call, put, select } from 'redux-saga/effects'

import api from "../../axios/api";
import webapi from "../../axios/webapi";
import * as route from "../../shared/constant/route";
import * as form from "../../shared/constant/form";
import * as field from "../../shared/constant/field";
import {Loan} from "../../shared/constant/loan";
import {
    MC_IDENTITY,
    ORGANIZATION as organization
} from "../../shared/constant/field";
import * as fieldtags from "../../shared/constant/fieldtags";
import * as errorMessages from "../../shared/constant/errorMessages";
import * as routeutility from "../../shared/utility/routeutility";
import * as utility from "../../shared/utility/utility";
import * as apiErrorCodes from "../../shared/constant/apiErrorCodes";
import * as applicationActions from "../../store/action/ApplicationAction";
import * as authActions from "../../store/action/AuthAction";
import * as errorActions from "../../store/action/ErrorAction";
import * as trackingActions from "../action/trackingActions";
import * as loanActions from "../action/loanActions";
import * as dashboardActions from "../action/DashboardAction";
import * as createAndTrackToastMessageAction from "../action/CreateAndTrackToastMessageAction";
import * as _helpers from '../../shared/helpers'
import * as applicationSagas from './ApplicationSaga';

import {
    _setAuthTokeninAxiosHeader,
    _setUserDetailsInAxiosHeader,
} from '../reducer/AuthReducer'

import {getIpAddress} from "../../shared/utility/ipify";
import * as constant from "../../shared/constant/constant";
import * as MfaConstant from "../../views/application/mfa/constant/MfaConstant";
import {getAPIErrorMessage} from "../../shared/utility/utility";
import styledToast from "../../components/widgets/toast";
import * as pushNotification from "../../views/OneSignal/PushNotification";
import * as onesignalTemplate from "../../shared/constant/onesignalTemplate";
import * as mcIdentityAction from "../action/McIdentityAction";
import {
    clearBehaviouralData,
    getMcInsightsDataAndClear,
    stopBehaviouralMonitoring
} from "../../external.integrations/mastercard.identity/master.card.identity.utils";
import {
    MC_IDENTITY_PLACEMENT_BOT_DETECTION,
    MC_IDENTITY_PLACEMENT_LOGIN_PAGE
} from "../../shared/constant/mcIdentityConstants";
import {MESSAGE_TYPE_SUCCESS} from "../../shared/constant/constant";
import {postMcIdentityData} from "./McIdentitySaga";
import {mcIdentityLoadingState} from "../reducer/McIdentityReducer";


let _ip;

/**
 * Network request for login user
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* loginUser(action) {
    let xlt = yield call(getTokens);

    try {
        if (!_ip) {
            try {
                _ip = yield getIpAddress();
            } catch (err) {}
        }
        let urlParams = _helpers.urlSplitting();
        let isRC = urlParams.isRC === "1" || urlParams.isEpicRC === "1";

        console.log("here..")

        const loginApiData = {
            method: "post",
            url: route.WEB_API_USER_LOGIN,
            auth: {
                username: action.payload.email,
                password: action.payload.password
            },
            headers: {
                organizationId: yield select(
                    state => state[field.REDUCER_NODE_ORGANIZATION][organization.CONFIG][organization.ORGANIZATION_ID]
                ),
                rcLogin: isRC,
                "X-Ip-Address":  _ip,
                "X-Page": action.payload.page,
                "X-Login-Token": xlt,
                "X-Verification-Token": action.payload.token
            }
        }

        // MCIdentity data submission
        const mcIdentityState = yield select(state => state[field.REDUCER_NODE_MC_IDENTITY]);
        const isMcIdentityPackageLoading = mcIdentityState[MC_IDENTITY.IS_MC_IDENTITY_PACKAGE_LOADING];
        const mcIdentityLoginBehaviourEnabled = mcIdentityState[MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_ENABLED];
        const mcIdentityLoginBehaviourStarted = mcIdentityState[MC_IDENTITY.LOGIN_PAGE][MC_IDENTITY.DETECTION_STARTED];
        if (isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED && mcIdentityLoginBehaviourEnabled && mcIdentityLoginBehaviourStarted) {
            const mcIdentityData = getMcInsightsDataAndClear(MC_IDENTITY_PLACEMENT_LOGIN_PAGE, false);
            const productType = MC_IDENTITY_PLACEMENT_LOGIN_PAGE;
            loginApiData.data = {
                sessionId: mcIdentityState[MC_IDENTITY.MC_IDENTITY_SESSION_ID],
                data: mcIdentityData,
                productType,
                userId: yield select(state => state[field.REDUCER_NODE_AUTH][field.USERID]),
                result: MESSAGE_TYPE_SUCCESS,
                action: mcIdentityState[MC_IDENTITY.MC_IDENTITY_TRACKING_PAGE]
            };
        }
        const response = yield webapi({...loginApiData});

        if(!!response.data.jSessionId){
            _setAuthTokeninAxiosHeader(response.data.jSessionId);
            yield call(applicationSagas.saveSubIdTracking, {payload: {userId: response.data.userId, email: action.payload.email}});
            if (isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED && mcIdentityLoginBehaviourEnabled && mcIdentityLoginBehaviourStarted) {
                yield put(mcIdentityAction.updatedMcIdentityDetectionStartedValue(MC_IDENTITY_PLACEMENT_LOGIN_PAGE, false));
                yield put(mcIdentityAction.updatedMcIdentityDetectionSubmittedValue(MC_IDENTITY_PLACEMENT_LOGIN_PAGE, true));
                stopBehaviouralMonitoring(MC_IDENTITY.LOGIN_PAGE)
                clearBehaviouralData(MC_IDENTITY.LOGIN_PAGE)
            }
        } else if (response.status === 200) {
            if(!!response.data.mfaContactResendDTO){
                if(response.data.mfaContactResendDTO.message === MfaConstant.ERR_MFA_MAX_RETRY_REACHED || response.data.mfaContactResendDTO.message === MfaConstant.ERR_MFA_MAX_RESEND_REACHED){
                    yield put(applicationActions.sendMfaVerificationCodeErrorMessage(response.data.mfaContactDTO.message));
                } else if(response.data.mfaContactResendDTO.message === MfaConstant.ERR_MFA_EARLY_RESEND){
                    yield put(authActions.createAndTrackMfaErrorMessage(utility.getAPIErrorPayload(errorMessages.AUTHENTICATION_CODE_ALREADY_SENT), null));
                } else if(response.data.mfaContactResendDTO.message === MfaConstant.ERR_MFA_CODE_EXPIRED){
                    yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(utility.getAPIErrorPayload("Verification code expired."), null));
                } else {
                    if(response.data.mfaContactResendDTO.contactType === constant.OTP_PROCESSDATA_TYPE.OTP_TYPE_EMAIL){
                        yield put(applicationActions.sendMfaSMSServiceDown(true));
                    }
                    yield put(createAndTrackToastMessageAction.createAndTrackSuccessMessage("Authentication code sent!"));
                    yield put(applicationActions.sendMfaVerificationCodeSuccess(response));
                }
            }

        }
        if(isRC){
            yield put(applicationActions.checkIfRCForMfaLogin(true));
        }
        _setUserDetailsInAxiosHeader(response.data.userId, response.data.customerId);
        const buildProfile = yield select(state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE]);
        yield  put(authActions.sendNotification(response.data.customerId, buildProfile));
        yield put(authActions.loginAttemptResponse(response.data, action.payload.email, action.payload.password));
        yield put(errorActions.clearApiError(null, field.ERROR_LOGIN));
        pushNotification.setTagsWhileCreatePasswordOrLogin(false, response.data.userId);

    } catch (error) {
        if (error.response.status === apiErrorCodes.API_ERROR_CODE.ERROR_417 || error.response.data.message === MfaConstant.ERR_MFA_USER_BLOCKED){
            yield put(errorActions.clearApiError(null, field.ERROR_LOGIN));
            yield put(authActions.createAndTrackMfaErrorMessage(error, field.ERROR_LOGIN_BLOCKED));
        } else {
            yield put(errorActions.clearApiError(null, field.ERROR_LOGIN_BLOCKED));
            yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_LOGIN));
        }
        //update login attempt timestamp
        yield put(authActions.loginAttemptResponse(null, action.payload.email, action.payload.password));
        //For removing async errors after the user starts typing again
        yield put(
            untouch(
                form.LOGIN,
                fieldtags.LOGIN_FOUR_DIGITS_SSN,
                fieldtags.LOGIN_PASSWORD,
                fieldtags.LOGIN_EMAIL_ADDRESS
            )
        );
    }
}

/**
 * generate login tokens
 */
export function* getTokens() {
    let st = "52e863a6e1d4fdfa79de8ec53770adc149d1ab32a51949d49f18048db33f0837";
    try {
        const response = yield webapi({
            method: "get",
            url: route.WEB_API_GET_TOKENS
        });

        let token = st;
        if(response.data){
            let timestamp = response.data.timestamp;
            let tokens = response.data.tokens;
            let millis = timestamp.substring(timestamp.indexOf('.') + 1);
            token = tokens[millis % tokens.length];
        }
        return token;
    }
    catch (error) {
        return st;
    }
}

/**
 * Network request to logout user.
 * @returns {IterableIterator<*>}
 */
export function* logoutUser(action) {
    try {

        const mcIdentityState = yield select(state => state[field.REDUCER_NODE_MC_IDENTITY]);

        const isMcIdentityPackageLoading = mcIdentityState[MC_IDENTITY.IS_MC_IDENTITY_PACKAGE_LOADING];
        const isBotDetectionEnabled = mcIdentityState[MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_ENABLED];
        const isBotDetectionStarted = mcIdentityState[MC_IDENTITY.BOT_DETECTION][MC_IDENTITY.DETECTION_STARTED];
        const isBotDetectionSubmitted = mcIdentityState[MC_IDENTITY.BOT_DETECTION][MC_IDENTITY.DATA_SUBMITTED];
        const lastPathname = yield select(state => state[field.REDUCER_NODE_APP_PROGRESS][field.PROGRESSBAR.LAST_PATHNAME]);
        const isCustomerAuthenticated = yield select(state=>state[field.REDUCER_NODE_AUTH][field.ISAUTHENTICATED])

        if (
            isCustomerAuthenticated &&
            isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED &&
            isBotDetectionEnabled &&
            isBotDetectionStarted &&
            !isBotDetectionSubmitted &&
            !lastPathname.includes(route.PAGE_DASHBOARD) &&
            !lastPathname.includes(route.APPLICATION_START_APP_PAGE) &&
            !lastPathname.includes(route.APPLICATION_STATE_SELECTION_PAGE)
        ) {
            const mcIdentityData = getMcInsightsDataAndClear(MC_IDENTITY_PLACEMENT_BOT_DETECTION, false);
            yield put(mcIdentityAction.updatedMcIdentityDetectionStartedValue(MC_IDENTITY_PLACEMENT_BOT_DETECTION, false));
            yield call(postMcIdentityData, {
                payload: {
                    mcIdentityData,
                    productType: MC_IDENTITY_PLACEMENT_BOT_DETECTION,
                    mcIdentityState
                }
            });
            stopBehaviouralMonitoring(MC_IDENTITY.BOT_DETECTION);
            clearBehaviouralData(MC_IDENTITY.BOT_DETECTION);
        }

        yield webapi.get(route.WEB_API_USER_LOGOUT);

        yield put(authActions.logoutSuccess());

        if (action.payload.isAvoidResetReducer !== true) {
            yield put(errorActions.resetErrorReducer());
            yield put(applicationActions.resetApplicationReducer());
            yield put(loanActions.resetLoanReducer());
            yield put(dashboardActions.resetDashboardReducer());
            let urlParams = _helpers.urlSplitting();
            urlParams.overrideTierKey = !!urlParams.tierkey;
            if (!urlParams.tierkey) {
                let _stateSelector = state => ({
                    buildProfile: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE],
                    organizationTestTierKey: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_TEST_TIERKEY],
                    organizationProdTierKey: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_PROD_TIERKEY]
                });
                const _state = yield select(_stateSelector);
                urlParams.tierkey = utility.getOrganizationTierKey(
                    _state.buildProfile,
                    _state.organizationTestTierKey,
                    _state.organizationProdTierKey
                );
            }
            yield put(applicationActions.saveUrlParams(urlParams))
        }
    } catch (error) {
        yield put(authActions.logoutFailure(error));
    }
}

export function* doesEmailExist(action) {
    try {
        let supportNumber = yield select(
            state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.SUPPORT_NUMBER]
        );
        let csName = yield select(
            state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT].common.cs
        );
        let doesEmailExistUrl = route.WEB_API_USER_UNIQUEEMAIL.replace(/:email/i, action.payload.email).replace(
            /:organizationId/,
            utility.getOrganizationId()
        );
        yield put(startAsyncValidation(form.RESET_PASSWORD));
        const response = yield webapi({
            method: "get",
            url: doesEmailExistUrl
        });
        // If the email provided is unique, it means our database do not contain this email which means it does not exists
        // then show the error message
        if (response.data.isUnique) {
            yield put(
                stopAsyncValidation(form.RESET_PASSWORD, {
                    [fieldtags.RESETPASSWORD_EMAIL_ADDRESS]: errorMessages.RESETPASSWORD_EMAIL_ADDRESS__DOES_NOT_EXIST(
                        supportNumber,
                        csName
                    )
                })
            );
        }
        // If the email is not unique, it means the databse do contain this email
        else {
            yield put(stopAsyncValidation(form.RESET_PASSWORD, {}));
        }
    } catch (error) {
        if (error.response.status === apiErrorCodes.API_ERROR_CODE.ERROR_400) {
            yield put(
                stopAsyncValidation(form.RESET_PASSWORD, {
                    [fieldtags.RESETPASSWORD_EMAIL_ADDRESS]: errorMessages.EMAIL_ADDRESS__INVALID
                })
            );
        } else {
            yield put(
                stopAsyncValidation(form.RESET_PASSWORD, {
                    [fieldtags.RESETPASSWORD_EMAIL_ADDRESS]: errorMessages.GENERIC_ERROR_MESSAGE
                })
            );
        }
    }
}

export function* emailResetPasswordLink(action) {
    try {
        // TODO : INTEGRATE REAL ENDPOINT AND MAKE SURE EMAIL IS GETTING SENT TO CUSTOMER UPON RESET PASSWORD REQUEST
        // let associatedOrg = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USER_LOGIN_INFO][field.ASSOCIATED_ORG]);
        // let org;
        // org = associatedOrg ? associatedOrg !== utility.getOrganizationId() ? associatedOrg : utility.getOrganizationId() : utility.getOrganizationId();
        let org = utility.getOrganizationId();
        const emailResetPasswordURL = route.WEB_API_USER_EMAIL_RESET_PASSWORD_LINK.replace(
            /:email/,
            action.payload.email
        ).replace(/:organizationId/, org);

        const response = yield webapi({
            method: "get",
            url: emailResetPasswordURL,
            data: action.payload
        });

        if (response.data) {
            yield put(setSubmitSucceeded(form.RESET_PASSWORD, fieldtags.RESETPASSWORD_EMAIL_ADDRESS));
            yield put(
                initialize(
                    form.RESET_PASSWORD,
                    {[fieldtags.RESETPASSWORD_EMAIL_ADDRESS]: action.payload.email},
                    {keepSubmitSucceeded: true}
                )
            );
        } else {
            yield put(setSubmitFailed(form.RESET_PASSWORD, fieldtags.RESETPASSWORD_EMAIL_ADDRESS));
        }
    } catch (error) {
        yield put(
            stopSubmit(form.RESET_PASSWORD, {
                [fieldtags.RESETPASSWORD_EMAIL_ADDRESS]: errorMessages.RESETPASSWORD_SUBMIT_ERROR
            })
        );
    }
}

export function* updatePassword(action) {
    try {
        const resetPasswordURL = route.WEB_API_USER_RESET_PASSWORD;
        const response = yield webapi({
            method: "put",
            url: resetPasswordURL,
            data: action.payload
        });

        if (response.data) {
            yield put(authActions.updatePasswordSuccess(true));
            yield put(push(route.APPLICATION_LOGIN_PAGE));
        }
        yield put(errorActions.clearApiError(null, field.ERROR_UPDATE_PASSWORD));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_UPDATE_PASSWORD));
    }
}

export function* updatePasswordWithoutToken(action) {
    try {
        const resetPasswordURL = route.WEB_API_USER_RESET_PASSWORD_BY_USERID.replace(/:id/i, action.payload.userId);

        const sendData = {
            organizationId: utility.getOrganizationId(),
            password: action.payload.password
        };

        const response = yield webapi({
            method: "put",
            url: resetPasswordURL,
            data: sendData
        });

        if (response.data) {
            yield put(push(route.DASHBOARD_BASE_ROUTE_PARAMETER));

            let email = yield select(state => state[field.REDUCER_NODE_AUTH][field.AUTH.USERAUTH][field.AUTH.EMAIL]);
            yield put(authActions.sendEmailVerification(email, action.payload.userId));
        }
        yield put(trackingActions.submitUpdatePasswordFromDashboardPage());
        yield put(errorActions.clearApiError(null, field.ERROR_UPDATE_PASSWORD));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_UPDATE_PASSWORD));
    }
}

export function* isResetPasswordTokenValid(action) {
    try {
        const url = route.WEB_API_USER_VERIFY_RESET_PASSWORD_TOKEN.replace(/:userId/, action.payload.userId).replace(
            /:token/,
            action.payload.resetpasswordtoken
        );

        const response = yield api({
            method: "get",
            url: url
        });

        if (response.data === true) {
            yield put(authActions.isResetPasswordTokenValidSuccess(response.data));
        } else {
            yield put(authActions.isInvalidResetPasswordToken(response.data));
            yield put(push(route.RESET_PASSWORD));
        }
        yield put(errorActions.clearApiError(null, field.ERROR_ISVALIDMKWEBRESETPASSWORDTOKEN));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(
                error,
                field.ERROR_ISVALIDMKWEBRESETPASSWORDTOKEN
            )
        );
    }
}

export function* sendEmailVerification(action) {
    let url = route.WEB_API_USER_SEND_EMAIL_VERIFICATION.replace(/:email/, action.payload.email).replace(
        /:organizationId/,utility.getOrganizationId()).replace(/:userId/, action.payload.userId);
    try {
        const response = yield webapi({
            method: "post",
            url
        });

        if (response.data) {
            yield put(authActions.sendEmailVerificationSuccess(true));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* sendEmailVerificationOneTimePassword(action) {
    let url = route.WEB_API_USER_SEND_EMAIL_VERIFICATION_ONE_TIME_PASSWORD.replace(/:userId/, action.payload.userId);
    try {
        const response = yield webapi({
            method: "get",
            url
        });

        if (response.data.responseMessage === "SENT" || response.data.responseMessage === "2" ) {
            yield put(createAndTrackToastMessageAction.createAndTrackSuccessMessage("Verification code sent!"));
            yield put(authActions.sendEmailVerificationSuccess(response));
        } else if(response.data.responseMessage === constant.OTP_EMAIL_VERIFICATION_EARLY_RESEND) {
            yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(utility.getAPIErrorPayload("Verification code already sent.\nPlease try again in 1 minute."), null));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* submitEmailVerificationOTPCode(action) {
    yield put(dashboardActions.setShowLoader());
    try {
        yield put(startAsyncValidation(action.payload.form));
        let url = route.WEB_API_USER_VERIFY_CODE_EMAIL_OTP_VERIFICATION.replace(/:userId/, action.payload.userId);
        let response = yield webapi({
            method: "post",
            url,
            data: {
                verificationCode: action.payload.verificationCode,
                isMobile: false
            }
        });
        yield put(dashboardActions.unsetShowLoader());
        let isEmailValidated = false;

        if(response.data.responseMessage === constant.PHONE_VERIFICATION_STATUS_MESSAGE.PHONE_VERIFICATION_STATUS_INVALID_CODE ||
            response.data.responseMessage === constant.PHONE_VERIFICATION_STATUS_MESSAGE.PHONE_VERIFICATION_STATUS_CODE_EXPIRED) {
            yield put(
                stopAsyncValidation(action.payload.form, {
                    [action.payload.field]: errorMessages.PHONE_VERIFICATION_CODE_INVALID_CODE
                })
            );
            yield put(authActions.submitOtpLoginVerificationCodeIncorrect());
            yield put(trackingActions.submitOTPEmailVerificationPage(response.data.responseMessage));
        } else if (response.data.responseMessage === constant.PHONE_VERIFICATION_STATUS_MESSAGE.PHONE_VERIFICATION_STATUS_SUCCESS){
            isEmailValidated = true;
            yield put(authActions.isEmailVerificationSuccess(isEmailValidated));
            if (routeutility.isAnyOfDashboardPage(window.location.href)){
                if (action.payload.prevPath === route.DASHBOARD_VIEW_ACCOUNT_PAGE) {
                    yield put(push(route.DASHBOARD_VIEW_ACCOUNT_PAGE));
                } else {
                    yield put(push(route.DASHBOARD_VIEW_PROFILE_PAGE));
                }
            } else {
                yield call(getLandingPage, {payload: {userId: action.payload.userId, currentPage: route.APPLICATION_OTP_EMAIL_VERIFICATION}});
            }
            yield put(trackingActions.submitOTPEmailVerificationPage(response.data.responseMessage));
        }
    } catch (error) {
        yield put(dashboardActions.unsetShowLoader());
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_OTP_VERIFY_CODE));
    }
}

export function* isEmailVerificationTokenValid(action) {
    let userId = action.payload.userId;
    let token = action.payload.token;
    let url = route.WEB_API_USER_VERIFY_EMAIL.replace(/:userId/, userId).replace(/:token/, token);
    try {
        const state = yield select();
        const ipAddress = yield getIpAddress();

        let data = {
            description: "customer verified email address",
            page: "email_verification",
            organizationId: utility.getOrganizationId(),
            tierKey: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.TIERKEY],
            userName: state[field.REDUCER_NODE_AUTH][field.USERAUTH][field.EMAIL],
            isExternal: !state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_ORGANIC],
            ipAddress
        };

        const response = yield webapi({
            method: "put",
            url,
            data
        });

        if (response.data.tokenValid) {
            yield put(authActions.isEmailVerificationSuccess(true));
            yield put(push(route.LOGIN));
        } else {
            yield put(authActions.isValidEmailVerificationToken(false));
            yield put(push(route.VERIFY_ACCOUNT.replace(/:userId/, response.data.userId)));
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_VERIFY_EMAIL));
    }
}

export function* getLandingPage(action) {
    try {
        let _stateSelector = state => ({
            buildProfile: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE],
            isPhoneVerificationPageSubmitted:
            state[field.REDUCER_NODE_APPLICATION][field.USERLOANAPPINFO][field.ISPHONEVERIFICATIONPAGESUBMITTED],
            isPreApprovedPageSubmitted:
                state[field.REDUCER_NODE_APPLICATION][field.USERLOANAPPINFO][field.ISPREAPPROVEDPAGESUBMITTED],
            isRAL: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.ISRAL],
            isShortForm: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_SHORTFORM],
            mfaContactType: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.MFA_VERIFICATION_CONTACT_TYPE]
        });
        const _state = yield select(_stateSelector);

        //if login and mfa is enabled then session id will not be available for the api call for landingPageUrl
        if (routeutility.isLoginPage(action.payload.currentPage)) {
            const stateValues = yield select();
            let userId = stateValues[field.REDUCER_NODE_AUTH][field.USERID];
            let isMfaEnabled = stateValues[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.USER_MFA_ENABLED];
            if(isMfaEnabled){
                let mfaVerificationPage = _state.mfaContactType === constant.OTP_PROCESSDATA_TYPE.OTP_TYPE_EMAIL ?
                    route.LOGIN_MFA_VERIFICATION_EMAIL_PAGE : route.LOGIN_MFA_VERIFICATION_SMS_PAGE;
                yield put(push(mfaVerificationPage, {isEnable2FAClicked: true}));
                return;
            }
            yield put(dashboardActions.getPersonalInformation(userId));
        }

        let landingPageUrl = action.payload.viaEmail
            ? route.WEB_API_USER_GET_LOC_LANDING_PAGE.replace(/:id/i, action.payload.userId).replace(
                  /:viaEmail/i,
                  action.payload.viaEmail
              ) + _state.buildProfile
            : route.WEB_API_USER_GET_LANDING_PAGE.replace(/:id/i, action.payload.userId) + _state.buildProfile;
        const response = yield webapi({
            method: "get",
            url: landingPageUrl
        });
        // If any of application page, then only load the state of application in the application redutermincer.
        if (
            routeutility.isAnyOfApplicationPage(response.data.landingPage) ||
            routeutility.isAnyOfDashboardPage(response.data.landingPage)
        ) {
            yield put(authActions.getLandingPageSuccess(response));
            if (response.data.customerActiveLoanDetails) {
                yield put(loanActions.updateActiveLoanDetails(response));
            }
        }

        let landingPage = response.data.landingPage;
        let currentPage = action.payload.currentPage;

        // TKP-587: Enforce payment schedule on SF (RAL variation)
        if (_state.isRAL && _state.isShortForm && landingPage === route.APPLICATION_BANKING_INFO_PAGE)
        {
            landingPage = route.APPLICATION_PAYMENT_SCHEDULE_PAGE;
        }

        if (routeutility.isLoginPage(currentPage)) {
            yield put(trackingActions.submitLoginPage());
        }

        if (currentPage === route.DASHBOARD_RELOAN_PAGE || currentPage === route.REAPPLY_EMPLOYMENT_INFO_PAGE) {
            yield put(push(landingPage));
        } else if ((routeutility.isLoanDocsPage(landingPage) || routeutility.isAnyOfApplicationPage(currentPage)) && !routeutility.isBeforeLandingLogic(currentPage)) {
            // If external flow, then trigger getting external customer application information
            if (response.data.isOrganic === false) {
                yield put(applicationActions.getExternalCustomerAppInfo(response.data.customerId));
            }
            yield put(push(landingPage));
        } else if (routeutility.isAnyOfApplicationPage(landingPage) && routeutility.isAnyOfDashboardPage(currentPage) && !routeutility.isBeforeLandingLogic(currentPage)) {
            // If phone verification or preapproved page then check whether it is already submitted in this flow.
            if (
                !(
                    landingPage === route.APPLICATION_EXTERNAL_PHONE_VERIFICATION_PAGE &&
                    _state.isPhoneVerificationPageSubmitted
                ) &&
                !(landingPage === route.APPLICATION_PRE_APPROVED_PAGE && _state.isPreApprovedPageSubmitted)
            ) {
                if (response.data.isOrganic === false) {
                    yield put(applicationActions.getExternalCustomerAppInfo(response.data.customerId));
                }
                yield put(push(landingPage));
            }
        }
        // if currentpage is login page, then we want to redirect customer to landing page
        else if (routeutility.isLoginPage(currentPage) || routeutility.isBeforeLandingLogic(currentPage)) {
            const stateValues = yield select();
            let isMfaEnabled = stateValues[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.USER_MFA_ENABLED];
            let isEmailVerificationOtpEnabled = stateValues[field.REDUCER_NODE_AUTH][field.ORGANIZATION.IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED];
            let isEmailVerified = stateValues[field.REDUCER_NODE_AUTH][field.ISEMAILVERIFIED];
            let userId = stateValues[field.REDUCER_NODE_AUTH][field.USERID];

            if(routeutility.isAnyOfDashboardPage(response.data.landingPage)){
                if (isMfaEnabled) {
                    let mfaVerificationPage = _state.mfaContactType === constant.OTP_PROCESSDATA_TYPE.OTP_TYPE_EMAIL ?
                        route.LOGIN_MFA_VERIFICATION_EMAIL_PAGE : route.LOGIN_MFA_VERIFICATION_SMS_PAGE;
                    yield put(push(mfaVerificationPage, {isEnable2FAClicked: true}));
                } else if (isEmailVerificationOtpEnabled && !isEmailVerified && !action.payload.pageSkip) {
                    yield put(push(route.APPLICATION_OTP_EMAIL_VERIFICATION));
                } else if (currentPage === route.OTP_EMAIL_LOGIN_PAGE && response.data.isPasswordTemporary && response.data.customerActiveLoanDetails.loanStatus === Loan.WITHDRAW) {
                    yield put(push(route.DASHBOARD_UPDATE_PASSWORD));

                } else if (response.data.customerActiveLoanDetails &&
                    utility.isPostOriginationLoanStatus(response.data.customerActiveLoanDetails.loanStatus) &&
                    isEmailVerified
                ) {
                    let url = route.WEB_API_CHECK_IF_MFA_SHOW_NOTIFICATION.replace(/:userId/i, userId);
                    const response = yield call(webapi, {
                        method: "post",
                        url: url,
                        data: userId
                    });
                    yield put(applicationActions.showMfaNotificationSuccess(response));
                    if (response.data)
                        yield put(push(route.LOGIN_MFA_SET_UP_PAGE, {isPasswordVerified: true}));
                    else
                        yield put(push(landingPage));
                } else {
                    yield put(push(landingPage));
                }
            } else {
                if (isEmailVerificationOtpEnabled && !isEmailVerified && !action.payload.pageSkip) {
                    yield put(push(route.APPLICATION_OTP_EMAIL_VERIFICATION));
                } else {
                    yield put(push(landingPage));
                }
            }
        }

        yield put(errorActions.clearApiError(null, field.ERROR_GET_LANDING_PAGE));
        yield put(authActions.isEmailVerificationSuccess(false));
        yield put(authActions.updatePasswordSuccess(false));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_LANDING_PAGE));
    }
}
export function* trackToastMessageForMfa(action) {
    let toastMessage = action.payload.message;
    let messageType = action.payload.messageType;
    let key = action.payload.key;

    if (messageType === constant.MESSAGE_TYPE_ERROR) {
        yield put(errorActions.createApiError(toastMessage, key));
        toastMessage = getAPIErrorMessage(toastMessage);
    } else {
        styledToast.success(toastMessage, {autoClose: 5000});
    }

    }

export function* sendNotifications(action) {
    if (!utility.isNullOrUndefined(action.payload.customerId) && onesignalTemplate.ONESIGNAL_API_ENABLED) {
        const param = {
            app_id: pushNotification.getOnesignalAppId(action.payload.buildProfile),
            // external_id: pushNotification.getOnesignalAppId(action.payload.buildProfile),
            include_external_user_ids: [(action.payload.customerId).toString()],
            included_segments: [onesignalTemplate.COMPLETE_APPLICATION],
            template_id: pushNotification.getTemplateKey("", onesignalTemplate.COMPLETE_APPLICATION),
        }
        yield fetch(route.ONE_SIGNAL_CREATE_PUSH_NOTIFICATION, {
            method: "post", body: JSON.stringify(param), headers: {
                "Authorization": "Basic " + pushNotification.getRestAPIKey(),
                "accept": "application/json",
                "content-type": "application/json"
            }
        }).then(response => {
        });
    }
}
