import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import binlookup from 'binlookup'

import * as constant from '../../shared/constant/constant'
import * as errorMessages from '../../shared/constant/errorMessages'
import {API_ERROR_CODE} from '../constant/apiErrorCodes'
import {API_ERROR_MESSAGE} from '../constant/apiErrorMessage'
import * as field from '../constant/field'
import {STATE_CODES} from '../constant/stateCodes'
import Organization from '../../models/Organization'
import * as employmentInfoConstants from '../../views/application/employmentinfo/utils/employmentInfoConstants'
import * as payFrequency from '../../views/application/paymentschedule/components/constants/choices/payfrequency'
import * as Loan from '../constant/loan'
import {Loan as loan} from '../constant/loan'
import * as sections from '../../views/application/paymentschedule/components/constants/sections'
import * as monthlyConstants from '../../views/application/paymentschedule/components/constants/choices/monthly'
import * as route from "../constant/route";

const locLowerFeesEnabledOrgIds = [constant.CREDITFRESH_ORGANIZATION_ID, constant.MONEYKEY_PLUS_ORGANIZATION_ID, constant.CREDITFRESH_PLUS_ORGANIZATION_ID, constant.MONEYKEY_ORGANIZATION_ID];
/**
 * Return base url for the website
 * @returns {string}
 */
export const getBaseURL = () => {
    let apiEndPoint = Organization.getApiEndPoint();
    let baseURL = window.location.origin;

    // If API Endpoint is not configured then only try to manipulate apiEndpoint
    if (!apiEndPoint) {
        // Change apiEndpoint for localhost
        if (baseURL.indexOf("localhost") >= 0) {
            apiEndPoint = constant.TOMCAT_LOCALHOST_BASE_URL;
        } else if (baseURL.indexOf("-") >= 0) {
            // Change API Endpoint for QA sites.
            // For qaweb-secure.creditfresh.com, api will be qaweb-api.moneykey.com
            apiEndPoint = window.location.origin.split("-")[0] + "-api.moneykey.com";
        }
    }

    return apiEndPoint;
};

/**
 * This method sanitize the user inputs to match with server expectations.
 * @param userInfo
 */
export const sanitizeUserInfo = (userInfo, isOrganic) => {
    // Replace "-" with blank string
    if (userInfo[field.SSN]) userInfo[field.SSN] = stringTrimAndRemoveDashes(userInfo[field.SSN]);

    if (userInfo[field.PHONE_NUMBER])
        userInfo[field.PHONE_NUMBER] = stringTrimAndRemoveDashes(userInfo[field.PHONE_NUMBER]);

    if (userInfo[field.STATE] && userInfo[field.STATE].length > 2)
        userInfo[field.STATE] = _.invert(STATE_CODES)[userInfo[field.STATE]];

    if (userInfo[field.ADDRESS]) userInfo[field.ADDRESS] = stringTrim(userInfo[field.ADDRESS]);

    if (userInfo[field.ADDRESS2]) userInfo[field.ADDRESS2] = stringTrim(userInfo[field.ADDRESS2]);

    if (userInfo[field.CITY]) userInfo[field.CITY] = stringTrim(userInfo[field.CITY]);

    if (!_.isEmpty(userInfo["choices"])) {
        let dom = normalizeDom(userInfo["choices"]);

        if (!_.isEmpty(dom)) {
            if (isOrganic) {
                userInfo["dom"] = dom;
            } else {
                if (dom.match(/dom:.*:/)) {
                    let doms = dom.split(':').slice(1);
                    userInfo["dom1"] = "dom:" + doms[0];
                    userInfo["dom2"] = "dom:" + doms[1];
                } else if (dom.match(/dom:.*/)) {
                    userInfo["dom1"] = dom;
                } else if (dom.match(/wom.*/)) {
                    userInfo["dom1"] = dom;
                } else {
                    let choices = userInfo["choices"];
                    if (choices[sections.SECTION_WEEKLY_DAYOFTHEWEEK]) {
                        userInfo["dow"] = choices[sections.SECTION_WEEKLY_DAYOFTHEWEEK];
                    } else if (choices[sections.SECTION_BIWEEKLY_DAYOFTHEWEEK]) {
                        userInfo["dow"] = choices[sections.SECTION_BIWEEKLY_DAYOFTHEWEEK];
                    }
                }
            }

        }
    }

    userInfo[field.ORGANIZATION.ORGANIZATION_ID] = getOrganizationId();

    return userInfo;
};

/**
 * This method returns the organization id.
 *Temporarily, it returns 1, but evenually it will have a logic.
 * @returns {number}
 */
export const getOrganizationId = () => {
    return Organization.getOrganizationId();
};

export const getOrganizationName = () => {
    return Organization.getOrganizationName();
};

export const newComponentSupportedOrganizations = () => {
    return [constant.FORA_ORGANIZATION_ID, constant.KOHO_ORGANIZATION_ID, constant.PATHWARD_ORGANIZATION_ID];
};

export const isOrgSupportNewComponent = (component) => {
    return newComponentSupportedOrganizations().includes(getOrganizationId());
};

export const getCancelDrawPath = () => {
    return getOrganizationId() === constant.PATHWARD_ORGANIZATION_ID ? route.DASHBOARD_CANCEL_ADVANCE_REQUEST : route.DASHBOARD_CANCEL_DRAW_REQUEST;
}

export const getCancelDrawPage = () => {
    return getOrganizationId() === constant.PATHWARD_ORGANIZATION_ID ? route.DASHBOARD_CANCEL_ADVANCE_REQUEST_PAGE : route.DASHBOARD_CANCEL_DRAW_REQUEST_PAGE;
}

export const isReapplyButtonDisabledForOrg = (orgId) => {
    return false;
}

export const getOrganizationTierKey = (profile, testTierKey, prodTierKey) => {
    if (profile === constant.PROFILE_PRODUCTION) {
        return prodTierKey;
    } else {
        return testTierKey;
    }
};

export const isOldSupportedState = state => ["WI"].indexOf(state) > -1;

export const isCCBState = state => ["AZ", "OK", "LA"].indexOf(state) > -1;

export const getCCBUrl = (state, buildProfile, didPasswordReset, isLogin = false, orgId = 1, tierKey, subId, subId2, subId3,adId, clickId, ranmid, ransiteid, utmCampaign, gclId, fbclId, cqSrc, email) => {
    let tierkeyParam = tierKey ? `&tierkey=${tierKey}&subid=${getNonNullStringValue(subId)}&subid2=${getNonNullStringValue(subId2)}&subid3=${getNonNullStringValue(subId3)}&adid=${getNonNullStringValue(adId)}&clickid=${getNonNullStringValue(clickId)}&ranMID=${getNonNullStringValue(ranmid)}&ranSiteID=${getNonNullStringValue(ransiteid)}&utm_campaign=${getNonNullStringValue(utmCampaign)}&gclid=${getNonNullStringValue(gclId)}&fbclid=${getNonNullStringValue(fbclId)}&cq_src=${getNonNullStringValue(cqSrc)}` : "";
    let page = isLogin ? `login?didPasswordReset=${didPasswordReset}&email=${email}` + tierkeyParam : `apply/stateinfo?state=${state}` + tierkeyParam;
    let urlParams = getUrlParameters(orgId);
    if (buildProfile === "production") {
        return `https://${urlParams.secure}.${urlParams.appName}.com/${page}`;
    }
    else if (buildProfile === "beta"){
        return `https://${urlParams.securebeta}.${urlParams.appName}.com/${page}`;
    } else if (buildProfile === "dr") {
        return `https://${urlParams.securedr}.${urlParams.appName}.com/${page}`;
    } else if (buildProfile === "staging") {
        return `https://${urlParams.securestg}.${urlParams.appName}.com/${page}`;
    } else {
        let domain = window.location.hostname.split("-")[0];
        let port = orgId === constant.CREDITFRESH_ORGANIZATION_ID ? "3000" : "3005";
        return domain === "localhost"
            ? `http://localhost:${port}/${page}`
            : `https://${domain}-${urlParams.secure}.${urlParams.appName}.com/${page}`;
    }
};

export const getStateInfoUrl = (state, buildProfile, orgId, didPasswordReset, isLogin = false, tierKey, subId, subId2, subId3, adId, clickId, ranmid, ransiteid, utmCampaign, gclId, fbclId, cqSrc, email) => {
    let tierkeyParam = tierKey ? `&tierkey=${tierKey}&subid=${getNonNullStringValue(subId)}&subid2=${getNonNullStringValue(subId2)}&subid3=${getNonNullStringValue(subId3)}&adid=${getNonNullStringValue(adId)}&clickid=${getNonNullStringValue(clickId)}&ranMID=${getNonNullStringValue(ranmid)}&ranSiteID=${getNonNullStringValue(ransiteid)}&utm_campaign=${getNonNullStringValue(utmCampaign)}&gclid=${getNonNullStringValue(gclId)}&fbclid=${getNonNullStringValue(fbclId)}&cq_src=${getNonNullStringValue(cqSrc)}` : "";
    let urlParams = getUrlParameters(orgId);
    let page = isLogin ? `login?didPasswordReset=${didPasswordReset}&email=${email}` + tierkeyParam : `apply/stateinfo?state=${state}` + tierkeyParam;
    if (buildProfile === "production") {
        return `https://${urlParams.secure}.${urlParams.appName}.com/${page}`;
    } else if (buildProfile === "beta") {
        return `https://${urlParams.securebeta}.${urlParams.appName}.com/${page}`;
    } else if (buildProfile === "dr") {
            return `https://${urlParams.securedr}.${urlParams.appName}.com/${page}`;
    } else if (buildProfile === "staging") {
            return `https://${urlParams.securestg}.${urlParams.appName}.com/${page}`;
    } else {
        let domain = window.location.hostname.split("-")[0];
        return domain === "localhost"
            ? `http://localhost:${urlParams.port}/${page}`
            : `https://${domain}-${urlParams.secure}.${urlParams.appName}.com/${page}`;
    }
};

export const invokeAutoLogin = (profile, orgId) => {
    let urlParams = getUrlParameters(orgId);
    let url;
    if (profile === "production") {
        url = `https://${urlParams.secure}.${urlParams.appName}.com/login`;
    } else if (profile === "beta") {
        url = `https://${urlParams.securebeta}.${urlParams.appName}.com/login`;
    } else if (profile === "dr") {
        url = `https://${urlParams.securedr}.${urlParams.appName}.com/login`;
    } else if (profile === "staging") {
        url = `https://${urlParams.securestg}.${urlParams.appName}.com/login`;
    } else {
        let domain = window.location.hostname.split("-")[0];
        url =
            domain === "localhost"
                ? `http://localhost:${urlParams.port}/login`
                : `https://${domain}-${urlParams.secure}.${urlParams.appName}.com/login`;
    }
    window.location.href = url;
};

export const getUrlParameters = (orgId) =>
{
    let port, appName, secure, securebeta, securedr, securestg;
    switch (orgId)
    {
        case constant.CREDITFRESH_ORGANIZATION_ID:
            port = "3000";
            appName = "creditfresh";
            secure = "secure";
            securebeta = "securebeta";
            securedr = "securedr";
            securestg = "securestg";
            break;
        case constant.CREDITFRESH_PLUS_ORGANIZATION_ID:
            port = "3009";
            appName = "creditfresh";
            secure = "secureplus";
            securebeta = "secureplusbeta";
            securedr = "secureplusdr";
            securestg = "secureplusstg";
            break;
        case constant.MONEYKEY_ORGANIZATION_ID:
            port = "3002";
            appName = "moneykey";
            secure = "secure";
            securebeta = "securebeta";
            securedr = "securedr";
            securestg = "securestg";
            break;
        case constant.MONEYKEY_PLUS_ORGANIZATION_ID:
            port = "3005";
            appName = "moneykey";
            secure = "secureplus";
            securebeta = "secureplusbeta";
            securedr = "secureplusdr";
            securestg = "secureplusstg";
            break;
        case constant.FORA_ORGANIZATION_ID:
            port = "3007";
            appName = "foracredit";
            secure = "secure";
            securebeta = "securebeta";
            securedr = "securedr";
            securestg = "securestg";
            break;
        case constant.PATHWARD_ORGANIZATION_ID:
            port = "3011";
            appName = "pathward";
            secure = "secure";
            securebeta = "securebeta";
            securedr = "securedr";
            securestg = "securestg";
            break;
        case constant.KOHO_ORGANIZATION_ID:
            port = "3013";
            appName = "line-of-credit.koho";
            secure = "secure";
            securebeta = "securebeta";
            securedr = "securedr";
            securestg = "securestg";
            break;
        default:
            console.log("Invalid orgID: " + orgId);
    }
    let urlParams = {};
    urlParams.port = port;
    urlParams.appName = appName;
    urlParams.secure = secure;
    urlParams.securebeta = securebeta;
    urlParams.securedr = securedr;
    urlParams.securestg = securestg;

    return urlParams;
}

export const initiateCrossRal = (state, email, orgId, profile) => {
    let urlParams = getUrlParameters(orgId);
    let url;
    if (profile === "production") {
        url = `https://${urlParams.secure}.${urlParams.appName}.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
    } else if (profile === "beta") {
        url = `https://${urlParams.securebeta}.${urlParams.appName}.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
    } else if (profile === "dr") {
        url = `https://${urlParams.securedr}.${urlParams.appName}.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
    } else if (profile === "staging") {
        url = `https://${urlParams.securestg}.${urlParams.appName}.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
    } else {
        let domain = window.location.hostname.split("-")[0];
        url =
            domain === "localhost"
                ? `http://localhost:${urlParams.port}/apply/stateinfo?state=${state}&email=${email}&isXRal=true`
                : `https://${domain}-${urlParams.secure}.${urlParams.appName}.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
    }
    window.location.href = url;
}

export const initiateCrossOrgForForaAndKoho = (orgId, profile) => {
    let urlParams = getUrlParameters(orgId);
    let url;
    if (profile === "production") {
        url = `https://${urlParams.secure}.${urlParams.appName}.ca/login`;
    } else if (profile === "beta") {
        url = `https://${urlParams.securebeta}.${urlParams.appName}.ca/login`;
    } else if (profile === "dr") {
        url = `https://${urlParams.securedr}.${urlParams.appName}.ca/login`;
    } else if (profile === "staging") {
        url = `https://${urlParams.securestg}.${urlParams.appName}.ca/login`;
    } else {
        let domain = window.location.hostname.split("-")[0];
        url =
            domain === "localhost"
                ? `http://localhost:${urlParams.port}/login`
                : `https://${domain}-${urlParams.secure}.${urlParams.appName}.ca/login`;
    }
    window.location.href = url;
}

export const getRedirectUrl = (url, profile, state, email) => {
    let target;
    switch (url){
        case "CBW":
            if (profile === "production") {
                target = `https://secure.creditfresh.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
            } else if (profile === "beta") {
                target = `https://securebeta.creditfresh.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
            } else if (profile === "dr") {
                target = `https://securedr.creditfresh.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
            } else if (profile === "staging") {
                target = `https://securestg.creditfresh.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
            } else {
                let domain = window.location.hostname.split("-")[0];
                target =
                    domain === "localhost"
                        ? `http://localhost:3000/apply/stateinfo?state=${state}&email=${email}&isXRal=true`
                        : `https://${domain}-secure.creditfresh.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
            }
            return target;
        case "FEB":
            if (profile === "production") {
                target = `https://secureplus.creditfresh.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
            } else if (profile === "beta") {
                target = `https://secureplusbeta.creditfresh.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
            } else if (profile === "dr") {
                target = `https://secureplusdr.creditfresh.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
            } else if (profile === "staging") {
                target = `https://secureplusstg.creditfresh.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
            } else {
                let domain = window.location.hostname.split("-")[0];
                target =
                    domain === "localhost"
                        ? `http://localhost:3009/apply/stateinfo?state=${state}&email=${email}&isXRal=true`
                        : `https://${domain}-secureplus.creditfresh.com/apply/stateinfo?state=${state}&email=${email}&isXRal=true`;
            }
            return target;
        default: return url;
    }
}

/**
 *  This method returns the list of consent documents to sign on createPassword Page (Organic flow and
 * createAccount Page (External Flow).
 *
 */
export const getConsentDocumentsListOnAccountCreation = (state = "") => {
    let documents = [constant.ECONSENT, constant.TERMS_OF_USE, constant.CONSENT_PRIVACY_POLICY, constant.JURY_TRIAL];
    if (getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID) {
        if (state === "DE") {
            documents = [...documents, constant.DELAWARE_CONSENT];
        }
    } else if (getOrganizationId() === constant.CREDITFRESH_ORGANIZATION_ID) {
        documents = [...documents, constant.BANK_PRIVACY_POLICY];
    } else if (getOrganizationId() === constant.MONEYKEY_PLUS_ORGANIZATION_ID) {
        documents = [...documents, constant.BANK_PRIVACY_POLICY, constant.PARTNER_PRIVACY_POLICY];
    } else if (getOrganizationId() === constant.CREDITFRESH_PLUS_ORGANIZATION_ID) {
        documents = [...documents, constant.BANK_PRIVACY_POLICY];
    } else if (isForaCredit()) {
        documents = [constant.ECONSENT, constant.TERMS_OF_USE, constant.CONSENT_PRIVACY_POLICY];
    }

    return documents;
};

export const getConsentDocumentsListWithNamesOnAccountCreation = (state = "") => {

    let mapDocTypeAndName = {};
    if (getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID) {
        mapDocTypeAndName[constant.ECONSENT] = constant.ECONSENT;
        mapDocTypeAndName[constant.TERMS_OF_USE] = constant.TERMS_OF_USE;
        mapDocTypeAndName[constant.CONSENT_PRIVACY_POLICY] = constant.CONSENT_PRIVACY_POLICY;
        mapDocTypeAndName[constant.JURY_TRIAL] = constant.JURY_TRIAL;
        if (state === constant.STATE_DELAWARE) {
            mapDocTypeAndName[constant.DELAWARE_CONSENT] = constant.DELAWARE_CONSENT;
        }
    } else if (getOrganizationId() === constant.CREDITFRESH_ORGANIZATION_ID) {
        mapDocTypeAndName[constant.ECONSENT] = constant.CF_ECONSENT;
        mapDocTypeAndName[constant.TERMS_OF_USE] = constant.CF_TERMS_OF_USE;
        mapDocTypeAndName[constant.CONSENT_PRIVACY_POLICY] = constant.CF_CONSENT_PRIVACY_POLICY;
        mapDocTypeAndName[constant.BANK_PRIVACY_POLICY] = constant.CF_BANK_PRIVACY_POLICY;
        if (isCBWCFFSupportedState(state, getOrganizationId())) {
            mapDocTypeAndName[constant.JURY_TRIAL] = constant.CFF_JURY_TRIAL;
        } else {
            mapDocTypeAndName[constant.JURY_TRIAL] = constant.CF_JURY_TRIAL;
        }
    } else if (getOrganizationId() === constant.MONEYKEY_PLUS_ORGANIZATION_ID) {
        mapDocTypeAndName[constant.ECONSENT] = constant.ECONSENT;
        mapDocTypeAndName[constant.TERMS_OF_USE] = constant.TERMS_OF_USE;
        mapDocTypeAndName[constant.CONSENT_PRIVACY_POLICY] = constant.CONSENT_PRIVACY_POLICY;
        mapDocTypeAndName[constant.JURY_TRIAL] = constant.JURY_TRIAL;
        mapDocTypeAndName[constant.BANK_PRIVACY_POLICY] = constant.CCF_BANK_PRIVACY_POLICY;
        mapDocTypeAndName[constant.PARTNER_PRIVACY_POLICY] = constant.TLS_PRIVACY_POLICY;
    } else if (getOrganizationId() === constant.CREDITFRESH_PLUS_ORGANIZATION_ID) {
        mapDocTypeAndName[constant.ECONSENT] = constant.ECONSENT;
        mapDocTypeAndName[constant.TERMS_OF_USE] = constant.TERMS_OF_USE;
        mapDocTypeAndName[constant.CONSENT_PRIVACY_POLICY] = constant.CONSENT_PRIVACY_POLICY;
        mapDocTypeAndName[constant.BANK_PRIVACY_POLICY] = constant.FEB_BANK_PRIVACY_POLICY;
        if (isFEBCFFSupportedState(state, getOrganizationId())) {
            mapDocTypeAndName[constant.JURY_TRIAL] = constant.CFF_FEB_JURY_TRIAL;
        } else {
            mapDocTypeAndName[constant.JURY_TRIAL] = constant.JURY_TRIAL;
        }
    } else if (isForaCredit()) {
        mapDocTypeAndName[constant.ECONSENT] = constant.ECONSENT;
        mapDocTypeAndName[constant.TERMS_OF_USE] = constant.TERMS_OF_USE;
        mapDocTypeAndName[constant.CONSENT_PRIVACY_POLICY] = constant.CONSENT_PRIVACY_POLICY;
    }
    return mapDocTypeAndName;
};

export const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
};

/**
 * Convert input YYYY-MM-DD format date to LL format
 * For ex: 2018-08-11 is converted to August 11, 2018
 * @param dateString
 * @returns {string}
 */
export const toDateFormatMMMDDYYYY = dateString => {
    if (dateString) {
        return moment(dateString).format("LL");
    }
};

/**
 * Convert input YYYY-MM-DD format date to MMM D, YYYY format
 * For ex: 2018-08-11 is converted to Aug 11, 2018
 * @param dateString
 * @returns {string}
 */
export const toDateFormatMonDYYYY = dateString => {
    if (dateString) {
        return moment(dateString).format("MMM D, YYYY");
    }
};

/**
 * Convert input YYYY-MM-DD format date to LLL format
 * For ex: 2019-03-18T12:47:28 is converted to March 18, 2019 12:47 PM.
 * @param dateString
 * @returns {string}
 */
export const toDateFormatMMMDDYYYYHHMM = dateString => {
    if (dateString) {
        return moment(dateString).format("LLL");
    }
};

/**
 * Convert input DateString format date to HH:MM AM/PM format
 * For ex: 2019-03-18T12:47:28 is converted to 12:47 PM.
 * @param dateString
 * @returns {string}
 */
export const toTimeFormatHHMM = dateString => {
    if (dateString) {
        return moment(dateString).format("HH:mm A");
    }
};

/**
 * Convert input YYYY-MM-DD format date to LL format
 * For ex: 2018-08-24 is converted to 08/24/2018
 * @param dateString
 * @returns {string}
 */
export const toDateFormatMMDDYYYY = dateString => {
    if (dateString) {
        return moment(dateString).format("L");
    }
    return "";
};

export const getNonNullStringValue = val => {
    if(typeof val === "undefined" || val === undefined || val === null)
        return "";
    else
        return val;
}

export const formatPhoneNumber = phoneNumberString => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
};

/**
 * Format input value to 2 scale value.
 * For ex: 23 will be converted to 23.00
 * @param value
 */
export const toNumberFormatScale2 = value => {
    let returnValue = "";

    // If truthy value
    if (value !== null && value !== undefined) {
        // Convert input to number
        let numberValue = _.toNumber(value);
        // If converted value is number
        if (_.isNumber(numberValue)) {
            // Format it to scale of 2
            returnValue = parseFloat(numberValue).toFixed(2);
        }
    }

    return returnValue;
};

export const toNumberFormatScale4 = value => {
    let returnValue = "";

    // If truthy value
    if (value !== null && value !== undefined) {
        // Convert input to number
        let numberValue = _.toNumber(value);
        // If converted value is number
        if (_.isNumber(numberValue)) {
            // Format it to scale of 2
            returnValue = parseFloat(numberValue).toFixed(4);
        }
    }

    return returnValue;
};

/**
 * Function to return a number with 2 scale and $ appended.
 * For ex: for 123.4 it will return $123.40
 * and for "" it will return ""
 * @param value
 * @returns {*}
 */
export const displayAmount = value => {
    value = toNumberFormatScale2(value);
    if (value) return numeral(value).format("$0,0.00");
    else return value;
};

export const displayAmountSimple = value => {
    value = toNumberFormatScale2(value);
    if (value) return numeral(value).format("$0,0");
    else return value;
};

/**
 * Function that returns the american spelling of a word
 * @param value
 * @returns {*}
 */
export const americanizeSpelling = value => {
    if (value === "cancelled") return "canceled";
    else return value;
};

/**
 * Error Reducer holds the complete error object from api.
 * This method returns the error message from the error object
 * @param error api error object
 * @returns {*}
 */
export const getAPIErrorMessage = error => {
    if(!error || !error.response || !error.response.status) {
        return error.response.data.message;
    }
    switch (error.response.status) {
        case API_ERROR_CODE.ERROR_400:
            if (/Token Expired/i.test(getAPIResponseErrorMessage(error)))
                return API_ERROR_MESSAGE.API_ERROR_JWT_TOKEN_EXPIRED;
            if (/Token no longer valid/i.test(getAPIResponseErrorMessage(error)))
                return API_ERROR_MESSAGE.API_ERROR_JWT_TOKEN_NO_LONGER_VALID;

            return API_ERROR_MESSAGE.API_ERROR_400_MEESAGE;
        case API_ERROR_CODE.ERROR_401:
            return API_ERROR_MESSAGE.API_ERROR_401_MEESAGE;
        case API_ERROR_CODE.ERROR_403:
            return API_ERROR_MESSAGE.API_ERROR_403_MEESAGE;
        case API_ERROR_CODE.ERROR_404:
            return API_ERROR_MESSAGE.API_ERROR_404_MEESAGE;
        case API_ERROR_CODE.ERROR_500:
            return API_ERROR_MESSAGE.API_ERROR_500_MESSAGE;
        case API_ERROR_CODE.ERROR_502:
            return API_ERROR_MESSAGE.API_ERROR_502_MESSAGE;
        case API_ERROR_CODE.ERROR_406:
            return API_ERROR_MESSAGE.API_ERROR_DOC_UPLOAD_SCAN;
        default:
            return error.response.data.message;
    }
};

export const getAPIErrorPayload = error => {
    return {
        response: {
            data: {
                message: error
            }
        }
    };
};

/**
 * Get status of api response
 * @param apiObject
 * @returns {*}
 */
export const getAPIResponseStatus = apiObject => {
    if (apiObject && apiObject.response) {
        return apiObject.response.status;
    } else {
        return null;
    }
};

/**
 * Get error message if present from api response.
 * @param apiObject
 * @returns {null|*}
 */
export const getAPIResponseErrorMessage = apiObject => {
    if (apiObject && apiObject.response && apiObject.response.data && apiObject.response.data.message) {
        return apiObject.response.data.message;
    } else {
        return null;
    }
};

/**
 * Get error code present in error api response.
 * @param errObject
 * @returns {null|*}
 */
 export const getResponseErrorCode = errObject => {
    if (errObject && errObject.response && errObject.response.data && errObject.response.data.errors && errObject.response.data.errors[0]) {
        return errObject.response.data.errors[0].errorCode;
    } else {
        return null;
    }
};

/**
 * Get error object woith status and response data added for Timeout error in document upload.
 * @param error
 * @returns {error}
 */
 export const getErrorObjForTimeout = error => {
    let data = {status: "NETWORK ERROR", message: null};
    //The API returns 502 response(bad gateway) when front end request is timed out
    error.response = {data: data, status: API_ERROR_CODE.ERROR_502};
    return error;
};

export const isToastPopupErrorMessage = errorMessage => {
    let isToastPopupErrorMessage = true;
    if(!errorMessage)
        return isToastPopupErrorMessage;

    _.forEach(errorMessages.IGNORE_TOAST_POPUP_ERROR_MESSAGE_LIST, (ignoreMessage) => {
        if(errorMessage.indexOf(ignoreMessage) >= 0){
            isToastPopupErrorMessage = false;
        }
    });
    return isToastPopupErrorMessage;
}

export const stringRemoveDashes = value => {
    if (!value) return value;

    return value.replace(/-/gi, "");
};

export const stringTrim = value => {
    if (!value) return value;

    return value.trim();
};

export const stringTrimAndRemoveDashes = value => {
    if (!value) return value;

    return stringRemoveDashes(stringTrim(value));
};

export const passthroughDash = value => {
    if (value) return value;

    return (getOrganizationId() === constant.PATHWARD_ORGANIZATION_ID ? "—" : "--");
};

/**
 * Return true if state is supported else false
 * @param isSupported
 * @param isActive
 * @param isPendingLicense
 * @returns {boolean}
 */
export const isStateSupported = (isSupported, isActive, isPendingLicense) => {
    return !!(isSupported && isActive && !isPendingLicense);
};

/**
 * Return true if state is tested before production release
 * @returns {boolean}
 * @param isTest
 * @param tierKey
 * @param testTierKey
 */
export const isTestState = (isTest, tierKey, testTierKey) => {
    return isTest && tierKey === testTierKey;
};

/**
 * This method converts longer doc subject names to shorter version for better readability in tracking tables.
 */
export const getLoanDocsSubjectShortForm = docSubject => {
    if (!docSubject) {
        return;
    }

    if (docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_CUSTOMER_INFO_KEYWORD.toLowerCase()) >= 0) {
        return constant.LOAN_DOC_SUBJECT_CUSTOMER_INFO_SHORTFORM;
    } else if (docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_TERMS_CONDITION_KEYWORD.toLowerCase()) >= 0) {
        return constant.LOAN_DOC_SUBJECT_TERMS_CONDITION_SHORTFORM;
    } else if (
        docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_TRUTH_IN_LENDING_KEYWORD.toLowerCase()) >= 0
    ) {
        return constant.LOAN_DOC_SUBJECT_TRUTH_IN_LENDING_SHORTFORM;
    } else if (docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_ACH_AUTH_KEYWORD.toLowerCase()) >= 0) {
        return constant.LOAN_DOC_SUBJECT_ACH_AUTH_SHORTFORM;
    } else if (docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_ARBITRATION_KEYWORD.toLowerCase()) >= 0) {
        return constant.LOAN_DOC_SUBJECT_ARBITRATION_SHORTFORM;
    } else if (
        docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_CREDIT_SERVICE_DISCLOSURE_KEYWORD.toLowerCase()) >= 0
    ) {
        return constant.LOAN_DOC_SUBJECT_CREDIT_SERVICE_DISCLOSURE_SHORTFORM;
    } else if (
        docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_CREDIT_SERVICES_AGREEMENT_KEYWORD.toLowerCase()) >= 0
    ) {
        return constant.LOAN_DOC_SUBJECT_CREDIT_SERVICES_AGREEMENT_SHORTFORM;
    } else if (docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_PRIVACY_POLICY_KEYWORD.toLowerCase()) >= 0) {
        return constant.LOAN_DOC_SUBJECT_PRIVACY_POLICY_SHORTFORM;
    } else if (docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_LOAN_AGREEMENT_KEYWORD.toLowerCase()) >= 0) {
        return constant.LOAN_DOC_SUBJECT_LOAN_AGREEMENT_SHORTFORM;
    } else if (docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_LINE_OF_CREDIT_AGREEMENT_SCHEDULE_AB.toLowerCase()) >= 0) {
        return constant.LOAN_DOC_SUBJECT_LINE_OF_CREDIT_AGREEMENT_SCHEDULE_AB_SHORTFORM;
    } else if (docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_LINE_OF_CREDIT_AGREEMENT_KEYWORD.toLowerCase()) >= 0)
        return constant.LOAN_DOC_SUBJECT_LINE_OF_CREDIT_AGREEMENT_SHORTFORM;
    else if (docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_DISCLOSURE_STATEMENT.toLowerCase()) >= 0)
        return constant.LOAN_DOC_SUBJECT_DISCLOSURE_STATEMENT_SHORTFORM;
    else if (docSubject.toLowerCase().indexOf(constant.LOAN_DOC_SUBJECT_PAD_AUTHORIZATION.toLowerCase()) >= 0)
        return constant.LOAN_DOC_SUBJECT_PAD_AUTHORIZATION_SHORTFORM;
};

/**
 * Get URL for the resource.
 * @param resourceCDN
 * @param resourceFolderName
 * @param resourceFileName
 * @returns {string}
 */
export const getResourceURL = (resourceCDN, resourceFolderName, resourceFileName) => {
    if (!resourceCDN) {
        return `/resources/images/${resourceFolderName}/${resourceFileName}`;
    } else {
        return `${resourceCDN}/${resourceFolderName}/${resourceFileName}`;
    }
};

// //https://css-tricks.com/snippets/javascript/unescape-html-in-js/
export const htmlDecode = input => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};

export const isEmptyOrNullString = input => {
    return input === undefined || input === null || input === "";
};

export const isNullOrUndefined = input => {
    return input === undefined || input === null;
};

export const isNullOrUndefinedString = input => {
    return input === undefined || input === null || input === "null" || input === "undefined";
};

export const setupSequentialTabNavigationForCurrentPage = formId => {
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }
    document.querySelectorAll("*[tabindex]").forEach(node => (node.tabIndex = -1));

    var formNode = document.querySelector(`#${formId}`);
    if (formNode) {
        var inputs = formNode.querySelectorAll("input, select");
        var currentTabIndex = 1;
        inputs.forEach(i => {
            i.tabIndex = currentTabIndex++;
        });
    }
};

export const setDropDownStyle = () => {
    document.querySelectorAll(".selection-options").forEach(node => {
        let selectNode = node.getElementsByTagName("select")[0];
        selectNode.style.margin = "0.2em 0em 0.1em 0em";
        selectNode.style.paddingLeft = "1em";
        selectNode.style.width = "92%";
    });
};

export const isValidIncomeType = input => {
    return employmentInfoConstants.INCOME_TYPES.indexOf(input) > -1;
};

export const isValidPayType = input => {
    return employmentInfoConstants.PAYMENT_TYPES.indexOf(input) > -1;
};

export const isValidPayFrequency = input => {
    return payFrequency.PAY_FREQUENCY_TYPES.indexOf(input) > -1;
};

export const isValidDebitCard = value => {
    // Accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    let nCheck = 0,
        bEven = false;
    value = value.replace(/\D/g, "");

    for (var n = value.length - 1; n >= 0; n--) {
        var cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);

        if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

        nCheck += nDigit;
        bEven = !bEven;
    }

    return nCheck % 10 === 0;
};

export async function isDebitCard(value) {
    let lookup = binlookup({mode: "no-cors"});
    let data = await lookup(value);
    if (data.type !== "debit") {
        throw errorMessages.INVALID_CARD_NUMBER;
    } else {
        return true;
    }
}

/**
 * Convert input Time format from 00:00:00 to HH:MM AM/PM format
 * For ex: 11:00:00 is converted to 11:00 AM.
 * @param time
 * @returns {string}
 */
export function formatTime(time) {
    var hourEnd = time.indexOf(":");
    var H = +time.substr(0, hourEnd);
    var h = H % 12 || 12;
    var ampm = H < 12 ? " AM" : " PM";
    time = h + time.substr(hourEnd, 3) + ampm;
    return time;
}

export const getStatementDate = (statementId, statements) => {
    const stmt = statements.find(x => x.statementId === parseInt(statementId));
    if (stmt) {
        return stmt.statementDate.split("T")[0];
    } else {
        return null;
    }
};

export const showDEUTMOProductChangePopup = (entityId, isProductChangePopupShown) => {
    if(!entityId || isProductChangePopupShown === null)
        return false;

    if(isProductChangePopupShown === true)
        return false;

    return isDEUTMOOlderEntity(entityId);
};

export const isDEUTMOOlderEntity = (entityId) => {
    if(!entityId)
        return false;

    if(entityId === constant.ENTITY_DE_BSW_NC_SEP || entityId === constant.ENTITY_DE_M_NC_SEP ||
        entityId === constant.ENTITY_DE_BSW_RC_SEP || entityId === constant.ENTITY_DE_M_RC_SEP ||
        entityId === constant.ENTITY_UT_BSW_NC_SEP || entityId === constant.ENTITY_UT_M_NC_SEP ||
        entityId === constant.ENTITY_UT_BSW_RC_SEP || entityId === constant.ENTITY_UT_M_RC_SEP ||
        entityId === constant.ENTITY_MO_BSW_SEP || entityId === constant.ENTITY_MO_M_SEP) {
        return true
    }
}

export const showIDWIProductChangePopup = (entityId, isProductChangePopupShown) => {
    if(!entityId || isProductChangePopupShown === null)
        return false;

    if(isProductChangePopupShown === true)
        return false;

    return isIDWIOlderEntity(entityId);
};

export const isIDWIOlderEntity = (entityId) => {
    if(!entityId)
        return false;

    if(entityId === constant.ENTITY_WI_M_SEP || entityId === constant.ENTITY_WI_BSW_SEP ||
        entityId === constant.ENTITY_ID_BSW_SEP || entityId === constant.ENTITY_ID_M_SEP)  {
        return true
    }
}

export const showTXProductChangePopup = (entityId, isProductChangePopupShown) => {
    if(!entityId || isProductChangePopupShown === null)
        return false;

    if(isProductChangePopupShown === true)
        return false;

    return isTXOlderEntity(entityId);
};

export const isTXOlderEntity = (entityId) => {
    if(!entityId)
        return false;

    if(
        entityId === constant.ENTITY_TX_BAS_BSW_SEP || entityId === constant.ENTITY_TX_BAS_M_SEP ||
        entityId === constant.ENTITY_TX_NCP_M_SEP || entityId === constant.ENTITY_TX_NCP_BSW_SEP ||
        entityId === constant.ENTITY_TX_BAS_BSW_2_SEP || entityId === constant.ENTITY_TX_NCP_BSW_2_SEP ||
        entityId === constant.ENTITY_TX_BAS2_M || entityId === constant.ENTITY_TX_BAS2_NM ||
        entityId === constant.ENTITY_TX_NCP2_M || entityId === constant.ENTITY_TX_NCP2_NM ||
        entityId === constant.ENTITY_TX_NCP_M || entityId === constant.ENTITY_TX_NCP_NM ||
        entityId === constant.ENTITY_TX_BASTION_M_NC || entityId === constant.ENTITY_TX_BASTION_BSW_NC ||
        entityId === constant.ENTITY_TX_CSO_FLEXPAY || entityId === constant.ENTITY_TX_JAG_INSTALL
        )  {
        return true
    }
}

export const showKSProductChangePopup = (entityId, isProductChangePopupShown, hasNewFees) => {
    if(!entityId || isProductChangePopupShown === null || getOrganizationId() !== constant.MONEYKEY_ORGANIZATION_ID )
        return false;

    if(isProductChangePopupShown === true)
        return false;

    return isKSNewFees(entityId, hasNewFees);
};

export const isKSNewFees = (entityId, hasNewFees) => {
    if(!entityId)
        return false;

    if(hasNewFees) {
return true
    }
}

export const showLOCLowerFeesPopup = (entityId, showPopup) => {
    let orgId = getOrganizationId();

    if(!entityId || showPopup === null || !isOrgIdWithLowerFees(orgId))
        return false;

    return showPopup !== false;


};

export const isOrgIdWithLowerFees = (orgId) => {
    return locLowerFeesEnabledOrgIds.includes(orgId);
};

export const isMoneykeySCState = (state) => {
    return getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID && state === constant.STATE_SOUTH_CAROLINA;
}

export const isLOCLoan = (loanType) => {
    if(!loanType)
        return false;

    return loanType === Loan.LoanType.LINE_OF_CREDIT;
}

export const isInstallmentLoan = (loanType) => {
    if(!loanType)
        return false;

    return loanType === Loan.LoanType.INSTALLMENT_LOAN;
}

export const isDefaultLoan = (loanStatus) => {
    if(!loanStatus)
        return false;
    return loanStatus === loan.DEFAULT;
}

export const isDeliquentLoan = (loanStatus) => {
    if(!loanStatus)
        return false;
    return loanStatus === loan.DELINQUENT;
}

export const isOriginatedLoan = (loanStatus) => {
    if(!loanStatus)
        return false;
    return loanStatus === loan.ORIGINATED;
}

export const getApplicationFlowName = (isRC, isRAL) => {
    if (isRAL) {
        return constant.APPFLOWNAME.RAL
    } else if (isRC) {
        return constant.APPFLOWNAME.RC
    } else {
        return constant.APPFLOWNAME.NC
    }
}


export const isStatusClosedOrVoidOrWithdraw = (status) => {
    return status === loan.CLOSED || status === loan.VOID || status === loan.WITHDRAW;
}

export const isPostOriginationLoanStatus = (status) => {
    if(!status)
        return false;

    status = _.toLower(status);
    return status === loan.CREDIT_RETURN || status === loan.VOID || status === loan.CLOSED ||
        status === loan.PAID_OFF || status === loan.PENDING_PAID_OFF || status === loan.ORIGINATED ||
        status === loan.DEFAULT_SIF || status === loan.DEFAULT_PIF || status === loan.DEFAULT_BKC ||
        status === loan.DEFAULT_FRD || status === loan.DEFAULT_SLD || status === loan.DEFAULT ||
        status === loan.DELINQUENT;
}

export const isOrganicRC = (isOrganic, isRC) => {
    return isOrganic && isRC;
}

export const isNumeric = str => {
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const isSEPLF = (isOrganic, loanType, isShortform) => {
    return isOrganic === false && isInstallmentLoan(loanType) && isShortform === false
}

export const isCreditFresh = () => {
    return getOrganizationId() === 2 || getOrganizationId() === 4;
}

export const isMoneykey = () => {
    return getOrganizationId() === 1 || getOrganizationId() === 3;
}

export const isForaCredit = (orgId) => {
    orgId = orgId ? orgId : getOrganizationId();
    return orgId === 5 || orgId === 7;
}

export const isFora = () => {
    return getOrganizationId() === 5;
}

export const isPathward = () => {
    return getOrganizationId() === 6;
}

export const isKohoLineOfCredit = () => {
    return getOrganizationId() === 7;
}

export const redirectToDefaultSLD = entityId => {
    return getOrganizationId() === constant.PATHWARD_ORGANIZATION_ID || isCreditfreshRepaySupportedEntity(entityId);
};

export const queryStringToObject = (queryString) => {
    if (queryString !== ""){
        const pairs = queryString.substring(1).split('&');

        var array = pairs.map((el) => {
            return el.split('=');
        });

        return Object.fromEntries(array);
    } else {
        return false;
    }
}

export const normalizeDom = (choices) => {
    if (!_.isEmpty(choices[sections.SECTION_PAYFREQUENCY])) {
        let frequency = choices[sections.SECTION_PAYFREQUENCY];

        if (frequency === "B" && _.isInteger(choices[sections.SECTION_BIWEEKLY_DAYOFTHEWEEK])) {
            return "dow:" + choices[sections.SECTION_BIWEEKLY_DAYOFTHEWEEK];
        }

        if (frequency === "W" && _.isInteger(choices[sections.SECTION_WEEKLY_DAYOFTHEWEEK])) {
            return "dow:" + choices[sections.SECTION_WEEKLY_DAYOFTHEWEEK];
        }

        if (frequency === "S") {
            if (isForaCredit()) {
                if (frequency === "S" && !_.isEmpty(choices[sections.CANADA_SECTION_SEMIMONTHLY_PAYDAYS])) {
                    let dom1 =
                        choices[sections.CANADA_SECTION_SEMIMONTHLY_PAYDAYS][0] === "Last"
                            ? -1
                            : (choices[sections.CANADA_SECTION_SEMIMONTHLY_PAYDAYS][0] === "First" ? 1 : choices[sections.CANADA_SECTION_SEMIMONTHLY_PAYDAYS][0]);
                    let dom2 =
                        choices[sections.CANADA_SECTION_SEMIMONTHLY_PAYDAYS][1] === "Last"
                            ? -1
                            : (choices[sections.CANADA_SECTION_SEMIMONTHLY_PAYDAYS][1] === "First" ? 1 : choices[sections.CANADA_SECTION_SEMIMONTHLY_PAYDAYS][1]);
                    return "dom:" + dom1 + ":" + dom2;

                }

            } else {
                if (_.isEmpty(choices[sections.SECTION_SEMIMONTHLY_OTHERPAYDAYS])) {
                    return choices[sections.SECTION_SEMIMONTHLY_PAYDAYS];
                } else {
                    let dom1 =
                        choices[sections.SECTION_SEMIMONTHLY_OTHERPAYDAYS][0] === "LAST"
                            ? -1
                            : choices[sections.SECTION_SEMIMONTHLY_OTHERPAYDAYS][0];
                    let dom2 =
                        choices[sections.SECTION_SEMIMONTHLY_OTHERPAYDAYS][1] === "LAST"
                            ? -1
                            : choices[sections.SECTION_SEMIMONTHLY_OTHERPAYDAYS][1];
                    return "dom:" + dom1 + ":" + dom2;
                }
            }
        }

        if (frequency === "M") {
            if (isForaCredit()) {
                if (choices[sections.CANADA_SECTION_MONTHLY_PAYDAY] === monthlyConstants.SAME_DATE_IN_EACH_MONTH) {
                    if (_.isEmpty(choices[sections.CANADA_SECTION_MONTHLY_DAYOFTHEMONTH].toString())) {
                        return choices[sections.CANADA_SECTION_MONTHLY_PAYDAY];
                    } else {
                        let dom =
                            choices[sections.CANADA_SECTION_MONTHLY_DAYOFTHEMONTH] === "Last"
                                ? -1
                                : (choices[sections.CANADA_SECTION_MONTHLY_DAYOFTHEMONTH] === "First" ? 1 : choices[sections.CANADA_SECTION_MONTHLY_DAYOFTHEMONTH]);
                        return "dom:" + dom;
                    }
                } else if ((choices[sections.CANADA_SECTION_MONTHLY_PAYDAY] === monthlyConstants.SAME_DAY_OF_WEEK_EACH_MONTH)) {
                    let week =
                        choices[sections.CANADA_SECTION_MONTHLY_WEEK] === "LAST" ? "L" : choices[sections.CANADA_SECTION_MONTHLY_WEEK];
                    return "wom:" + week + ":" + choices[sections.CANADA_SECTION_MONTHLY_WEEKDAY];
                }
            } else {
                if (_.isEmpty(choices[sections.SECTION_MONTHLY_OTHER])) {
                    return choices[sections.SECTION_MONTHLY_PAYDAY];
                } else if (choices[sections.SECTION_MONTHLY_OTHER] === monthlyConstants.SAME_DATE_EACH_MONTH) {
                    let dom =
                        choices[sections.SECTION_MONTHLY_DAYOFTHEMONTH] === "LAST"
                            ? -1
                            : choices[sections.SECTION_MONTHLY_DAYOFTHEMONTH];
                    return "dom:" + dom;
                } else if (choices[sections.SECTION_MONTHLY_OTHER] === monthlyConstants.SAME_DATE_EACH_WEEK) {
                    let week =
                        choices[sections.SECTION_MONTHLY_WEEK] === "LAST" ? "L" : choices[sections.SECTION_MONTHLY_WEEK];
                    return "wom:" + week + ":" + choices[sections.SECTION_MONTHLY_WEEKDAY];
                }
            }
        }
    }
};

export const isRepayPaymentSupported = (entityId) => {
    return isOrgSupportNewComponent() || (isCreditfreshRepaySupportedEntity(entityId));
}

export const hasNewPaymentScheduleComponent = () => {
    return  isOrgSupportNewComponent();
}

export const hasRentField = () => {
    return getOrganizationId() === constant.PATHWARD_ORGANIZATION_ID;
}

export const showApplicationRequirements = () => {
    let orgId = getOrganizationId();
    return (
        orgId === constant.MONEYKEY_ORGANIZATION_ID ||
        orgId === constant.CREDITFRESH_ORGANIZATION_ID ||
        orgId === constant.MONEYKEY_PLUS_ORGANIZATION_ID ||
        orgId === constant.CREDITFRESH_PLUS_ORGANIZATION_ID ||
        isForaCredit() ||
        orgId === constant.PATHWARD_ORGANIZATION_ID);
}

export const glassboxActiveForOrganization = () => {
    let orgId = getOrganizationId();
    return (orgId === constant.MONEYKEY_ORGANIZATION_ID ||
        orgId === constant.CREDITFRESH_ORGANIZATION_ID ||
        orgId === constant.MONEYKEY_PLUS_ORGANIZATION_ID ||
        orgId === constant.CREDITFRESH_PLUS_ORGANIZATION_ID ||
        isForaCredit() ||
        isKohoLineOfCredit() ||
        orgId === constant.PATHWARD_ORGANIZATION_ID);
}

export const rejectNoRedirect = (stateKey) => {
    let orgId = getOrganizationId();
    const noRedirectMK = orgId === constant.MONEYKEY_ORGANIZATION_ID && stateKey === constant.STATE_CALIFORNIA;

    const noRedirectCF = orgId === (constant.CREDITFRESH_ORGANIZATION_ID || orgId === constant.CREDITFRESH_PLUS_ORGANIZATION_ID) && stateKey === constant.STATE_CALIFORNIA;

    return noRedirectMK || noRedirectCF;
}

export const getReapplyDate = (withdrawnDate) => {
    let withdrawDate = moment(withdrawnDate, "YYYY-MM-DD");
    let reapplyDate = withdrawDate.add(30, 'days').format('MM-DD-YYYY');
    reapplyDate = moment(reapplyDate).format('MM‑DD‑YYYY');
    return reapplyDate;
}

export const maskEmail = (email) => {
    var emailAtIndex = email.indexOf('@');
    var emailLastDotIndex = email.lastIndexOf('.');
    return email.substring(0, 2) + email.substring(2, emailAtIndex).replace(/./g, '*') +
        email.substring(emailAtIndex, emailAtIndex + 2) + email.substring(emailAtIndex + 2, emailLastDotIndex).replace(/./g, '*') +
        email.substring(emailLastDotIndex);
}


export const isCreditfreshRepaySupportedEntity = (entityId) => {
    if(!entityId)
    return false;
    let creditfreshRepaySupportedEntities = [constant.ENTITY_CFF_CBW_M, constant.ENTITY_CFF_CBW_NM, constant.ENTITY_CFF_FEB_M, constant.ENTITY_CFF_FEB_NM]

    if(creditfreshRepaySupportedEntities.includes(entityId)) {
        return true
    }
}

export const isFEBCFFSupportedState = (stateKey, orgId) => {
    if (!stateKey) return false;
    if (orgId !== constant.CREDITFRESH_PLUS_ORGANIZATION_ID) return false;
    const febCFFSupportedStates = [constant.STATE_NY];

    if (febCFFSupportedStates.includes(stateKey) && orgId === constant.CREDITFRESH_PLUS_ORGANIZATION_ID) {
        return true;
    }
}

export const isCBWCFFSupportedState = (stateKey, orgId) => {
    if (!stateKey) return false;
    if (orgId !== constant.CREDITFRESH_ORGANIZATION_ID) return false;
    let cbwCFFSupportedStates = [constant.STATE_GA, constant.STATE_NC, constant.STATE_VA, constant.STATE_NJ, constant.STATE_MT, constant.STATE_WY, constant.STATE_MN, constant.STATE_ND, constant.STATE_NV, constant.STATE_WV];

    if (cbwCFFSupportedStates.includes(stateKey) && orgId === constant.CREDITFRESH_ORGANIZATION_ID) {
        return true;
    }
}

export const getSuperscriptForEffectiveDate = () => {
    let superscript = null;
    switch (getOrganizationId()) {
        case constant.MONEYKEY_ORGANIZATION_ID:
            return superscript = "*";
        case constant.MONEYKEY_PLUS_ORGANIZATION_ID:
            return superscript = "1";
        case constant.CREDITFRESH_ORGANIZATION_ID:
            return superscript = "**";
        case constant.CREDITFRESH_PLUS_ORGANIZATION_ID:
            return superscript = "2";
        case constant.FORA_ORGANIZATION_ID:
        case constant.KOHO_ORGANIZATION_ID:
            return superscript = "3";
        case constant.PATHWARD_ORGANIZATION_ID:
            return superscript = "2";
        default:
            break;
    }
    return superscript;
}

export const getLinkStyleForButton = () => {
    return {
        backgroundColor: "transparent",
        color: "#598CC7",
        display: "inline-block",
        backgroundRepeat: "no-repeat",
        border: "none",
        cursor: "pointer",
        overflow: "hidden",
        outline: "none"
    };
};

export const isOriginatedDefaultOrDelinquent = (status) => {
    if(!status)
        return false;

    return isOriginatedLoan(status) || isDefaultLoan(status) || isDeliquentLoan(status);
}

export const isStandaloneAgreement = (docSubject, docOrder) => {
    return ["Voluntary ACH Authorization", "PAD Authorization"].includes(docSubject) && docOrder === 0;
}

export const scrollToTop = () => {
    try {
        window.scroll(0, 0);
    } catch (e) {
        console.log("Scrolling to top is not working for this browser.");
    };
}

export const getHeaderLogoUrl = (isAuthenticated, resourceCDN) => {
    switch (getOrganizationId()) {
        case constant.MONEYKEY_ORGANIZATION_ID:
            return "https://cdn.moneykey.com/images/logo/brand.svg";
        case constant.MONEYKEY_PLUS_ORGANIZATION_ID:
            return "https://cdn.moneykey.com/images/ccflow-logo.png";
        case constant.CREDITFRESH_ORGANIZATION_ID:
        case constant.CREDITFRESH_PLUS_ORGANIZATION_ID:
            return isAuthenticated ? (window.location.origin.includes("secureplus") || window.location.origin.includes("3009")) ? "https://cdn.creditfresh.com/img/cf-feb-logo.svg" : "https://cdn.creditfresh.com/img/brand.svg" : "https://cdn.creditfresh.com/img/creditfresh-logo.svg";
        case constant.FORA_ORGANIZATION_ID:
            return "https://cdn.foracredit.ca/web/canada-fora-logo.svg";
        case constant.KOHO_ORGANIZATION_ID:
            return getResourceURL(resourceCDN, "koholineofcredit", "canada-koho-logo.svg")
        case constant.PATHWARD_ORGANIZATION_ID:
            return "https://cdn.loc.pathward.com/web/images/pathward-main.png"
        default:
            break;
    }
}

export const getHeaderLogoCrossOrgUrl = (resourceCDN) => {
    switch (getOrganizationId()) {
        case constant.FORA_ORGANIZATION_ID:
            return getResourceURL(resourceCDN, "koholineofcredit", "canada-koho-logo.svg")
        case constant.KOHO_ORGANIZATION_ID:
            return "https://cdn.foracredit.ca/web/canada-fora-logo.svg";
        default:
            break;
    }
}

export const getTargetPath = ()=> {
    return getOrganizationId() === 7? "_self":"_blank"
}
