import _ from "lodash";
import axios from "axios";

import * as actionTypes from "../action/actionTypes";
import * as field from "../../shared/constant/field";
import * as constant from "../../shared/constant/constant";
import {API_ERROR_CODE} from "../../shared/constant/apiErrorCodes";
import * as cookieUtility from "../../shared/utility/cookieutility";
import {setDataTags} from "../../views/OneSignal/PushNotification";
import {IS_LOGIN} from "../../shared/constant/tagConstants";

const initialState = {
    [field.USERAUTH]: {
        [field.EMAIL]: "",
        [field.PASSWORD]: ""
    },
    [field.USERID]: undefined,
    [field.CUSTOMER_ID]: undefined,
    [field.CA_CUSTOMER_ID]: undefined,
    [field.LMS_CODE]: undefined,
    [field.SWITCHED_ORG]: undefined,
    [field.ISAUTHENTICATED]: false,
    [field.MKWEBAUTHTOKEN]: undefined,
    [field.DIDPASSWORDRESET]: false,
    [field.ISMKWEBRESETPASSWORDTOKENVALID]: undefined,
    [field.ISEMAILVERIFICATIONTOKENVALID]: undefined,
    [field.AUTHREQUESTTIMESTAMP]: undefined,
    [field.ISEMAILVERIFIED]: false,
    [field.ISEMAILSENT]: false,
    [field.ISPASSWORDTEMPORARY]: true,
    [field.ISCONTACTUPDATED]: null,
    [field.MFA_CONTACT_RESPONSE]: undefined,
    [field.IS_EMAIL_VERIFICATION_SKIPPED]: false,
    [field.OTP_LOGIN_SEND_COUNT]: 0,
    [field.OTP_LOGIN_ATTEMPT_COUNT]: 0,
    [field.ORGANIZATION.IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED]: null
};

const _update = (state, data) => {
    return {
        ...state,
        ...data
    };
};

const _updateUserAuth = (state, data) => {
    return {
        ...state[field.USERAUTH],
        ...data
    };
};

const _setAuthReducerCookie = state => {
    // Clone state and hide credential values
    let cookieMKWebAuthData = _.cloneDeep(state);
    cookieMKWebAuthData[field.USERAUTH][field.PASSWORD] = "";

    // Set auth token in Axios Header
    _setAuthTokeninAxiosHeader(cookieMKWebAuthData[field.MKWEBAUTHTOKEN]);
    _setUserDetailsInAxiosHeader(cookieMKWebAuthData[field.USERID], cookieMKWebAuthData[field.CUSTOMER_ID]);

    cookieUtility.setCookie(constant.COOKIE_MKWEBAUTH_DATA, JSON.stringify(cookieMKWebAuthData));
};

export const _setAuthTokeninAxiosHeader = mkwebAuthToken => {
    axios.defaults.headers.common[constant.HEADER_X_AUTH_TOKEN] = mkwebAuthToken;
};

export const _setUserDetailsInAxiosHeader = (userId, customerId) => {
    if(userId)
        axios.defaults.headers.common[constant.HEADER_X_USERID] = userId;
    if(customerId)
        axios.defaults.headers.common[constant.HEADER_X_CUSTOMERID_FE] = customerId;
}

const updateLogin = (state, action) => {
    return _update(state, {
        [field.USERAUTH]: _updateUserAuth(state, {
            [action.fieldName]: action.fieldValue
        })
    });
};

const loginAttemptResponse = (state, action) => {
    let updatedState;
    if(action.payload.responsedata !== null){
        updatedState = _update(state, {
            [field.ISAUTHENTICATED]: true,
            [field.USERID]: action.payload.responsedata.userId,
            [field.CUSTOMER_ID]: action.payload.responsedata.customerId,
            [field.CA_CUSTOMER_ID]: action.payload.responsedata.caCustomerId,
            [field.LMS_CODE]: action.payload.responsedata.lmsCode,
            [field.SWITCHED_ORG]: action.payload.responsedata.switchedOrg,
            [field.LAST_VISITED_TIME]: new Date(action.payload.responsedata.lastVisitedTime).toLocaleString(),
            [field.MKWEBAUTHTOKEN]: action.payload.responsedata.jSessionId,
            [field.MFA_CONTACT_RESPONSE]: action.payload.responsedata.mfaContactResendDTO,
            [field.USERAUTH]: _updateUserAuth(state, {
                [field.EMAIL]: action.payload.email,
                [field.PASSWORD]: action.payload.password
            }),
            [field.ISEMAILVERIFIED]: action.payload.responsedata.emailVerified,
            [field.AUTHREQUESTTIMESTAMP]: new Date().getTime()
        });
        cookieUtility.setCookie(constant.COOKIE_MKEWBAUTH_TOKEN, action.payload.responsedata.jSessionId);
    } else {
        updatedState = _update(state, {
            [field.ISAUTHENTICATED]: false,
            [field.ISEMAILVERIFIED]: false,
            [field.AUTHREQUESTTIMESTAMP]: new Date().getTime(),
            [field.MFA_CONTACT_RESPONSE]: null
        });
    }

    _setAuthReducerCookie(updatedState);
    return updatedState;
};

const updateSessionPostMfa = (state, action) => {
    let updatedState = _update(state, {
        [field.MKWEBAUTHTOKEN]: action.payload.response.data.jSessionId
    });

    _setAuthReducerCookie(updatedState);
    cookieUtility.setCookie(constant.COOKIE_MKEWBAUTH_TOKEN, action.payload.response.data.jSessionId);
    return updatedState;
}

const updateSessionPostOtpLogin = (state, action) => {
    let updatedState = _update(state, {
        [field.MKWEBAUTHTOKEN]: action.payload.response.data.jSessionId,
        [field.USERID]: action.payload.response.data.userId,
        [field.CUSTOMER_ID]: action.payload.response.data.customerId,
        [field.ISAUTHENTICATED]: true,
        [field.LMS_CODE]: constant.LMS_JAGLMS
    });

    _setAuthReducerCookie(updatedState);
    cookieUtility.setCookie(constant.COOKIE_MKEWBAUTH_TOKEN, action.payload.response.data.jSessionId);
    return updatedState;
}

const addEmailToAuth = (state, action) => {
    let updatedState = _update(state, {
        [field.USERAUTH]: _updateUserAuth(state, {
            [field.EMAIL]: action.payload.email
        })
    });

    return updatedState;
}

const loginFailure = (state, action) => {
    return _update(state, {
        [field.ISAUTHENTICATED]: false
    });
};

const logoutSuccess = state => {
    // remove cookies
    cookieUtility.removeCookie(constant.COOKIE_MKEWBAUTH_TOKEN);
    cookieUtility.removeCookie(constant.COOKIE_MKWEBAUTH_DATA);
    cookieUtility.removeCookie(constant.COOKIE_MKWEBAPPLICATION_DATA);
    cookieUtility.removeCookie(constant.COOKIE_MKWEBLOAN_DATA);
    cookieUtility.removeCookie(constant.COOKIE_MKWEBDASHBOARD_DATA);
    cookieUtility.removeCookie(constant.COOKIE_MKWEBINSURANCE_DATA);

    setDataTags({[IS_LOGIN]:false})
    return _update(state, {
        [field.ISAUTHENTICATED]: false,
        [field.USERID]: undefined,
        [field.CUSTOMER_ID]: undefined,
        [field.CA_CUSTOMER_ID]: undefined,
        [field.LMS_CODE]: undefined,
        [field.SWITCHED_ORG]: undefined,
        [field.MKWEBAUTHTOKEN]: undefined,
        //
        [field.USERAUTH]: _updateUserAuth(state, {
            [field.EMAIL]: "",
            [field.PASSWORD]: ""
        })
    });
};

const logoutFailure = (state, action) => {
    return state;
};

const updateResponsefromHBPreStageCall = (state, action) => {
    let updatedState = _update(state, {
        [field.CUSTOMER_ID]: action.payload.response.data.preStageHBPayload.customerId
    });
    _setAuthReducerCookie(updatedState);
    return updatedState;
};

const passwordResetSuccess = (state, action) => {
    cookieUtility.removeCookie(constant.COOKIE_MKEWBAUTH_TOKEN);

    return _update(state, {
        [field.DIDPASSWORDRESET]: action.payload.isPasswordResetSuccess
    });
};

const emailVerificationSuccess = (state, action) => {
    cookieUtility.removeCookie(constant.COOKIE_MKEWBAUTH_TOKEN);
    return _update(state, {
        [field.ISEMAILVERIFIED]: action.payload.isEmailVerifiedSuccess
    });
};

export const isValidEmailVerificationToken = (state, action) => {
    return _update(state, {
        [field.ISEMAILVERIFICATIONTOKENVALID]: action.payload.isValidEmailVerificationToken
    });
};

const sendVerificationEmailSuccess = (state, action) => {
    cookieUtility.removeCookie(constant.COOKIE_MKEWBAUTH_TOKEN);
    let updatedState = _update(state, {
        [field.ISEMAILSENT]: true,
        [field.OTP_LOGIN_SEND_COUNT]: state[field.OTP_LOGIN_SEND_COUNT] + 1
    });

    return updatedState;
};

const isMkwebResetPasswordTokenValidSuccess = (state, action) => {
    return _update(state, {
        [field.ISMKWEBRESETPASSWORDTOKENVALID]: true,
        //
        [field.USERAUTH]: _updateUserAuth(state, {
            [field.EMAIL]: action.payload.email
        })
    });
};

export const isInvalidResetPasswordToken = (state, action) => {
    return _update(state, {
        [field.ISMKWEBRESETPASSWORDTOKENVALID]: false
    });
};

export const loadAuthDatafromCookie = () => {
    let authState = initialState;
    let mkwebAuthData = cookieUtility.getCookie(constant.COOKIE_MKWEBAUTH_DATA);
    if (mkwebAuthData && mkwebAuthData[field.ISAUTHENTICATED] && mkwebAuthData[field.MKWEBAUTHTOKEN]) {
        authState = _update(authState, mkwebAuthData);
    }

    // Set auth token in Axios Header
    _setAuthTokeninAxiosHeader(authState[field.MKWEBAUTHTOKEN]);
    _setUserDetailsInAxiosHeader(authState[field.USERID], authState[field.CUSTOMER_ID]);

    return authState;
};

export const saveCreateAccountSuccess = (state, action) => {
    if (action.payload.email && action.payload.email !== state[field.USERAUTH][field.EMAIL]) {
        let updatedState = _update(state, {
            [field.USERAUTH]: _updateUserAuth(state, {
                [field.EMAIL]: action.payload.email
            })
        });
        _setAuthReducerCookie(updatedState);
        return updatedState;
    } else {
        return state;
    }
};

export const landingPageSuccess = (state, action) => {
    let updatedState = _.cloneDeep(state);
    updatedState = _update(state, {
        [field.ISPASSWORDTEMPORARY]: action.payload.response.data.isPasswordTemporary,
        [field.ORGANIZATION.IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED]: action.payload.response.data.stateOrganizationConfig !== null?
            action.payload.response.data.stateOrganizationConfig.isEmailVerificationViaOTPEnabled : false
    });

    _setAuthReducerCookie(updatedState);
    return updatedState;
};

const submitEmailVerificationOTPSuccess = (state, action) => {
    let updatedState = _updateUserAuth(state, {
        [field.ISEMAILVERIFIED]: action.payload.response.data.responseMessage === "SUCCESS" ? true : false,
        [field.EMAIL_VERIFICATION_OTP_STATUS_MESSAGE]: action.payload.response.data.responseMessage,
        [field.EMAIL_VERIFICATION_OTP_STATUS_MESSAGE_TIMESTAMP]: Date.now()
    });

    return updatedState;
};

const resetOtpLoginCounts = (state,action) => {
    let updatedState = _update(state, {
        [field.OTP_LOGIN_SEND_COUNT]: 0,
        [field.OTP_LOGIN_ATTEMPT_COUNT]: 0
    });

    return updatedState;
}

const submitOtpLoginVerificationCodeIncorrect = (state) => {
    let updatedState = _update(state, {
        [field.OTP_LOGIN_ATTEMPT_COUNT]: state[field.OTP_LOGIN_ATTEMPT_COUNT]+1
    });

    return updatedState;
};


export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_LOGIN:
            return updateLogin(state, action);

        case actionTypes.AUTH_ATTEMPT_RESPONSE:
            return loginAttemptResponse(state, action);

        case actionTypes.LOGIN_ERROR:
            return loginFailure(state, action);

        case actionTypes.LOGOUT_SUCCESS:
            return logoutSuccess(state);

        case actionTypes.GET_LANDING_PAGE_SUCCESS:
            return landingPageSuccess(state, action);

        case actionTypes.LOGOUT_ERROR:
            return logoutFailure(state, action);

        case actionTypes.UPDATE_PASSWORD_SUCCESS:
            return passwordResetSuccess(state, action);

        case actionTypes.IS_MKWEB_RESET_PASSWORD_TOKEN_VALID_SUCCESS:
            return isMkwebResetPasswordTokenValidSuccess(state, action);

        case actionTypes.IS_MKWEB_INVALID_RESET_PASSWORD_TOKEN:
            return isInvalidResetPasswordToken(state, action);

        case actionTypes.EMAIL_VERIFICATION_SUCCESS:
            return emailVerificationSuccess(state, action);

        case actionTypes.IS_MKWEB_INVALID_EMAIL_VERIFICATION_TOKEN:
            return isValidEmailVerificationToken(state, action);

        case actionTypes.START_HB_PRE_STAGE_CALL_SUCCESS:
            return updateResponsefromHBPreStageCall(state, action);

        case actionTypes.SEND_EMAIL_VERIFICATION_SUCCESS:
            return sendVerificationEmailSuccess(state, action);

        case actionTypes.API_ERROR_CREATE:
            if (action.payload.error.response.status === API_ERROR_CODE.ERROR_401) return logoutSuccess(state);
            else return state;

        case actionTypes.SAVE_CREATE_ACCOUNT_SUCCESS:
            return saveCreateAccountSuccess(state, action);

        case actionTypes.UPDATE_SESSION_ID:
            return updateSessionPostMfa(state, action);

        case actionTypes.POST_OTP_LOGIN_UPDATE:
            return updateSessionPostOtpLogin(state, action);

        case actionTypes.ADD_EMAIL_TO_AUTH:
            return addEmailToAuth(state, action);

        case actionTypes.SUBMIT_EMAIL_VERIFICATION_OTP_CODE_SUCCESS:
            return submitEmailVerificationOTPSuccess(state, action);

        case actionTypes.RESET_OTP_LOGIN_COUNTS:
            return resetOtpLoginCounts(state, action);

        case actionTypes.SUBMIT_OTP_LOGIN_VERIFICATION_CODE_INCORRECT:
            return submitOtpLoginVerificationCodeIncorrect(state);
        default:
            return state;
    }
}
