export const WEEKLY = "W";
export const BIWEEKLY = "B";
export const SEMI_MONTHLY = "S";
export const MONTHLY = "M";

export const PAY_FREQUENCY_TYPES = [
    WEEKLY,
    BIWEEKLY,
    SEMI_MONTHLY,
    MONTHLY
];
