import _ from "lodash";
import {push} from "connected-react-router";
import {all, call, put, select} from "redux-saga/effects";
import {getFormValues, startAsyncValidation, startSubmit, stopAsyncValidation, stopSubmit} from "redux-form";

import api from "../../axios/api";
import webapi from "../../axios/webapi";
import jagapi from "../../axios/jagapi";

import * as constant from "../../shared/constant/constant";
import * as helpers from "../../shared/helpers";
import * as field from "../../shared/constant/field";
import {
    CQ_SRC,
    MC_IDENTITY,
    ORGANIZATION as organization
} from "../../shared/constant/field";
import * as fieldtags from "../../shared/constant/fieldtags";
import * as form from "../../shared/constant/form";
import * as errorMessages from "../../shared/constant/errorMessages";
import * as route from "../../shared/constant/route";
import * as routes from "../../shared/constant/route";
import {WEB_API_RESEND_LOGIN_MFA_CODE} from "../../shared/constant/route";
import * as routeutility from "../../shared/utility/routeutility";
import * as utility from "../../shared/utility/utility";
import * as contentUtility from "../../shared/utility/contentutility";

import * as apiErrorCodes from "../../shared/constant/apiErrorCodes";
import * as loanActions from "../action/loanActions";

import * as authActions from "../action/AuthAction";
import * as applicationActions from "../action/ApplicationAction";
import * as errorActions from "../action/ErrorAction";
import * as trackingActions from "../action/trackingActions";
import * as createAndTrackToastMessageAction from "../action/CreateAndTrackToastMessageAction";
import {setOrganizationContent} from "../action/OrganizationActions";
import {getIpAddress} from "../../shared/utility/ipify";
import {getConsentContentSuccess} from "../action/ContentAction";

import * as authSagas from "./AuthSaga";
import * as localStorageUtility from "../../shared/utility/localstorageutility";
import * as MfaConstant from "../../views/application/mfa/constant/MfaConstant";
import {_setAuthTokeninAxiosHeader} from "../reducer/AuthReducer";
import * as pushNotification from "../../views/OneSignal/PushNotification";
import * as dashboardActions from "../action/DashboardAction";
import * as tagConstants from "../../shared/constant/tagConstants";
import {isForaCredit} from "../../shared/utility/utility";
import {postMcIdentityData} from "./McIdentitySaga";
import {
    clearBehaviouralData,
    getMcInsightsDataAndClear
} from "../../external.integrations/mastercard.identity/master.card.identity.utils";
import {
    MC_IDENTITY_PLACEMENT_BOT_DETECTION
} from "../../shared/constant/mcIdentityConstants";
import * as mcIdentityAction from "../action/McIdentityAction";
import {mcIdentityLoadingState} from "../reducer/McIdentityReducer";

let _ip = null;

export function* validateAndExtractHBJWTToken(action) {
    if (!_ip) {
        try {
            _ip = yield getIpAddress();
        } catch (err) {
        }
    }
    try {
        const response = yield api({
            method: "post",
            url: route.API_VALIDATE_AND_EXTRACT_JWT_HB_TOKEN + "/" + action.JWTHBToken,
            headers: {
                organizationId: yield select(
                    state => state[field.REDUCER_NODE_ORGANIZATION][organization.CONFIG][organization.ORGANIZATION_ID]
                ),
                "X-Ip-Address": _ip,
                "X-Verification-Token": action.token
            }
        });

        if (!action.isCloudflareTurnstileValidationEnabled) {
            yield put(authActions.loginAction(response.data.email, response.data.ssnLastFour, "", route.EXTERNAL_APPLICATION_WELCOME));
        }

        yield put(applicationActions.validateAndExtractJWTHBTokenSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_JWTHB_TOKEN));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_JWTHB_TOKEN));
    }
}

export function* saveExternalLeadQueryParams(action) {
    try {
        yield api({
            method: "post",
            url: route.API_SAVE_EXTERNAL_LEAD_QUERY_PARAMS,
            data: action.payload
        });

        yield put(errorActions.clearApiError(null, field.ERROR_SAVE_EXTERNAL_LEAD_QUERY_PARAMS));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SAVE_EXTERNAL_LEAD_QUERY_PARAMS));
    }
}

export function* saveEmailLoginResponse(action) {
    let xlt = yield call(authSagas.getTokens);

    try {
        let url = route.WEB_API_LOGIN_EMAIL;
        const response = yield webapi({
            method: "post",
            url,
            data: {
                organizationId: action.payload.orgId,
                stateCode: action.payload.stateCode,
                email: action.payload.email,
                origin: action.payload.value.orgKey
            },
            headers: {
                "X-Login-Token": xlt
            }
        });

        if(!!response) {
            yield put(applicationActions.saveEmailLoginResponseSuccess(action.payload.email));
        }

        // FORA: do not need to check for redirect
        if ((isForaCredit(response.data.organizationId) || response.data.organizationId === constant.PATHWARD_ORGANIZATION_ID)
                && !response.data.isPasswordRequired && response.data.emailOTPEnabled){
            yield put(push(route.OTP_EMAIL_LOGIN_PAGE));
            return;
        } else if (isForaCredit(response.data.organizationId) || response.data.organizationId === constant.PATHWARD_ORGANIZATION_ID) {
            yield put(push(route.LOGIN + `?email=` + action.payload.email));
            return;
        }

        if (action.payload.value.isLogin === false){
            yield call(getOrganizationId, {payload: action.payload.value});
            return;
        }

        if (!response.data.isPasswordRequired && response.data.emailOTPEnabled){
            routeutility.switchOrgOTPLogin(response.data.organizationId, action.payload.value.buildProfile, action.payload.email);
        } else {
            yield call(orgRouteFromUser, {payload: action.payload.value, orgId: response.data.organizationId, email: action.payload.email});
        }
    } catch (error) {
        yield put(applicationActions.saveEmailLoginResponseSuccess(null));
    }
}
export function* orgRouteFromUser(action) {
    try {
        let didPasswordReset = yield select(state => state[field.REDUCER_NODE_AUTH][field.DIDPASSWORDRESET]);
        let orgId = action.orgId;

        if (orgId === constant.MONEYKEY_PLUS_ORGANIZATION_ID) {
            window.location.href = utility.getCCBUrl(
                action.payload.state,
                action.payload.buildProfile,
                didPasswordReset,
                action.payload.isLogin,
                orgId,
                action.payload.tierKey,
                action.payload.subId,
                action.payload.subId2,
                action.payload.subId3,
                action.payload.adId,
                action.payload.clickId,
                action.payload.ranmid,
                action.payload.ransiteid,
                action.payload.utmCampaign,
                action.payload.gclId,
                action.payload.fbcId,
                action.payload.cqSrc,
                action.email
            );
        } else {
            window.location.href = utility.getStateInfoUrl(
                action.payload.state,
                action.payload.buildProfile,
                orgId,
                didPasswordReset,
                action.payload.isLogin,
                action.payload.tierKey,
                action.payload.subId,
                action.payload.subId2,
                action.payload.subId3,
                action.payload.adId,
                action.payload.clickId,
                action.payload.ranmid,
                action.payload.ransiteid,
                action.payload.utmCampaign,
                action.payload.gclId,
                action.payload.fbcId,
                action.payload.cqSrc,
                action.email
            );
        }
        yield put(errorActions.clearApiError(null, field.ERROR_STATE_SELECTION_ORGANIZTION));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_STATE_SELECTION_ORGANIZTION)
        );
    }
}

export function* getOrganizationId(action) {
    let didPasswordReset = yield select(state => state[field.REDUCER_NODE_AUTH][field.DIDPASSWORDRESET]);
    let url = route.STATE_SELECTION_API_URL.replace(/:state/i, action.payload.state) + action.payload.orgKey;

    try {
        const response = yield jagapi({
            method: "get",
            url
        });
        let orgId = response.data.organizationId ? response.data.organizationId : action.payload.orgId;

        if (response.data.status === "Error"){
            yield put(push(routeutility.getRejectPage(constant.REJECT_UNSUPPORTED_STATE)));
            yield * sendRejectEventUnsupportedState(action);
            return;
        }

        if (orgId === constant.MONEYKEY_PLUS_ORGANIZATION_ID) {
            window.location.href = utility.getCCBUrl(
                action.payload.state,
                action.payload.buildProfile,
                didPasswordReset,
                action.payload.isLogin,
                orgId,
                action.payload.tierKey,
                action.payload.subId,
                action.payload.subId2,
                action.payload.subId3,
                action.payload.adId,
                action.payload.clickId,
                action.payload.ranmid,
                action.payload.ransiteid,
                action.payload.utmCampaign,
                action.payload.gclId,
                action.payload.fbcId,
                action.payload.cqSrc,
                action.email
            );
        } else {
            window.location.href = utility.getStateInfoUrl(
                action.payload.state,
                action.payload.buildProfile,
                orgId,
                didPasswordReset,
                action.payload.isLogin,
                action.payload.tierKey,
                action.payload.subId,
                action.payload.subId2,
                action.payload.subId3,
                action.payload.adId,
                action.payload.clickId,
                action.payload.ranmid,
                action.payload.ransiteid,
                action.payload.utmCampaign,
                action.payload.gclId,
                action.payload.fbcId,
                action.payload.cqSrc,
                action.email
            );
        }
        // yield put(applicationActions.getStateSelectionOrganizationIdSuccess(response, email));
        yield put(errorActions.clearApiError(null, field.ERROR_STATE_SELECTION_ORGANIZTION));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_STATE_SELECTION_ORGANIZTION)
        );
    }
}

export function* getExternalCustomerAppInfo(action) {
    try {
        let getCustomerAppInfoURL = route.JAG_API_CUSTOMER_GET_EXTERNAL_APPLICATION_INFO.replace(
            /:customerId/i,
            action.payload.customerId
        ).replace(/:organizationId/, utility.getOrganizationId());

        const response = yield jagapi({
            method: "get",
            url: getCustomerAppInfoURL
        });

        yield put(applicationActions.getExternalCustomerAppInfoSuccess(response));
        yield put(loanActions.updateActiveLoanDetails(response));
        const organizationContentKeys = {};
        if (response && response.data && response.data.state) {
            organizationContentKeys.state = response.data.state;
        }
        if (
            response &&
            response.data &&
            response.data.customerActiveLoanDetails &&
            response.data.customerActiveLoanDetails.loanType
        ) {
            organizationContentKeys.product = contentUtility.mapLoanTypes(
                response.data.customerActiveLoanDetails.loanType
            );
        }
        if (!_.isEmpty(organizationContentKeys)) {
            yield put(setOrganizationContent(organizationContentKeys));
        }
        yield put(errorActions.clearApiError(null, field.ERROR_GET_EXTERNAL_CUSTOMER_APP_INFO));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(
                error,
                field.ERROR_GET_EXTERNAL_CUSTOMER_APP_INFO
            )
        );
    }
}

export function* saveStateSelection(action) {
    if (utility.isCCBState(action.payload.state) && action.payload.orgId !== constant.CREDITFRESH_ORGANIZATION_ID) {
        window.location.href = utility.getCCBUrl(
            action.payload.state,
            action.payload.buildProfile,
            action.payload.orgId
        );
    } else {
        window.location.href = utility.getStateInfoUrl(
            action.payload.state,
            action.payload.buildProfile,
            action.payload.orgId
        );
    }

    yield null;
}

export function* saveStartPage(action) {
    if (!_ip) {
        try {
            _ip = yield getIpAddress();
        } catch (err) {}
    }
    let getSelectedProps = state => {
        return _.omitBy(
            {
                appCode: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.APPCODE],
                firstname: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.FIRSTNAME],
                lastname: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.LASTNAME],
                zip: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.ZIP],
                city: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.CITY],
                address: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.ADDRESS],
                tierKey: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.TIERKEY]
            },
            _.isNull
        );
    };

    yield put(startSubmit(form.STATE_INFO));
    try {
        if (
            (action.payload[field.STATE] === constant.STATE_ALABAMA ||
                action.payload[field.STATE] === constant.STATE_CALIFORNIA) &&
            utility.getOrganizationId() === constant.ORGANIZATION_MONEYKEY
        ) {
            action.payload[field.AMOUNT] = 1;
        }

        let organizationStateStatusUrl = route.WEB_API_ORGANIZATION_STATE_STATUS;
        organizationStateStatusUrl = organizationStateStatusUrl
            .replace(/:organizationId/i, utility.getOrganizationId())
            .replace(/:stateCode/i, action.payload[field.STATE]);

        let data = {
            ...action.payload,
            ...(yield select(getSelectedProps))
        };
        data.isXclusive = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_XRAL]);
        const [organizationStateStatusResponse, appUpdateResponse] = yield all([
            call(jagapi, {
                method: "get",
                url: organizationStateStatusUrl
            }),
            call(webapi, {
                method: "post",
                url: route.WEB_API_START_APPLICATION,
                data: data,
                headers: {
                    "X-Ip-Address": _ip,
                }
            })
        ]);

        yield put(applicationActions.saveStartPageSuccess(appUpdateResponse));
        pushNotification.setDataTags({[tagConstants.TAG_IS_USER_CREATED_PASSWORD]: false});
        // Disabling tracking for this page, because authentication is not done yet.
        // yield put(trackingActions.submitStateInfo());

        // Redirect to next page based on whether state is supported or not.
        let stateStatus = organizationStateStatusResponse.data;
        //temporary hard-code block on AL NC. Database doesn't support partially-supported states (AL can still get re-loans)

        let testTierKey = yield select(
            state =>
                state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][
                    field.ORGANIZATION.ORGANIZATION_TEST_TIERKEY
                ]
        );
        let buildProfile = yield select(
            state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE]
        );

        let orgId = yield select(
            state =>
                state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_ID]
        );
        if (
            !(
                (action.payload[field.STATE] === constant.STATE_ALABAMA ||
                    action.payload[field.STATE] === constant.STATE_CALIFORNIA) &&
                utility.getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID
            ) && !isForaCredit() &&
            (utility.isStateSupported(stateStatus.isSupported, stateStatus.isActive, stateStatus.isPendingLicense) ||
                utility.isTestState(stateStatus.isTest, data.tierKey, testTierKey))
        ) {
                yield put(push(routeutility.getNextPage(route.APPLICATION_STATE_INFO_PAGE, true)));
        } else if (isForaCredit() &&
            (utility.isStateSupported(stateStatus.isSupported, stateStatus.isActive, stateStatus.isPendingLicense) ||
                utility.isTestState(stateStatus.isTest, data.tierKey, testTierKey))) {
            yield put(push(routeutility.getNextPage(route.APPLICATION_STATE_INFO_PAGE, true)));
        } else {
            let state = action.payload[field.STATE];
            if (orgId === constant.MONEYKEY_ORGANIZATION_ID && utility.isOldSupportedState(state)) {
                let email = action.payload[field.EMAIL];
                yield call(routeutility.getOldApplicationPage(buildProfile, state, email));
            } else {
                yield put(push(routeutility.getRejectPage(constant.REJECT_UNSUPPORTED_STATE)));
                yield * sendRejectEventUnsupportedState(action);
            }
        }
    } catch (error) {
        //Error needs to be completed
        //Error happens on: email invalid. Email duplicate. State invalid.
        //Error should be different on State reject.
        yield put(applicationActions.saveStartPageError(error));
    }
    yield put(stopSubmit(form.STATE_INFO));
}

export function* checkEmailStatusAndSubmitStateInfo(action){
    // call isUniqueEmailAddress
    yield call(isUniqueEmailAddress, {payload: action.payload});

    let isUniqueEmail = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USER_LOGIN_INFO][field.UNIQUE]);

    if (isUniqueEmail === false && isForaCredit()) {
        yield put(push(route.EMAIL_LOGIN_PAGE + "?email=" + action.payload.data.email));
    } else if (isUniqueEmail === false){
        yield put(push(route.STATE_LOGIN + "?state=" + action.payload.data.state + "&email=" + action.payload.data.email));
    } else {
        yield put(applicationActions.saveStartPage(action.payload.data));
    }
}

export function* isUniqueEmailAddress(action) {
    try {
        yield put(startAsyncValidation(action.payload.form));
        let url = route.WEB_API_USER_UNIQUEEMAIL.replace(/:email/, action.payload.emailAddress).replace(
            /:organizationId/,
            utility.getOrganizationId()
        );
        let isEmailOTPEnabled = yield select(state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED]);
        let isXRal = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_XRAL]);
        url = isXRal ? url + '/XRal' : url;
        const response = yield webapi({
            method: "get",
            url
        });
        yield put(applicationActions.updateUserLoginInfo(response.data));
        if (response.data.unique) {
            yield put(stopAsyncValidation(action.payload.form, {}));
        } else {
            switch (action.payload.form) {
                case form.STATE_INFO:
                    if(isForaCredit()){
                        break;
                    }
                    if(!isEmailOTPEnabled){
                        yield put(
                            stopAsyncValidation(action.payload.form, {
                                [action.payload.field]: errorMessages.STATEINFO_EMAIL_ADDRESS__IS_NOT_UNIQUE(action.payload.emailAddress)
                            })
                        );
                    }
                    break;
                case form.CREATE_ACCOUNT:
                    yield put(
                        stopAsyncValidation(action.payload.form, {
                            [action.payload.field]: errorMessages.STATEINFO_EMAIL_ADDRESS__IS_NOT_UNIQUE(action.payload.emailAddress)
                        })
                    );
                    break;
                default:
                    console.log("Unknown Payload Form");
            }
        }
    } catch (error) {
        if (error.response.status === apiErrorCodes.API_ERROR_CODE.ERROR_400) {
            switch (action.payload.form) {
                case form.STATE_INFO:
                case form.CREATE_ACCOUNT:
                    yield put(
                        stopAsyncValidation(action.payload.form, {
                            [action.payload.field]: errorMessages.EMAIL_ADDRESS__INVALID
                        })
                    );
                    break;
                case form.LOGIN:
                    yield put(
                        stopAsyncValidation(action.payload.form, {
                            [action.payload.field]: errorMessages.EMAIL_ADDRESS__INVALID
                        })
                    );
                    break;
                default:
                    throw new Error("not implemented");
            }
        } else {
            yield put(
                stopAsyncValidation(action.payload.form, {[action.payload.field]: errorMessages.GENERIC_ERROR_MESSAGE})
            );
        }
    }
}

export function* isUniqueSSNGlobally(action) {
    try {
        let supportNumber = yield select(
            state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.SUPPORT_NUMBER]
        );
        let isUniqueSSNGloballyURL = route.WEB_API_USERINFO_UNIQUESSN_GLOBALLY.replace(
            /:userAppId/i,
            action.payload.userAppInfoId
        );
        let ssnValidationFormData = new FormData();
        ssnValidationFormData.set("email", action.payload.email);
        ssnValidationFormData.set("ssn", utility.stringTrimAndRemoveDashes(action.payload.ssn));
        ssnValidationFormData.set("organizationId", action.payload.organizationId);

        yield put(startAsyncValidation(form.PERSONAL_INFO));

        const response = yield webapi({
            method: "post",
            url: isUniqueSSNGloballyURL,

            data: {
                email: action.payload.email,
                ssn: utility.stringTrimAndRemoveDashes(action.payload.ssn),
                organizationId: action.payload.organizationId
            }
        });
        // If response is false, means ssn is not unique
        if (response.data.exists) {
            let ssnErrorMsg = errorMessages.PERSONALINFO_SSN__IS_NOT_UNIQUE(
                response.data.obfuscatedEmails,
                supportNumber
            );
            yield put(
                stopAsyncValidation(form.PERSONAL_INFO, {
                    [fieldtags.PERSONALINFO_SSN]: ssnErrorMsg
                })
            );
        } else {
            yield put(stopAsyncValidation(form.PERSONAL_INFO, {}));
        }
    } catch (error) {
        yield put(
            stopAsyncValidation(form.PERSONAL_INFO, {[fieldtags.PERSONALINFO_SSN]: errorMessages.GENERIC_ERROR_MESSAGE})
        );
    }
}

export function* savePersonalInformationPage(action) {
    yield put(startSubmit(form.PERSONAL_INFO));
    try {
        let sanitizedUserInfo = utility.sanitizeUserInfo(action.payload[field.USERAPPINFO]);
        let isPhoneVerificationEnabledInFlow = yield select(state =>
            state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_SMS_ENABLED]
            || state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_LANDLINE_ENABLED]
        );

        if (isPhoneVerificationEnabledInFlow === true) {
            const stateCode = action.payload[field.USERAPPINFO][field.STATE];

            let organizationStateStatusUrl = route.WEB_API_ORGANIZATION_STATE_STATUS;
            organizationStateStatusUrl = organizationStateStatusUrl
                .replace(/:organizationId/i, utility.getOrganizationId())
                .replace(/:stateCode/i, stateCode);

            const organizationStateStatusResponse = yield call(jagapi, {
                method: "get",
                url: organizationStateStatusUrl
            });

            // Save address info only if state is supported. else redirect to reject page.
            // This way when user relogin , it will lands on address info page.
            let stateStatus = organizationStateStatusResponse.data;
            if (stateStatus.switchedOrg && stateStatus.isSupported) {
                let data = {};
                data.associatedOrg = stateStatus.switchedOrg;
                yield put(applicationActions.updateUserLoginInfo(data));
                yield put(applicationActions.saveAddressInfo(sanitizedUserInfo));
                yield put(push(routeutility.getRejectPage(constant.REJECT_UNSUPPORTED_STATE)));
                yield * sendRejectEventUnsupportedState(action);
                yield put(trackingActions.submitPersonalInfo());
                return;
            }

            if (
                !utility.isStateSupported(stateStatus.isSupported, stateStatus.isActive, stateStatus.isPendingLicense)
                || (constant.STATE_BLOCKED_NC.includes(stateCode) && utility.getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID)
            ){
                yield put(applicationActions.saveAddressInfo(sanitizedUserInfo));
                yield put(push(routeutility.getRejectPage(constant.REJECT_UNSUPPORTED_STATE)));
                yield * sendRejectEventUnsupportedState(action);
                return;
            }

        }

        let savePersonalInfoURL = route.WEB_API_USER_SAVE_APPLICATION.replace(/:userAppId/i, sanitizedUserInfo.id);
        const response = yield webapi({
            method: "put",
            url: savePersonalInfoURL,
            data: sanitizedUserInfo
        });

        let phoneVerificationFlow = yield select(state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_SMS_ENABLED]
            || state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_LANDLINE_ENABLED]);

        yield put(trackingActions.submitPersonalInfo());
        yield put(applicationActions.saveApplicationSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_START_PERSONALINFO));
        yield put(push(routeutility.getNextPage(route.APPLICATION_PERSONAL_INFO_PAGE, true, phoneVerificationFlow)));
        pushNotification.setDataTags({[tagConstants.TAG_IS_PERSONAL_INFO_SUBMITTED]: true});
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_PERSONALINFO));
    }
    yield put(stopSubmit(form.PERSONAL_INFO));
}

export function* savePersonalInfoData(action) {
    yield put(startSubmit(form.RAL_PERSONAL_INFO));
    try {

        if(isForaCredit(action.payload.data.organizationId)){
            let updateSinURL = route.WEB_API_UPDATE_SIN;
            updateSinURL = updateSinURL.replace(/:userAppId/i, action.payload.data.userId);
            const updateSinURLResponse = yield call(webapi, {
                method: "put",
                url: updateSinURL,
                data: action.payload.data
            });
            yield put(applicationActions.saveApplicationSuccess(updateSinURLResponse));
        }
        yield put(trackingActions.submitReapplyRALLoan(action.payload.productName, action.payload.flowName, "LF1"));
        pushNotification.setDataTags({[tagConstants.TAG_IS_PERSONAL_INFO_SUBMITTED]: true});
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_PERSONALINFO));
    }
}

export function* saveAddressInformationPage(action) {
    yield put(startSubmit(form.ADDRESS_INFO));
    const stateCode = action.payload[field.USERAPPINFO][field.STATE];
    try {
        let organizationStateStatusUrl = route.WEB_API_ORGANIZATION_STATE_STATUS;
        organizationStateStatusUrl = organizationStateStatusUrl
            .replace(/:organizationId/i, utility.getOrganizationId())
            .replace(/:stateCode/i, stateCode);

        let id = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.ID]);
        let tierkey = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.TIERKEY]);
        let testTierKey = yield select(
            state =>
                state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][
                    field.ORGANIZATION.ORGANIZATION_TEST_TIERKEY
                ]
        );
        let sanitizedUserInfo = utility.sanitizeUserInfo(action.payload[field.USERAPPINFO]);
        let saveAddressInfoURL = route.WEB_API_USER_SAVE_APPLICATION.replace(/:userAppId/i, id);

        const organizationStateStatusResponse = yield call(jagapi, {
            method: "get",
            url: organizationStateStatusUrl
        });

        // Save address info only if state is supported. else redirect to reject page.
        // This way when user relogin , it will lands on address info page.
        let stateStatus = organizationStateStatusResponse.data;
        if (stateStatus.switchedOrg && stateStatus.isSupported){
            let data = {};
            data.associatedOrg = stateStatus.switchedOrg;
            yield put(applicationActions.updateUserLoginInfo(data));
            yield put(applicationActions.saveAddressInfo(sanitizedUserInfo));
            yield put(push(routeutility.getRejectPage(constant.REJECT_UNSUPPORTED_STATE)));
            yield * sendRejectEventUnsupportedState(action);
            yield put(trackingActions.submitAddressInfo());
            return;
        }
        if (
            (utility.isStateSupported(stateStatus.isSupported, stateStatus.isActive, stateStatus.isPendingLicense) && !(constant.STATE_BLOCKED_NC.includes(stateCode) && utility.getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID)) ||
            utility.isTestState(stateStatus.isTest, tierkey, testTierKey)
        ) {
            const appUpdateResponse = yield call(webapi, {
                method: "put",
                url: saveAddressInfoURL,
                data: sanitizedUserInfo
            });
            yield put(trackingActions.submitAddressInfo());
            yield put(applicationActions.saveApplicationSuccess(appUpdateResponse));
            yield put(errorActions.clearApiError(null, field.ERROR_START_ADDRESSINFO));
            yield put(push(routeutility.getNextPage(route.APPLICATION_ADDRESS_INFO_PAGE, true)));
        } else {
            yield put(applicationActions.saveAddressInfo(sanitizedUserInfo));
            yield put(push(routeutility.getRejectPage(constant.REJECT_UNSUPPORTED_STATE)));
            yield * sendRejectEventUnsupportedState(action);
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_ADDRESSINFO));
    }
    yield put(stopSubmit(form.ADDRESS_INFO));
}

export function* saveLivingSituationPage(action) {
    yield put(startSubmit(form.LIVING_SITUATION));
    try {
        let sanitizedUserInfo = utility.sanitizeUserInfo(action.payload[field.USERAPPINFO]);
        // delete sanitizedUserInfo.id;
        let saveLivingSituationURL = route.WEB_API_USER_SAVE_APPLICATION.replace(/:userAppId/i, sanitizedUserInfo.id);
        const response = yield webapi({
            method: "put",
            url: saveLivingSituationURL,
            data: sanitizedUserInfo
        });

        yield put(trackingActions.submitLivingSituation());
        yield put(applicationActions.saveApplicationSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_START_LIVINGSITUATION));
        yield put(push(routeutility.getNextPage(route.APPLICATION_LIVING_SITUATION_PAGE, true)));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_LIVINGSITUATION)
        );
    }
    yield put(stopSubmit(form.LIVING_SITUATION));
}

export function* savePaymentSchedulePage(action) {
    yield put(startSubmit(form.PAYMENT_SCHEDULE));
    let data = {
        isOrganic: yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_ORGANIC]),
        isShortform: yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_SHORTFORM])
    };
    if (data.isOrganic) {
        let id = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.ID]);
        let sanitizedUserInfo = utility.sanitizeUserInfo(action.payload[field.USERAPPINFO], data.isOrganic);
        let paymentScheduleUserAppInfoResponse, preStageHBCheckResponse;
        try {
            let savePaymentScheduleUserAppInfoURL = route.WEB_API_USER_SAVE_APPLICATION.replace(/:userAppId/i, id);
            paymentScheduleUserAppInfoResponse = yield webapi({
                method: "put",
                url: savePaymentScheduleUserAppInfoURL,
                data: sanitizedUserInfo
            });
            //uncomment if back functionality is available in Organic Application.
            //paymentScheduleUserAppInfoResponse.data[field.CHOICES] = sanitizedUserInfo[field.CHOICES];
            yield put(trackingActions.submitPaymentSchedule());
            yield put(applicationActions.saveApplicationSuccess(paymentScheduleUserAppInfoResponse));
            yield put(errorActions.clearApiError(null, field.ERROR_START_PAYMENTSCHEDULE));

            yield put(applicationActions.updatePaymentScheduleChoices(action.payload[field.USERAPPINFO].choices));
        } catch (error) {
            yield put(
                createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_PAYMENTSCHEDULE)
            );
        }

        try {
            if (paymentScheduleUserAppInfoResponse.status === 200) {
                const mcIdentityState = yield select(state => state[field.REDUCER_NODE_MC_IDENTITY]);
                const isMcIdentityPackageLoading = mcIdentityState[MC_IDENTITY.IS_MC_IDENTITY_PACKAGE_LOADING];
                if (isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED) {
                    const mcIdentityData = getMcInsightsDataAndClear(MC_IDENTITY_PLACEMENT_BOT_DETECTION, false);
                    clearBehaviouralData();
                    yield call(postMcIdentityData, {
                        payload: {
                            mcIdentityData,
                            productType: MC_IDENTITY_PLACEMENT_BOT_DETECTION,
                            mcIdentityState
                        }
                    });
                    yield put(mcIdentityAction.updatedMcIdentityDetectionSubmittedValue(MC_IDENTITY_PLACEMENT_BOT_DETECTION, false));
                }
            }
        } catch (error) {
            yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_POST_MC_IDENTITY_DATA));
        }

        try {
            if (paymentScheduleUserAppInfoResponse.status === 200) {
                let preStageHBCheckURL = route.WEB_API_PRE_STAGE_HB_CHECK;
                let tierKey = yield select(
                    state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.TIERKEY]
                );
                let subIdTrackingId = yield select(
                    state => state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.ID]
                );
                subIdTrackingId = subIdTrackingId ? subIdTrackingId : null;
                let organizationId = utility.getOrganizationId();

                let userId = yield select(state => state[field.REDUCER_NODE_AUTH][field.USERID]);

                if (!_ip) {
                    try {
                        _ip = yield getIpAddress();
                    } catch (err) {}
                }

                let ipAddress = _ip;

                preStageHBCheckResponse = yield webapi({
                    method: "post",
                    url: preStageHBCheckURL,
                    data: {
                        tierKey,
                        organizationId,
                        ipAddress,
                        userId,
                        subIdTrackingId,
                        ...data
                    }
                });
                if(preStageHBCheckResponse.data.Response.Result === constant.HOTBOX_REJECT){
                    yield put(applicationActions.setDuplicateOrganization(preStageHBCheckResponse?.data?.Response?.DuplicateOrganization));
                }
                if (preStageHBCheckResponse.data.Response.Result === constant.HOTBOX_ACCEPT) {
                    // Trigger an action to save this response
                    // It is being picked up by Application Reducer and AuthReducer
                    yield put(applicationActions.saveHBPreStageCallSuccess(preStageHBCheckResponse));
                    yield put(loanActions.updateActiveLoanDetails(preStageHBCheckResponse));
                    yield put(applicationActions.updateLoanApplicationProgress({[field.ISPAYMENTSCHEDULEPAGESUBMITTED]: true}));
                    yield put(push(routeutility.getNextPage(route.APPLICATION_PAYMENT_SCHEDULE_PAGE, true)));
                } else {
                    yield put(push(routeutility.getRejectPage("APPLICATION_REJECTED")));
                }

                yield put(errorActions.clearApiError(null, field.ERROR_START_PRESTAGEHBCHECK));
            }
        } catch (error) {
            yield put(
                createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_PRESTAGEHBCHECK)
            );
            yield put(applicationActions.submitHBCallFail(true));
        }
    } else {
        let customerId = yield select(
            state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.CUSTOMERID]
        );
        let sanitizedUserInfo = utility.sanitizeUserInfo(action.payload[field.USERAPPINFO], data.isOrganic);
        let paymentScheduleCustomerInfoFlatResponse;
        try {
            let savePaymentScheduleCustomerInfoFlatURL = route.JAG_API_CUSTOMER_SAVE_APPLICATION.replace(
                /:customerId/i,
                customerId
            );
            paymentScheduleCustomerInfoFlatResponse = yield jagapi({
                method: "put",
                url: savePaymentScheduleCustomerInfoFlatURL,
                data: sanitizedUserInfo
            });
            paymentScheduleCustomerInfoFlatResponse.data[field.CHOICES] = sanitizedUserInfo[field.CHOICES];

            yield put(trackingActions.submitPaymentSchedule());
            yield put(applicationActions.saveApplicationSuccess(paymentScheduleCustomerInfoFlatResponse));
            yield put(applicationActions.updateLoanApplicationProgress({[field.ISPAYMENTSCHEDULEPAGESUBMITTED]: true}));
            yield put(push(routeutility.getNextPage(route.APPLICATION_PAYMENT_SCHEDULE_PAGE, false)));
            yield put(errorActions.clearApiError(null, field.ERROR_START_PAYMENTSCHEDULE));
        } catch (error) {
            yield put(
                createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_PAYMENTSCHEDULE)
            );
        }
    }
    let customerId = yield select(
        state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.CUSTOMERID]
    );
    pushNotification.setDataTags({[tagConstants.TAG_CUSTOMER_ID]:customerId});
    yield put(stopSubmit(form.PAYMENT_SCHEDULE));
}

export function* saveEmploymentInformationPage(action) {
    yield put(startSubmit(form.EMPLOYMENT_INFO));
    try {
        let sanitizedUserInfo = utility.sanitizeUserInfo(action.payload[field.USERAPPINFO]);
        let saveEmploymentInfoURL = route.JAG_API_CUSTOMER_SAVE_APPLICATION.replace(
            /:customerId/i,
            sanitizedUserInfo.customerId
        );
        const response = yield jagapi({
            method: "put",
            url: saveEmploymentInfoURL,
            data: sanitizedUserInfo
        });

        yield put(trackingActions.submitEmploymentInfo());
        yield put(applicationActions.saveApplicationSuccess(response));
        yield put(applicationActions.updateLoanApplicationProgress({[field.ISEMPLOYMENTINFOPAGESUBMITTED]: true}));
        yield put(errorActions.clearApiError(null, field.ERROR_START_EMPLOYMENTINFO));

        /*
        let isShortform = yield select(
            state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_SHORTFORM]
        );
        let isLoanNCAutoFund = yield select(
            state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_IS_NC_AUTO_FUND_FLOW]
        );

        if (isShortform && !isLoanNCAutoFund) {
            let userAppInfo = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO]);
            yield put(push(routeutility.getNextPageForShortForm(userAppInfo)));
        } else {
            yield put(
                push(routeutility.getNextPage(route.APPLICATION_EMPLOYMENT_INFO_PAGE, action.payload[field.IS_ORGANIC]))
            );
        }
        */
        yield put(
            push(routeutility.getNextPage(route.APPLICATION_EMPLOYMENT_INFO_PAGE, action.payload[field.IS_ORGANIC]))
        );
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_EMPLOYMENTINFO));
    }
    yield put(stopSubmit(form.EMPLOYMENT_INFO));
}

export function* saveBankingInfoPage(action) {
    yield put(startSubmit(form.BANKING_INFO));
    let sanitizedUserInfo = utility.sanitizeUserInfo(action.payload[field.USERAPPINFO]);
    let bankingInfoResponse, postStageHBCheckResponse;
    try {
        let saveBankingInfoURL = route.JAG_API_CUSTOMER_SAVE_APPLICATION.replace(
            /:customerId/i,
            sanitizedUserInfo.customerId
        );

        bankingInfoResponse = yield jagapi({
            method: "put",
            url: saveBankingInfoURL,
            data: sanitizedUserInfo
        });

        yield put(trackingActions.submitBankingInfo());
        yield put(applicationActions.saveApplicationSuccess(bankingInfoResponse));
        yield put(errorActions.clearApiError(null, field.ERROR_START_BANKINGINFO));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_BANKINGINFO));
    }

    try {
        if (bankingInfoResponse.status === 200) {
            const mcIdentityState = yield select(state => state[field.REDUCER_NODE_MC_IDENTITY]);
            const isMcIdentityPackageLoading = mcIdentityState[MC_IDENTITY.IS_MC_IDENTITY_PACKAGE_LOADING];
            if (isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED) {
                const mcIdentityData = getMcInsightsDataAndClear(MC_IDENTITY_PLACEMENT_BOT_DETECTION, true);
                clearBehaviouralData();
                yield call(postMcIdentityData, {
                    payload: {
                        mcIdentityData,
                        productType: MC_IDENTITY_PLACEMENT_BOT_DETECTION,
                        mcIdentityState
                    }
                });
                yield put(mcIdentityAction.updatedMcIdentityDetectionStartedValue(MC_IDENTITY_PLACEMENT_BOT_DETECTION, false));
                yield put(mcIdentityAction.updatedMcIdentityDetectionSubmittedValue(MC_IDENTITY_PLACEMENT_BOT_DETECTION, false));
            }
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_POST_MC_IDENTITY_DATA));
    }

    try {
        if (bankingInfoResponse.status === 200) {
            let postStageHBCheckURL = route.WEB_API_POST_STAGE_HB_CHECK;
            let tierKey = yield select(
                state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.TIERKEY]
            );
            let organizationId = utility.getOrganizationId();
            let isOrganic = yield select(
                state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_ORGANIC]
            );
            let isShortForm = yield select(
                state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_SHORTFORM]
            );

            let userId = yield select(state => state[field.REDUCER_NODE_AUTH][field.USERID]);
            let clickId = yield select(
                state => state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.CLICK_ID_1]
            );
            let subId = yield select(
                state => state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.SUB_ID]
            );
            let state = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.STATE]);
            let buildProfile = yield select(
                state =>
                    state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE]
            );

            const _stateSelector = state => ({
                isLoanDocsinDashboard:
                    state[field.REDUCER_NODE_LOAN][field.LOAN_LIMITS_BY_ORG_AND_STATE][
                        field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD
                    ],
                isNCAutoFundFlow: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_IS_NC_AUTO_FUND_FLOW]
            });
            const _state = yield select(_stateSelector);

            let customerId = sanitizedUserInfo.customerId;

            postStageHBCheckResponse = yield webapi({
                method: "post",
                url: postStageHBCheckURL,
                data: {
                    tierKey,
                    organizationId,
                    isOrganic,
                    isShortForm,
                    customerId,
                    userId
                }
            });
            if (postStageHBCheckResponse.data.Response.Result === constant.HOTBOX_CONTINUE) {
                if (subId === "fin" && clickId !== null) {
                    if(buildProfile === "production") {
                        yield call(helpers.sendFianancerPixelPostBack(clickId, state));
                    } else {
                        console.log("Call to financer pixel would have made in production");
                    }
                }
                yield put(applicationActions.saveHBPostStageCallSuccess(postStageHBCheckResponse));
                if (postStageHBCheckResponse.data.customerActiveLoanDetails) {
                    yield put(loanActions.updateActiveLoanDetails(postStageHBCheckResponse));
                }
                yield put(
                    push(
                        routeutility.getNextPageAfterBankingInfoPage(
                            action.payload[field.IS_ORGANIC],
                            postStageHBCheckResponse.data.Response.kyc,
                            action.payload.isShortform,
                            _state.isLoanDocsinDashboard,
                            _state.isNCAutoFundFlow
                        )
                    )
                );

            } else {
                yield put(push(routeutility.getRejectPage("APPLICATION_REJECTED")));
            }

            yield put(errorActions.clearApiError(null, field.ERROR_START_PRESTAGEHBCHECK));
        }
    } catch (error) {
        yield put(applicationActions.submitHBCallFail(true));
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_PRESTAGEHBCHECK)
        );
    }
    yield put(applicationActions.updateLoanApplicationProgress({[field.ISBANKINGINFOPAGESUBMITTED]: true}));
    yield put(stopSubmit(form.BANKING_INFO));
}

export function* saveCreatePasswordPage(action) {
    yield put(startSubmit(form.CREATE_PASSWORD));
    try {
        let id = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.ID]);
        let userId = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.USERID]);
        let email = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.EMAIL]);
        let mkwebAuthToken = yield select(state => state[field.REDUCER_NODE_AUTH][field.MKWEBAUTHTOKEN]);
        let isEmailOTPEnabled = yield select(state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED]);
        let externalReferenceId = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.EXTERNAL_REFERENCE_ID]);

        let token = action.payload.token;
        let password = action.payload.password;
        let organizationId = utility.getOrganizationId();

        // If user id is not created then only create user account with password in webapi.users and send email verification
        if (userId === null || userId === undefined) {
            let savePasswordAndEConsentURL = route.WEB_API_USER_CREATENEWUSER.replace(/:userAppId/i, id);
            const response = yield webapi({
                method: "post",
                url: savePasswordAndEConsentURL,
                data: {
                    email,
                    password: password,
                    organizationId,
                    externalReferenceId
                }
            });
            yield put(applicationActions.saveCreatePasswordPageSuccess(response));

            // Fetch the userId again after user account has been created
            userId = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.USERID]);
            const data = {
                userId: userId,
                organizationId: utility.getOrganizationId(),
                playerId: pushNotification.getPlayerId(),
                email: email,
                isPublic: false
            }
            yield  put(applicationActions.saveOneSignalUser(data));
            if (!isEmailOTPEnabled){
                yield put(authActions.sendEmailVerification(email, userId));
            }
        }
        // If userId is present and authtoken is there, then update password.
        else if (mkwebAuthToken) {
            try {
                let createAccountURL = route.WEB_API_USER_RESET_PASSWORD_BY_USERID.replace(/:id/i, userId);

                yield webapi({
                    method: "put",
                    url: createAccountURL,
                    data: {
                        email: email,
                        password: password,
                        organizationId: organizationId
                    }
                });
            } catch (e) {
                // ignore this error. This could be because auth token is expired.
            }
        }
        // Trigger login action
        yield call(authSagas.loginUser, {payload: {email, password, token}});
        pushNotification.setTagsWhileCreatePasswordOrLogin(true, userId);
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_CREATEACCOUNT));
    }
    yield put(stopSubmit(form.CREATE_PASSWORD));
    yield put(trackingActions.submitCreatePassword());
    yield put(push(routeutility.getNextPage(route.APPLICATION_CREATE_PASSWORD_PAGE, true)));
}

// ---------------- [External Flow Pages] ----------------
export function* saveCreateAccountPage(action) {
    yield put(startSubmit(form.CREATE_ACCOUNT));
    try {
        let isEmailOTPEnabled = yield select(state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED]);
        let createAccountURL = route.WEB_API_USER_RESET_PASSWORD_BY_USERID.replace(/:id/i, action.payload.userId);
        let payload = {
            email: action.payload.email,
            password: action.payload.password,
            organizationId: utility.getOrganizationId(),
            isPrivacyPolicyAccepted: true
        };

        yield webapi({
            method: "put",
            url: createAccountURL,
            data: payload
        });

        // Trigger login action - This is not required because user is already logged in on welcome page.
        // yield put(authActions.loginAction(action.payload.email, action.payload.password));
        //Update field ISPASSWORDTEMPRORY to false
        yield put(applicationActions.saveCreateAccountSuccess(payload));
        yield put(trackingActions.submitCreateAccount());
        yield put(errorActions.clearApiError(null, field.ERROR_START_CREATEACCOUNT));
        if (!isEmailOTPEnabled){
            yield put(authActions.sendEmailVerification(action.payload.email, action.payload.userId));
        }
        yield put(push(routeutility.getNextPage(route.APPLICATION_EXTERNAL_CREATE_ACCOUNT_PAGE, false)));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_CREATEACCOUNT));
    }
    yield put(stopSubmit(form.CREATE_ACCOUNT));
}

function* savePhoneVerificationForExternalCustomers(action) {
    try {
        let customerId = yield select(state => state[field.REDUCER_NODE_AUTH][field.CUSTOMER_ID]);
        let userId = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.USERID]);
        let verifyPhoneURL = route.JAG_API_CUSTOMER_UPDATE_CONTACT_INFO.replace(/:customerId/i, customerId);

        const _stateSelector = state => ({
            isLoanDocsinDashboard:
                state[field.REDUCER_NODE_LOAN][field.LOAN_LIMITS_BY_ORG_AND_STATE][field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD],
            isNCAutoFundFlow: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_IS_NC_AUTO_FUND_FLOW],
            isOrganic: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_ORGANIC]
        });
        const _state = yield select(_stateSelector);

        let payload = {
            contactNumber: action.payload[field.PHONE_NUMBER],
            isMobile: action.payload[field.IS_MOBILE],
            receiveOffers: action.payload[field.IS_RECEIVE_OFFERS],
            organizationId: utility.getOrganizationId()
        };

        yield call(jagapi, {
            method: "post",
            url: verifyPhoneURL,
            data: payload
        });

        yield put(applicationActions.savePhoneNumberUpdate(action.payload[field.PHONE_NUMBER]));
        yield call(getVerificationStatus, {payload: {userId, contactNumber: action.payload[field.PHONE_NUMBER], isMobile: action.payload[field.IS_MOBILE]}});
        yield put(trackingActions.submitPhoneVerification());
        yield put(errorActions.clearApiError(null, field.ERROR_START_VERIFYPHONE));
        yield put(applicationActions.saveVerifyPhoneSuccess(payload));
        yield put(applicationActions.updateLoanApplicationProgress({[field.ISPHONEVERIFICATIONPAGESUBMITTED]: true}));
        let isShortform = yield select(
            state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_SHORTFORM]
        );
        let isKYC = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.ISKYC]);


        const stateSelector = state => ({
            isPhoneVerificationFlow:
                (state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_SMS_ENABLED] === true
                    && action.payload[field.IS_MOBILE] === true)
                || state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_LANDLINE_ENABLED],
            isPhoneVerified: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_PHONE_VERIFIED],
            isPhoneVerificationSmsEnabled: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_SMS_ENABLED],
            isPhoneVerificationLandlineEnabled: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_LANDLINE_ENABLED],
            isPhoneVerificationSkipped:
                state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_PHONE_VERIFICATION_SKIPPED]
        });
        const state = yield select(stateSelector);
        if (state.isPhoneVerificationFlow &&
            !state.isPhoneVerificationSkipped &&
            !state.isPhoneVerified
        ) {
            if (action.payload[field.IS_MOBILE] === true && state.isPhoneVerificationSmsEnabled === true) {
                yield put(applicationActions.sendVerificationCode(userId, action.payload[field.IS_MOBILE]));
            } else if (state.isPhoneVerificationLandlineEnabled === true) {
                yield put(applicationActions.sendVerificationCodeThroughCall(userId, action.payload[field.IS_MOBILE]));
            }
        }
        yield put(
            push(routeutility.getNextPageAfterVerifyPhonePage(isShortform, isKYC, _state.isLoanDocsinDashboard, _state.isNCAutoFundFlow, _state.isOrganic, state.isPhoneVerificationFlow))
        );
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_VERIFYPHONE));
    }
}

function* savePhoneVerificationForOrganicCustomers(action, id, userId, isRC) {
    try {

        let payload = {
            phone_number: action.payload[field.PHONE_NUMBER],
            is_mobile: action.payload[field.IS_MOBILE],
            receiveOffers: action.payload[field.IS_RECEIVE_OFFERS],
            organizationId: utility.getOrganizationId()
        };

        let savePersonalInfoURL = route.WEB_API_USER_SAVE_APPLICATION.replace(/:userAppId/i, id);
        const response = yield call(webapi, {
            method: "put",
            url: savePersonalInfoURL,
            data: payload
        });
        yield call(getVerificationStatus, {payload: {userId, contactNumber: action.payload[field.PHONE_NUMBER], isMobile: action.payload[field.IS_MOBILE]}});
        const _stateSelector = state => ({
            isLoanDocsinDashboard:
                state[field.REDUCER_NODE_LOAN][field.LOAN_LIMITS_BY_ORG_AND_STATE][field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD],
            isNCAutoFundFlow: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_IS_NC_AUTO_FUND_FLOW],
            isOrganic: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_ORGANIC],
            isPhoneVerificationFlow: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_SMS_ENABLED]
                || state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_LANDLINE_ENABLED],
            isPhoneVerificationSkipped:
                state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_PHONE_VERIFICATION_SKIPPED],
            hasMobile: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.PHONE_VERIFICATION_HAS_MOBILE],
            isPhoneVerified: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_PHONE_VERIFIED],
            isPhoneVerificationSmsEnabled: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_SMS_ENABLED],
            isPhoneVerificationLandlineEnabled: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.IS_PHONE_VERIFICATION_LANDLINE_ENABLED]
        });
        const _state = yield select(_stateSelector);

        yield put(trackingActions.submitPhoneVerification());
        yield put(applicationActions.saveVerifyPhoneSuccess(payload));

        if (isRC) {
            yield put(applicationActions.updateLoanApplicationProgress({[field.ISPHONEVERIFICATIONPAGESUBMITTED]: true}));
        }

        yield put(applicationActions.saveApplicationSuccess(response));
        if (_state.isPhoneVerificationFlow &&
            !_state.isPhoneVerificationSkipped &&
            !_state.isPhoneVerified
        ) {
            if (_state.hasMobile === true && _state.isPhoneVerificationSmsEnabled === true) {
                yield put(applicationActions.sendVerificationCode(userId, _state.hasMobile));
            } else if (_state.hasMobile === false && _state.isPhoneVerificationLandlineEnabled === true) {
                yield put(applicationActions.sendVerificationCodeThroughCall(userId, _state.hasMobile));
            }
        }
        yield put(
            push(routeutility.getNextPageAfterVerifyPhonePage(false, false, _state.isLoanDocsinDashboard, _state.isNCAutoFundFlow, _state.isOrganic, _state.isPhoneVerificationFlow))
        );
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_VERIFYPHONE));
    }
}

export function* savePhoneVerificationPage(action) {
    yield put(startSubmit(form.PHONE_VERIFICATION));
    let data = {
        isOrganic: yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_ORGANIC]),
    };

    let id = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.ID]);
    let userId = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.USERID]);
    let isRC = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.ISRC]);
    if (data.isOrganic) {
        yield * savePhoneVerificationForOrganicCustomers(action, id, userId, isRC);
    } else {
        yield * savePhoneVerificationForExternalCustomers(action);

    }
    yield put(stopSubmit(form.PHONE_VERIFICATION));
}

// ---------------- [KYC] ----------------
export function* getKYCQuestions(action) {
    try {
        let url = route.JAG_API_KYC_GET_KYC_QUESTIONS.replace(
            /:leadSequenceId/i,
            action.payload.leadSequenceId
        ).replace(/:loanHeaderId/i, action.payload.loanHeaderId);
        let response = yield jagapi({
            method: "get",
            url
        });
        yield put(applicationActions.getKYCQuestionsSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_KYC_QUESTIONS));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_KYC_QUESTIONS));
    }
}

export function* saveKYCQA(action) {
    yield put(startSubmit(form.KNOW_YOUR_CUSTOMER));
    try {
        let url = route.JAG_API_KYC_ANSWERS;
        let response = yield jagapi({
            method: "post",
            url,
            data: action.payload
        });

        yield put(trackingActions.submitKYCPage());

        yield put(applicationActions.saveKYCQASuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_SAVE_KYC_QA));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SAVE_KYC_QA));
    }
    yield put(stopSubmit(form.KNOW_YOUR_CUSTOMER));
}

export function* saveKYCTimeout(action) {
    try {
        let url = route.JAG_API_KYC_TIMEOUT.replace(/:loanHeaderId/i, action.payload.loanHeaderId).replace(
            /:leadSequenceId/i,
            action.payload.leadSequenceId
        );
        yield jagapi({
            method: "get",
            url
        });
        yield put(trackingActions.submitKYCTimeoutPage());
        yield put(push(routeutility.getRejectPage(constant.REJECT_KYC)));
    } catch (error) {
        yield put(push(routeutility.getRejectPage(constant.REJECT_KYC)));
    }
}

export function* getKYCReapplyTime(action) {
    try {
        let url = route.JAG_API_KYC_REAPPLY_TIME.replace(/:loanHeaderId/i, action.payload.loanHeaderId);
        let response = yield jagapi({
            method: "get",
            url
        });
        yield put(applicationActions.getKYCReapplyTimeSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_KYC_REAPPLY_TIME));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_KYC_REAPPLY_TIME));
    }
}

export function* isCreditfreshSupported(action) {
    try {
        let brandStateStatusUrl = route.WEB_API_BRAND_STATE_STATUS;
        brandStateStatusUrl = brandStateStatusUrl
            .replace(/:brandName/i, constant.BRAND_CREDITFRESH)
            .replace(/:stateCode/i, action.payload.stateKey);

        const [brandStateStatusResponse] = yield all([
            call(jagapi, {
                method: "get",
                url: brandStateStatusUrl
            })
        ]);

        let stateStatus = brandStateStatusResponse.data;
        if (stateStatus.organizationId){
            let data = {};
            data.associatedOrg = stateStatus.organizationId;
            yield put(applicationActions.updateUserLoginInfo(data));
        }

        if (utility.isStateSupported(stateStatus.isSupported, stateStatus.isActive, stateStatus.isPendingLicense)) {
            yield put(applicationActions.isCreditfreshSupportedSuccess(true));
            yield put(applicationActions.setCreditFreshLimit(stateStatus.maxAmount));
        } else {
            yield put(applicationActions.isCreditfreshSupportedSuccess(false));
        }
        yield put(errorActions.clearApiError(null, field.ERROR_IS_CREDITFRESH_SUPPORTED));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_IS_CREDITFRESH_SUPPORTED)
        );
    }
}

export function* saveOptOutPreferences(action) {
    try {
        let url = route.WEB_API_OPTOUT_SAVE_PREFERENCES;
        let response = yield webapi({
            method: "post",
            url,
            data: action.payload
        });
        yield put(applicationActions.saveOptOutPreferencesSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_SAVE_OPTOUT_PREFERENCES));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SAVE_OPTOUT_PREFERENCES)
        );
    }
}

export function* getRejectPageCampaignUrl(action) {
    try {
        let tierkey;
        let testTierkey = yield select(
            state =>
                state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][
                    field.ORGANIZATION.ORGANIZATION_TEST_TIERKEY
                ]
        );
        let prodTierkey = yield select(
            state =>
                state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][
                    field.ORGANIZATION.ORGANIZATION_PROD_TIERKEY
                ]
        );
        let buildProfile = yield select(
            state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE]
        );
        let email = yield select(
            state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.EMAIL]
        );
        let usState = yield select(
            state =>
                state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.STATE] ||
                state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.PERSONAL_INFO][field.DASHBOARD.STATE]
        );
        if (usState === constant.STATE_NEW_MEXICO && utility.getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID) {
            usState = 'zz';
        }
        let zip = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.ZIP]);
        let amount = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.AMOUNT]);
        let customerId = yield select(
            state => state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.ACCOUNT][field.DASHBOARD.CUSTOMER_ID]
        );

        let redirectUnsupported = action.payload.redirectUnsupported;
        tierkey = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.TIERKEY]);
        if (!tierkey) {
            tierkey = utility.getOrganizationTierKey(buildProfile, testTierkey, prodTierkey);
        }

        let url = route.WEB_API_GET_CAMPAIGN_URL;

        let payload = {
            state: usState,
            organizationId: utility.getOrganizationId(),
            requestedLoanAmount: amount,
            email: email,
            tierkey,
            zip,
            customerId,
            redirectUnsupported
        };

        let response = yield webapi({
            method: "post",
            url,
            data: payload
        });

        window.location.href = utility.getRedirectUrl(response.data.url, buildProfile, usState, email);
        yield put(authActions.logoutAction(true));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(
                utility.getAPIErrorPayload(errorMessages.GENERIC_ERROR_MESSAGE),
                null
            )
        );
    }
}

export function* getReapplyApplicationData(action) {
    try {
        let userId = yield select(state => state[field.REDUCER_NODE_AUTH][field.USERID]);
        let orgId = utility.getOrganizationId();
        let url = route.RAL_REAPPLY_URL.replace(/:userId/i, userId).replace(/:orgId/i, orgId);

        const reapplyData = yield call(webapi, {
            method: "get",
            url
        });

        yield put(errorActions.clearApiError(null, field.ERROR_REAPPLY_RAL_LOAN));
        yield put(applicationActions.getReapplyApplicationDataSuccess(reapplyData));

        if(action.payload.isFromAccountPage === true) {
            yield put(push(route.REAPPLY_APPLICATION_REQUIREMENTS_PAGE));
        } else {
            yield put(push(route.REAPPLY_PERSONAL_INFO_PAGE));
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_REAPPLY_RAL_LOAN));
    }
}

export function* submitRALApplicationData(action) {
    try {

        const mcIdentityState = yield select(state => state[field.REDUCER_NODE_MC_IDENTITY]);
        const isMcIdentityPackageLoading = mcIdentityState[MC_IDENTITY.IS_MC_IDENTITY_PACKAGE_LOADING];
        if (isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED) {
            const mcIdentityData = getMcInsightsDataAndClear(MC_IDENTITY_PLACEMENT_BOT_DETECTION, false);
            clearBehaviouralData();
            yield call(postMcIdentityData, {
                payload: {
                    mcIdentityData,
                    productType: MC_IDENTITY_PLACEMENT_BOT_DETECTION,
                    mcIdentityState
                }
            });
            yield put(mcIdentityAction.updatedMcIdentityDetectionSubmittedValue(MC_IDENTITY_PLACEMENT_BOT_DETECTION, false));
        }

        let userId = yield select(state => state[field.REDUCER_NODE_AUTH][field.USERID]);
        let orgId = utility.getOrganizationId();
        action.payload.data.dom = utility.normalizeDom(action.payload.data.choices);
        let url = route.RAL_REAPPLY_URL.replace(/:userId/i, userId).replace(/:orgId/i, orgId);
        const ralData = yield call(webapi, {
            method: "post",
            url,
            data: action.payload.data
        });

        yield put(errorActions.clearApiError(null, field.ERROR_REAPPLY_RAL_LOAN));
        yield put(trackingActions.submitReapplyRALLoan(action.payload.productName, action.payload.flowName, "LF2"));

        yield put(applicationActions.submitRALApplicationDataSuccess(ralData));

        if (ralData.data.Response.Result === constant.HOTBOX_ACCEPT) {
            yield put(loanActions.updateActiveLoanDetails(ralData));
            if (!_ip) {
                try {
                    _ip = yield getIpAddress();
                } catch (err) {}
            }

            let data = yield select(state => ({
                documentTypes: utility.getConsentDocumentsListOnAccountCreation(
                    state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT_KEYS][
                        field.ORGANIZATION.ORG_CONTENT_KEY_STATE
                    ]
                ),
                mapDocTypeAndName: utility.getConsentDocumentsListWithNamesOnAccountCreation(state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT_KEYS][
                        field.ORGANIZATION.ORG_CONTENT_KEY_STATE
                    ]),
                eConsent: true,
                ssaConsent: utility.getOrganizationId() === 2,
                haveExistingFlexLoan: false,
                userName: state[field.REDUCER_NODE_AUTH][field.USERAUTH][field.EMAIL],
                organizationId:
                    state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][
                        field.ORGANIZATION.ORGANIZATION_ID
                    ],
                state:
                    state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT_KEYS][
                        field.ORGANIZATION.ORG_CONTENT_KEY_STATE
                    ]
            }));

            const consentResponse = yield call(webapi, {
                method: "post",
                url: route.WEB_API_SAVE_CONSENT_CONTENT,
                headers: {
                    "User-Ip": _ip
                },
                data
            });

            yield put(errorActions.clearApiError(null, field.ERROR_SAVE_CONSENT_DOCUMENT));
            yield put(getConsentContentSuccess(consentResponse));
            yield put(authActions.getLandingPage(userId, route.REAPPLY_EMPLOYMENT_INFO_PAGE));
        } else {
            yield put(push(route.APPLICATION_REJECT_PAGE));
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_REAPPLY_RAL_LOAN));
    }
}

export function* validateDMCode(action) {
    try {
        let userId = yield select(state => state[field.REDUCER_NODE_AUTH][field.USERID]);
        let url = route.RAL_VALIDATE_DM_CODE.replace(/:userId/i, userId);
        const dmData = yield call(webapi, {
            method: "post",
            url,
            data: action.payload
        });

        yield put(errorActions.clearApiError(null, field.ERROR_VALIDATE_DM_CODE));
        yield put(applicationActions.validateDMCodeSuccess(dmData));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_VALIDATE_DM_CODE));
    }
}

export function* validateDMApplicationCode(action) {
    try {
        let url = route.VALIDATE_DM_APPLICATION_CODE;
        const dmData = yield call(webapi, {
            method: "post",
            url,
            data: action.payload
        });
        yield put(applicationActions.validateDMApplicationCodeSuccess(dmData, action.payload.email));
        yield put(errorActions.clearApiError(null, field.ERROR_VALIDATE_DM_CODE));

        if (dmData.data.message === null) {
            if (dmData.data.lmsCustomerPromotions.organizationId === utility.getOrganizationId()) {
                yield put(push(`${route.APPLICATION_STATE_INFO_PAGE}`));
            } else {
                const _stateSelector = state => ({
                    buildProfile: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE],
                    formValues: getFormValues(form.OFFER_FORM)(state)
                });
                const _state = yield select(_stateSelector);
                let url = utility.getStateInfoUrl(dmData.data.lmsCustomerPromotions.state, _state.buildProfile, dmData.data.lmsCustomerPromotions.organizationId);
                window.location.href = url + `&email=${_state.formValues[fieldtags.LOGIN_EMAIL_ADDRESS]}&promoCode=${_state.formValues[fieldtags.PERSONALINFO_DM_CODE]}&l4ssn=${_state.formValues[fieldtags.LOGIN_FOUR_DIGITS_SSN]}`
            }

        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_VALIDATE_DM_CODE));
    }
}

export function* updateLoanApplicationProgress(action) {
    try {
        let _stateSelector = state => ({
            userId: state[field.REDUCER_NODE_AUTH][field.USERID]
        });
        const _state = yield select(_stateSelector);

        let updateLoanApplicationProgressURL = route.WEB_API_USERLOANINFO_UPDATE_PROGRESS.replace(
            /:userId/i,
            _state.userId
        );

        yield webapi({
            method: "put",
            url: updateLoanApplicationProgressURL,
            data: action.payload
        });
    } catch (error) {
        // Ignore error. We dont want to create notification.
    }
}

export function* saveSubIdTracking(action) {
    try {
        const _stateSelector = state => ({
            id: state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.ID],
            userId: action.payload.userId ? action.payload.userId : state[field.REDUCER_NODE_AUTH][field.USERID],
            email: action.payload.email ? action.payload.email : state[field.REDUCER_NODE_AUTH][field.USERAUTH][field.EMAIL],
            organizationId: utility.getOrganizationId(),
            subId: localStorageUtility.getLcalStorageValue('subId1') || null,
            subId2: localStorageUtility.getLcalStorageValue('subId2') || null,
            subId3: localStorageUtility.getLcalStorageValue('subId3') || null,
            clickId: localStorageUtility.getLcalStorageValue('clickId') || null,
            adId: localStorageUtility.getLcalStorageValue('adId') || null,
            utmCampaign: localStorageUtility.getLcalStorageValue('utmCampaign') || null,
            merchantId: localStorageUtility.getLcalStorageValue('amid') || null,
            ranSiteId: localStorageUtility.getLcalStorageValue('atrv') || null,
            subIdLandingTime: localStorageUtility.getLcalStorageValue('ald') || null,
            tierKey: localStorageUtility.getLcalStorageValue('tierkey') || null,
            tierKeyUsed: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.TIERKEY],
            gclId: state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.GCLID]
                || localStorageUtility.getLcalStorageValue('gclId'),
            fbcId:state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.FBC]
                || localStorageUtility.getLcalStorageValue('fbc'),
            fbpId:state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.FBP]
                || localStorageUtility.getLcalStorageValue('fbp'),
            cqSrc:state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.CQ_SRC]
                ?state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.CQ_SRC]:localStorageUtility.getLcalStorageValue(CQ_SRC),
        });

        const _state = yield select(_stateSelector);


        if(!_state.userId){
            console.log("No user id to save sub id tracking");
            return;
        }

        if(_state.id){
            console.log("The subid record is already saved. No need to send request again to save the same record.");
            return;
        }

        if(!_state.tierKey){
            console.log("Sub id record without tierkey. This is not possible. So return it without saving it")
            return;
        }
        if (_state.cqSrc) {
            if (_state.cqSrc === constant.GOOGLE_ADS) {
                _state.fbcId = null;
                _state.fbpId = null;
            } else if (_state.cqSrc === constant.FACEBOOK_ADS) {
                _state.gclId = null;
            } else {
                _state.fbcId = null;
                _state.fbpId = null;
                _state.gclId = null;
            }
        }
        let url = route.WEB_API_USER_SAVE_SUB_ID_TRACKING;
        let response = yield webapi({
            method: "post",
            url,
            data: _state
        });
        yield put(applicationActions.saveSubIdTrackingSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_SAVE_SUB_ID_TRACKING));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SAVE_SUB_ID_TRACKING)
        );
    }
}

export function* getSubIdTracking(action) {
    try {
        let url = route.WEB_API_USER_GET_SUB_ID_TRACKING
            .replace(/:userId/i, action.payload.userId)
            .replace(/:organizationId/i, action.payload.organizationId);

        let response = yield webapi({
            method: "get",
            url
        });
        yield put(applicationActions.getSubIdTrackingSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_SUB_ID_TRACKING));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_SUB_ID_TRACKING)
        );
    }
}

export function* saveProductChangePopupAcknowledgement(action) {
    try {
        let sanitizedUserInfo = utility.sanitizeUserInfo(action.payload[field.USERAPPINFO]);
        let savePersonalInfoURL = route.WEB_API_USER_SAVE_APPLICATION.replace(/:userAppId/i, sanitizedUserInfo.id);
        const response = yield webapi({
            method: "put",
            url: savePersonalInfoURL,
            data: sanitizedUserInfo
        });

        yield put(applicationActions.saveProductChangePopupAcknowledgementSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT));
    }
}

export function* getBankingInformationForRC(action) {
    try {
        let getBankingInformationForRCURL = route.JAG_API_CUSTOMER_GET_BANKING_INFORMATION_FOR_RC.replace(/:customerId/i, action.payload.customerId);
        const response = yield jagapi({
            method: "get",
            url: getBankingInformationForRCURL
        });

        yield put(applicationActions.getBankingInformationForRCSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_BANK_INFO));
    }
}

export function* getVerificationStatus(action) {
    try {

        let url = route.WEB_API_USER_CHECK_VERIFICATION_STATUS.replace(/:userId/i, action.payload.userId);
        let data = {
            contactNumber: action.payload.contactNumber,
            isMobile: action.payload.isMobile
        };
        let response = yield webapi({
            method: "post",
            url,
            data
        });

        yield put(applicationActions.getVerificationStatusSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_OTP_SEND_CODE));

    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_OTP_SEND_CODE));
    }
}

export function* sendVerificationCode(action) {
    try {
        let url = route.WEB_API_USER_SEND_VERIFICATION_CODE.replace(/:userId/i, action.payload.userId);
        let response = yield webapi({
            method: "post",
            url,
            data: {
                isMobile: action.payload.isMobile
            }
        });

        if (response.data.message === "SENT") {
            yield put(createAndTrackToastMessageAction.createAndTrackSuccessMessage("Verification code sent"));
        } else if (response.data.message === constant.PHONE_VERIFICATION_STATUS_MESSAGE.PHONE_VERIFICATION_STATUS_SMS_TIME_LIMIT) {
            yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(utility.getAPIErrorPayload(errorMessages.OTP_VERIFICATION_CODE_ALREADY_SENT), null));
        }

        yield put(applicationActions.sendVerificationCodeSuccess(response));

    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_OTP_SEND_CODE));
    }
}

export function* sendVerificationCodeThroughCall(action) {
    try {
        let url = route.WEB_API_USER_SEND_VERIFICATION_CODE_THROUGH_CALL.replace(/:userId/i, action.payload.userId);
        let response = yield webapi({
            method: "post",
            url,
            data: {
                isMobile: action.payload.isMobile
            }
        });

        if (response.data.message === "SENT_VIA_CALL") {
            yield put(createAndTrackToastMessageAction.createAndTrackSuccessMessage("Verification code sent"));
        } else if (response.data.message === constant.PHONE_VERIFICATION_STATUS_MESSAGE.PHONE_VERIFICATION_STATUS_SMS_TIME_LIMIT) {
            yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(utility.getAPIErrorPayload(errorMessages.OTP_VERIFICATION_CODE_ALREADY_SENT), null));
        }

        yield put(applicationActions.sendVerificationCodeSuccess(response));

    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_OTP_SEND_CODE));
    }
}

export function* submitVerificationCode(action) {
    try {

        yield put(startAsyncValidation(action.payload.form));
        let url = route.WEB_API_USER_SUBMIT_PHONE_VERIFICATION_CODE.replace(/:userId/i, action.payload.userId);
        let response = yield webapi({
            method: "post",
            url,
            data: {
                verificationCode: action.payload.verificationCode,
                isMobile: action.payload.hasMobile
            }
        });

        yield put(applicationActions.submitVerificationCodeSuccess(response));
        if(response.data.message === constant.PHONE_VERIFICATION_STATUS_MESSAGE.PHONE_VERIFICATION_STATUS_INVALID_CODE ||
            response.data.message === constant.PHONE_VERIFICATION_STATUS_MESSAGE.PHONE_VERIFICATION_STATUS_CODE_EXPIRED) {
            yield put(
                stopAsyncValidation(action.payload.form, {
                    [action.payload.field]: errorMessages.PHONE_VERIFICATION_CODE_INVALID_CODE
                })
            );
        }

        //if successful, add system note
        if(response.data.message === constant.PHONE_VERIFICATION_STATUS_MESSAGE.PHONE_VERIFICATION_STATUS_SUCCESS && action.payload.baseLoanId) {
            let url = route.WEB_API_USER_ADD_PHONE_VERIFICATION_NOTE
                .replace(/:userId/i, action.payload.userId)
                .replace(/:baseLoanId/i, action.payload.baseLoanId);
            yield webapi({
                method: "post",
                url
            });
        }

    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_OTP_VERIFY_CODE));
    }

}

export function* skipPhoneVerification(action) {
    try {

        let url = route.WEB_API_USER_SKIP_PHONE_VERIFICATION_CODE.replace(/:userId/i, action.payload.userId);
        let response = yield webapi({
            method: "post",
            url
        });

        yield put(applicationActions.getVerificationStatusSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_OTP_SEND_CODE));

    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_OTP_SEND_CODE));
    }
}

export function* verifyPassword(action) {
    try {
        let _ip = yield getIpAddress();

        yield put(startAsyncValidation(action.payload.form));
        let url = route.WEB_API_USER_SUBMIT_PASSWORD_VERIFICATION.replace(/:userId/i, action.payload.userId);
        let response = yield webapi({
            method: "post", url, data: {
                password: action.payload.password,
                ipAddress: _ip
            }
        });
        if (response.data === false) {
            yield put(stopAsyncValidation(action.payload.form, {
                [action.payload.field]: errorMessages.MFA_INCORRECT_PASSWORD_ERR_CODE
            }));
        }
        yield put(trackingActions.submitEnableMfaPage());
        yield put(applicationActions.submitPasswordVerificationSuccess(response));

    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERR_PASSWORD_VERIFICATION));
    }

}

export function* sendMfaVerificationCode(action) {
    try {
        let _ip;
        try {
            _ip = yield getIpAddress();
        } catch (err) {}
        let isRC = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_RC_LOGIN_FOR_MFA]);
        yield put(startAsyncValidation(action.payload.form));
        let url;
        let response;
        if(action.payload.mfaSetupRequest){
            url = route.WEB_API_SEND_MFA_VERIFICATION_CODE.replace(/:userId/i, action.payload.userId);
            response = yield webapi({
                method: "post",
                url: url
            });
        } else {
            url = WEB_API_RESEND_LOGIN_MFA_CODE.replace(/:contactType/i, action.payload.contactType);
            response = yield webapi({
                method: "post",
                url: url,
                auth: {
                    username: action.payload.email,
                    password: action.payload.password
                },
                headers: {
                    organizationId: yield select(
                        state => state[field.REDUCER_NODE_ORGANIZATION][organization.CONFIG][organization.ORGANIZATION_ID]
                    ),
                    rcLogin: isRC,
                    "X-Ip-Address":  _ip,
                    "X-Page": ""
                }
            });
        }
        if (response.status === 200) {
            if(!!response.data){
                if (response.data.message === MfaConstant.ERR_MFA_MAX_RETRY_REACHED || response.data.message === MfaConstant.ERR_MFA_MAX_RESEND_REACHED) {
                    yield put(applicationActions.sendMfaVerificationCodeErrorMessage(response));
                } else if (response.data.message === MfaConstant.ERR_MFA_EARLY_RESEND) {
                    yield put(authActions.createAndTrackMfaErrorMessage(utility.getAPIErrorPayload(errorMessages.AUTHENTICATION_CODE_ALREADY_SENT), null));
                } else if (response.data.message === MfaConstant.ERR_MFA_CODE_EXPIRED) {
                    yield put(authActions.createAndTrackMfaErrorMessage(utility.getAPIErrorPayload("Verification code expired."), null));
                } else {
                    if(response.data.contactType === constant.OTP_PROCESSDATA_TYPE.OTP_TYPE_EMAIL &&
                        action.payload.contactType !== constant.OTP_PROCESSDATA_TYPE.OTP_TYPE_EMAIL){
                        yield put(applicationActions.sendMfaSMSServiceDown(true));
                    }
                    yield put(authActions.createAndTrackMfaSuccessMessage("Authentication code sent!"));
                }
                yield put(applicationActions.sendMfaVerificationCodeSuccess(response));
            }

        }
    } catch (error) {
        yield put(authActions.createAndTrackMfaErrorMessage(error, field.ERR_PASSWORD_VERIFICATION));
    }

}

export function* submitMfaVerificationCode(action) {
    try {
        let _ip;
        try {
            _ip = yield getIpAddress();
        } catch (err) {}
        yield put(startAsyncValidation(action.payload.form));
        let isRC = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_RC_LOGIN_FOR_MFA]);
        let response;
        if (action.payload.mfaSetupRequest) {
            response = yield webapi({
                method: "post",
                url: route.WEB_API_VERIFY_MFA_CODE.replace(/:userId/i, action.payload.userId),
                data: {
                    mfaCode: action.payload.verificationCode
                }
            });
        } else {
            response = yield webapi({
                method: "post",
                url: route.WEB_API_VERIFY_LOGIN_MFA_CODE,
                auth: {
                    username: action.payload.email,
                    password: action.payload.password
                },
                headers: {
                    organizationId: yield select(
                        state => state[field.REDUCER_NODE_ORGANIZATION][organization.CONFIG][organization.ORGANIZATION_ID]
                    ),
                    rcLogin: isRC,
                    "X-Ip-Address":  _ip,
                    "X-Page": ""
                },
                data: {
                    mfaCode: action.payload.verificationCode
                }
            });
        }
        response.isForLogin = action.payload.mfaSetupRequest;
        if (!action.payload.mfaSetupRequest && response.data.isMfaEnabled === false) {
            yield put(stopAsyncValidation(action.payload.form, {
                [action.payload.field]: errorMessages.MFA_INCORRECT_PASSWORD_ERR_CODE
            }));
        }
        if(!!response.data.jSessionId){
            _setAuthTokeninAxiosHeader(response.data.jSessionId);
            yield put(authActions.updateSessionIdPostMfa(response));
            yield call(saveSubIdTracking, {payload: {userId: action.payload.userId, email: action.payload.email}});
            yield put(applicationActions.checkIfRCForMfaLogin(false));
        }
        if (response.status === 200) {
            if (response.data.message === MfaConstant.ERR_MFA_MAX_RETRY_REACHED) {
                yield put(authActions.isEmailVerificationSuccess(false));
                yield put(errorActions.clearApiError(response.data.message, field.ERROR_LOGIN_BLOCKED));
                yield put(applicationActions.sendMfaVerificationCodeErrorMessage(response));
            } else if (response.data.message === MfaConstant.ERR_MFA_INVALID_VERIFICATION_CODE ||
                response.data.message === MfaConstant.ERR_MFA_CODE_EXPIRED) {

                yield put(stopAsyncValidation(action.payload.form, {
                    [action.payload.field]: errorMessages.AUTHENTICATION_CODE_INVALID_CODE
                }));
                yield put(applicationActions.mfaVerificationCodeResponse());
            } else {
                yield put(applicationActions.submitMfaVerificationSuccess(response));
                yield put(errorActions.clearApiError(null, field.ERROR_LOGIN));
            }
        }

    } catch (error) {
        yield put(authActions.createAndTrackMfaErrorMessage(error, field.ERR_PASSWORD_VERIFICATION));
    }

}

export function* checkIfMfaEnabled(action) {
    try {
        if(action.payload.userId){
            let url = route.WEB_API_CHECK_IF_MFA_ENABLED.replace(/:userId/i, action.payload.userId);
            let response = yield webapi({
                method: "post", url
            });
            yield put(applicationActions.isMfaEnabled(response));
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERR_PASSWORD_VERIFICATION));
    }

}

export function* checkIfMfaEnabledForOrg(action) {
    try {
        let url = route.WEB_API_CHECK_IF_MFA_ENABLED_FOR_ORG.replace(/:userId/i, action.payload.userId);
        let response = yield webapi({
            method: "post", url
        });
        yield put(applicationActions.isMfaEnabledForOrg(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERR_PASSWORD_VERIFICATION));
    }

}

export function* disableMfa(action) {
    try {
        let url = route.WEB_API_DISABLE_MFA.replace(/:userId/i, action.payload.userId);
        let response = yield webapi({
            method: "post", url
        });
        yield put(trackingActions.submitDisableMfaPage());
        yield put(applicationActions.isMfaDisabled(response));
        yield put(createAndTrackToastMessageAction.createAndTrackSuccessMessage(constant.MFA_SYSTEM_MESSAGE_DISABLED));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERR_PASSWORD_VERIFICATION));
    }

}

export function* checkIfMfaNotificationCanBeShown(action) {
    try {

        let url = route.WEB_API_CHECK_IF_MFA_SHOW_NOTIFICATION.replace(/:userId/i, action.payload.userId);
        let response = yield webapi({
            method: "post", url
        });
        yield put(applicationActions.showMfaNotificationSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERR_PASSWORD_VERIFICATION));
    }
}

export function* skipMfaNotificationScreen(action) {
    try {
        let url = route.WEB_API_SKIP_MFA_NOTIFICATION_SCREEN.replace(/:userId/i, action.payload.userId);
        yield webapi({
            method: "post", url
        });
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERR_PASSWORD_VERIFICATION));
    }
}

export function* sendOtpLoginEmail(action) {
    try {
        let url = route.WEB_API_SEND_OTP_LOGIN_EMAIL;
        let response = yield webapi({
            method: "post", url,
            data: {
                organizationId: utility.getOrganizationId(),
                email: action.payload.email
            }
        });

        if(response.data.responseMessage === constant.OTP_EMAIL_VERIFICATION_EARLY_RESEND) {
            yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(utility.getAPIErrorPayload("Verification code already sent.\nPlease try again in 1 minute."), null));
        } else {
            yield put(authActions.createAndTrackMfaSuccessMessage("Verification code sent!"));
            yield put(applicationActions.sendOtpLoginEmailSuccess(response));
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* submitOtpLoginVerificationCode(action) {
    yield put(dashboardActions.setShowLoader());
    try {
        yield put(startAsyncValidation(action.payload.form));
        let url = route.WEB_API_SUBMIT_OTP_LOGIN_VERIFICATION_CODE;
        let response = yield webapi({
            method: "post", url,
            auth: {
                username: action.payload.email,
                password: action.payload.verificationCode
            },
            headers: {
                organizationId: yield select(
                    state => state[field.REDUCER_NODE_ORGANIZATION][organization.CONFIG][organization.ORGANIZATION_ID]
                ),
                "X-Page": "email-confirmation-page"
            },
            data: {
                organizationId: utility.getOrganizationId(),
                email: action.payload.email,
                verificationCode: action.payload.verificationCode,
            }
        });
        if(!!response.data.jSessionId && !!response.data.userId){
            _setAuthTokeninAxiosHeader(response.data.jSessionId);
            yield put(authActions.updateSessionIdPostOtpLogin(response));
            yield put(authActions.isEmailVerificationSuccess(true));
            yield put(authActions.addEmailToAuth(action.payload.email));
            yield put(applicationActions.submitOtpLoginVerificationCodeSuccess(response));
            yield put(trackingActions.submitConfirmItsYouOTPEmailVerificationPage());
            yield put(authActions.getLandingPage(response.data.userId, routes.OTP_EMAIL_LOGIN_PAGE));
        }

        yield put(dashboardActions.unsetShowLoader());

        //yield put(applicationActions.mfaVerificationCodeResponse());
    } catch (error) {
        yield put(stopAsyncValidation(action.payload.form, {
            [action.payload.field]: errorMessages.OTP_VERIFICATION_CODE_INVALID_CODE
        }));
        yield put(dashboardActions.unsetShowLoader());
        yield put(applicationActions.submitOtpLoginVerificationCodeIncorrect());
    }
}

export function* saveOneSignalUsers(action) {
    try {
        if (utility.isNullOrUndefined(action.payload.data.playerId))
            return;

        let getOneSignalUserUrl = route.GET_ONE_SIGNAL_USER
            .replace(/:playerId/i, action.payload.data.playerId);

        let responseOfUser = yield webapi({
            method: "get",
            url: getOneSignalUserUrl
        });

        if (!!responseOfUser && !!responseOfUser.data && !!responseOfUser.data.customerId
            && responseOfUser.data.customerId === action.payload.data.customerId)
            return;

        let url = route.CREATE_ONE_SIGNAL_USER
            .replace(/:userId/i, action.payload.data.userId)
            .replace(/:organizationId/i, action.payload.data.organizationId);
        let response = yield webapi({
            method: "post", url,
            data: action.payload.data
        });
        yield put(applicationActions.saveOneSignalUserSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERR_PASSWORD_VERIFICATION));
    }
}

export function* validatePartnerParam(action) {
    try {
        let validatePartnerParamUrl = route.VALIDATE_PARTNER_PARAM
            .replace(/:organizationId/i, utility.getOrganizationId);

        let response = yield webapi({
            method: "post",
            url: validatePartnerParamUrl,
            data: action.payload
        });

        if (!response.data.isValid) {
            yield put(push(route.APPLICATION_ACCESS_DENIED_PAGE));
        } else {
            yield put(applicationActions.validatePartnerParamSuccess(action.payload.tierkey, action.payload.externalReferenceId, action.payload.signature, action.payload.url));
            if (response.data.isExternalReferenceIdExists) {
                yield put(push(route.LOGIN))
            }
        }

    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERR_PARTNER_PARAM_VALIDATION));
    }
}

function* sendRejectEventUnsupportedState(action) {
    try {
        if (utility.isKohoLineOfCredit()) {
            let externalRefId = yield select(state => state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.EXTERNAL_REFERENCE_ID]);
            let userId = yield select(state => state[field.REDUCER_NODE_AUTH][field.USERID]);
            let sendRejectEventUrl = route.WEB_API_REJECT_EVENT_UNSUPPORTED_STATE
                .replace(/:organizationId/i, utility.getOrganizationId);

            let response = yield webapi({
                method: "post",
                url: sendRejectEventUrl,
                data: {
                    externalRefId: externalRefId, userId: userId
                }
            });
            if (response.status !== 200) {
                yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(utility.getAPIErrorPayload(errorMessages.GENERIC_ERROR_MESSAGE), null));
            }
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}
