import {select, put} from "redux-saga/effects";

import webapi from "../../axios/webapi";
import {getIpAddress} from "../../shared/utility/ipify";
import * as routes from "../../shared/constant/route";
import * as field from "../../shared/constant/field";
import * as utility from "../../shared/utility/utility";
import * as errorActions from "../action/ErrorAction";
import * as createAndTrackToastMessageAction from "../action/CreateAndTrackToastMessageAction";
import * as authActions from "../action/AuthAction";
import {push} from 'connected-react-router';
import * as mcIdentityAction from "../action/McIdentityAction";

let _ip;

function setTierKey(data, state) {
    if (!data.isExternal && utility.isEmptyOrNullString(data.tierKey)) {
        const buildProfile =
            state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE];
        const organizationProdTierKey =
            state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][
                field.ORGANIZATION.ORGANIZATION_PROD_TIERKEY
            ];
        const organizationTestTierKey =
            state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][
                field.ORGANIZATION.ORGANIZATION_TEST_TIERKEY
            ];

        data.tierKey = utility.getOrganizationTierKey(buildProfile, organizationTestTierKey, organizationProdTierKey);
    }
}

export function* tracking(action) {
    try {
        if (!_ip) {
            try {
                _ip = yield getIpAddress();
            } catch (err) {}
        }

        // the whole store state is retrived from the redux store
        const state = yield select();
        let data = {
            ...action.payload,
            organizationId: utility.getOrganizationId(),
            sessionId: state[field.REDUCER_NODE_AUTH][field.MKWEBAUTHTOKEN],
            tierKey: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.TIERKEY],
            userName: state[field.REDUCER_NODE_AUTH][field.USERAUTH][field.EMAIL],
            lmsCustomerId: state[field.REDUCER_NODE_AUTH][field.CUSTOMERID] || null,
            isExternal: !state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_ORGANIC],
            ipAddress: _ip,
            isLoanDocsinDashboard: state[field.REDUCER_NODE_LOAN][field.LOAN_LIMITS_BY_ORG_AND_STATE][field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD],
            lmsCode: state[field.REDUCER_NODE_AUTH][field.LMS_CODE],
            subIdTrackingId: state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.ID]
        };

        setTierKey(data, state);


        yield put(mcIdentityAction.postMcIdentityTRACKINGData( action.payload.page));


        yield webapi({
            method: "post",
            url: routes.WEB_API_TRACKING,
            data
        });
        yield put(errorActions.clearApiError(null, field.ERROR_TRACKING_BEACON));
    } catch (err) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(err, field.ERROR_TRACKING_BEACON));

        if(err.response && err.response.status === 401) {
            yield put(authActions.logoutAction(true));
            yield put(push(routes.LOGIN));
        }
    }
}


