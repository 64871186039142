const URL = "https://api.ipify.org?format=json";

let _ip = null;
// Waitover time in seconds
let waitOverTime = 5;
let totalAttempts = 0;
let maxAttempts = 3;
const defaultIp = "0.0.0.0";

let request;

export const getIpAddress = () => {
    let startTime = Date.now();
    let endTime = Date.now();
    while (_ip == null && (endTime - startTime) / 1000 < waitOverTime) {
        populaterIPAddress();
        endTime = Date.now();
    }

    // Cut the wait time in half and attempt to get the ip address again
    // Max attempt to get ip address is 3. After that ip value will be null or 0.0.0.0
    if ((_ip == null || _ip === defaultIp) && totalAttempts < maxAttempts) {
        waitOverTime = waitOverTime / 2;
        totalAttempts++;
        _ip = null;
        getIPAddressAsync();
    } else if (waitOverTime !== 0 && totalAttempts >= maxAttempts) {
        waitOverTime = 0;
    }

    return _ip;
};

export const getIPAddressAsync = () => {
    if (_ip !== null && _ip !== defaultIp) {
        return _ip;
    }
    initializeRequestObj();
    request.open("GET", URL, true);
    request.send(null);
};

/**
 * Populate IP Address
 */
function populaterIPAddress() {
    try {
        if (request.readyState === 4) {
            if (request.status === 200) {
                _ip = JSON.parse(request.responseText).ip;
            } else {
                _ip = defaultIp;
            }
        }
    } catch (e) {
        _ip = defaultIp;
    }
}

function initializeRequestObj() {
    request = new XMLHttpRequest();
    request.onreadystatechange = populaterIPAddress;
    request.onerror = function() {
        _ip = defaultIp;
    };
}
