import * as route from "../../shared/constant/route";
import {put, select} from "redux-saga/effects";
import * as utility from "../../shared/utility/utility";
import * as mcIdentityActions from "../action/McIdentityAction";
import * as field from "../../shared/constant/field";
import {MC_IDENTITY} from "../../shared/constant/field";
import {MESSAGE_TYPE_SUCCESS} from "../../shared/constant/constant";
import webapi from "../../axios/webapi";

export function* getMcIdentitySessionId() {
    try {
        const url = route.MC_IDENTITY_INSIGHTS_STATUS.replace(/:organizationId/, String(utility.getOrganizationId()));
        const response = yield webapi({
            method: "get",
            url
        });
        yield put(mcIdentityActions.setMcIdentityGlobalValues(response.data));
    } catch (error) {

    }
}

export function* postMcIdentityData(action) {
    try {
        const {mcIdentityData, productType, mcIdentityState} = action.payload;

        const url = route.MC_IDENTITY_INSIGHTS_SUBMIT_DATA;

        const data = {
            sessionId: mcIdentityState[MC_IDENTITY.MC_IDENTITY_SESSION_ID],
            data: mcIdentityData,
            productType,
            userId: yield select(state => state[field.REDUCER_NODE_AUTH][field.USERID]),
            result: MESSAGE_TYPE_SUCCESS,
            action: mcIdentityState[MC_IDENTITY.MC_IDENTITY_TRACKING_PAGE]
        };

        yield webapi({
            method: "post",
            url,
            data
        });
    } catch (error) {

    }
}
