import React from "react";
import { connect } from 'react-redux'
import * as field from '../shared/constant/field'
import * as constant from '../shared/constant/constant'
import ErrorBoundary from "./ErrorBoundary";
import Layout from "../components/layout/Layout";

class GlobalErrorBoundary extends React.Component {
    constructor(props){
        super(props)
        this.optionalTextFrag = {
            0: organizationWebsite => <a href={constant.PUBLIC_WEBSITE}>{organizationWebsite}</a>,
            1: contactUsText => <a href={this.props.content.common.contactUsURL}>{contactUsText}</a>
        }
    }

    render() {
        return (
            <ErrorBoundary Layout={Layout} errorContent={this.props.content.common.error} optionalTextFrag={this.optionalTextFrag}>
                {this.props.children}
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => ({
    content: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT]
})

export default connect(mapStateToProps, null)(GlobalErrorBoundary)
