import * as actionTypes from "./actionTypes";
import * as constant from "../../shared/constant/constant";

/**
 * Reset error reducer action
 * @returns {{type: string, payload: {}}}
 */
export const resetErrorReducer = () => {
    return {
        type: actionTypes.RESET_ERROR_REDUCER,
        payload: {}
    }
};

/**
 * @Deprecated
 * Use CreateAndTrackToastMessageAction
 * @param error
 * @param field
 * @returns {{payload: {from: string, error: *, key: *}, type: string}}
 */
export const createApiError = (error, field) => {
    return {
        type: actionTypes.API_ERROR_CREATE,
        payload: {
            from: constant.ERROR_FROM_API,
            key: field,
            error: error
        }
    };
};

export const clearApiError = (error, field) => {
    return {
        type: actionTypes.API_ERROR_CLEAR,
        payload: {
            from: constant.ERROR_FROM_API,
            key: field,
            error: error
        }
    };
};

