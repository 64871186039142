import React from 'react'
import P from 'prop-types'
import {NavLink, withRouter} from 'react-router-dom'

import {
    CONTENT_LOAN_TYPE_INSTALLMENT,
    CONTENT_LOAN_TYPE_LOC,
    STATUS_HEADER_FILTER,
} from '../../../shared/constant/loan'
import * as routes from '../../../shared/constant/route'
import { DASHBOARD_VIEW_ACCOUNT_PAGE } from '../../../shared/constant/route'
import { mapLoanTypes } from '../../../shared/utility/contentutility'
import * as constant from '../../../shared/constant/constant'

export const classes = {
    "nav-item": "nav-link align-content-center"
};

function NavBarLinks(props) {
    let productType = mapLoanTypes(props.productType);

    return (
        <React.Fragment>
            {props.lmsCode === constant.LMS_JAGLMS && (
                <NavLink className={classes["nav-item"]} activeClassName={"active"} onClick={props.toggler} to={DASHBOARD_VIEW_ACCOUNT_PAGE}>
                    Account
                </NavLink>
            )}
            {(!props.isTXEpicImport || (props.isTXEpicImport && props.loanItems.length !== 0)) &&
                (productType === CONTENT_LOAN_TYPE_INSTALLMENT &&
                (STATUS_HEADER_FILTER[props.status] &&
                    STATUS_HEADER_FILTER[props.status].historySEP &&
                    STATUS_HEADER_FILTER[props.status].historyNotClickable &&
                    !props.isRC) ? (
                    <span className={classes["nav-item"]}>Documents</span>
                ) : (
                    productType === CONTENT_LOAN_TYPE_INSTALLMENT &&
                    (STATUS_HEADER_FILTER[props.status] || STATUS_HEADER_FILTER[props.nonWithdrawnLoanStatus]) &&
                    ((STATUS_HEADER_FILTER[props.status] && STATUS_HEADER_FILTER[props.status].historySEP) ||
                        (STATUS_HEADER_FILTER[props.nonWithdrawnLoanStatus] &&
                            STATUS_HEADER_FILTER[props.nonWithdrawnLoanStatus].historySEP) ||
                        (STATUS_HEADER_FILTER[props.status] &&
                            STATUS_HEADER_FILTER[props.status].visibleForRC &&
                            props.isRC)) && (
                        <NavLink
                            id="nav-documents"
                            className={classes["nav-item"]}
                            activeClassName={"active"}
                            onClick={props.toggler}
                            to={routes.DASHBOARD_HISTORY_PAGE}
                        >
                            Documents
                        </NavLink>
                    )
                ))}
            {productType === CONTENT_LOAN_TYPE_LOC &&
            (STATUS_HEADER_FILTER[props.status] || STATUS_HEADER_FILTER[props.nonWithdrawnLoanStatus]) &&
            ((STATUS_HEADER_FILTER[props.status] && STATUS_HEADER_FILTER[props.status].statements) ||
                (STATUS_HEADER_FILTER[props.nonWithdrawnLoanStatus] && STATUS_HEADER_FILTER[props.nonWithdrawnLoanStatus].historyLOC) ||
                (STATUS_HEADER_FILTER[props.status] && STATUS_HEADER_FILTER[props.status].visibleForRC && props.isRC)) && (
                <NavLink className={classes["nav-item"]} activeClassName={"active"} onClick={props.toggler} to={routes.DASHBOARD_HISTORY_PAGE}>
                    Documents
                </NavLink>
            )}

            {props.lmsCode === constant.LMS_JAGLMS && (
                    <NavLink
                        className={classes["nav-item"]}
                        activeClassName={"active"}
                        onClick={props.toggler}
                        to={routes.DASHBOARD_VIEW_PROFILE_PAGE}
                    >
                        Profile
                    </NavLink>
                )}
        </React.Fragment>
    );
}

NavBarLinks.propTypes = {
    status: P.string,
    toggler: P.func.isRequired,
    productType: P.string,
    isRAL: P.bool,
    nonWithdrawnLoanStatus: P.string
};

export default withRouter(NavBarLinks);
