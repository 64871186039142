import * as actionTypes from "./actionTypes";

export const getConsentContent = (organizationId, documentType, documentName = null) => {
    return {
        type: actionTypes.GET_CONSENT_DOCUMENT,
        payload: {organizationId, documentType, documentName}
    }
};

export const getConsentContentSuccess = (response) => {
    return {
        type: actionTypes.GET_CONSENT_DOCUMENT_SUCCESS,
        payload: {response}
    }
};

export const getContentByDocName = (organizationId, documentName) => {
    return {
        type: actionTypes.GET_CONTENT_BY_DOC_NAME,
        payload: {organizationId, documentName}
    }
};

export const getContentByDocNameSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTENT_BY_DOC_NAME_SUCCESS,
        payload: {response}
    }
};

/**
 *Action to save consent content
 * @param organizationId
 * @param eConsent
 * @param documents Arrray with a value of documents to save on the server.
 * @param isOrganic isOrganic flow or external flow
 * @param userName username to login
 * @param haveExistingFlexLoan flag to check if user has outstanding flex loan plan (applicable only for Tennessee state)
 * @param state user's residence state
 * @returns {{type: string, payload: {organizationId: *, documents: *}}}
 */
export const saveConsentContent = (data) => {
    return {
        type: actionTypes.SAVE_CONSENT_DOCUMENT,
        payload: data
    }
};

export const saveConsentContentSuccess = (response) => {
    return {
        type: actionTypes.SAVE_CONSENT_DOCUMENT_SUCCESS,
        payload: {response}
    }
};

export const clearContent = () => {
    return {
        type: actionTypes.CLEAR_CONTENT,
    };
};
