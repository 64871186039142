export const DRAW_REMINDER_SEGMENT = "Draw Reminder";
export const PAID_OFF_SEGMENT = "Paid Off";
export const WITHDRAWN_SEGMENT = "Withdrawn";
export const PAYMENT_DUE_DATE_SEGMENT = "Payment Due Date";
export const COMPLETE_APPLICATION = "Complete Application Disabled";
export const ONESIGNAL_API_ENABLED = false;

// QA IDs

export const QA_DRAW_REMINDER_TEMPLATE_IDS = {
    1: "a5c265fd-36a5-466b-8c8d-b7bab52c438f", //MoneyKey
    2: "b8442694-418a-46ec-9c44-f0645365f283", // Credit Fresh
    3: "0f480272-fc82-47bd-9315-5bbe80c66414", // MoneyKey Plus
    4: "cb7c2c47-efae-4bac-ae72-e0a3525d383f", // Credit Fresh Plus
    5: "154e0259-1f97-42b2-80df-159569d5b287", // Fora Credit
    6: "", // Path Ward
    7: "" // KOHO line of credit
}

export const QA_PAYMENT_DUE_DATE_TEMPLATE_IDS = {
    1: "4e5e327d-46b0-4596-8a63-3634166f0635", //MoneyKey
    2: "02e331c9-9e03-4f2c-88a5-0778858880ee", // Credit Fresh
    3: "e4d84fa3-d44b-493e-ae88-5fc121eea8f5", // MoneyKey Plus
    4: "ded4e108-7797-4d24-b13b-09c909f9fe87", // Credit Fresh Plus
    5: "a8af21f9-f3fd-4d40-9e7a-7d15c84df284", // Fora Credit
    6: "", // Path Ward
    7: "" // KOHO line of credit
}

export const QA_WITHDRAWN_TEMPLATE_IDS = {
    1: "15c8e512-58b5-4d7d-a3fa-1fcb90660bdb", //MoneyKey
    2: "6f18cb08-1524-4efc-b98e-ec5e61669033", // Credit Fresh
    3: "6d4c6db3-a8f8-48ce-8841-c0c7717ed2d3", // MoneyKey Plus
    4: "9287aac8-93a7-4dc0-bfff-f50b8f124d99", // Credit Fresh Plus
    5: "cbcc992d-0c2d-4237-a6bd-33fb596a2e02", // Fora Credit
    6: "", // Path Ward
    7: "" // KOHO line of credit
}

export const QA_PAID_OFF_TEMPLATE_IDS = {
    1: "ad7afc49-a927-4acd-95a3-be66974ea01c", //MoneyKey
    3: "e2471ad5-3ee9-4e48-b52e-8744add4bd8c", // MoneyKey Plus
}

export const QA_COMPLETE_APPLICATION_TEMPLATE_IDS = {
    1: "b0935e36-36d1-4fe5-88d9-65a1691ae855", //MoneyKey
    2: "836d1cf7-8f8e-4260-80df-1bea4626a716", // Credit Fresh
    3: "e71afafb-ab2d-40b9-a686-543ff7baab27", // MoneyKey Plus
    4: "caa591cd-f752-4a04-98e0-54cb627e8a5e", // Credit Fresh Plus
    5: "5e11275e-a630-4baf-ab6f-2cfa0ab6b6fd", // Fora Credit
    6: "", // Path Ward
    7: "" // KOHO line of credit
}
export const QA_REST_API_KEYS = {
    1: "YmQ4ZWQ3MjgtOTlmMC00NzY0LTgxZTktZTQwMTA0ZjgyYzFk", //MoneyKey
    2: "YmY3ZDZiNWUtNzRiZS00ZTI4LWI1NDMtYTkxZGM3YzM3ZDc3", // Credit Fresh
    3: "Y2U1M2VhYWQtZWY0YS00N2NkLWJkOTAtZmQwZGVjNTYxNGM1", // MoneyKey Plus
    4: "MTdhZDllNjUtMGRlNS00M2EyLTgzOWYtNTViOTVlYjUzM2Y3", // Credit Fresh Plus
    5: "MThkODliZmQtZGI5Ni00NjdlLTg4OTEtYzAzYzBmMDZiZjM2", // Fora Credit
    6: "Nzc2NzRmMTQtYWIxZC00OTM4LTgwZDQtYzI0YmYyYWZhZjk2",//Path Ward
    7: "" // KOHO line of credit
}


// Production IDs

export const PROD_DRAW_REMINDER_TEMPLATE_IDS = {
    1: "96ce0674-baf6-414b-b330-6e1eb93cf00e", //MoneyKey
    2: "a4ce0d96-4dc0-4279-ba93-f3905d931f03", // Credit Fresh
    3: "f1308e2c-ff86-4484-a71f-a1c44c39a89d", // MoneyKey Plus
    4: "e429b663-8e9a-4aaa-826e-fd62eac85798", // Credit Fresh Plus
    5: "2433524d-0cea-489e-9083-254231a4b424", // Fora Credit
    6: "", // Path Ward
    7: "" // KOHO line of credit
}

export const PROD_PAYMENT_DUE_DATE_TEMPLATE_IDS = {
    1: "93368056-c4ea-496b-871b-6088916ddb8e", //MoneyKey
    2: "55910e35-529e-41a1-96bc-e8669184ea19", // Credit Fresh
    3: "a6b2eab5-4874-4350-811b-190428dc7598", // MoneyKey Plus
    4: "5c3b0a77-bb27-48f6-8ae9-a6fb676cc00c", // Credit Fresh Plus
    5: "8a2d593c-ea78-46b7-8156-0fa69670b8a9", // Fora Credit
    6: "", // Path Ward
    7: "" // KOHO line of credit
}
export const PROD_WITHDRAWN_TEMPLATE_IDS = {
    1: "3d563710-8849-4a67-ae3e-d99746f3d308", //MoneyKey
    2: "cda0c686-47f0-44cd-8bf2-47c394ab00ee", // Credit Fresh
    3: "1c4e52dc-1017-4b69-bf54-7dcbd55e0314", // MoneyKey Plus
    4: "21bd9e83-56f1-4eb6-b130-bfe6ecdea2c7", // Credit Fresh Plus
    5: "328c863e-4024-4dc8-bb72-fcb7b17315d6", // Fora Credit
    6: "", // Path Ward
    7: "" // KOHO line of credit
}

export const PROD_REST_API_KEYS = {
    1: "NWQ1ZDRjZjItNzBmNy00ZGRiLTkzZmItMDI5NmExY2JiZmI2", //MoneyKey
    2: "N2Y1ZGU2NmUtZTgyYS00ZTQxLWI2MTctYmU3NzE1ZTExMDNl", // Credit Fresh
    3: "YzhiMjM5NjAtYWViMi00NDhhLTk1YTAtMmY2ZTg2MmM1OWMx", // MoneyKey Plus
    4: "ZDc4ZGIzYzgtMDg4Mi00OTdlLWFhNWItMWUzNzI1OGI1ZTUx", // Credit Fresh Plus
    5: "NWE2ZjBmOGMtNGIzNC00OWE0LTgyMmMtMjcyZjY2YzJmYjk4", // Fora Credit
    6: "NDU5MmI0ZWEtYzY5NS00ZTdkLThjNzItNTI3Y2I2ODQ2Yjk0", //Path Ward
    7: "" // KOHO line of credit
}

export const PROD_PAID_OFF_TEMPLATE_IDS = {
    1: "f1ac7324-105c-4c61-b5fd-5773e2bfbe0e", //MoneyKey
    3: "3c02334a-ca49-42a6-9907-eb9a5ba43858", // MoneyKey Plus
}
