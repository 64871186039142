import axios from "axios";
import * as utility from "../shared/utility/utility";
import * as route from "../shared/constant/route";

/**
 * Axios instance for <baseurl>/api/jag
 * @type {AxiosInstance}
 */
const loanapi = axios.create({
    baseURL: utility.getBaseURL() + route.LOAN_API_BASE_PATH
});

export const changeLoanApiBaseURL = () => {
    loanapi.defaults.baseURL = utility.getBaseURL() + route.LOAN_API_BASE_PATH;
};

/**
 * Append X-Auth-Token to every request.
 */
/*
Experimenting to Set HEADER_X_AUTH_TOKEN using axios.defaults from AuthReducer _setAuthReducerCookie
jagapi.interceptors.request.use(function (config) {
    console.log(config);
    console.log(jagapi.defaults);
    // config.headers[constant.HEADER_X_AUTH_TOKEN] = cookieUtility.getCookie(constant.COOKIE_MKEWBAUTH_TOKEN);
    return config;
});
*/

export default loanapi;
