import {PROGRESSBAR} from "../../shared/constant/field";
import * as actionTypes from "../action/actionTypes";

const initialState = {
    [PROGRESSBAR.LATEST_APPLICATION_STEP]: 0
};

const ProgressReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LATEST_APPLICATION_STEP:
            return {
                [PROGRESSBAR.LATEST_APPLICATION_STEP]: action.payload.step
            };
        case actionTypes.SET_PREVIOUS_PATH_NAME:
            return {
                [PROGRESSBAR.LAST_PATHNAME]: action.payload.url
            }
        default:
            return state;
    }
};

export default ProgressReducer;
