import * as actionTypes from "../action/actionTypes";
import * as field from "../../shared/constant/field";
import { getAPIErrorMessage, isToastPopupErrorMessage, getAPIResponseErrorMessage } from '../../shared/utility/utility'
import styledToast from "../../components/widgets/toast";
import {toast} from "react-toastify";
import _ from "lodash";

const initialState = {
    [field.API]: {
        [field.ERROR_START_PERSONALINFO]: null,
        [field.ERROR_START_ADDRESSINFO]: null,
        [field.ERROR_START_LIVINGSITUATION]: null,
        [field.ERROR_START_EMPLOYMENTINFO]: null,
        [field.ERROR_START_PAYMENTSCHEDULE]: null,
        [field.ERROR_START_PRESTAGEHBCHECK]: null,
        [field.ERROR_START_CREATEACCOUNT]: null,
        [field.ERROR_START_VERIFYPHONE]: null,
        [field.ERROR_LOAN_LIMITS_BY_ORG_AND_STATE]: null,
        [field.ERROR_UPDATE_PASSWORD]: null,
        [field.ERROR_GET_ACTIVE_LOAN]: null,
        [field.ERROR_GET_ACTIVE_LOAN_SUMMARY]: null,
        [field.ERROR_LOAN_DOCS_BY_CUSTOMER]: null,
        [field.ERROR_LOAN_DOC_CONTENT_BY_DOCVERSIONID]: null,
        [field.ERROR_SAVE_LOAN_AGREEMENT]: null,
        [field.ERROR_GET_KYC_QUESTIONS]: null,
        [field.ERROR_SAVE_COMM_PREFS]: null,
        [field.ERROR_GET_EXTERNAL_CUSTOMER_APP_INFO]: null,
        [field.ERROR_UPDATE_ACTIVE_LOAN_AMOUNT]: null,
        [field.ERROR_SAVE_OPTOUT_PREFERENCES]: null,
        [field.ERROR_GET_AGREEMENT_DETAILS]: null,
        [field.ERROR_LOGIN]: null,
        [field.ERROR_LOGIN_BLOCKED]: null,
        [field.ERROR_SAVE_CONSENT_DOCUMENT]: null,
        [field.ERROR_GET_ORGANIZATION_CONFIG]: null,
        [field.ERROR_SAVE_CONSENT_DOCUMENT]: null,
        [field.ERROR_RELOANHBCHECK]: null,
        [field.ERROR_GET_JWTHB_TOKEN]: null,
        [field.ERROR_CREDIT_LIMIT_INCREASE]: null,
        [field.ERROR_TDC_REDIRECT_PAGE]: null,
        [field.ERROR_OTP_GET_STATUS]: null,
        [field.ERROR_OTP_SEND_CODE]: null,
        [field.ERROR_OTP_VERIFY_CODE]: null,
        [field.ERROR_OTP_PROCESS_DATA]: null,
        [field.ERROR_PAYROLL_PROCESS_DATA]: null,
        [field.ERROR_CHECK_DRAW_ELIGIBILITY]: null,
        [field.ERROR_DOCUMENT_UPLOAD]: null,
        [field.ERROR_DOCUMENT_UPLOAD_VIRUS]: null
    },
    [field.UI]: {}
};

const resetErrorReducer = (state, action) => {
    let updatedState = _.cloneDeep(initialState);

    return updatedState;
};

const updateAPIError = (state, action) => {
    return {
        ...state,
        [field.API]: {
            ...state[field.API],
            ...{
                [action.payload.key]: action.payload.error
            }
        }
    };
};

let errorToastId = "errorToast";
const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_ERROR_REDUCER:
            return resetErrorReducer(state, action);

        case actionTypes.API_ERROR_CREATE:
            if (action.payload.key !== field.ERROR_LOGIN && action.payload.key !== field.ERROR_DOCUMENT_UPLOAD && action.payload.key !== field.ERROR_DOCUMENT_UPLOAD_VIRUS && !toast.isActive(errorToastId) && isToastPopupErrorMessage(getAPIResponseErrorMessage(action.payload.error))) {
                styledToast.error(getAPIErrorMessage(action.payload.error), {toastId: errorToastId, autoClose: 5000});
            }
            return updateAPIError(state, action);

        case actionTypes.API_ERROR_CLEAR:
            return updateAPIError(state, action);

        default:
            return state;
    }
};

export default errorReducer;
