import * as validators from "../../../shared/utility/validators";
import * as errorCodes from "../../../shared/constant/errorCodes";
import * as errorMessages from "../../../shared/constant/errorMessages";
import * as fieldtags from "../../../shared/constant/fieldtags";
import {
    _isNumbersOnly
} from "../../../views/application/addressInfo/components/_validate";

function isValidFirstName(firstName, errors = {}) {
    switch (validators.isValidFirstName(firstName)) {
        case errorCodes.FIRST_NAME__EMPTY:
            errors[fieldtags.FEEDBACK_FIRST_NAME] = errorMessages.FIRST_NAME__EMPTY;
            return errors[fieldtags.FEEDBACK_FIRST_NAME];

        case errorCodes.FIRST_NAME__LESS_THAN_2_CHARS:
            errors[fieldtags.FEEDBACK_FIRST_NAME] = errorMessages.FIRST_NAME__LESS_THAN_2_CHARS;
            return errors[fieldtags.FEEDBACK_FIRST_NAME];

        default:
            return true;
    }
}

function isValidLastName(lastName, errors = {}) {
    switch (validators.isValidLastName(lastName)) {
        case errorCodes.LAST_NAME__EMPTY:
            errors[fieldtags.FEEDBACK_LAST_NAME] = errorMessages.LAST_NAME__EMPTY;
            return errors[fieldtags.FEEDBACK_LAST_NAME];

        case errorCodes.LAST_NAME__LESS_THAN_2_CHARS:
            errors[fieldtags.FEEDBACK_LAST_NAME] = errorMessages.LAST_NAME__LESS_THAN_2_CHARS;
            return errors[fieldtags.FEEDBACK_LAST_NAME];

        default:
            return true;
    }
}

function isValidEmailAddress(emailAddress, errors = {}) {
    switch (validators.isValidEmailAddress(emailAddress ? emailAddress.trim() : "")) {
        case errorCodes.EMAIL_ADDRESS__EMPTY:
            errors[fieldtags.FEEDBACK_EMAIL_ADDRESS] = errorMessages.EMAIL_ADDRESS__EMPTY;
            return errors[fieldtags.FEEDBACK_EMAIL_ADDRESS];

        case errorCodes.EMAIL_ADDRESS__INVALID:
            errors[fieldtags.FEEDBACK_EMAIL_ADDRESS] = errorMessages.EMAIL_ADDRESS__INVALID;
            return errors[fieldtags.FEEDBACK_EMAIL_ADDRESS];

        default:
            return true;
    }
}

function isValidPhoneNumber(phoneNumber, errors = {}) {
    switch (validators.isValidPhoneNumber(phoneNumber) ) {
        case errorCodes.PHONE_NUMBER__EMPTY:
            return true;

        case errorCodes.PHONE_NUMBER__INVALID:
            errors[fieldtags.FEEDBACK_PHONE_NUMBER] = errorMessages.PERSONALINFO_PHONE_NUMBER__INVALID;
            return errors[fieldtags.FEEDBACK_PHONE_NUMBER];

        default:
            return true;
    }
}

function isValidLoanNumber(loanNumber, errors = {}) {
    if (validators.isTextEmpty(loanNumber)) {
        return true;
    }

    if (!_isNumbersOnly(loanNumber)) {
        errors[fieldtags.FEEDBACK_LOAN_NUMBER] = errorMessages.FEEDBACK_LOAN_NUMBER__HAS_NUMBERS_ONLY;
        return errors[fieldtags.FEEDBACK_LOAN_NUMBER];
    }
}

function isValidFeedbackBox(feedbackContent, errors = {}) {
    if (validators.isTextEmpty(feedbackContent)) {
        errors[fieldtags.FEEDBACK_CONTENT] = errorMessages.FEEDBACK_CONTENT__EMPTY;
        return errors[fieldtags.FEEDBACK_CONTENT];
    }
}

function isHTMLTag(fieldName, value, errors) {
    if(value && value.indexOf("<") >= 0 && value.indexOf(">") >= 0){
        return errors[fieldName] = errorMessages.FEEDBACK_HTML_TAG_NOT_ALLOWED;
    }
}

export function validate(values, props) {

    const errors = {};

    isHTMLTag(fieldtags.FEEDBACK_FIRST_NAME, values[fieldtags.FEEDBACK_FIRST_NAME], errors);
    isHTMLTag(fieldtags.FEEDBACK_LAST_NAME, values[fieldtags.FEEDBACK_LAST_NAME], errors);
    isHTMLTag(fieldtags.FEEDBACK_EMAIL_ADDRESS, values[fieldtags.FEEDBACK_EMAIL_ADDRESS], errors);
    isHTMLTag(fieldtags.FEEDBACK_PHONE_NUMBER, values[fieldtags.FEEDBACK_PHONE_NUMBER], errors);
    isHTMLTag(fieldtags.FEEDBACK_LOAN_NUMBER, values[fieldtags.FEEDBACK_LOAN_NUMBER], errors);
    isHTMLTag(fieldtags.FEEDBACK_CATEGORY, values[fieldtags.FEEDBACK_CATEGORY], errors);
    isHTMLTag(fieldtags.FEEDBACK_SUBJECT, values[fieldtags.FEEDBACK_SUBJECT], errors);
    isHTMLTag(fieldtags.FEEDBACK_CONTENT, values[fieldtags.FEEDBACK_CONTENT], errors);

    isValidFirstName(values[fieldtags.FEEDBACK_FIRST_NAME], errors);
    isValidLastName(values[fieldtags.FEEDBACK_LAST_NAME], errors);
    isValidEmailAddress(values[fieldtags.FEEDBACK_EMAIL_ADDRESS], errors);
    isValidPhoneNumber(values[fieldtags.FEEDBACK_PHONE_NUMBER], errors);
    isValidLoanNumber(values[fieldtags.FEEDBACK_LOAN_NUMBER], errors);
    isValidFeedbackBox(values[fieldtags.FEEDBACK_CONTENT], errors);

    return errors;
}
