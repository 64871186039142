import Cookies from 'universal-cookie';
import * as constant from '../constant/constant'
import {removeLSValue} from './localstorageutility'

const cookies = new Cookies();

/**
 * Get cookie value by its name
 * @param cookieName
 * @returns {any}
 */
export const getCookie = (cookieName) => {
    return cookies.get(cookieName);
};

/**
 * Set cookie
 * @param cookieName
 * @param cookieValue
 */
export const setCookie = (cookieName, cookieValue) => {
    // Remove existing cookie with same name if any before setting it.
    removeCookie(cookieName);
    cookies.set(cookieName, cookieValue, {path: "/"});
};

export const setCookieWithDomain = (cookieName, cookieValue, domain) => {
    // Remove existing cookie with same name if any before setting it.
    removeCookie(cookieName);
    let expiryDate = new Date();
    let time = expiryDate.getTime();
    let expiryTime = time + 20000;
    expiryDate.setTime(expiryTime);
    cookies.set(cookieName, cookieValue, {path: "/", domain: domain, expires: expiryDate});
};

/**
 * Remove cookiee
 *
 * @param cookieName
 */
export const removeCookie = (cookieName) => {
    if (cookieName === constant.COOKIE_MKWEBDASHBOARD_DATA)
        removeLSValue(cookieName);
    else {
        cookies.remove(cookieName, {path: "/"});
    }
};

export const removeCrossOrgCookie = () => {
    document.cookie = "xOrgLoginToken=setfordeltet; expires=Thu, 01 Dec 2010 12:00:00 UTC; domain=.creditfresh.com";
};
