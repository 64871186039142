import React from "react";

class FeedbackSubmitted extends React.Component {
    render() {
        return (
            <div>
                <article className="x-article-stateinfo">
                    <h1>Tell Us How We're Doing</h1>
                    <p>At MoneyKey, we value your feedback about our products and services.</p>
                    <p>
                        If you have something you'd like to share with us, please complete the feedback form below. If
                        your message requires a response, we will get back to you as soon as possible.
                    </p>
                    <hr />
                    <p>
                        <strong>Feedback Submitted</strong>
                    </p>
                    <p>Thank you for getting in touch.</p>
                    <p>
                        A member of our team will review your feedback and get back to you as soon as possible. A copy
                        of your message has been sent to the email address you provided.
                    </p>
                </article>
            </div>
        );
    }
}

export default FeedbackSubmitted;
