import * as actionTypes from "./actionTypes";

export const setLatestApplicationStep = step => {
    return {
        type: actionTypes.SET_LATEST_APPLICATION_STEP,
        payload: {step}
    };
};

export const setPreviousPathname = url => {
    return {
        type: actionTypes.SET_PREVIOUS_PATH_NAME,
        payload: {url}
    };
};
