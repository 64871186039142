import React from "react";
import P from "prop-types";
import {connect} from "react-redux";
import LazyLoad from 'react-lazyload';
import * as constants from "../../shared/constant/constant";
import * as field from "../../shared/constant/field";
import gdLogo from "../../images/third-party/gd-logo.png";


class MoneykeyFooter extends React.Component {
    render() {

        return (
            <React.Fragment>
                <div className="x-background_color_canada_footer wrapper p-5">
                    <div className="text-left mk-header">
                    <LazyLoad>
                    <img
                        src="https://cdn.moneykey.com/images/mk-footer-logo.png"
                        alt="mk-logo"
                        className="mk-footer-img"
                    />
                    </LazyLoad>
                    </div>
                    <div className="footer-content pr-lg-3 footer-text-color">
                        <p>
                            <small>Product availability varies by state. For credit products offered in your state
                                of
                                residence, please visit our <a href={this.props.uriRatesTermsLink} target={"_blank"}
                                                               className={"footer-links-color"}>Rates and
                                    Terms</a> page.
                            </small>
                        </p>
                        <p>
                            <small>MoneyKey – TX, Inc. is licensed as a Credit Access Business (CAB), License No.
                                16641-62815, by the Office of the Consumer Credit Commissioner and registered as a
                                Credit Services Organization (CSO), Registration No. 20110150, by the State of
                                Texas.
                                All loans for which MoneyKey acts as a CSO/CAB are funded by an unaffiliated
                                third-party
                                lender and serviced by MoneyKey.
                            </small>
                        </p>
                        <p>
                            <small>MoneyKey reserves the right to assess your creditworthiness periodically.</small>
                        </p>
                        <p>
                            <small>IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To help the
                                government fight the funding of terrorism and money laundering activities, federal
                                law
                                requires all financial institutions to obtain, verify and record information that
                                identifies each person who opens an account. What this means for you: When you open
                                an account, we will ask your name, address, date of birth, and other information
                                that
                                will allow us to identify you. We may also ask to see your driver’s license or other
                                identifying documents.</small>
                        </p>
                        <p>
                            <small>*Not all requests are approved; duration of approval process may vary. Credit
                                limit/loan amount is subject to further verification criteria and may vary depending on
                                your individual circumstances and eligibility. Any requested funds will typically be
                                deposited into your bank account the same business day; timing of funding may vary. The
                                date and time the funds are made available to you by your bank are subject to your
                                bank’s policies. For specific funding cut-off times <a href={"https://www.moneykey.com/faq/#qq9"} target={"_blank"}
                                         className={"footer-links-color"}>click here</a>.</small>
                        </p>
                        <hr className="x-footer-hr"/>
                    </div>
                    <div className="footer-sidebar">
                        <section className="p-3 pr-0 ml-lg-5">
                            <p className="footer-text-color text-center">
                                <b>
                                    <span className="material-symbols-rounded footer-question-mark">help</span>
                                    &nbsp; Questions? We're here to help.
                                </b>
                            </p>
                            <div className="text-center">
                                <button
                                    id="submit-login"
                                    type="submit"
                                    className="contact-us-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(this.props.content.common.contactUsURL, "_blank");
                                    }}
                                >
                                    <b>Contact Us</b>
                                </button>
                            </div>
                        </section>
                    </div>

                    <div className="temporary-hr-footer d-md-none">
                        <hr className="x-footer-hr"/>
                    </div>

                    <div className="footer">

                        <section className="text-sm-left text-lg-left footer-links-color">
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.uriAboutMoneyKey}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small>About MoneyKey</small>
                            </a>
                            <span className="px-1 footer-text-color">|</span>
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.termsOfUseURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small>Terms of Use</small>
                            </a>
                            <span className="px-1 footer-text-color">|</span>
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.privacyPolicy}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small>Privacy Policies</small>
                            </a>
                        </section>

                        <p className="footer-text-color text-sm-left text-lg-left mb-0 pt-2">
                            <small>
                                &copy; {(new Date()).getFullYear()} MoneyKey, Inc. All rights reserved.
                            </small>
                        </p>
                        <div>
                            <LazyLoad>
                                <img src={gdLogo} alt="gd-logo" id="gd-logo-img"/>
                            </LazyLoad>
                        </div>
                        <p className="footer-bottom-height"></p>
                    </div>
                </div>
            </React.Fragment>

        );
    }

}

MoneykeyFooter.propTypes = {
    uriEmailIcon: P.string.isRequired,
    email: P.string.isRequired,
    uriPhoneIcon: P.string.isRequired,
    tel: P.string,
    uriSitemapLink: P.string.isRequired,
    uriAboutMoneyKey: P.string.isRequired,
    uriLegalLink: P.string.isRequired,
    uriRatesTermsLink: P.string.isRequired,
    uriReviewsLink: P.string.isRequired,
    uriAppleStoreLink: P.string.isRequired,
    uriAppleStoreImage: P.string.isRequired,
    uriGooglePlayLink: P.string.isRequired,
    uriGooglePlayImage: P.string.isRequired
};

const mapStateToProps = state => {
    return {
        uriEmailIcon: "/resources/images/moneykey/emailicon.svg",
        email: "customercare@moneykey.com",
        uriPhoneIcon: "/resources/images/moneykey/phoneicon.svg",
        tel: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.SUPPORT_NUMBER],
        uriSitemapLink: `${constants.PUBLIC_WEBSITE}/sitemap/`,
        uriAboutMoneyKey: `https://moneykey.com/about/`,
        uriLegalLink: `${constants.PUBLIC_WEBSITE}/legal/`,
        uriRatesTermsLink: `${constants.PUBLIC_WEBSITE}/rates-terms/`,
        uriReviewsLink: `${constants.PUBLIC_WEBSITE}/reviews/`,
        uriAppleStoreLink: "https://itunes.apple.com/us/app/moneykey-mobile-loans/id1244771369?mt=8",
        uriAppleStoreImage: "/resources/images/moneykey/appleitunes.png",
        uriGooglePlayLink: "https://play.google.com/store/apps/details?id=com.money.key.selfserve&hl=en_US",
        uriGooglePlayImage: "/resources/images/moneykey/googleplay.png",
        resourceCDN: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.RESOURCE_CDN],
        resourceFolderName:
            state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.RESOURCE_FOLDERNAME],
        orgId: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_ID],
        content: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT]
    };
};

const mapDispatchToProps = dispatch => ({});

MoneykeyFooter = connect(
    mapStateToProps,
    mapDispatchToProps
)(MoneykeyFooter);

export default MoneykeyFooter;
