import * as field from "../../shared/constant/field";
import Organization from "../../models/Organization";
import * as actionTypes from "../action/actionTypes";
import * as jagapi from "../../axios/jagapi";
import * as loanapi from "../../axios/loanapi";
import * as api from "../../axios/api";
import * as webapi from "../../axios/webapi";
import * as hotboxapi from "../../axios/hotboxapi";

import {getContent, getContentTreeMemoizer} from "../../shared/utility/contentutility";

//For hot reloading content while in devlopment
//=============================================
var getNewContent = getContent;
if (process.env.NODE_ENV === "development") {
    var memoized_getContentTree = undefined;
    getNewContent = (...keys) => {
        if (!memoized_getContentTree) {
            const content = require("../../content/common/organizations");

            //keys[0] should be the current organization
            memoized_getContentTree = getContentTreeMemoizer(
                JSON.parse(JSON.stringify(content[keys[0]]).replace(/&apos;/g, "'").replace(/&dollar;/g, "$"))
            );
        }

        return memoized_getContentTree(keys);
    };
}
//=============================================

export const initialState = {
    [field.ORGANIZATION.CONFIG]: JSON.parse(document.getElementById("organizationConfig").value),
    [field.ORGANIZATION.UI]: {
        [field.ORGANIZATION.SUPPORT_NUMBER]: null,
        [field.ORGANIZATION.SKIP_PAYMENTCARD_VALIDATION]: null,
        [field.ORGANIZATION.SUB_ID_TRACKING_THRESHOLD]: null,
        [field.ORGANIZATION.CHARGE_TABLE_SWITCH_DATE]: null,
        [field.ORGANIZATION.IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED]: null
    },
    [field.ORGANIZATION.ORG_CONTENT]: {},
    [field.ORGANIZATION.ORG_CONTENT_KEYS]: {},
    [field.ORGANIZATION.IS_CHAT_ACTIVE]: {},
    [field.ORGANIZATION.CHAT_HOURS]: {},
    [field.ORGANIZATION.SERVER_TIME]: {}
};

//TODO: THIS IS AN ANTI-PATTERN. SHOULD BE ABLE TO PULL THESE VALUES FROM REDUX STORE
const _populateOrganizationConfig = state => {
    Organization.setOrganizationId(state[field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_ID]);
    Organization.setOrganizationName(state[field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_NAME]);
    Organization.setApiEndPoint(state[field.ORGANIZATION.CONFIG][field.ORGANIZATION.API_ENDPOINT]);
    // API Endpoint value is changed, so update the base URL.
    api.changeApiBaseURL();
    webapi.changeWebapiBaseURL();
    jagapi.changeJagApiBaseURL();
    loanapi.changeLoanApiBaseURL();
    hotboxapi.changeHotboxBaseURL();
};
const _updateOrganizationUI = (state, data) => {
    return {
        ...state,
        ...{
            [field.ORGANIZATION.UI]: {...state[field.ORGANIZATION.UI], ...data}
        }
    };
};
const getOrganizationConfigSuccess = (state, action) => {
    const response = action.payload.response;

    return _updateOrganizationUI(state, {
        [field.ORGANIZATION.SUPPORT_NUMBER]: response.data.supportNumber,
        [field.ORGANIZATION.SKIP_PAYMENTCARD_VALIDATION]: response.data.skipPaymentCardValidation || null,
        [field.ORGANIZATION.SUB_ID_TRACKING_THRESHOLD]: response.data.subIdTrackingThreshold || null,
        [field.ORGANIZATION.CHARGE_TABLE_SWITCH_DATE]: response.data.chargeTableSwitchDate || null,
        [field.ORGANIZATION.IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED]: response.data.isEmailVerificationViaOtpEnabled === "1",
        [field.ORGANIZATION.IS_CLOUDFLARE_TURNSTILE_VALIDATION_ENABLED]: JSON.parse(response.data.isCloudflareTurnstileValidationEnabled ?? false),
    });
};

export const isChatActiveSuccess = (state, action) => {
    const response = action.payload.isChatActiveSuccess;

    return {
        ...state,
        [field.ORGANIZATION.IS_CHAT_ACTIVE]: response
    };
};

export const getChatHoursSuccess = (state, action) => {
    const {response} = action.payload;
    return {
        ...state,
        [field.ORGANIZATION.CHAT_HOURS]: response
    };
};

const getStateOrganizationConfigSuccess = (state, action) => {
    const response = action.payload.response;
    return _updateOrganizationUI(state, {
        [field.ORGANIZATION.BANK_VERIFICATION_CONFIRM_ENABLED]: response.data.isBankVerificationConfirmEnabled,
        [field.ORGANIZATION.PRODUCT_TYPE]: response.data.productType,
        [field.ORGANIZATION.IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED]: response.data.isEmailVerificationViaOTPEnabled,
        [field.ORGANIZATION.IS_PHONE_VERIFICATION_SMS_ENABLED]: response.data.isPhoneVerificationSMSEnabled,
        [field.ORGANIZATION.IS_PHONE_VERIFICATION_LANDLINE_ENABLED]: response.data.isPhoneVerificationLandlineEnabled,
        [field.ORGANIZATION.IS_PHONE_VERIFICATION_SKIP_ENABLED]: response.data.isPhoneVerificationSkipEnabled,
        [field.ORGANIZATION.IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED]: response.data.isEmailVerificationViaOTPEnabled,
        [field.ORGANIZATION.IS_CANCEL_DRAW_ENABLED]: response.data.isCancelDrawEnabled,
        [field.ORGANIZATION.IS_LOAN_AGREEMENT_REDESIGN_ENABLED]: response.data.isLoanAgreementRedesignEnabled
    });
};

const getServerTimeSuccess = (state, action) => {
    const response = action.payload.response;
    return {
        ...state,
        [field.ORGANIZATION.SERVER_TIME]: response.data
    };
};
export default function organizationReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_ORGANIZATION_CONFIG:
            return {
                ...state,
                ...{[field.ORGANIZATION.CONFIG]: {...state[field.ORGANIZATION.CONFIG], ...action.payload}}
            };
        case actionTypes.SET_ORGANIZATION_CONTENT:
            var organizationKey = state[field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_NAME].toLowerCase();
            var newStateKey =
                action.payload.state ||
                state[field.ORGANIZATION.ORG_CONTENT_KEYS][field.ORGANIZATION.ORG_CONTENT_KEY_STATE];
            var newProductKey =
                action.payload.product ||
                state[field.ORGANIZATION.ORG_CONTENT_KEYS][field.ORGANIZATION.ORG_CONTENT_KEY_PRODUCT];
            return {
                ...state,
                [field.ORGANIZATION.ORG_CONTENT]: getNewContent(organizationKey, newStateKey, newProductKey),
                [field.ORGANIZATION.ORG_CONTENT_KEYS]: {
                    state: newStateKey,
                    product: newProductKey
                }
            };
        case actionTypes.RESET_ORGANIZATION_CONTENT:
            organizationKey = state[field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_NAME].toLowerCase();
            return {
                ...state,
                [field.ORGANIZATION.ORG_CONTENT]: getNewContent(organizationKey),
                [field.ORGANIZATION.ORG_CONTENT_KEYS]: {}
            };
        case actionTypes.POPULATE_ORGANIZATION_INITIAL_CONFIG:
            _populateOrganizationConfig(state, action);
            return state;
        case actionTypes.GET_ORGANIZATION_CONFIG_SUCCESS:
            return getOrganizationConfigSuccess(state, action);
        case actionTypes.IS_CHAT_ACTIVE_SUCCESS:
            return isChatActiveSuccess(state, action);
        case actionTypes.GET_CHAT_HOURS_SUCCESS:
            return getChatHoursSuccess(state, action);
        case actionTypes.GET_STATE_ORGANIZATION_CONFIG_SUCCESS:
            return getStateOrganizationConfigSuccess(state, action);
        case actionTypes.GET_SERVER_TIME_SUCCESS:
            return getServerTimeSuccess(state, action);
        default:
            return state;
    }
}
