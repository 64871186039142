import {push} from "connected-react-router";
import {call, put, select} from "redux-saga/effects";
import {startSubmit, stopSubmit} from "redux-form";

import api from "../../axios/api";
import jagapi from "../../axios/jagapi";
import loanapi from "../../axios/loanapi";

import * as errorActions from "../action/ErrorAction";
import * as loanActions from "../action/loanActions";
import {setOrganizationContent} from "../action/OrganizationActions";
import * as trackingActions from "../action/trackingActions";
import * as createAndTrackToastMessageAction from "../action/CreateAndTrackToastMessageAction";
import * as field from "../../shared/constant/field";
import * as route from "../../shared/constant/route";
import * as constant from "../../shared/constant/constant";
import * as utility from "../../shared/utility/utility";
import * as contentUtility from "../../shared/utility/contentutility";
import * as progressutility from "../../shared/utility/routeutility";
import webapi from "../../axios/webapi";
import * as applicationActions from "../action/ApplicationAction";
import {getIpAddress} from "../../shared/utility/ipify";
import * as actions from "../action/DashboardAction";

let _ip;

export function* getLoanLimits() {
    const _stateSelector = state => ({
        averagePaycheck: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.PAYCHECK_AMOUNT],
        email: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.EMAIL],
        netMonthlyIncome: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.NMI],
        payFrequency: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.PAY_FREQUENCY],
        state: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.STATE],
        //
        baseLoanId: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.BASE_LOAN_ID],
        entityId: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_LMS_ENTITY_ID],
        leadSequenceId: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_LEAD_SEQUENCE_ID],
        isRC: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.ISRC],
        customerId: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.CUSTOMERID],
        loanType: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_TYPE]
    });

    try {
        const _state = yield select(_stateSelector);
        let {isRC, loanType, customerId, ...payload} = _state;
        let url = (isRC && loanType !== "loc") ? route.LOAN_API_LOAN_LIMIT_RC : route.LOAN_API_APPROVED_LOAN_LIMIT;
        let response = yield api({
            method: "post",
            url,
            data: url === route.LOAN_API_LOAN_LIMIT_RC
                ? {
                      entityId: _state.entityId,
                      customerId
                  }
                : {
                      ...payload,
                      //
                      preview: true,
                      organizationId: utility.getOrganizationId()
                  }
        });
        if (response.data.errorCode === "18") {
            yield put(push(route.APPLICATION_REJECT_PAGE));
        }
        yield put(loanActions.getLoanLimitsSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_LOAN_LIMITS));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_LOAN_LIMITS));
    }
}

export function* getLoanLimitsByOrgAndState(action) {
    try {
        //temporary block for AL. If state is AL, set state to invalid value so that the call fails as with other invalid states.
        if (!action.payload.isForceFetch &&
            (action.payload.stateCode === constant.STATE_ALABAMA ||
                action.payload.stateCode === constant.STATE_CALIFORNIA) &&
            action.payload.organizationId === constant.MONEYKEY_ORGANIZATION_ID
        ) {
            action.payload.stateCode = "XX";
        }
        let url = route.WEB_API_ORGANIZATION_STATE_LOAN_LIMITS.replace(
            /:organizationId/,
            action.payload.organizationId
        ).replace(/:stateCode/, action.payload.stateCode);
        let response = yield jagapi({
            method: "get",
            url
        });
        yield put(loanActions.getLoanLimitsByOrgAndStateSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_LOAN_LIMITS_BY_ORG_AND_STATE));
    } catch (error) {
        yield put(loanActions.getLoanLimitsByOrgAndStateFailure(error));
    }
}

export function* getLoanLimitsForRcCustomer(action) {
    try {
        let url = route.LOAN_API_LOAN_LIMIT_RC;
        let response = yield api({
            method: "post",
            url,
            data: action.payload.loanLimitPayload
        });
        yield put(loanActions.getLoanLimitsForRcCustomerSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_LOAN_LIMITS));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_LOAN_LIMITS));
    }
}

export function* getLoanDocsByCustomer(action) {
    try {
        let url = route.JAG_API_LOAN_DOCS_GET_BY_CUSTOMER.replace(/:customerId/i, action.payload.customerId).replace(
            /:organizationId/,
            utility.getOrganizationId()
        );
        let response = yield jagapi({
            method: "get",
            url
        });
        yield put(loanActions.getLoanDocsByCustomerSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_LOAN_DOCS_BY_CUSTOMER));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_LOAN_DOCS_BY_CUSTOMER)
        );
    }
}

export function* getLoanDocContentByDocVersionId(action) {
    try {
        let url = route.JAG_API_LOAN_DOCS_CONTENT_BY_DOCUMENT_VERSION_ID.replace(
            /:documentVersionId/i,
            action.payload.documentVersionId
        )
            .replace(/:baseLoanId/i, action.payload.baseLoanId)
            .replace(/:customerId/i, action.payload.customerId)
            .replace(/:organizationId/, utility.getOrganizationId());
        let response = yield jagapi({
            method: "get",
            url
        });
        yield put(loanActions.getLoanDocContentSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_LOAN_DOC_CONTENT_BY_DOCVERSIONID));
        yield put(trackingActions.viewLoanDocs(response.data.docSubject));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(
                error,
                field.ERROR_LOAN_DOC_CONTENT_BY_DOCVERSIONID
            )
        );
    }
}

export function* saveLoanAgreement(action) {
    try {
        if (!_ip || _ip === "0.0.0.0") {
            try {
                _ip = yield getIpAddress();
            } catch (err) {}
        }
        let ipAddress = _ip;
        let url = route.JAG_API_LOAN_DOCS_SAVE_AGREEMENTS;
        let data = {
            ...action.payload.data,
            ipAddress
        }
        let response = yield jagapi({
            method: "post",
            url,
            data
        });
        yield put(trackingActions.submitLoanDocs(action.payload.data.docSubject));
        yield put(loanActions.saveLoanAgreementSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_SAVE_LOAN_AGREEMENT));
        utility.scrollToTop();
        if (action.payload.data.lastLoanDocument && action.payload.data.isLoanAgreementRedesign){
            yield put(createAndTrackToastMessageAction.createAndTrackSuccessMessage("You've completed your agreements!"));
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SAVE_LOAN_AGREEMENT));
    }
}

export function* getActiveLoan(action) {
    try {
        let url = route.JAG_API_CUSTOMER_ACTIVE_LOAN.replace(/:customerId/i, action.payload.customerId);
        let response = yield jagapi({
            method: "get",
            url
        });
        yield put(loanActions.getActiveLoanSuccess(response));
        if (response && response.data && response.data.productType) {
            const product = contentUtility.mapLoanTypes(response.data.productType);
            yield put(setOrganizationContent({product}));
        }
        yield put(errorActions.clearApiError(null, field.ERROR_GET_ACTIVE_LOAN));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_ACTIVE_LOAN));
    }
}

export function* getActiveLoanSummary(action) {
    try {
        let url = route.JAG_API_CUSTOMER_ACTIVE_LOAN_SUMMARY.replace(/:customerId/i, action.payload.customerId);
        let response = yield jagapi({
            method: "get",
            url
        });
        yield put(loanActions.getActiveLoanSummarySuccess(response));
        if (response && response.data && response.data.productType) {
            const product = contentUtility.mapLoanTypes(response.data.productType);
            yield put(setOrganizationContent({product}));
        }
        yield put(errorActions.clearApiError(null, field.ERROR_GET_ACTIVE_LOAN_SUMMARY));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_ACTIVE_LOAN_SUMMARY)
        );
    }
}

export function* getLatestNonWithdrawnLoanSummary(action) {
    try {
        let url = route.JAG_API_LATEST_NON_WITHDRAWN_LOAN_SUMMARY.replace(/:customerId/i, action.payload.customerId);
        let response = yield jagapi({
            method: "get",
            url
        });
        yield put(loanActions.getLatestNonWithdrawnLoanSummarySuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(
                error,
                field.ERROR_GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY
            )
        );
    }
}

export function* updateActiveLoanAmount(action) {
    const _stateSelector = state => ({
        customerId: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.CUSTOMERID],
        isKYC: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.ISKYC],
        isRAL: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.ISRAL],
        isXRAL: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_XRAL],
        isRC: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.ISRC],
        isOrganic: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_ORGANIC],
        isShortform: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.IS_SHORTFORM],
        productType: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_TYPE],
        isLoanDocsinDashboard:
            state[field.REDUCER_NODE_LOAN][field.LOAN_LIMITS_BY_ORG_AND_STATE][
                field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD
            ],
        isNCAutoFundFlow: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_IS_NC_AUTO_FUND_FLOW]
    });

    yield put(startSubmit(action.form));
    try {
        const _state = yield select(_stateSelector);

        let url = route.JAG_API_CUSTOMER_UPDATE_ACTIVE_LOAN_AMOUNT.replace(/:customerId/i, _state.customerId);
        yield jagapi({
            method: "put",
            url,
            data: {
                customerId: _state.customerId,
                isKYC: _state.isKYC,
                isOrganic: _state.isOrganic,
                isShortform: _state.isShortform,
                productType: _state.productType,
                organizationId: utility.getOrganizationId(),
                //
                ...action.payload
            }
        });

        // If organic app or external short form app
        if (_state.isOrganic === true || (_state.isOrganic === false && _state.isShortform === true) || (_state.isNCAutoFundFlow === true && action.payload.currentPage === route.APPLICATION_PRE_APPROVED_PAGE)) {
            yield put(trackingActions.submitPreApproved());
            yield put(applicationActions.updateLoanApplicationProgress({[field.ISPREAPPROVEDPAGESUBMITTED]: true}));
        } else if (_state.isOrganic === false) {
            yield put(trackingActions.submitWelcomePage(_state.isRAL));
        }
        yield put(errorActions.clearApiError(null, field.ERROR_UPDATE_ACTIVE_LOAN_AMOUNT));
        yield put(
            push(
                progressutility.getNextPageAfterLoanAmountUpdate(
                    action.payload.currentPage,
                    //
                    _state.isOrganic,
                    _state.isRAL,
                    _state.isXRAL,
                    _state.isRC,
                    _state.productType,
                    _state.isKYC,
                    _state.isShortform,
                    _state.isLoanDocsinDashboard
                )
            )
        );
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_UPDATE_ACTIVE_LOAN_AMOUNT)
        );
    }
    yield put(stopSubmit(action.form));
}

export function* updateLoanLimitHistory(action) {
    const _stateSelector = state => ({
        customerId: state[field.REDUCER_NODE_AUTH][field.CUSTOMER_ID],
        isRC: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.ISRC]
    });

    const _state = yield select(_stateSelector);

    let url = route.JAG_API_CUSTOMER_UPDATE_LOAN_LIMIT_HISTORY.replace(/:customerId/i, _state.customerId);
    try {
        yield jagapi({
            method: "put",
            url,
            data: {
                customerId: _state.customerId,
                isRC: _state.isRC,
                ...action.payload
            }
        });
    } catch (e) {}
}

export function* getPaymentSchedule(action) {
    try {
        let getPaymentScheduleUrl = route.JAG_API_GET_PAYMENT_SCHEDULE.replace(
            /:customerId/i,
            action.payload.customerId
        ).replace(/:baseLoanId/i, action.payload.baseLoanId);

        let response = yield jagapi({
            method: "get",
            url: getPaymentScheduleUrl
        });

        yield put(loanActions.getPaymentScheduleSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_PAYMENT_SCHEDULE));
    } catch (error) {
        yield put(errorActions.createApiError(error, field.ERROR_GET_PAYMENT_SCHEDULE));
    }
}

export function* acceptCreditLimitIncrease(action) {
    try {
        let acceptCreditLimitIncreaseUrl = route.WEB_API_ACCEPT_CREDIT_LIMIT_INCREASE.replace(
            /:customerId/i,
            action.payload.customerId
        ).replace(/:loanId/i, action.payload.loanId);
        let response = yield webapi({
            method: "put",
            url: acceptCreditLimitIncreaseUrl,
            data: {}
        });
        if (response.data.errorCode === "NO_CUSTOMER" || response.data.errorCode === "NO_LOAN") {
            yield put(push(route.DASHBOARD_INVALID_CL_OFFER));
        } else {
            yield put(loanActions.acceptCreditLimitIncreaseSuccess(response));
            yield put(push(route.DASHBOARD_CREDIT_INCREASE_CONFIRMATION_PAGE));
        }

        yield put(errorActions.clearApiError(null, field.ERROR_CREDIT_LIMIT_INCREASE));
    } catch (error) {
        yield put(errorActions.createApiError(error, field.ERROR_CREDIT_LIMIT_INCREASE));
    }
}

export function* getPaymentInfo(action) {
    try {
        let getPaymentInfoUrl = route.LOAN_PAYMENT_INFO.replace(/:baseLoanId/i, action.payload.baseLoanId);

        const response = yield call(api, {
            method: "get",
            url: getPaymentInfoUrl
        });

        yield put(loanActions.getPaymentInfoSuccess(response));
        yield put(actions.getStatementBalanceSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_PAYMENT_INFO));
    } catch (error) {
        yield put(errorActions.createApiError(error, field.ERROR_GET_PAYMENT_INFO));
    }
}

export function* getPaymentTransactionInfo(action) {
    try {
        let isLppSupported = yield select(
            state => state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.PAYMENT_ENABLED][field.DASHBOARD.IS_LPP_IFRAME_SUPPORTED]
        )
        const transactionInfoUrl = isLppSupported ? route.LPP_PAYMENT_TRANSACTION_INFO_URL : route.REPAY_PAYMENT_TRANSACTION_INFO_URL
        let url =  transactionInfoUrl
            .replace(/:loanId/i, action.payload.loanId)
            .replace(/:logId/i, action.payload.logId);

        const response = yield call(webapi, {
            method: "get",
            url
        });

        yield put(loanActions.getPaymentTransactionInfoSuccess(response));
        yield put(loanActions.getPaymentInfo(action.payload.loanId));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_PAYMENT_TRANSACTION_INFO));
    } catch (error) {
        yield put(errorActions.createApiError(error, field.ERROR_GET_PAYMENT_TRANSACTION_INFO));
    }
}

export function* getFeeLevel(action){
    try {
        let url = route.LOAN_GET_LOC_FEE_LEVEL.replace(/:baseLoanId/i, action.payload.baseLoanId);
        let response = yield call(loanapi, {
            method: "get",
            url: url
        });

        yield put(loanActions.getFeeLevelSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_LOC_LOWER_FEE_LEVEL));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_LOC_LOWER_FEE_LEVEL)
        );
    }
}

export function* getBankingInformation(action) {
    try {
        let url = route.JAG_API_CUSTOMER_GET_BANKING_INFORMATION.replace(/:customerId/i, action.payload.customerId);
        const response = yield jagapi({
            method: "get",
            url
        });

        yield put(loanActions.getBankingInformationSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_BANK_INFO));

    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_BANK_INFO)
        );
    }
}

export function* retrieveAnnualInterestRate(action) {
    try {
        let url = route.WEB_API_LOAN_RETRIEVE_ANNUAL_INTEREST_RATE.replace(/:baseLoanId/i, action.payload.baseLoanId);
        const response = yield jagapi({
            method: "get",
            url
        });
        yield put(loanActions.retrieveInterestRateSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_RETRIEVE_ANNUAL_INTEREST_RATE));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_RETRIEVE_ANNUAL_INTEREST_RATE)
        );
    }
}
