let _organizationId, _organizationName, _apiEndPoint;
export default class Organization {

    static setOrganizationId(organizationId) {
        _organizationId = organizationId;
    }

    static getOrganizationId() {
        return _organizationId;
    }

    static setOrganizationName(organizationName) {
        _organizationName = organizationName;
    }

    static getOrganizationName() {
        return _organizationName;
    }

    static setApiEndPoint(apiEndPoint) {
        _apiEndPoint = apiEndPoint;
    }

    static getApiEndPoint() {
        return _apiEndPoint;
    }
}


