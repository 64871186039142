import * as field from '../../../../../../../../shared/constant/field'

export function isEmailVerificationRemaining(actionItems) {
    return !!actionItems
        && !!actionItems[field.DASHBOARD.TODO_EMAIL_VERIFICATION]
        && !actionItems[field.DASHBOARD.TODO_EMAIL_VERIFICATION][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME]
}


export function isESigRemaining(actionItems) {
    return !!actionItems
        && !!actionItems[field.DASHBOARD.TODO_ESIG]
        && !actionItems[field.DASHBOARD.TODO_ESIG][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isBankVerificationRemaining(actionItems) {
    return !!actionItems
        && !!actionItems[field.DASHBOARD.TODO_BANK_VERIFICATION]
        && !actionItems[field.DASHBOARD.TODO_BANK_VERIFICATION][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isVerifyPaidOffPriorLoansRemaining(todoVerifyPaidoffPriorLoans) {
    return todoVerifyPaidoffPriorLoans != null && !todoVerifyPaidoffPriorLoans[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isVerifyPreviousLoanPaidOff(actionItems) {
    return !!actionItems
        && !!actionItems[field.DASHBOARD.TODO_VERIFY_PREVIOUS_LOAN_PAIDOFF]
        && !actionItems[field.DASHBOARD.TODO_VERIFY_PREVIOUS_LOAN_PAIDOFF][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isCollectPaymentInfo(actionItems) {
    return !!actionItems && !!actionItems[field.DASHBOARD.TODO_COLLECT_PAYMENT_INFO]
}

export function isEmploymentVerificationRemaining(todoEmploymentVerification) {
    return todoEmploymentVerification != null && !todoEmploymentVerification[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isReferencesRemaining(todoReferences) {
    return todoReferences != null && !todoReferences[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isSpousalInformationRemaining(actionItems) {
    return !!actionItems
        && !!actionItems[field.DASHBOARD.TODO_SPOUSAL_INFORMATION]
        && !actionItems[field.DASHBOARD.TODO_SPOUSAL_INFORMATION][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isOTPEnabled(userOTPInfo) {
    return userOTPInfo != null && userOTPInfo[field.ISOTPENABLED];
}

export function isIdentityVerificationRemaining(actionItem) {
    return !!actionItem
        && !!actionItem[field.DASHBOARD.TODO_IDENTITY_VERIFICATION]
        && !actionItem[field.DASHBOARD.TODO_IDENTITY_VERIFICATION][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isIdentityVerificationCompleted(actionItem) {
    return !!actionItem
        && !!actionItem[field.DASHBOARD.TODO_IDENTITY_VERIFICATION]
        && !!actionItem[field.DASHBOARD.TODO_IDENTITY_VERIFICATION][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isKYCCompleted(actionItem) {
    return !!actionItem
        && !!actionItem[field.DASHBOARD.TODO_KYC]
        && !!actionItem[field.DASHBOARD.TODO_KYC][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isActionItemRemaining(actionItem) {
    return actionItem != null && !actionItem[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isActionItemCompleted(actionItem) {
    return !!actionItem && !!actionItem[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}
