import axios from "axios";
import * as utility from "../shared/utility/utility";
import * as route from "../shared/constant/route";

/**
 * Axios instance for <baseurl>/api/web
 * @type {AxiosInstance}
 */
let hotboxapi = axios.create({
    baseURL: utility.getBaseURL() + route.HB_API_BASE_PATH
});

export const changeHotboxBaseURL = () => {
    hotboxapi.defaults.baseURL = utility.getBaseURL() + route.HB_API_BASE_PATH;
};

export default hotboxapi;
