import * as actionTypes from './actionTypes'
import * as utility from '../../shared/utility/utility'
import * as constant from '../../shared/constant/constant'

// ====== Application Tracking Actions Start ======
export const submitStateInfo = () => {
    return track({
        description: "user submitted state info page",
        page: "state_info_submit"
    });
};

export const viewCreatePassword = () => {
    return track({
        description: "user viewed create password page",
        page: "create_password"
    });
};

export const submitCreatePassword = () => {
    return track({
        description: "user submitted create password page",
        page: "create_password_submit"
    });
};

export const viewAccountTerms = () => {
    return track({
        description: "user viewed account terms page",
        page: "account_terms"
    });
};

export const submitAccountTerms = () => {
    return track({
        description: "user submitted account terms page",
        page: "account_terms_submit",
        geoTracking: true
    });
};

export const viewPersonalInfo = () => {
    return track({
        description: "user viewed personal info page",
        page: "personal_info"
    });
};

export const submitPersonalInfo = () => {
    return track({
        description: "user submitted personal info page",
        page: "personal_info_submit"
    });
};

export const viewAddressInfo = () => {
    return track({
        description: "user viewed address info page",
        page: "address_info"
    });
};

export const submitAddressInfo = () => {
    return track({
        description: "user submitted address info page",
        page: "address_info_submit"
    });
};

export const viewLivingSituation = () => {
    return track({
        description: "user viewed living situation page",
        page: "living_situation"
    });
};

export const submitLivingSituation = () => {
    return track({
        description: "user submitted living situation page",
        page: "living_situation_submit"
    });
};

export const viewPaymentSchedule = () => {
    return track({
        description: "user viewed payment schedule page",
        page: "payment_schedule"
    });
};

export const submitPaymentSchedule = () => {
    return track({
        description: "user submitted payment schedule page",
        page: "payment_schedule_submit"
    });
};

export const viewPreApproved = () => {
    return track({
        description: "user viewed pre approved page",
        page: "pre_approved"
    });
};

export const submitPreApproved = () => {
    return track({
        description: "user submitted pre approved page",
        page: "pre_approved_submit"
    });
};

export const viewApplicationRequirements = () => {
    return track({
        description: "user viewed application requirements page",
        page: "application_requirements"
    });
};

export const submitAppRequirementsPage = () => {
    return track({
        description: "user submitted application requirements page",
        page: "application_requirements_submit"
    });
};

export const viewEmploymentInfo = () => {
    return track({
        description: "user viewed employment info page",
        page: "employment_info"
    });
};

export const submitEmploymentInfo = () => {
    return track({
        description: "user submitted employment info page",
        page: "employment_info_submit"
    });
};

export const viewBankingInfo = () => {
    return track({
        description: "user viewed banking info page",
        page: "banking_info"
    });
};

export const submitBankingInfo = () => {
    return track({
        description: "user submitted banking info page",
        page: "banking_info_submit"
    });
};

export const viewKYCPage = () => {
    return track({
        description: "user viewed kyc page",
        page: "kyc"
    });
};

export const submitKYCPage = () => {
    return track({
        description: "user submitted kyc page",
        page: "kyc_submit"
    });
};

export const submitKYCTimeoutPage = () => {
    return track({
        description: "timeout on kyc page",
        page: "kyc_submit_timeout"
    });
};

export const viewLoanDocs = docSubject => {
    return track({
        description: "user viewed loan docs page - " + docSubject,
        page: "loan_docs-" + utility.getLoanDocsSubjectShortForm(docSubject)
    });
};

export const submitLoanDocs = docSubject => {
    return track({
        description: "user submitted loan docs page - " + docSubject,
        page: "loan_docs_submit-" + utility.getLoanDocsSubjectShortForm(docSubject),
        geoTracking: true
    });
};

export const submitLoanDocsReadOnly = docSubject => {
    return track({
        description: "user submitted loan docs page - ro -" + docSubject,
        page: "loan_docs_submit-ro-" + utility.getLoanDocsSubjectShortForm(docSubject)
    });
};

export const viewWelcomePage = isRAL => {
    return track({
        description: `user viewed welcome page${isRAL ? "-RAL" : ""}`,
        page: `welcome${isRAL ? "-RAL" : ""}`
    });
};

export const submitWelcomePage = isRAL => {
    return track({
        description: `user submitted welcome page${isRAL ? "-RAL" : ""}`,
        page: `welcome_submit${isRAL ? "-RAL" : ""}`
    });
};

export const submitTermsAndConditions = isRAL => {
    return track({
        description: `user submitted terms and conditions ${isRAL ? "-RAL" : ""}`,
        page: `welcome_popup_submit${isRAL ? "-RAL" : ""}`
    });
};

export const submitProductChangePopup = (stateCode) => {
    return track({
        description: `user submitted ${stateCode} product change pop up`,
        page: `dashboard_product_change_popup_${stateCode}`
    })
}

export const viewCreateAccount = () => {
    return track({
        description: "user viewed create account page",
        page: "create_account"
    });
};

export const submitCreateAccount = () => {
    return track({
        description: "user submitted create account page",
        page: "create_account_submit"
    });
};

export const viewPhoneVerification = () => {
    return track({
        description: "user viewed contact-information page",
        page: "contact-information"
    });
};

export const submitPhoneVerification = () => {
    return track({
        description: "user submitted contact-information page",
        page: "contact-information-submit"
    });
};

export const viewVerifyPhone = () => {
    return track({
        description: "user viewed verify-your-phone page",
        page: "verify-your-phone"
    });
};

export const submitVerifyPhone = () => {
    return track({
        description: "user submitted verify-your-phone page",
        page: "verify-your-phone-submit"
    });
};

export const clickedVerifyPhoneVerification = () => {
    return track({
        description: "user clicked verify on phone verification page",
        page: "verify-on-phone-verification-clicked"
    });
};

export const clickedResendPhoneVerification = () => {
    return track({
        description: "user clicked resend on phone verification page",
        page: "resend-on-phone-verification-clicked"
    });
};

export const clickedGetCallPhoneVerification = () => {
    return track({
        description: "user clicked get-a-call on phone verification page",
        page: "get-a-call-on-phone-verification-clicked"
    });
};

export const viewFinalSteps = () => {
    return track({
        description: "user viewed final steps page",
        page: "final_steps"
    });
};

export const submitFinalSteps = () => {
    return track({
        description: "user submitted final steps page",
        page: "final_steps_submit"
    });
};

export const viewRejectPage = () => {
    return track({
        description: "user viewed reject page",
        page: "reject"
    });
};

export const submitRejectPage = () => {
    return track({
        description: "user submitted reject page",
        page: "reject_submit"
    });
};

export const clickTermsOfUse = () => {
    return track({
        description: "user viewed Terms of Use",
        page: "terms_of_use"
    });
}

export const clickPrivacyOfPolicy = () => {
    return track({
        description: "user viewed Privacy Policy",
        page: "privacy_policy"
    });
}

export const clickCAPrivacyPolicy = () => {
    return track({
        description: "user viewed CA Privacy Policy",
        page: "ca_privacy_policy"
    });
}
export const clickFAQ = (page) => {
    return track({
        description: `user viewed ${page}`,
        page: "FAQ"
    });
}

export const viewMfaSetUpPage = () => {
    return track({
        description: "user viewed set up mfa page",
        page: "mfa-setup"
    });
};

export const submitMfaSetUpPage = () => {
    return track({
        description: "user submitted set up mfa page",
        page: "mfa-setup-submit"
    });
};

export const skipMfaSetUpPage = () => {
    return track({
        description: "user skipped set up mfa page",
        page: "mfa-setup-skip"
    });
};

export const viewMfaSetUpVerifyPage = () => {
    return track({
        description: "user viewed set up verify mfa page",
        page: "mfa-setup-verify"
    });
};

export const cancelMfaSetUpVerifyPage = () => {
    return track({
        description: "user canceled set up verify mfa page",
        page: "mfa-setup-verify-cancel"
    });
};

export const resendMfaCode = () => {
    return track({
        description: "user resent mfa code",
        page: "mfa-code-resend"
    });
};

export const submitMfaSetUpVerifyPage = () => {
    return track({
        description: "user submitted set up verify mfa page",
        page: "mfa-setup-verify-submit"
    });
};

export const viewEnableMfaPage = () => {
    return track({
        description: "user viewed verify password mfa page",
        page: "mfa-verify-password"
    });
};

export const submitEnableMfaPage = () => {
    return track({
        description: "user submitted verify password mfa page",
        page: "mfa-verify-password-submit"
    });
};

export const cancelEnableMfaPage = () => {
    return track({
        description: "user canceled verify password mfa page",
        page: "mfa-verify-password-cancel"
    });
};

export const viewMfaEmailPage = () => {
    return track({
        description: "user viewed mfa email page",
        page: "mfa-email"
    });
};

export const submitMfaEmailPage = () => {
    return track({
        description: "user submitted mfa email page",
        page: "mfa-email-submit"
    });
};


export const viewMfaSmsPage = () => {
    return track({
        description: "user viewed mfa sms page",
        page: "mfa-sms"
    });
};

export const submitMfaSmsPage = () => {
    return track({
        description: "user submitted mfa sms page",
        page: "mfa-sms-submit"
    });
};

export const viewDisableMfaPage = () => {
    return track({
        description: "user viewed disable mfa page",
        page: "mfa-disable"
    });
};

export const submitDisableMfaPage = () => {
    return track({
        description: "user submitted disable mfa page",
        page: "mfa-disable-submit"
    });
};

export const cancelDisableMfaPage = () => {
    return track({
        description: "user canceled disable mfa page",
        page: "mfa-disable-cancel"
    });
};


export const viewConsentContent = tooltip => {
    let descriptionContent;
    let pageText;
    if (tooltip === constant.CONSENT_TOOL_TIP || tooltip === constant.LOCKED_CONSENT_TOOL_TIP) {
        descriptionContent = "user viewed econsent";
        pageText = "econsent";
    } else if (tooltip === constant.BANK_PRIVACY_POLICY_TOOL_TIP) {
        descriptionContent = "user viewed bankprivacypolicy";
        pageText = "bankprivacypolicy";
    } else if (tooltip === constant.ARBITRATION_TOOL_TIP) {
        descriptionContent = "user viewed arbitration";
        pageText = "arbitration";
    } else if (tooltip === constant.PRIVACY_POLICY_TOOL_TIP) {
        descriptionContent = "user viewed privacypolicy";
        pageText = "privacypolicy";
    } else if (tooltip === constant.TERMS_OF_USE_TOOL_TIP) {
        descriptionContent = "user viewed termsofuse";
        pageText = "termsofuse";
    } else if (tooltip === constant.AUTO_DIALER_TOOL_TIP) {
        descriptionContent = "user viewed autodialier";
        pageText = "autodialier";
    } else if (tooltip === constant.PAYMENT_SCHEDULE_TOOL_TIP) {
        descriptionContent = "user viewed pay schedule difference";
        pageText = "payment schedule";
    } else if (tooltip === constant.DECISION_LOGIC_TOOL_TIP) {
        descriptionContent = "user viewed decisionlogic faq";
        pageText = "decisionlogicfaq";
    } else if (tooltip === constant.FLINKS_TOOL_TIP) {
        descriptionContent = "user viewed flinks faq";
        pageText = "flinksfaq";
    } else if (tooltip === constant.APPLICATION_CODE_TOOL_TIP) {
        descriptionContent = "user viewed application code tool tip";
        pageText = "personalinfo";
    } else if (tooltip === constant.FLEX_LOAN_PLAN) {
        descriptionContent = "user viewed flex loan plan";
        pageText = "flexloanplan";
    } else if (tooltip === constant.TEXAS_INSTALLMENT_LOAN_FEATURE) {
        descriptionContent = "user viewed Texas installment loan feature";
        pageText = "texasinstallmentloanfeature";
    } else if (tooltip === constant.DIRECT_MAIL_TIP) {
        descriptionContent = "user viewed direct mail tip";
        pageText = "directmail";
    } else if (tooltip === constant.OFFER_PAGE_TOOL_TIP) {
        descriptionContent = "user entered wrong information on the offers page";
        pageText = "offerpage";
    } else if (tooltip === constant.LINE_OF_CREDIT_TIP) {
        descriptionContent = "user viewed line of credit tip";
        pageText = "lineofcredittip";
    } else if (tooltip === constant.INSTALLMENT_LOANS_TIP) {
        descriptionContent = "user viewed installment loans tip";
        pageText = "installmentloanstip";
    } else {
        return;
    }
    return track({
        description: descriptionContent,
        page: pageText
    });
};
// ====== Application Tracking Actions End ======

// ====== Auth Tracking Actions Start ======
export const submitLoginPage = () => {
    return track({
        description: "user submitted login page",
        page: "login_submit"
    });
};
// ====== Auth Tracking Actions End ======

// ====== Dashboard Tracking Actions Start ======
export const viewAccountPage = () => {
    return track({
        description: "user viewed account page",
        page: "user_account"
    });
};

export const userLoggedOut = () => {
    return track({
        description: "user logged out",
        page: "user_log_out"
    });
};

export const viewProfilePage = () => {
    return track({
        description: "user viewed profile page",
        page: "user_profile"
    });
};

export const viewAgreementsPage = () => {
    return track({
        description: "user viewed agreements page",
        page: "user_agreements"
    });
};

export const viewStatementsPage = () => {
    return track({
        description: "user viewed statements page",
        page: "user_statements"
    });
};

export const clickStatementsFilter = (option) => {
    return track({
        description: `user clicked statements filter option : ${option}`,
        page: "user_statements_click_filter"
    });
}

export const submitStatementFilter = (option) => {
    return track({
        description: `user submitted statements filter option : ${option}`,
        page: "user_statements_click_filter_submit"
    });
}

export const viewHistoryPage = () => {
    return track({
        description: "user viewed documents page",
        page: "user_documents"
    });
};

export const clickHistoryDetail = (loanId) => {
    return track({
        description: `user clicked to access documents details for Loan ID: ${loanId}`,
        page: `user_documents_click_${loanId}`
    })
}

export const viewDrawRequestPage = () => {
    return track({
        description: "user viewed draw request page",
        page: "user_drawrequest"
    });
};

export const submitDrawRequestPage = () => {
    return track({
        description: "user submitted draw request page",
        page: "user_drawrequest_submit"
    });
};

export const viewDrawResultPage = () => {
    return track({
        description: "user viewed draw result page",
        page: "user_drawresult"
    });
};

export const submitDrawResultPage = () => {
    return track({
        description: "user submitted draw result page",
        page: "user_drawresult_submit"
    });
};

export const viewUpdatePasswordFromDashboardPage = () => {
    return track({
        description: "user viewed update password page",
        page: "update_password_dashboard"
    });
};

export const submitUpdatePasswordFromDashboardPage = () => {
    return track({
        description: "user submitted update password page",
        page: "update_password_dashboard_submit"
    });
};

export const viewCreditLimitIncreasePage = () => {
    return track({
        description: "user viewed credit limit increase page",
        page: "user_creditlimit_increase"
    });
};

export const submitCreditLimitIncreasePage = () => {
    return track({
        description: "user submitted credit limit increase page",
        page: "user_creditlimit_increase_submit"
    });
};

export const viewCreditLimitIncreaseConfirmationPage = () => {
    return track({
        description: "user viewed credit limit increase confirmation page",
        page: "user_creditlimit_increase_confirmation"
    });
};

export const viewBankVerificationPage = () => {
    return track({
        description: "user viewed bank verification page",
        page: "user_bankverification"
    });
};

export const viewBankVerificationInfoPage = (bvSystemName) => {
    return track({
        description: `user viewed the (${bvSystemName}) bank verification information page.`,
        page: "user_view_bank_verification_info_page"
    });
};

export const viewBankVerificationRedirectPage = () => {
    return track({
        description: "user viewed the bank verification redirect page.",
        page: "user_view_bank_verification_redirect_page"
    });
};

export const viewConfirmBankVerificationPage = () => {
    return track({
        description: "user viewed confirm-bank-verification page",
        page: "user_view_confirm_bank_verification"
    });
};

export const viewBankVerificationPopUp = tooltip => {
    let descriptionContent;
    let pageText;
    switch (tooltip) {
        case constant.BANK_VERIFICATION_ACCOUNT_ERROR_TIP:
            descriptionContent = "user viewed bank verification account error tip";
            pageText = constant.BANK_VERIFICATION_ACCOUNT_ERROR_TIP;
            break;
        case constant.BANK_VERIFICATION_COMPLETE_TIP:
            descriptionContent = "user viewed bank verification complete tip";
            pageText = constant.BANK_VERIFICATION_COMPLETE_TIP;
            break;
        case constant.BANK_VERIFICATION_INCOMPLETE_TIP:
            descriptionContent = "user viewed bank verification incomplete tip";
            pageText = constant.BANK_VERIFICATION_INCOMPLETE_TIP;
            break;
        case constant.BANK_VERIFICATION_IN_PROGRESS_TIP:
            descriptionContent = "user viewed bank verification in progress tip";
            pageText = constant.BANK_VERIFICATION_IN_PROGRESS_TIP;
            break;
        case constant.BANK_VERIFICATION_TECHNICAL_ERROR_TIP:
            descriptionContent = "user viewed bank verification technical error tip";
            pageText = constant.BANK_VERIFICATION_TECHNICAL_ERROR_TIP;
            break;
        default:
            return;
    }
    return track({
        description: descriptionContent,
        page: pageText
    });
};

export const viewAdditionalVerificationRequiredPopup = () => {
    return track({
        description: "user viewed dashboard additional verification required popup",
        page: "user_view_additional_verification_required"
    });
};

export const viewDashboardReloanPage = () => {
    return track({
        description: "user viewed dashboard reloan page",
        page: "user_dashboard_reloan"
    });
};

export const submitDashboardReloanPage = () => {
    return track({
        description: "user submitted dashboard reloan page",
        page: "user_dashboard_reloan_submit"
    });
};

export const submitReapplyRALLoan = (productName, flowName, page) => {
    return track({
        description: `user submitted reapply loan ${page} for ${productName} ${flowName}.`,
        page: `user_dashboard_${productName}_${flowName}_submit_${page}`
    });
};

export const viewReapplyRALLoan = (productName, flowName, page) => {
    return track({
        description: `user viewed reapply loan ${page} for ${productName} ${flowName}.`,
        page: `user_dashboard_${productName}_${flowName}_view_${page}`
    });
};

export const viewLoanDetailsPage = baseLoanId => {
    return track({
        description: `user viewed loan details page Loan ID: ${baseLoanId}`,
        page: "user_loan_detail"
    });
};

export const viewAddPaymentPage = () => {
    return track({
        description: "user viewed add payment page.",
        page: "add_payment"
    });
};

export const submitAddPaymentPage = () => {
    return track({
        description: "user submitted add payment page.",
        page: "add_payment_submit"
    });
};

export const viewConfirmPaymentPage = () => {
    return track({
        description: "user viewed confirm online payment page.",
        page: "confirm_online_payment"
    });
};

export const submitConfirmPaymentPage = () => {
    return track({
        description: "user submitted confirm online payment page.",
        page: "confirm_online_payment_submit"
    });
};

export const viewPaymentSuccessPage = () => {
    return track({
        description: "user viewed online payment success page",
        page: "online_payment_success"
    });
};

export const viewInvalidIncreaseOfferPage = () => {
    return track({
        description: "user viewed invalid credit limit increase offer page",
        page: "user_invalid_credit_increase_offer_page"
    });
};

export const viewDocumentUploadPage = () => {
    return track({
        description: "user viewed document upload page",
        page: "dashboard_documentupload"
    });
};

export const submitDocumentUploadPage = () => {
    return track({
        description: "user submitted document upload page",
        page: "dashboard_documentupload_submit"
    });
};

export const viewDocumentUploadConfirmationPage = () => {
    return track({
        description: "user viewed document upload confirmation page",
        page: "dashboard_documentupload_confirmation"
    });
};
// ====== Dashboard Tracking Actions End ======

// ====== Dashboard Action Items Tracking Starts ======
export const clickedSignLoanDocsButton = () => {
    return track({
        description: "user clicked to access loan docs",
        page: "useraccount_click_sign_loan_doc"
    });
};

export const verifyEffectiveDate = (loanId, result) => {
    return track({
        description: `verifying effective date... the effective date for loan id ${loanId} is ${result ? "valid" : "invalid"} `,
        page: "useraccount_click_sign_loan_doc"
    });
};

export const loanReexpressed = () => {
    return track({
        description: "loan has been reexpressed",
        page: "useraccount_click_sign_loan_doc"
    });
};

export const reexpressError = (error) => {
    return track({
        description: `loan has not been reexpressed (Reason: ${error})`,
        page: "useraccount_click_sign_loan_doc"
    });
};

export const clickedBankVerificationButton = () => {
    return track({
        description: "user clicked to access bv",
        page: "useraccount_click_bv"
    });
};

export const clickedConfirmBankVerificationButton = () => {
    return track({
        description: "user clicked confirm-bank-verification",
        page: "user_click_confirm_bank_verification"
    });
};

export const submitBankVerificationInfo = (bvSystemName) => {
    return track({
        description: `user submitted the (${bvSystemName}) bank verification information page.`,
        page: "user_submitted_bank_verification_info_page"
    });
};

export const clickedBankVerificationContinueButton = () => {
    return track({
        description: "user clicked continue on bank-verification page",
        page: "user_click_bank_verification_continue"
    });
};

export const clickedBankVerificationReviewButton = () => {
    return track({
        description: "user clicked review-details on bank-verification page",
        page: "user_click_bank_verification_review"
    });
};

export const startingBVDataCheck = (data) => {
    return track({
        description: "started polling BV data for bvRequestId: " + data,
        page: "start_poll_bank_verification_redirect_page"
    });
};

export const bvDataCheckSuccess = (data) => {
    return track({
        description: "polled BV data for bvRequestId " + data.bvRequestId + ", result: " + data.result,
        page: "poll_bank_verification_redirect_page"
    });
};

export const clickedAdditionalVerificationRequiredCloseButton = () => {
    return track({
        description: "user clicked close on additional verification required popup",
        page: "user_click_close_additional_info_popup"
    });
};

export const clickedUploadDocumentButton = () => {
    return track({
        description: "user clicked to upload void check item on dashboard",
        page: "user_click_upload_void_check"
    });
};

export const clickedContinueToSignAgreement = () => {
    return track({
        description: "user clicked continue to sign agreement on additional verification required popup",
        page: "user_click_continue_additional_info_popup"
    });
};

export const submitConfirmBankVerificationPage = () => {
    return track({
        description: "user submitted confirm-bank-verification page",
        page: "user_submit_confirm_bank_verification"
    });
};

export const clickedResendEmailVerificationButton = () => {
    return track({
        description: "user clicked to resend email for verification",
        page: "my_account_dashboard"
    });
};

export const clickedCollapsiblePanel = (panelShortName, panelName, isExpand) => {
    return track({
        description: `user clicked to ${isExpand ? "expand" : "collapse"} ${panelName}`,
        page: `${isExpand ? "expand_" : "collapse_"}${panelShortName}`
    });
};

export const submitSpouseInfoItem = () => {
    return track({
        description: "user submitted spouse information",
        page: "useraccount_submit_spouse_info"
    });
};

export const trackSubmitForAutoApproval = () => {
    return track({
        description: "submit for auto approval triggered.",
        page: "useraccount_submit_auto_approval"
    });
};

export const trackSubmitForAutoApprovalSuccess = () => {
    return track({
        description: "submit for auto approval performed successfully",
        page: "useraccount_submit_auto_approval_success"
    });
};

export const trackSubmitForAutoApprovalError = () => {
    return track({
        description: "submit for auto approval encountered an error.",
        page: "useraccount_submit_auto_approval_error"
    });
};

export const trackContinueClickForReapply = (productName, flowName) => {
    return track({
        description: `user clicked continue for ${productName} ${flowName} reapply.`,
        page: `useraccount_dashboard_${productName}_${flowName}_reapply`
    });
}

// ====== OTP ======

export const otpVerifyCode = (error, status) => {
    return track({
        description: `user attempted to verify OTP Code: errorCode: ${error}, status: ${status}`,
        page: `useraccount_dashboard_otp_verify`
    });
}

export const otpSendCodeSuccess = () => {
    return track({
        description: `user successfully sent a verification code to their phone`,
        page: `useraccount_dashboard_otp_send_code`
    });
}

export const otpSendCodeFailed = () => {
    return track({
        description: `user attempted to send a verification code to their phone - 1 minute time out due to frequent requests`,
        page: `useraccount_dashboard_otp_send_code_frequent`
    });
}

export const otpProcessDataSuccess = () => {
    return track({
        description: `Risk call to process otp data successful`,
        page: `process_otp_data_success`
    });
}

export const otpProcessDataFailed = () => {
    return track({
        description: `Risk call to process otp data failed`,
        page: `process_otp_data_error`
    });
}

export const payrollProcessDataSuccess = () => {
    return track({
        description: `Risk call to process payroll data successful`,
        page: `process_payroll_data_success`
    });
}

export const payrollProcessDataFailed = () => {
    return track({
        description: `Risk call to process payroll data failed`,
        page: `process_payroll_data_error`
    });
}

// ====== OTP Email Verification Feature  ======

export const viewOTPEmailVerificationPageDashboard = () => {
    return track({
        description: "user viewed dashboard OTP email verification page",
        page: "dashboard_email_otp_verification"
    });
};

export const viewOTPEmailVerificationPageApplication = () => {
    return track({
        description: "user viewed application OTP email verification page",
        page: "apply_email_otp_verification"
    });
};

export const submitOTPEmailVerificationPage = (data) => {
    return track({
        description: "user submitted OTP email verification page with response: " + data,
        page: "email_otp_verification_submit"
    });
};

export const SubmitSendOTPEmailVerification = (data) => {
    return track({
        description: "attempt to send email verification code with response: " + data,
        page: "email_otp_verification_send_code"
    });
};

export const SubmitRemindMeLaterOTPEmailVerification = () => {
    return track({
        description: "user clicked remind me later",
        page: "email_otp_verification_skip"
    });
};
export const SubmitContinueOTPEmailVerification = () => {
    return track({
        description: "user clicked continue on email OTP verification page",
        page: "email_otp_verification_continue"
    });
};
export const submitConfirmItsYouOTPEmailVerificationPage = (data) => {
    return track({
        description: "user submitted OTP verification 'Confirm its you' page",
        page: "confirm_its_you_email_otp_verification_submit"
    });
};


// ====== Dashboard Action Items Tracking Ends ======

// ====== TDC Pages Action Items Tracking Starts ======
export const viewTDCMoneyKeyRedirect = () => {
    return track({
        description: "TDC user viewed MoneyKey redirect page",
        page: "tdc_user_moneykey_redirect"
    });
};

export const submitTDCMoneyKeyRedirect = () => {
    return track({
        description: 'TDC user clicked Apply Now',
        page: "tdc_user_moneykey_redirect_submit"
    });
};

export const submitTDCViewRate = () => {
    return track({
        description: "TDC user clicked View Rates on MoneyKey Redirect Page ",
        page: "tdc_user_moneykey_redirect_view_rates"
    });
};

export const viewThirdPartyRedirectTDC = () => {
    return track({
        description: "TDC user viewed Third Party Redirect Page",
        page: "tdc_user_third_party_redirect"
    });
};

export const submitThirdPartyRedirectTDC = () => {
    return track({
        description: "TDC user clicked Continue to redirect to third party",
        page: "tdc_user_third_party_redirect_submit"
    });
};

export const viewTDCUnsupported = () => {
    return track({
        description: "TDC user viewed Unsupported Page",
        page: "tdc_user_unsupported"
    });
};

export const viewTDCLoanSummary = () => {
    return track({
        description: "TDC user viewed loan summary",
        page: "tdc_user_loan_summary"
    });
};

export const repayOneTimePaymentUrlGenerated = () => {
    return track({
        description: "Repay Payment URL generated",
        page: "repay_payment_url"
    });
};

export const repayOneTimeUrlError = () => {
    return track({
        description: "Could not generate repay payment url",
        page: "error_repay_payment_url"
    });
};

export const submitVerifyIdentity = () => {
    return track({
        description: "user clicked identity verification button",
        page: "useraccount_click_identity_verification"
    });
};

export const viewIdentityVerificationPage = () => {
    return track({
        description: "user viewed identity verification page",
        page: "useraccount_identity_verification"
    });
};

export const redirectFromIdentityVerificationPage = () => {
    return track({
        description: "user redirected from identity verification page",
        page: "identity_verification_redirect"
    });
};
// ====== TDC Pages Action Items Tracking Ends ======

export const track = o => {
    return {
        type: actionTypes.TRACKING_BEACON,
        payload: {
            ...o
        }
    };
};

export const viewCreditEvolutionPage = () => {
    return track({
        description: "user viewed Credit Evolution Program page",
        page: "credit_evolution_program"
    });
};

export const clickedViewLoanDocument = () => {
    return track({
        description: "user clicked view loan document",
        page: "useraccount_click_view_loan_document"
    });
};

export const clickedViewStatementPDF = () => {
    return track({
        description: "user clicked view statement",
        page: "useraccount_click_view_statement"
    });
};

export const viewedLoanDocument = () => {
    return track({
        description: "user viewed loan document",
        page: "useraccount_viewed_loan_document"
    });
};

export const viewedStatementPDF = () => {
    return track({
        description: "user viewed statement",
        page: "useraccount_viewed_statement"
    });
};
export const submitResendCodeOTPEmailVerification = () => {
    return track({
        description: "user clicked on resend code on email OTP verification page",
        page: "email_otp_verification_resend_code"
    });
};

export const viewCancelDrawPage = () => {
    return track({
        description: "user viewed cancel draw page",
        page: "cancel_draw_page"
    });
};

export const submitYesCancelDrawAdvancePage = () => {
    return track({
        description: "user clicked on yes cancel it on cancel draw page",
        page: "cancel_draw_yes_cancel_it"
    });
};

export const submitNoKeepItCancelDrawAdvancePage = () => {
    return track({
        description: "user clicked on keep it on cancel draw page",
        page: "cancel_draw_no_keep_it"
    });
};

export const clickedLeanMoreDashboardInsurance = () => {
    return track({
        description: "user clicked learn more for Insurance from Dashboard",
        page: "insurance_learn_more_from_dashboard_clicked"
    });
};

export const clickedLeanMoreProfileInsurance = () => {
    return track({
        description: "user clicked learn more for Insurance from Profile",
        page: "insurance_learn_more_from_profile_clicked"
    });
};

export const viewPaymentProtectionPlanEnroll = () => {
    return track({
        description: "user viewed Payment Protection Plan Enrollment page",
        page: "payment_protection_plan_enrollment_viewed"
    });
};

export const submitPaymentProtectionPlanEnroll = () => {
    return track({
        description: "user submitted Payment Protection Plan Enrollment",
        page: "payment_protection_plan_enrollment_submit"
    });
};

export const viewInsuranceDisclosures = () => {
    return track({
        description: "user viewed Payment Protection Plan disclosures page",
        page: "user_viewed_insurance_disclosures_page"
    });
};

export const viewPADAuthSetUp = () => {
    return track({
        description: "user viewed PAD Auth Set up page",
        page: "user_viewed_pad_auth_setup_page"
    });
};

export const viewPADAuthSummary = () => {
    return track({
        description: "user viewed PAD Auth Summary page",
        page: "user_viewed_pad_auth_summary_page"
    });
};

export const submitPADAuthSummary = () => {
    return track({
        description: "user submitted PAD Auth Summary page",
        page: "pad_auth_summary_page_submit"
    });
};

export const viewPADAuthConfirmation = () => {
    return track({
        description: "user viewed PAD Auth Confirmation page",
        page: "user_viewed_pad_auth_confirmation_page"
    });
};

export const viewedCertificateOfInsurance = () => {
    return track({
        description: "user viewed Certificate of Insurance",
        page: "useraccount_viewed_insurance_certificate"
    });
};

export const clickedInsurancePaymentPortal = () => {
    return track({
        description: "user clicked Insurance Payment Portal link",
        page: "useraccount_clicked_insurance_payment_portal"
    });
};

export const viewAchAuthPage = () => {
    return track({
        description: "user viewed sign Standalone ACH page",
        page: "user_viewed_sign_standalone_ach_page"
    });
};

export const clickedGoToSignAchAuthPage = () => {
    return track({
        description: "user clicked 'Provide Authorization' button on the Profile page",
        page: "user_clicked_ach_auth_button_from_profile"
    });
};

export const clickedSubmitDocumentPage = document => {
    return track({
        description: `user submitted ${document} document page`,
        page: `user_submitted_${document}_document_page`
    });
};

export const lppOneTimePaymentUrlGenerated = () => {
    return track({
        description: "Lpp Payment URL generated",
        page: "lpp_payment_url"
    });
};

export const insurancePopupViewed = () => {
    return track({
        description: "user viewed insurance popup on dashboard",
        page: "insurance_popup_dashboard"
    });
};

export const insurancePopupClickedLearnMore = () => {
    return track({
        description: "user clicked learn more on insurance popup",
        page: "insurance_popup_learn_more_clicked"
    });
};

export const insurancePopupClickedSkip = () => {
    return track({
        description: "user clicked skip on insurance popup",
        page: "insurance_popup_skip_clicked"
    });
};

export const insurancePopupClickedCertificate = () => {
    return track({
        description: "user clicked certificate of insurance on insurance popup",
        page: "insurance_popup_certificate_link_clicked"
    });
};

export const loanAgreementAchPopupViewed = () => {
    return track({
        description: "user viewed loan doc ACH popup notification",
        page: "loan_docs_ACH_popup"
    });
};

export const loanAgreementAchPopupSignAchAuthClicked = () => {
    return track({
        description: "user clicked 'Sign ACH Auth' button on the ACH popup notification",
        page: "loan_docs_ACH_popup_closed"
    });
};

export const loanAgreementAchPopupDeclineAndContinueClicked = () => {
    return track({
        description: "user clicked 'Decline and Continue' button on the ACH popup notification",
        page: "loan_docs_ACH_popup_declined"
    });
};

export const viewedOriginationUniqueUrl = () => {
    return track({
        description: "user viewed unique origination URL",
        page: "user_viewed_unique_origination_url"
    });
};

export const settlementDashboardPopupViewed = () => {
    return track({
        description: "user viewed settlement popup on dashboard",
        page: "settlement_dashboard_popup_viewed"
    });
};

export const settlementDashboardPopupLinkClicked = () => {
    return track({
        description: "user clicked link in settlement popup on dashboard",
        page: "settlement_dashboard_popup_link_clicked"
    });
};

export const settlementDashboardPopupClosed = () => {
    return track({
        description: "settlement popup on dashboard was closed",
        page: "settlement_dashboard_popup_closed"
    });
};

export const settlementPaymentWarningPopupViewed = () => {
    return track({
        description: "user viewed settlement payment warning popup on payment page",
        page: "settlement_payment_warning_popup_viewed"
    });
};

export const settlementPaymentWarningPopupClosed = () => {
    return track({
        description: "user closed settlement payment warning popup on payment page",
        page: "settlement_payment_warning_popup_closed"
    });
};
