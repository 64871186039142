export const SECTION_PAYFREQUENCY = "section--payfrequency";

export const SECTION_BIWEEKLY_DAYOFTHEWEEK = "section--biweekly-dayoftheweek";
export const SECTION_BIWEEKLY_LASTPAYDATE = "section--biweekly-lastpaydate";

export const SECTION_MONTHLY_PAYDAY = "section--monthly-payday";
export const CANADA_SECTION_MONTHLY_PAYDAY = "canada-section--monthly-payday";
export const SECTION_MONTHLY_OTHER = "section--monthly-other";
export const SECTION_MONTHLY_DAYOFTHEMONTH = "section--monthly-dayofthemonth";
export const CANADA_SECTION_MONTHLY_DAYOFTHEMONTH = "canada-section--monthly-dayofthemonth";
export const SECTION_MONTHLY_WEEK = "section--monthly-week";
export const CANADA_SECTION_MONTHLY_WEEK = "canada-section--monthly-week";
export const SECTION_MONTHLY_WEEKDAY = "section--monthly-weekday";
export const CANADA_SECTION_MONTHLY_WEEKDAY = "canada-section--monthly-weekday";

export const SECTION_WEEKLY_DAYOFTHEWEEK = "section--weekly-dayoftheweek";

export const SECTION_SEMIMONTHLY_PAYDAYS = "section--semimonthly-paydays";
export const CANADA_SECTION_SEMIMONTHLY_PAYDAYS = "canada-section--semimonthly-paydays";
export const SECTION_SEMIMONTHLY_OTHERPAYDAYS = "section--semimonthly-otherpaydays";

export const SECTION_PAYCHECK = "section--paycheck";
