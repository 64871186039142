export const INCOME_TYPE__EMPLOYED = "E";
export const INCOME_TYPE__SELF_EMPLOYED = "SE";
export const INCOME_TYPE__SOCIAL_SECURITY = "S";
export const INCOME_TYPE__EMPLOYMENT_INSURANCE = "S";
export const INCOME_TYPE__VETERAN_BENEFIT = "V";
export const INCOME_TYPE__PENSION = "P";
export const INCOME_TYPE__CPP_OR_OTHER_PENSION = "P"
export const INCOME_TYPE__DISABILITY = "D";
export const INCOME_TYPE__UNEMPLOYED = "U";
export const INCOME_TYPE__OTHER = "O";

export const PAYMENT_TYPE__DIRECT_DEPOSIT = "D";
export const PAYMENT_TYPE__CASH = "C";
export const PAYMENT_TYPE__PAPER_CHECK = "P";
export const PAYMENT_TYPE__PAPER_CHEQUE = "P";


export const INCOME_TYPES = [
    INCOME_TYPE__EMPLOYED,
    INCOME_TYPE__SELF_EMPLOYED,
    INCOME_TYPE__EMPLOYMENT_INSURANCE,
    INCOME_TYPE__SOCIAL_SECURITY,
    INCOME_TYPE__VETERAN_BENEFIT,
    INCOME_TYPE__PENSION,
    INCOME_TYPE__CPP_OR_OTHER_PENSION,
    INCOME_TYPE__DISABILITY,
    INCOME_TYPE__UNEMPLOYED,
    INCOME_TYPE__OTHER
];

export const PAYMENT_TYPES = [
    PAYMENT_TYPE__DIRECT_DEPOSIT,
    PAYMENT_TYPE__CASH,
    PAYMENT_TYPE__PAPER_CHECK,
    PAYMENT_TYPE__PAPER_CHEQUE
];
