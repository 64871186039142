import React from "react";
import FeedbackForm from "./components/FeedbackForm";
import {Helmet} from "react-helmet";

class Feedback extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta name="robots" content="noindex,nofollow" />
                </Helmet>
                <article className="x-article-stateinfo">
                    <h1>Tell Us How We're Doing</h1>
                    <p>At MoneyKey, we value your feedback about our products and services.</p>
                    <p>
                        If you have something you'd like to share with us, please complete the feedback form below. If
                        your message requires a response, we will get back to you as soon as possible.
                    </p>
                    <hr />
                    <FeedbackForm />
                </article>
            </div>
        );
    }
}
export default Feedback;
