import React from "react";
import {Field} from "redux-form";
import * as fieldtags from "../../../shared/constant/fieldtags";
import * as _helpers from "../../../shared/helpers";
import XFieldErrorMessage from "../../../components/fields/XFieldErrorMessage";
import XInputField from "../../../components/fields/XInputField";
import XTextAreaField from "../../../components/fields/XTextAreaField";
import XPhoneNumberField from "../../../components/fields/XPhoneNumberField";
import {Select} from "@material-ui/core";

function FirstNameField({fields}) {
    let fieldtag = fieldtags.FEEDBACK_FIRST_NAME;
    let field = fields[fieldtag];

    let valid = field.meta.valid;
    let touched = field.meta.touched;

    let labelClassName = _helpers.getLabelClassName(valid, touched);
    let fieldClassName = _helpers.getFieldClassName(valid, touched);

    return (
        <div className="x-form-group">
            <label htmlFor={fieldtag} className={labelClassName}>
                First Name
            </label>
            <XFieldErrorMessage id={`err-${fieldtag}`} visible={!valid && touched}>
                {field.meta.error}
            </XFieldErrorMessage>
            <Field
                component={XInputField}
                id={fieldtag}
                name={fieldtag}
                type="text"
                placeholder="First Name (required)"
                maxLength={25}
                tabIndex={1}
                autoFocus={true}
                className={fieldClassName}
            />
        </div>
    );
}

function LastNameField({fields}) {
    let fieldtag = fieldtags.FEEDBACK_LAST_NAME;
    let field = fields[fieldtag];

    let valid = field.meta.valid;
    let touched = field.meta.touched;

    let labelClassName = _helpers.getLabelClassName(valid, touched);
    let fieldClassName = _helpers.getFieldClassName(valid, touched);

    return (
        <div className="x-form-group">
            <label htmlFor={fieldtag} className={labelClassName}>
                Last Name
            </label>
            <XFieldErrorMessage id={`err-${fieldtag}`} visible={!valid && touched}>
                {field.meta.error}
            </XFieldErrorMessage>
            <Field
                component={XInputField}
                id={fieldtag}
                name={fieldtag}
                type="text"
                placeholder="Last Name (required)"
                maxLength={25}
                tabIndex={2}
                className={fieldClassName}
            />
        </div>
    );
}

function EmailAddressField({fields}) {
    let fieldtag = fieldtags.FEEDBACK_EMAIL_ADDRESS;
    let field = fields[fieldtag];

    let valid = field.meta.valid;
    let touched = field.meta.touched;

    let labelClassName = _helpers.getLabelClassName(valid, touched);
    let fieldClassName = _helpers.getFieldClassName(valid, touched);

    return (
        <div className="x-form-group">
            <label htmlFor={fieldtag} className={labelClassName}>
                Email Address
            </label>
            <XFieldErrorMessage id={`err-${fieldtag}`} visible={!valid && touched}>
                {field.meta.error}
            </XFieldErrorMessage>
            <Field
                component={XInputField}
                id={fieldtag}
                name={fieldtag}
                type="text"
                placeholder="Email Address (required)"
                tabIndex={3}
                maxLength={254}
                className={fieldClassName}
            />
        </div>
    );
}

function PhoneNumberField({fields}) {
    let fieldtag = fieldtags.FEEDBACK_PHONE_NUMBER;
    let field = fields[fieldtag];

    let valid = field.meta.valid;
    let touched = field.meta.touched;

    let labelClassName = _helpers.getLabelClassName(valid, touched);
    const optionalFeedbackPhoneNumber = fields[fieldtags.FEEDBACK_PHONE_NUMBER].input.value;
    let fieldClassName = optionalFeedbackPhoneNumber === "" ? _helpers.getOptionalFieldClassName(valid, touched) : _helpers.getFieldClassName(valid, touched);
    return (
        <div className="x-form-group">
            <label htmlFor={fieldtag} className={labelClassName}>
                Phone Number
            </label>
            <XFieldErrorMessage id={`err-${fieldtag}`} visible={!valid && touched}>
                {field.meta.error}
            </XFieldErrorMessage>
            <Field
                component={XPhoneNumberField}
                id={fieldtag}
                name={fieldtag}
                type="text"
                placeholder="Phone Number"
                tabIndex={4}
                className={fieldClassName}
            />
        </div>
    );
}

function LoanField({fields}) {
    let fieldtag = fieldtags.FEEDBACK_LOAN_NUMBER;
    let field = fields[fieldtag];

    let valid = field.meta.valid;
    let touched = field.meta.touched;

    let labelClassName = _helpers.getLabelClassName(valid, touched);
    const optionalFeedbackLoanNumber = fields[fieldtags.FEEDBACK_LOAN_NUMBER].input.value;
    let fieldClassName = optionalFeedbackLoanNumber === "" ? _helpers.getOptionalFieldClassName(valid, touched) : _helpers.getFieldClassName(valid, touched);
    return (
        <div className="x-form-group">
            <label htmlFor={fieldtag} className={labelClassName}>
                Loan Number
            </label>
            <XFieldErrorMessage id={`err-${fieldtag}`} visible={!valid && touched}>
                {field.meta.error}
            </XFieldErrorMessage>
            <Field
                component={XInputField}
                id={fieldtag}
                name={fieldtag}
                maxLength="12"
                type="text"
                placeholder="Loan Number"
                tabIndex={5}
                className={fieldClassName}
            />
        </div>
    );
}

const CategoryField = ({fields}) => {
    let feedbackCategoryOptions = _helpers.FEEDBACK_CATEGORY_OPTIONS;

    let fieldtag = fieldtags.FEEDBACK_CATEGORY;
    let field = fields[fieldtag];
    let touched = field.meta.touched;
    let selectOptionValue = fields[fieldtags.FEEDBACK_CATEGORY].input.value;
    let selectOptionValid = (selectOptionValue === "" || selectOptionValue === undefined) ? false : true;

    let fieldClassName = _helpers.getOptionalSelectLabelClassName(selectOptionValid, touched);

    return (
        <React.Fragment>
            <label className="x-form-label">Category</label>
            {/* <XFieldErrorMessage id={`err-${fieldtag}`} visible={touched && invalid}>
                {field.meta.error}
            </XFieldErrorMessage> */}
            <Select
                native
                id={fieldtag}
                tabIndex={7}
                style={{
                    border:
                        touched && selectOptionValid ? "2px solid #22c728" : "2px solid #c8c8c8",
                    backgroundColor: "#fff",
                    width: "100%",
                    margin: "0 0 1em",
                    padding: "0.3em 2em 0.3em 0.3em"
                }}
                className={fieldClassName}
                onChange={e => field.input.onChange(e.target.value)}
            >
                {feedbackCategoryOptions.map(categoryOption => {
                    return (
                        <option
                            style={{margin: "10px", backgroundColor: "#fff"}}
                            id={categoryOption.id}
                            value={categoryOption.value}
                            key={categoryOption.id}
                        >
                            {categoryOption.text}
                        </option>
                    );
                })}
            </Select>
        </React.Fragment>
    );
};

function SubjectField({fields}) {
    let fieldtag = fieldtags.FEEDBACK_SUBJECT;
    let field = fields[fieldtag];

    let valid = field.meta.valid;
    let touched = field.meta.touched;

    let labelClassName = _helpers.getLabelClassName(valid, touched);
    const optionalFeedbackSubject = fields[fieldtags.FEEDBACK_SUBJECT].input.value;
    let fieldClassName = optionalFeedbackSubject === "" ? _helpers.getOptionalFieldClassName(valid, touched) : _helpers.getFieldClassName(valid, touched);

    return (
        <div className="x-form-group">
            <label htmlFor={fieldtag} className={labelClassName}>
                Subject
            </label>
            <XFieldErrorMessage id={`err-${fieldtag}`} visible={!valid && touched}>
                {field.meta.error}
            </XFieldErrorMessage>
            <Field
                component={XInputField}
                id={fieldtag}
                name={fieldtag}
                type="text"
                placeholder="Subject"
                maxLength="150"
                tabIndex={7}
                className={fieldClassName}
            />
        </div>
    );
}

function FeedbackField({fields}) {
    let fieldtag = fieldtags.FEEDBACK_CONTENT;
    let field = fields[fieldtag];

    let valid = field.meta.valid;
    let touched = field.meta.touched;

    let labelClassName = _helpers.getLabelClassName(valid, touched);
    let fieldClassName = _helpers.getFieldClassName(valid, touched);

    return (
        <div className="x-form-group">
            <label htmlFor={fieldtag} className={labelClassName}>
                Feedback
            </label>
            <XFieldErrorMessage id={`err-${fieldtag}`} visible={!valid && touched}>
                {field.meta.error}
            </XFieldErrorMessage>
            <Field
                component={XTextAreaField}
                multiline
                id={fieldtag}
                name={fieldtag}
                type="text"
                placeholder="Provide your feedback here"
                maxLength="1000"
                rows={6}
                tabIndex={8}
                className={fieldClassName}
            />
        </div>
    );
}

export default function FeedbackFields(fields) {
    return (
        <React.Fragment>
            <FirstNameField fields={fields} />
            <LastNameField fields={fields} />
            <EmailAddressField fields={fields} />
            <PhoneNumberField fields={fields} />
            <LoanField fields={fields} />
            <CategoryField fields={fields} />
            <SubjectField fields={fields} />
            <FeedbackField fields={fields} />
        </React.Fragment>
    );
}
