import {toast} from 'react-toastify';

const styledToast = {
    success(msg, options = {}) {
        return toast.success(msg, {
            ...options,
            className: "x-toast-success",
            progressClassName: "x-toast-success-progress"
        });
    },
    error(msg, options = {}) {
        return toast.error(msg, {
            ...options,
            className: "x-toast-error",
            progressClassName: "x-toast-error-progress",

        });
    }
};

export default styledToast;
