import React from "react";

export default function XField(props) {
    return (
        <props.FieldType
            {...props.input}
            id={props.id}
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            tabIndex={props.tabIndex}
            autoFocus={props.autoFocus}
            maxLength={props.maxLength}
            className={props.className}
            style={props.style}
            disabled={props.disabled}
            autoComplete="off"
            rows={props.rows}
        />
    );
}
