import * as actionTypes from "./actionTypes";
import {
    MC_IDENTITY_PLACEMENT_LOGIN_PAGE,
    MC_IDENTITY_PLACEMENT_BOT_DETECTION
} from "../../shared/constant/mcIdentityConstants";

export const setMcIdentityGlobalValues = (response) => {
    return {
        type: actionTypes.ADD_MC_IDENTITY_SESSION_ID,
        payload: response
    };
};

export const getMcIdentitySessionId = () => {
    return {
        type: actionTypes.GET_MC_IDENTITY_SESSION_ID
    };
};

export const updateIsMcIdentityPackageLoaded = (value) => {
    return {
        type: actionTypes.UPDATE_IS_MC_IDENTITY_PACKAGE_LOADED,
        payload: value
    };
};

export const updatedMcIdentityDetectionStartedValue = (page, value) => {
    if (page === MC_IDENTITY_PLACEMENT_LOGIN_PAGE) {
        return {
            type: actionTypes.UPDATE_LOGIN_PAGE_DETECTION_STARTED,
            payload: value
        };
    } else if (page === MC_IDENTITY_PLACEMENT_BOT_DETECTION) {
        return {
            type: actionTypes.UPDATE_BOT_DETECTION_STARTED,
            payload: value
        };
    }
};

export const updatedMcIdentityDetectionSubmittedValue = (page, value) => {
    if (page === MC_IDENTITY_PLACEMENT_LOGIN_PAGE) {
        return {
            type: actionTypes.UPDATE_LOGIN_PAGE_DATA_SUBMITTED,
            payload: value
        };
    } else if (page === MC_IDENTITY_PLACEMENT_BOT_DETECTION) {
        return {
            type: actionTypes.UPDATE_BOT_DETECTION_DATA_SUBMITTED,
            payload: value
        };
    }
};

export const postMcIdentityData = (data) => {
    return {
        type: actionTypes.POST_MC_IDENTITY_INSIGHTS_DATA,
        payload: data
    };
};

export const postMcIdentityTRACKINGData = (data) => {
    return {
        type: actionTypes.UPDATE_MC_IDENTITY_TRACKING_DATA,
        payload: data
    };
};

export const resetMcIdentityReducer = () => {
    return {
        type: actionTypes.RESET_MC_IDENTITY_STATE,
        payload: {}
    };
};

export const setMcIdentitySessionIdFetching = () => {
    return {
        type: actionTypes.FETCHING_MC_IDENTITY_SESSION_ID,
        payload: {}
    };
};
