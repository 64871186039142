export const EMPLOYER_SOCIAL_SECURITY = "SOCIAL SECURITY";
export const EMPLOYER_SOCIAL_ASSISTANCE = "SOCIAL ASSISTANCE";
export const EMPLOYER_EMPLOYMENT_INSURANCE = "EMPLOYMENT INSURANCE";
export const EMPLOYER_SELF_EMPLOYED = "SELF EMPLOYED";
export const EMPLOYER_VETERANS_BENEFITS = "VETERAN'S BENEFITS";
export const EMPLOYER_PENSION = "PENSION";
export const EMPLOYER_CPP_OR_OTHER_PENSION = "CPP_OR_OTHER_PENSION";
export const EMPLOYER_DISABILITY = "DISABILITY";
export const EMPLOYER_OTHER = "OTHER";
export const EMPLOYER_UNEMPLOYED = "UNEMPLOYED";

export const EMPLOYERS = [
    EMPLOYER_SOCIAL_SECURITY,
    EMPLOYER_SOCIAL_ASSISTANCE,
    EMPLOYER_EMPLOYMENT_INSURANCE,
    EMPLOYER_SELF_EMPLOYED,
    EMPLOYER_VETERANS_BENEFITS,
    EMPLOYER_PENSION,
    EMPLOYER_CPP_OR_OTHER_PENSION,
    EMPLOYER_DISABILITY,
    EMPLOYER_OTHER,
    EMPLOYER_UNEMPLOYED
]
