import * as actionTypes from "./actionTypes";
import * as constant from "../../shared/constant/constant";

/**
 * Update login Action
 * @param fieldName
 * @param fieldValue
 * @returns {{type: string, fieldName: *, fieldValue: *}}
 */
export const updateLogin = (fieldName, fieldValue) => {
    return {
        type: actionTypes.UPDATE_LOGIN,
        fieldName: fieldName,
        fieldValue: fieldValue
    };
};

/**
 * Trigger login action
 * @param email
 * @param password
 * @param token
 * @param page
 * @returns {{type: string, payload: {email: *, password: *}}}
 */
export const loginAction = (email, password, token, page = "") => {
    return {
        type: actionTypes.LOGIN,
        payload: {
            email: email,
            password: password,
            token: token,
            page: page
        }
    };
};

/**
 * Trigger login attempt
 * @param responsedata
 * @param email
 * @param password
 * @returns {{type: string, payload: {responsedata: *, email: *, password: *}}}
 */
export const loginAttemptResponse = (responsedata, email, password) => {
    return {
        type: actionTypes.AUTH_ATTEMPT_RESPONSE,
        payload: {
            responsedata: responsedata,
            email: email,
            password: password
        }
    };
};

/**
 * Trigger login failure
 * @param error
 * @returns {{type: string, error: *}}
 */
export const loginFailure = error => {
    return {
        type: actionTypes.LOGIN_ERROR,
        error: error
    };
};

/**
 * Trigger logout action
 * @returns {{type: string}}
 */
export const logoutAction = isAvoidResetReducer => {
    return {
        type: actionTypes.LOGOUT,
        payload: {
            isAvoidResetReducer
        }
    };
};

/**
 * Trigger logout success
 * @returns {{type: string}}
 */
export const logoutSuccess = () => {
    return {
        type: actionTypes.LOGOUT_SUCCESS
    };
};

/**
 * Trigger logout failure
 * @param error
 * @returns {{type: string, error: *}}
 */
export const logoutFailure = error => {
    return {
        type: actionTypes.LOGOUT_ERROR,
        error: error
    };
};

export const doesEmailExists = email => {
    return {
        type: actionTypes.DOES_EMAIL_EXIST,
        payload: {
            email
        }
    };
};

export const sendEmailVerification = (email, userId) => {
    return {
        type: actionTypes.SEND_EMAIL_VERIFICTION,
        payload: {
            email,
            userId
        }
    };
};

export const sendEmailVerificationOneTimePassword = (userId, isMobile) => {
    return {
        type: actionTypes.SEND_EMAIL_VERIFICTION_ONE_TIME_PASSWORD,
        payload: {
            userId,
            isMobile
        }
    };
};

export const submitEmailVerificationOTPCode = (userId, verificationCode, form, field, isAutoFund, prevPath) => {
    return {
        type: actionTypes.SUBMIT_EMAIL_VERIFICATION_OTP_CODE,
        payload: {
            userId, verificationCode, form, field, isAutoFund, prevPath
        }
    };
};

export const submitEmailVerificationOTPCodeSuccess = response => {
    return {
        type: actionTypes.SUBMIT_EMAIL_VERIFICATION_OTP_CODE_SUCCESS,
        payload: {
            response
        }
    };
};

export const isEmailVerificationTokenValid = (userId, token) => {
    return {
        type: actionTypes.VERIFY_EMAIL,
        payload: {
            userId,
            token
        }
    };
};

export const isValidEmailVerificationToken = (isValidEmailVerificationToken) => {
    return {
        type: actionTypes.IS_MKWEB_INVALID_EMAIL_VERIFICATION_TOKEN,
        payload: {
            isValidEmailVerificationToken: isValidEmailVerificationToken
        }
    };
};

export const isResetPasswordTokenValid = (userId, uniqueResetId) => {
    return {
        type: actionTypes.IS_MKWEB_RESET_PASSWORD_TOKEN_VALID,
        payload: {
            resetpasswordtoken: uniqueResetId,
            userId: userId
        }
    };
};

export const isResetPasswordTokenValidSuccess = data => {
    return {
        type: actionTypes.IS_MKWEB_RESET_PASSWORD_TOKEN_VALID_SUCCESS,
        payload: data
    };
};

export const isInvalidResetPasswordToken = data => {
    return {
        type: actionTypes.IS_MKWEB_INVALID_RESET_PASSWORD_TOKEN,
        payload: data
    };
};

export const updatePassword = (userId, password, resetPasswordToken) => {
    return {
        type: actionTypes.UPDATE_PASSWORD,
        payload: {
            userId,
            password,
            resetPasswordToken
        }
    };
};

export const updatePasswordWithoutToken = (userId, password) => {
    return {
        type: actionTypes.UPDATE_PASSWORD_WITHOUT_TOKEN,
        payload: {
            userId,
            password
        }
    };
};

export const emailResetPasswordLink = email => {
    return {
        type: actionTypes.EMAIL_RESET_PASSWORD_LINK,
        payload: {
            email
        }
    };
};

export const sendEmailVerificationSuccess = value => {
    return {
        type: actionTypes.SEND_EMAIL_VERIFICATION_SUCCESS,
        payload: {
            emailSent: value
        }
    };
};

export const isEmailVerificationSuccess = value => {
    return {
        type: actionTypes.EMAIL_VERIFICATION_SUCCESS,
        payload: {
            isEmailVerifiedSuccess: value
        }
    };
};

export const updatePasswordSuccess = value => {
    return {
        type: actionTypes.UPDATE_PASSWORD_SUCCESS,
        payload: {
            isPasswordResetSuccess: value
        }
    };
};

export const getLandingPage = (userId, currentPage, viaEmail = false, pageSkip = false) => {
    return {
        type: actionTypes.GET_LANDING_PAGE,
        payload: {
            userId,
            currentPage,
            viaEmail,
            pageSkip
        }
    };
};

export const getLandingPageSuccess = (response) => {
    return {
        type: actionTypes.GET_LANDING_PAGE_SUCCESS,
        payload: {
            response
        }
    };
};

export const updateSessionIdPostMfa = response => {
    return {
        type: actionTypes.UPDATE_SESSION_ID,
        payload: {
            response
        }
    };
}

export const updateSessionIdPostOtpLogin = response => {
    return {
        type: actionTypes.POST_OTP_LOGIN_UPDATE,
        payload: {
            response
        }
    };
}

export const addEmailToAuth = email => {
    return {
        type: actionTypes.ADD_EMAIL_TO_AUTH,
        payload: {
            email
        }
    };
}

export const createAndTrackMfaErrorMessage = (message, field) => {
    return {
        type: actionTypes.CREATE_AND_TRACK_MFA_TOAST_MESSAGE,
        payload: {
            from: constant.ERROR_FROM_API,
            key: field,
            message,
            messageType: constant.MESSAGE_TYPE_ERROR
        }
    };
};

export const createAndTrackMfaSuccessMessage = (message) => {
    return {
        type: actionTypes.CREATE_AND_TRACK_MFA_TOAST_MESSAGE,
        payload: {
            message,
            messageType: constant.MESSAGE_TYPE_SUCCESS
        }
    };
};

export const resetOtpLoginCounts = () => {
    return {
        type: actionTypes.RESET_OTP_LOGIN_COUNTS,
        payload: {}
    };
}

export const submitOtpLoginVerificationCodeIncorrect = () => {
    return {
        type: actionTypes.SUBMIT_OTP_LOGIN_VERIFICATION_CODE_INCORRECT,
        payload: {
        }
    };
};


export const sendNotification = (customerId,profile) => {
    return {
        type: actionTypes.SEND_NOTIFICATIONS,
        payload: {customerId,profile}
    };
};

export const sendNotificationSuccess = response => {
    return {
        type: actionTypes.SEND_NOTIFICATIONS_SUCCESS,
        payload: {
            response
        }
    };
};
