export const MFA_LOGIN_SET_UP_PAGE = "MFA_LOGIN_SET_UP_PAGE";
export const MFA_LOGIN_SMS_PAGE = "MFA_LOGIN_SMS_PAGE";
export const MFA_LOGIN_EMAIL_PAGE = "MFA_LOGIN_EMAIL_PAGE";
export const MFA_PROFILE_SET_UP_PAGE = "MFA_PROFILE_SET_UP_PAGE";
export const ERR_MFA_MAX_RETRY_REACHED = "max_retry_count_reached";
export const ERR_MFA_MAX_RESEND_REACHED = "max_resend_count_reached";
export const ERR_MFA_INVALID_VERIFICATION_CODE = "invalid_verification_code";
export const ERR_MFA_EARLY_RESEND = "early_resend";
export const ERR_MFA_CODE_EXPIRED = "code_expired";
export const ERR_MFA_USER_BLOCKED = "account_blocked";
export const MFA_CODE_VERIFIED = "success";
export const MFA_VERIFICATION_CODE_SENT = "sent";

