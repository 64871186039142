import axios from 'axios';
import * as utility from "../shared/utility/utility";
import * as route from "../shared/constant/route";

/**
 * Axios instance for <baseurl>/api/web
 * @type {AxiosInstance}
 */
let webapi = axios.create({
    baseURL: utility.getBaseURL() + route.WEB_API_BASE_PATH
});

export const changeWebapiBaseURL = () => {
    webapi.defaults.baseURL = utility.getBaseURL() + route.WEB_API_BASE_PATH
};

/**
 * Append X-Auth-Token to every request.
 */
/*
Experimenting to Set HEADER_X_AUTH_TOKEN using axios.defaults from AuthReducer _setAuthReducerCookie
webapi.interceptors.request.use(function (config) {
    console.log(config);
    console.log(webapi.defaults);
    //config.headers[constant.HEADER_X_AUTH_TOKEN] = cookieUtility.getCookie(constant.COOKIE_MKEWBAUTH_TOKEN);
    return config;
});
*/


export default webapi;
