import * as actionTypes from "./actionTypes";

/**
 * Reset loan reducer action
 * @returns {{type: string, payload: {}}}
 */
export const resetLoanReducer = () => {
    return {
        type: actionTypes.RESET_LOAN_REDUCER,
        payload: {}
    };
};

export const getLoanLimits = () => {
    return {
        type: actionTypes.GET_LOAN_LIMITS
    };
};

export const getLoanLimitsSuccess = response => {
    return {
        type: actionTypes.GET_LOAN_LIMITS_SUCCESS,
        payload: {
            response
        }
    };
};

export const getLoanLimitsByOrgAndState = (organizationId, stateCode, isForceFetch = false) => {
    return {
        type: actionTypes.GET_LOAN_LIMITS_BY_ORG_AND_STATE,
        payload: {
            organizationId,
            stateCode,
            isForceFetch
        }
    };
};

export const getLoanLimitsByOrgAndStateSuccess = response => {
    return {
        type: actionTypes.GET_LOAN_LIMITS_BY_ORG_AND_STATE_SUCCESS,
        payload: {
            response
        }
    };
};

export const getLoanLimitsByOrgAndStateFailure = error => {
    return {
        type: actionTypes.GET_LOAN_LIMITS_BY_ORG_AND_STATE_FAILURE,
        payload: {
            error
        }
    };
};

export const resetStateIsActive = () => {
    return {
        type: actionTypes.RESET_STATE_IS_ACTIVE,
        payload: {}
    };
};

export const getLoanLimitsForRcCustomer = loanLimitPayload => {
    return {
        type: actionTypes.GET_LOAN_LIMITS_FOR_RC_CUSTOMER,
        payload: {
            loanLimitPayload
        }
    };
};

export const getLoanLimitsForRcCustomerSuccess = response => {
    return {
        type: actionTypes.GET_LOAN_LIMITS_FOR_RC_CUSTOMER_SUCCESS,
        payload: {
            response
        }
    };
};

export const getActiveLoan = customerId => {
    return {
        type: actionTypes.GET_ACTIVE_LOAN,
        payload: {
            customerId
        }
    };
};

export const getActiveLoanSuccess = response => {
    return {
        type: actionTypes.GET_ACTIVE_LOAN_SUCCESS,
        payload: {
            response
        }
    };
};

export const getActiveLoanSummary = customerId => {
    return {
        type: actionTypes.GET_ACTIVE_LOAN_SUMMARY,
        payload: {
            customerId
        }
    };
};

export const getLatestNonWithdrawnLoanSummary = customerId => {
    return {
        type: actionTypes.GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY,
        payload: {
            customerId
        }
    };
};

export const getActiveLoanSummarySuccess = response => {
    return {
        type: actionTypes.GET_ACTIVE_LOAN_SUMMARY_SUCCESS,
        payload: {
            response
        }
    };
};

export const getLatestNonWithdrawnLoanSummarySuccess = response => {
    return {
        type: actionTypes.GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY_SUCCESS,
        payload: {
            response
        }
    };
};

export const updateActiveLoanDetails = response => {
    return {
        type: actionTypes.UPDATE_ACTIVE_LOAN_DETAILS,
        payload: {
            response
        }
    };
};

export const updateActiveLoanEntityId = response => {
    return {
        type: actionTypes.UPDATE_ACTIVE_LOAN_ENTITY_ID,
        payload: {
            response
        }
    };
};

export const updateActiveLoanAmount = (form, currentPage, loanAmount) => {
    return {
        type: actionTypes.UPDATE_ACTIVE_LOAN_AMOUNT,
        form: form,
        payload: {
            currentPage,
            loanAmount
        }
    };
};

export const updateLoanLimitHistory = (maxLoanAmount, loanAmount) => {
    return {
        type: actionTypes.UPDATE_LOAN_LIMIT_HISTORY,
        payload: {
            maxLoanAmount,
            loanAmount
        }
    };
};

export const getLoanDocsByCustomer = customerId => {
    return {
        type: actionTypes.GET_LOAN_DOCS_BY_CUSTOMER,
        payload: {
            customerId
        }
    };
};

export const getLoanDocsByCustomerSuccess = response => {
    return {
        type: actionTypes.GET_LOAN_DOCS_BY_CUSTOMER_SUCCESS,
        payload: {
            response
        }
    };
};

export const getLoanDocContent = (documentVersionId, baseLoanId, customerId) => {
    return {
        type: actionTypes.GET_LOAN_DOC_CONTENT,
        payload: {
            documentVersionId,
            baseLoanId,
            customerId
        }
    };
};

export const getLoanDocContentSuccess = response => {
    return {
        type: actionTypes.GET_LOAN_DOC_CONTENT_SUCCESS,
        payload: {
            response
        }
    };
};

export const saveLoanAgreement = data => {
    return {
        type: actionTypes.SAVE_LOAN_AGREEMENT,
        payload: {
            data
        }
    };
};

export const saveLoanAgreementSuccess = response => {
    return {
        type: actionTypes.SAVE_LOAN_AGREEMENT_SUCCESS,
        payload: {
            response
        }
    };
};

export const getPaymentSchedule = (customerId, baseLoanId) => {
    return {
        type: actionTypes.GET_PAYMENT_SCHEDULE,
        payload: {
            customerId,
            baseLoanId
        }
    };
};

export const getPaymentInfo = (baseLoanId) => {
    return {
        type: actionTypes.GET_PAYMENT_INFO,
        payload: {
            baseLoanId
        }
    };
};

export const getPaymentScheduleSuccess = response => {
    return {
        type: actionTypes.GET_PAYMENT_SCHEDULE_SUCCESS,
        payload: {
            response
        }
    };
};

export const getPaymentInfoSuccess = response => {
    return {
        type: actionTypes.GET_PAYMENT_INFO_SUCCESS,
        payload: {
            response
        }
    };
};

export const getPaymentTransactionInfo = (loanId, logId) => {
    return {
        type: actionTypes.GET_PAYMENT_TRANSACTION_INFO,
        payload: {
            loanId,
            logId
        }
    };
}

export const getPaymentTransactionInfoSuccess = response => {
    return {
        type: actionTypes.GET_PAYMENT_TRANSACTION_INFO_SUCCESS,
        payload: {
            response
        }
    }
}
export const acceptCreditLimitIncrease = (customerId, loanId) => {
    return {
        type: actionTypes.ACCEPT_CREDIT_LIMIT_INCREASE,
        payload: {
            customerId,
            loanId
        }
    };
};

export const acceptCreditLimitIncreaseSuccess = response => {
    return {
        type: actionTypes.ACCEPT_CREDIT_LIMIT_INCREASE_SUCCESS,
        payload: {
            response
        }
    };
};

export const submitReloan = (loanAmount, promoCode) => {
    return {
        type: actionTypes.SUBMIT_RELOAN,
        payload: {
            loanAmount,
            promoCode
        }
    };
};

export const submitReloanSuccess = response => {
    return {
        type: actionTypes.SUBMIT_RELOAN_SUCCESS,
        payload: {
            response
        }
    };
};

export const getFeeLevel = (baseLoanId) => {
    return {
        type: actionTypes.GET_LOC_LOWER_FEE_LEVEL,
        payload: {
            baseLoanId
        }
    };
};

export const getFeeLevelSuccess = response => {
    return {
        type: actionTypes.GET_LOC_LOWER_FEE_LEVEL_SUCCESS,
        payload: {
            response
        }
    };
};

export const getBankingInformation = customerId => {
    return {
        type: actionTypes.GET_CUSTOMER_BANKING_INFORMATION,
        payload: {
            customerId
        }
    }
}

export const getBankingInformationSuccess = response => {
    return {
        type: actionTypes.GET_CUSTOMER_BANKING_INFORMATION_SUCCESS,
        payload: {
            response
        }
    }
}

export const retrieveInterestRate = (baseLoanId) => {
    return {
        type: actionTypes.RETRIEVE_ANNUAL_INTEREST_RATE,
        payload: {
            baseLoanId
        }
    };
};

export const retrieveInterestRateSuccess = (response) => {
    return {
        type: actionTypes.RETRIEVE_ANNUAL_INTEREST_RATE_SUCCESS,
        payload: {
            response
        }
    };
};
