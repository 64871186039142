import React from "react";
import {put} from "redux-saga/effects";
import {push} from "connected-react-router";
import * as field from "../../shared/constant/field";
import * as route from "../../shared/constant/route";
import * as utility from "../../shared/utility/utility";
import * as errorActions from "../action/ErrorAction";
import * as organizationActions from "../action/OrganizationActions";
import api from "../../axios/api";
import * as createAndTrackToastMessageAction from "../action/CreateAndTrackToastMessageAction";
import * as form from "../../shared/constant/form";
import {startSubmit, stopSubmit} from "redux-form";
import styledToast from "../../components/widgets/toast";
import webapi from "../../axios/webapi";

export function* getOrganizationConfig(action) {
    try {
        let url = route.API_ORGANIZATION_CONFIG.replace(/:organizationId/, action.payload.organizationId);

        let response = yield api({
            method: "get",
            url
        });
        if (typeof response.data.supportNumber === "string") {
            response.data.supportNumber = response.data.supportNumber.trim();
        }
        yield put(organizationActions.getOrganizationConfigSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_ORGANIZATION_CONFIG));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_ORGANIZATION_CONFIG)
        );
    }
}

export function* isChatActive(action) {
    try {
        const response = yield api({
            method: "get",
            url: route.API_IS_CHAT_ACTIVE
        });
        yield put(organizationActions.isChatActiveSuccess(response.data));
        yield put(errorActions.clearApiError(null, field.ERROR_IS_CHAT_ACTIVE));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_IS_CHAT_ACTIVE));
    }
}

export function* getChatHours(action) {
    try {
        const response = yield api({
            method: "get",
            url: route.API_GET_CHAT_HOURS
        });

        yield put(organizationActions.getChatHoursSuccess(response.data));
        yield put(errorActions.clearApiError(null, field.ERROR_GET_CHAT_HOURS));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_CHAT_HOURS));
    }
}

export function* submitFeedbackForm(action) {
    yield put(startSubmit(form.FEEDBACK_FORM));
    try {
        const response = yield api({
            method: "post",
            url: route.API_SUBMIT_FEEDBACK,
            data: action.payload.data
        });

        yield put(organizationActions.submitFeedbackFormSuccess(response.data));
        yield put(errorActions.clearApiError(null, field.ERROR_SUBMIT_FEEDBACK));
        yield put(push(route.FEEDBACK_FORM_PAGE_SUBMITTED));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SUBMIT_FEEDBACK));
    }
    yield put(stopSubmit(form.FEEDBACK_FORM));
}

export function* submitDebtValidation(action) {
    try {
        yield api({
            method: "post",
            url: route.API_SUBMIT_DEBT_VALIDATION,
            data: action.payload.data
        });

        styledToast.success(<div>Debt validation response submitted successfully<br /><br /> Account Number: {action.payload.data.loanId} </div>, {autoClose: 5000});

    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SUBMIT_DEBT_VALIDATION));
    }

}

export function* getStateOrganizationConfiguration(action) {
    try {
        let url = route.WEB_API_STATE_ORGANIZATION_CONFIG
            .replace(/:organizationId/i, utility.getOrganizationId)
            .replace(/:statecode/i, action.payload.stateCode)

        const response = yield webapi({
            method: "get",
            url: url
        });
        yield put(organizationActions.getStateOrganizationConfigSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_STATE_ORGANIZATION_CONFIG));
    } catch (error) {
        // Do nothing.
    }
    yield put(stopSubmit(form.FEEDBACK_FORM));
}

export function* getServerTime(action) {
    try {
        let url = route.API_SERVER_TIME
        let response = yield api({
            method: "get",
            url
        });
        yield put(organizationActions.getServerTimeSuccess(response));
    } catch (error) {
    }
}

export function* emailOptOut(action) {
    try {
        let url = route.API_EMAIL_OPT_OUT
            .replace(/:customerId/i, action.payload.customerId)
            .replace(/:baseLoanId/i, action.payload.loanId)

        yield api({
            method: "post",
            url: url
        });

    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SUBMIT_FEEDBACK));
    }
    yield put(stopSubmit(form.FEEDBACK_FORM));
}
