import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {connect} from "react-redux";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import * as field from "../shared/constant/field";
import * as route from "../shared/constant/route";
import Auth from "./auth/AuthPage";
import * as organizationActions from "../store/action/OrganizationActions";
import PushNotification, {setDataTags, setExternalUserId} from "./OneSignal/PushNotification";
import * as progressAction from "../store/action/ProgressAction";
import * as tagConstants from "../shared/constant/tagConstants";
import {isForaCredit} from "../shared/utility/utility";
import {MC_IDENTITY} from "../shared/constant/field";
import {
    beginBehaviouralMonitoring, bindNewFormFields, clearBehaviouralData,
    loadMasterCardIdentityPackage, stopBehaviouralMonitoring
} from "../external.integrations/mastercard.identity/master.card.identity.utils";
import * as mcIdentityAction from "../store/action/McIdentityAction";
import {
    MC_IDENTITY_PLACEMENT_BOT_DETECTION,
    MC_IDENTITY_PLACEMENT_LOGIN_PAGE
} from "../shared/constant/mcIdentityConstants";
import {mcIdentityLoadingState} from "../store/reducer/McIdentityReducer";

const Application = React.lazy(() => import("./application/ApplicationPage"));
const Reapply = React.lazy(() => import("./reapply/ReapplyPage"));
const TDCPage = React.lazy(() => import("./dashboard/tdc/TDCPage"));
const Dashboard = React.lazy(() => import("./dashboard/DashboardPage"));
const Offers = React.lazy(() => import("./offers/OfferPage"));

const theme = createMuiTheme({
    typography: {
        fontFamily: "inherit",
        fontSize: "inherit",
        useNextVariants: true
    }
});

class App extends React.Component {
    componentDidMount() {
        this.props.setPreviousPathname(this.props.history.location.pathname);
        this.props.getOrganizationConfig(this.props.organizationId);
        PushNotification(this.props.buildProfile);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.disableBackButtonForEmailVerification(prevProps);
        try {
            window.scroll(0, 0);
        } catch (e) {
            console.log("Scrolling to top is not working for this browser.");
        }
        if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
            this.checkUserAuthentication(!!this.props.isAuthenticated);
        }
        if (prevProps.userId !== this.props[field.USERID]) {
            if (!!this.props[field.USERID])
                setExternalUserId(this.props[field.USERID].toString());
        }

        if (
            prevProps.history.location.pathname !== this.props.history.location.pathname ||
            prevProps.isMcIdentityPackageLoading !== this.props.isMcIdentityPackageLoading ||
            prevProps.mcIdentitySessionId !== this.props.mcIdentitySessionId ||
            prevProps.lastPathname !== this.props.lastPathname
        ) {

            if (this.props.history.location.pathname.includes(route.PAGE_DASHBOARD) || this.props.isMcIdentityPackageLoading === mcIdentityLoadingState.LOADING) {
                return;
            }

            const loginRoutes = [
                route.EMAIL_LOGIN_PAGE,
                route.APPLICATION_LOGIN_PAGE,
                route.STATE_LOGIN_PAGE
            ];

            const isLoginMcIdentityPackageRequired = loginRoutes.some(route => route.includes(this.props.history.location.pathname));
            const isApplyNowRoute = this.props.history.location.pathname.includes(route.APPLICATION_BASE_ROUTE_PARAMETER);

            if (isApplyNowRoute && this.props.isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED && this.props.mcIdentityLoginBehaviourEnabled && this.props.mcIdentityLoginDetectionStarted) {
                this.props.updatedMcIdentityDetectionStartedValue(MC_IDENTITY_PLACEMENT_LOGIN_PAGE, false);
                stopBehaviouralMonitoring(MC_IDENTITY.LOGIN_PAGE);
                clearBehaviouralData(MC_IDENTITY.LOGIN_PAGE);
            }

            if (isLoginMcIdentityPackageRequired && this.props.mcIdentityLoginBehaviourEnabled && !this.props.mcIdentityLoginDetectionSubmitted) {
                if (this.props.isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED && this.props.mcIdentityBotDetectionEnabled && this.props.mcIdentityLoginBehaviourEnabled && this.props.mcIdentityBotDetectionStarted) {
                    this.props.updatedMcIdentityDetectionStartedValue(MC_IDENTITY_PLACEMENT_BOT_DETECTION, false);
                    stopBehaviouralMonitoring(MC_IDENTITY.LOGIN_PAGE);
                    clearBehaviouralData(MC_IDENTITY.LOGIN_PAGE);
                }
                if (this.props.isMcIdentityPackageLoading === mcIdentityLoadingState.NOT_LOADED) {
                    this.props.updateIsMcIdentityPackageLoaded(mcIdentityLoadingState.LOADING);
                    loadMasterCardIdentityPackage(this.props.updateIsMcIdentityPackageLoaded, this.props.mcIdentityLoginClientId, MC_IDENTITY_PLACEMENT_LOGIN_PAGE);
                } else if (this.props.isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED){
                    bindNewFormFields();
                }
                if (this.props.isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED && !this.props.mcIdentityLoginDetectionStarted) {
                    beginBehaviouralMonitoring(MC_IDENTITY.LOGIN_PAGE, 1, this.props.mcIdentitySessionId, this.props.mcIdentityLoginClientId);
                    this.props.updatedMcIdentityDetectionStartedValue(MC_IDENTITY_PLACEMENT_LOGIN_PAGE, true);
                }
            } else if (!isLoginMcIdentityPackageRequired && this.props.mcIdentityBotDetectionEnabled) {
                if (this.props.isMcIdentityPackageLoading === mcIdentityLoadingState.NOT_LOADED) {
                    this.props.updateIsMcIdentityPackageLoaded(mcIdentityLoadingState.LOADING);
                    loadMasterCardIdentityPackage(this.props.updateIsMcIdentityPackageLoaded, this.props.mcIdentityBotDetectionClientId, MC_IDENTITY_PLACEMENT_BOT_DETECTION);
                } else if (this.props.isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED){
                    bindNewFormFields();
                }
                if (this.props.isMcIdentityPackageLoading === mcIdentityLoadingState.LOADED && !this.props.mcIdentityBotDetectionStarted) {
                    beginBehaviouralMonitoring(MC_IDENTITY.BOT_DETECTION, 1, this.props.mcIdentitySessionId, this.props.mcIdentityBotDetectionClientId);
                    this.props.updatedMcIdentityDetectionStartedValue(MC_IDENTITY_PLACEMENT_BOT_DETECTION, true);
                }
            }

            if (this.props.mcIdentitySessionId === null && (this.props.mcIdentityBotDetectionEnabled === "" && this.props.mcIdentityLoginBehaviourEnabled === "")) {
                this.props.getMcIdentitySessionId();
                this.props.setMcIdentitySessionIdFetching();
            }
        }
    }

    checkUserAuthentication = (isAuthenticated) => {
        setDataTags({[tagConstants.IS_LOGIN]: isAuthenticated});
    };

    disableBackButtonForEmailVerification(prevProps) {
        if (prevProps.lastPathname !== this.props.history.location.pathname) {
            if (prevProps.lastPathname === route.OTP_EMAIL_LOGIN_PAGE
                || prevProps.lastPathname === route.APPLICATION_OTP_EMAIL_VERIFICATION
                || prevProps.lastPathname === route.DASHBOARD_OTP_EMAIL_VERIFICATION) {
                this.disableBackButton();
            } else {
                window.removeEventListener("popstate", this.disableBackButtonOnPopState);
            }

            this.props.setPreviousPathname(this.props.history.location.pathname);
        }
    }

    disableBackButton = () => {
        window.history.pushState(null, null, window.location.href);
        window.addEventListener("popstate", this.disableBackButtonOnPopState);
    };

    disableBackButtonOnPopState = () => {
        window.history.go(1);
    };

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <React.Suspense fallback={<h1>Loading...</h1>}>
                    <Switch>
                        <Route path={`/${route.APPLICATION_BASE_ROUTE_PARAMETER}/:page`} component={Application} />
                        <Route path={route.DASHBOARD_TDC_PAGE} component={TDCPage} />
                        <Route path={`/${route.DASHBOARD_BASE_ROUTE_PARAMETER}`} component={Dashboard} />
                        <Route path={`/${route.REAPPLY_BASE_ROUTE_PARAMETER}/:page`} component={Reapply} />
                        {!isForaCredit() && <Route path={`/${route.OFFERS_BASE_ROUTE_PARAMETER}`} component={Offers} />}
                        <Route component={Auth} />
                    </Switch>
                </React.Suspense>

                <ToastContainer className="x-toast" bodyClassName="x-toast__body" />
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        organizationId:
            state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_ID],
        buildProfile: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE],
        lastPathname: state[field.REDUCER_NODE_APP_PROGRESS][field.PROGRESSBAR.LAST_PATHNAME],
        isAuthenticated: state[field.REDUCER_NODE_AUTH][field.ISAUTHENTICATED],
        userId: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.USERID],
        mcIdentitySessionId: state[field.REDUCER_NODE_MC_IDENTITY][MC_IDENTITY.MC_IDENTITY_SESSION_ID],
        mcIdentityBotDetectionEnabled: state[field.REDUCER_NODE_MC_IDENTITY][MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_ENABLED],
        mcIdentityLoginBehaviourEnabled: state[field.REDUCER_NODE_MC_IDENTITY][MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_ENABLED],
        mcIdentityLoginDetectionStarted: state[field.REDUCER_NODE_MC_IDENTITY][MC_IDENTITY.LOGIN_PAGE][MC_IDENTITY.DETECTION_STARTED],
        mcIdentityBotDetectionStarted: state[field.REDUCER_NODE_MC_IDENTITY][MC_IDENTITY.BOT_DETECTION][MC_IDENTITY.DETECTION_STARTED],
        mcIdentityLoginDetectionSubmitted: state[field.REDUCER_NODE_MC_IDENTITY][MC_IDENTITY.LOGIN_PAGE][MC_IDENTITY.DATA_SUBMITTED],
        mcIdentityLoginClientId: state[field.REDUCER_NODE_MC_IDENTITY][MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_CLIENT_ID],
        mcIdentityBotDetectionClientId: state[field.REDUCER_NODE_MC_IDENTITY][MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_CLIENT_ID],
        isMcIdentityPackageLoading: state[field.REDUCER_NODE_MC_IDENTITY][MC_IDENTITY.IS_MC_IDENTITY_PACKAGE_LOADING]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationConfig: organizationId => dispatch(organizationActions.getOrganizationConfig(organizationId)),
        setPreviousPathname: url => dispatch(progressAction.setPreviousPathname(url)),
        getMcIdentitySessionId: () => dispatch(mcIdentityAction.getMcIdentitySessionId()),
        updateIsMcIdentityPackageLoaded: (value) => dispatch(mcIdentityAction.updateIsMcIdentityPackageLoaded(value)),
        setMcIdentitySessionIdFetching: () => dispatch(mcIdentityAction.setMcIdentitySessionIdFetching()),
        updatedMcIdentityDetectionStartedValue: (placement, value) => dispatch(mcIdentityAction.updatedMcIdentityDetectionStartedValue(placement, value))
    };
};

App = connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

export default withRouter(App);
