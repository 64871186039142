import * as field from '../../shared/constant/field'
import * as actionTypes from "../action/actionTypes";
import _ from "lodash";
import * as cookieUtility from "../../shared/utility/cookieutility";
import * as constant from "../../shared/constant/constant";

const initialState = {
    [field.IS_INSURANCE_SUPPORTED]: null,
    [field.INSURANCE_STATUS]: null,
    [field.PAYMENT_PROTECTION_PLAN_ACKNOWLEDGEMENT]: null,
    [field.IS_ALL_ACTION_ITEM_COMPLETED_FOR_INSURANCE]: false,
    [field.SHOW_INSURANCE_POPUP]: null,
    [field.PAYMENT_PROTECTION_PLAN_DETAILS] : {
        [field.INSURANCE_DISCLOSURE_AND_ACKNOWLEDGEMENT]: false,
        [field.INSURANCE_USER_FIRST_NAME]: null,
        [field.INSURANCE_USER_LAST_NAME]: null,
        [field.INSURANCE_USER_EMAIL]: null,
        [field.BANK_NAME]: null,
        [field.INSURANCE_BANK_INSTITUTION_NUMBER]: null,
        [field.INSURANCE_BANK_TRANSIT_NUMBER]: null,
        [field.ACCOUNT_NUMBER]: null,
        [field.ACCOUNT_TYPE]: null,
        [field.DATE_OF_AUTH_AGREEMENT]: null,
        [field.IS_PRE_FILL_BANKING_INFORMATION]: false,
        [field.INSURANCE_POLICY_NUMBER]: null,
        [field.INSURANCE_ENQUIRY_EMAIL]: null,
        [field.INSURANCE_ENQUIRY_PHONE]: null,
        [field.CUSTOMER_DASHBOARD_URL]: null,
        [field.INSURANCE_POLICY_DOCUMENT_URL]: null

    }
};

const resetInsuranceReducer = () => {
    return _.cloneDeep(initialState);
};

const getInsuranceStatusSuccess = (state,action) => {
    let updatedState = {
        ...state,
        ...action.payload.response.data
    }
    _setInsuranceReducerCookie(updatedState);
    return updatedState;
}
const submitInsuranceEnrollmentSuccess = (state,action)=>{
    let updatedState = {
        ...state,
        [field.PAYMENT_PROTECTION_PLAN_ACKNOWLEDGEMENT]: action.payload.enrollment
    }
    _setInsuranceReducerCookie(updatedState);
    return updatedState;
}

const updatePaymentProtectionPlanDetails = (state, action) => {
    let updatedState =  {
        ...state,
        ...{
            [field.PAYMENT_PROTECTION_PLAN_DETAILS]: {...state[field.PAYMENT_PROTECTION_PLAN_DETAILS], ...action.payload.data}
        }
    };
    _setInsuranceReducerCookie(updatedState);
    return updatedState;
}

const updateActionItemCompleted = (state) => {
    let updatedState = {
        ...state,
        [field.IS_ALL_ACTION_ITEM_COMPLETED_FOR_INSURANCE]: true
    }
    _setInsuranceReducerCookie(updatedState);
    return updatedState;
}

const getPaymentProtectionPlanDetailsSuccess = (state,action) => {
    let updatedState = {
        ...state,
        [field.INSURANCE_STATUS]: action.payload.response.data.status,
        [field.PAYMENT_PROTECTION_PLAN_DETAILS]: {
            [field.INSURANCE_POLICY_NUMBER]: action.payload.response.data.policyNumber,
            [field.INSURANCE_ENQUIRY_EMAIL]: action.payload.response.data.enquiryEmail,
            [field.INSURANCE_ENQUIRY_PHONE]: action.payload.response.data.enquiryPhone,
            [field.CUSTOMER_DASHBOARD_URL]: action.payload.response.data.customerDashboardUrl,
            [field.INSURANCE_POLICY_DOCUMENT_URL]: action.payload.response.data.policyDocumentUrl,
        },


    }
    _setInsuranceReducerCookie(updatedState);
    return updatedState;
}

const showInsurancePopupSuccess = (state,action) => {
    let updatedState = {
        ...state,
        [field.SHOW_INSURANCE_POPUP]: action.payload.response.data,
    }
    return updatedState;
}

const resetInsurancePopupFlag = (state) => {
    let updatedState = {
        ...state,
        [field.SHOW_INSURANCE_POPUP]: null,
    }
    return updatedState;
}

export const loadInsuranceDataFromCookie = () => {
    let insuranceState = initialState;
    let mkWebInsuranceData = cookieUtility.getCookie(constant.COOKIE_MKWEBINSURANCE_DATA);
    insuranceState = {...insuranceState, ...mkWebInsuranceData};
    return insuranceState;
};

const _setInsuranceReducerCookie = state => {
    let cookieMkWebInsuranceData = _.cloneDeep(state);
    cookieUtility.setCookie(constant.COOKIE_MKWEBINSURANCE_DATA, JSON.stringify(cookieMkWebInsuranceData));
};

const _clearInsuranceReducerCookie = () =>{
    cookieUtility.removeCookie(constant.COOKIE_MKWEBINSURANCE_DATA);
    return resetInsuranceReducer();
}
export default function insuranceReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SUBMIT_INSURANCE_ENROLLMENT_SUCCESS:
            return submitInsuranceEnrollmentSuccess(state, action);
        case actionTypes.UPDATE_PAYMENT_PROTECTION_PLAN_DETAILS:
            return updatePaymentProtectionPlanDetails(state, action);
        case actionTypes.GET_INSURANCE_STATUS_SUCCESS:
            return getInsuranceStatusSuccess(state, action);
        case actionTypes.GET_PAYMENT_PROTECTION_PLAN_DETAILS_SUCCESS:
            return getPaymentProtectionPlanDetailsSuccess(state, action);
        case actionTypes.GET_ACTION_ITEM_COMPLETED_SUCCESS:
            return updateActionItemCompleted(state);
        case actionTypes.RESET_INSURANCE_DATA:
            return _clearInsuranceReducerCookie();
        case actionTypes.SHOW_INSURANCE_POPUP_SUCCESS:
            return showInsurancePopupSuccess(state,action);
        case actionTypes.RESET_INSURANCE_POPUP:
            return resetInsurancePopupFlag(state);
        default:
            return state;
    }
};
