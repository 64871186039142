import React from "react";

import CustomMaskedInput from "./CustomMaskedInput";

export default class MaskedPhoneField extends React.Component {
    constructor(props) {
        super(props);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleKeyPress(e) {
        if (!/\d/.test(e.key)) {
            e.preventDefault();
        }
    }

    render() {
        return <CustomMaskedInput {...this.props} onKeyPress={this.handleKeyPress} />;
    }
}

MaskedPhoneField.defaultProps = {
    mask: ["(", /[2-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
    placeholder: "Enter 10 digit number"
};
