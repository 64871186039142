import {select, put} from "redux-saga/effects";
import * as route from "../../shared/constant/route";
import * as utility from "../../shared/utility/utility";
import * as insuranceActions from "../action/InsuranceAction";
import * as createAndTrackToastMessageAction from "../action/CreateAndTrackToastMessageAction";
import webapi from "../../axios/webapi";
import * as field from "../../shared/constant/field";

export function* getInsuranceStatus(action){
    try {
        let url = route.WEB_API_GET_INSURANCE_STATUS
            .replace(/:organizationId/i, utility.getOrganizationId())
            .replace(/:stateCode/i, action.payload.stateCode)
            .replace(/:baseLoanId/i, action.payload.baseLoanId);
        const response = yield webapi({
            method: "get",
            url
        });

        yield put(insuranceActions.getInsuranceStatusSuccess(response));
    } catch(error){
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* submitInsuranceEnrollment(action){
    try {
        let url = route.WEB_API_SUBMIT_INSURANCE_ACKNOWLEDGEMENT_ACTION;
        yield webapi({
            method: "post",
            url,
            data: {
                action: action.payload.enrollment,
                organizationId: utility.getOrganizationId(),
                state: action.payload.stateCode,
                baseLoanId: action.payload.baseLoanId
            }
        });
        yield put(insuranceActions.submitInsuranceEnrollmentSuccess(action.payload.enrollment));
    } catch(error){
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* submitPaymentProtectionPlanDetails(action){
    try {
        let url = route.WEB_API_APPLY_FOR_PAYMENT_PROTECTION_PLAN;
        const state = yield select();
        yield webapi({
            method: "post",
            url,
            data: {
                customerId: state[field.REDUCER_NODE_AUTH][field.CUSTOMER_ID],
                baseLoanId: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.BASE_LOAN_ID],
                currentLoanStatus: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN_SUMMARY][field.LOAN.LOAN_STATUS],
                ...action.payload.data
            }
        });
        yield put(insuranceActions.submitInsuranceEnrollmentSuccess(action.payload.enrollment));
    } catch(error){
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* getPaymentProtectionPlanDetails(action){
    try {
        let url = route.WEB_API_GET_PAYMENT_PROTECTION_PLAN_DETAILS
            .replace(/:baseLoanId/i, action.payload.baseLoanId);
        const response = yield webapi({
            method: "get",
            url
        });
        yield put(insuranceActions.getPaymentProtectionPlanDetailsSuccess(response));
    } catch(error){
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}


export function* showInsurancePopup(action) {
    try {
        let url = route.WEB_API_SHOW_INSURANCE_POPUP
            .replace(/:userId/i, action.payload.userId);

        let response = yield webapi({
            method: "get",
            url
        });
        yield put(insuranceActions.showInsurancePopupSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* logInsurancePopupTime(action) {
    try {
        let url = route.WEB_API_LOG_INSURANCE_POPUP_TIME
            .replace(/:userId/i, action.payload.userId);

        yield webapi({
            method: "get",
            url
        });
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}
