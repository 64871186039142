export const FIRST_DAY_OF_THE_MONTH = "dom:1";
export const THIRD_OF_THE_MONTH = "dom:3";
export const LAST_DAY_OF_THE_MONTH = "dom:-1";
export const SECOND_WED_OF_THE_MONTH = "wom:2:3";
export const THIRD_WED_OF_THE_MONTH = "wom:3:3";
export const FOURTH_WED_OF_THE_MONTH = "wom:4:3";
export const I_DONT_SEE_MY_PAY_DATE = "iDontSeeMyPayDate";
export const SAME_DATE_EACH_MONTH = "sameDateEachMonth";
export const SAME_DATE_EACH_WEEK = "sameDateEachWeek";
export const SAME_DAY_OF_WEEK_EACH_MONTH = "SameDayOfWeekEachMonth";
export const  SAME_DATE_IN_EACH_MONTH ="sameDateInEachMonth";
