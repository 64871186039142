import React from "react";
import P from "prop-types";
import {connect} from "react-redux";
import LazyLoad from 'react-lazyload';
import * as constants from "../../shared/constant/constant";
import * as field from "../../shared/constant/field";
import gdLogo from "../../images/third-party/gd-logo.png";

class CreditfreshPlusFooter extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="x-background_color_cf_footer wrapper p-3">
                    <div className="text-left cf-header">
                        <br/>
                        <br/>
                        <br/>
                        <LazyLoad>
                            <img src={this.props.uriCreditfreshImage} alt="creditfresh-logo" className="cf-footer-img"/>
                        </LazyLoad>
                    </div>
                    <br/>
                    <br/>

                    <div className="footer-content py-5 pr-lg-3 cf-footer-content">
                        <p>The Line of Credit is offered by First Electronic Bank, Member FDIC.</p>
                        <br/>
                        <p>First Electronic Bank reserves the right to assess your creditworthiness periodically.</p>
                        <br/>
                        <p>IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To help the government
                            fight the funding of terrorism and money laundering activities, federal law requires all
                            financial institutions to obtain, verify and record information that identifies each person
                            who opens an account. What this means for you: When you open an account, we will ask your
                            name, address, date of birth, and other information that will allow us to identify you. We
                            may also ask to see your driver’s license or other identifying documents.</p>
                        <p><sup>1</sup>Not all requests are approved; duration of approval process may vary. Credit
                            limit may change and is subject to further verification criteria.</p>
                        <p><sup>2</sup>If approved, any requested funds will typically be deposited into your bank
                            account the same business day; timing of funding may vary. The date and time the funds are
                            made available to you by your bank are subject to your bank’s policies. For specific funding
                            cut-off times,
                            {" "}<a className="footer-links-color" href={this.props.cutOffTimesLink} target="_blank"
                                    rel="noopener noreferrer">click here</a>.</p>
                        <p><sup>3</sup>As you move through your credit journey, your account may become eligible for
                            reduced billing cycle charges and/or increased credit limits if available. Eligibility is
                            based on account behavior over a period of time. You will receive notices, as applicable,
                            regarding your individual circumstances and eligibility. If your Line of Credit amount has
                            reached the lowest billing cycle charges or the maximum credit limit available to you, you
                            may not currently be subject to further program benefits. Additional criteria may apply;
                            eligibility varies by state and program.
                        </p>
                        <hr className="x-footer-hr"/>
                    </div>

                    <div className="footer-sidebar regular-font">
                        <section className="p-3 pr-0 ml-lg-5">
                            <p className="footer-text-color text-center">
                                <b>
                                    <span className="material-symbols-rounded footer-question-mark">help</span>
                                    &nbsp; Questions? We're here to help.
                                </b>
                            </p>
                            <div className="text-center">
                                <button
                                    id="submit-login"
                                    type="submit"
                                    className="contact-us-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(this.props.content.common.contactUsURL, "_blank");
                                    }}
                                >
                                    <b>Contact Us</b>
                                </button>
                            </div>
                        </section>
                    </div>

                    <div className="footer">
                        <section className="text-sm-left text-lg-left footer-links-color">
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.termsOfUseURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small className="small-font">Terms of Use</small>
                            </a>
                            <span className="px-1 seperator-color">|</span>
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.privacyPolicyURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small className="small-font">Privacy Notices</small>
                            </a>
                            <span className="px-1 seperator-color">|</span>
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.resourceCenter}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small className="small-font">Resource Center</small>
                            </a>
                        </section>

                        <p className="footer-text-color text-sm-left text-lg-left mb-0 pt-2">
                            <small className="small-font">
                                © {(new Date()).getFullYear()} CreditFresh, Inc. All rights reserved.
                            </small>
                        </p>
                        <div>
                            <LazyLoad>
                                <img src={gdLogo} alt="gd-logo" id="gd-logo-img"/>
                            </LazyLoad>
                        </div>
                        <p className="footer-bottom-height"></p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CreditfreshPlusFooter.propTypes = {
    uriCreditfreshImage: P.string.isRequired,
    tel: P.string,
    uriSitemapLink: P.string.isRequired,
    uriTermsOfUseLink: P.string.isRequired,
    cutOffTimesLink: P.string.isRequired
};

const mapStateToProps = state => {
    return {
        uriCreditfreshImage: "/resources/images/creditfresh/creditfresh-logo-footer.svg",
        tel: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.SUPPORT_NUMBER],
        uriSitemapLink: `${constants.PUBLIC_WEBSITE}/sitemap/`,
        uriTermsOfUseLink: `${constants.PUBLIC_WEBSITE}/legal/terms-of-use/`,
        cutOffTimesLink: `${constants.PUBLIC_WEBSITE}/resources/faqs/#faq8`,
        //
        resourceCDN: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.RESOURCE_CDN],
        resourceFolderName:
            state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.RESOURCE_FOLDERNAME],
        content: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT]
    };
};

CreditfreshPlusFooter = connect(
    mapStateToProps
)(CreditfreshPlusFooter);

export default CreditfreshPlusFooter;
