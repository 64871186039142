/**
 * Get LS value by its name
 * @param LSName
 * @returns {any}
 */
export const getLcalStorageValue = (LSName) => {
    return window.localStorage.getItem(LSName)
}

/**
 * Set LS Value
 * @param LSName
 * @param LSValue
 */
export const setLocalStorageValue = (LSName, LSValue) => {
    // Remove existing LSName with same name if any before setting it.
    removeLSValue(LSName)
    window.localStorage.setItem(LSName, LSValue)
}

/**
 * Remove Local storage value
 *
 * @param LSName
 */
export const removeLSValue = (LSName) => {
    window.localStorage.removeItem(LSName)
}
