import * as route from "../../shared/constant/route";
import {call, put, select} from "redux-saga/effects";
import * as actions from "../action/ContentAction";
import * as trackingActions from "../action/trackingActions";
import * as authAction from "../action/AuthAction";
import webapi from "../../axios/webapi";
import {push} from "connected-react-router";
import * as routeutility from "../../shared/utility/routeutility";
import {getIpAddress} from "../../shared/utility/ipify";
import * as form from "../../shared/constant/form";
import {startSubmit, stopSubmit} from "redux-form";
import * as field from "../../shared/constant/field";
import * as constant from "../../shared/constant/constant";
import * as errorActions from "../action/ErrorAction";
import * as createAndTrackToastMessageAction from "../action/CreateAndTrackToastMessageAction";
import hotboxapi from "../../axios/hotboxapi";
import * as utility from "../../shared/utility/utility";

let _ip = null;

export function* getConsentDocument(action) {
    try {
        let organizationId = action.payload.organizationId;
        let documentTypes = [];
        let documentNames = [];
        documentTypes.push(action.payload.documentType);
        if (!utility.isNullOrUndefined(action.payload.documentName)) {
            documentNames.push(action.payload.documentName);
        }

        let getDocumentURL = route.WEB_API_GET_CONSENT_CONTENT;
        const response = yield call(webapi, {
            method: "post",
            url: getDocumentURL,
            data: {
                organizationId: organizationId,
                documentTypes: documentTypes,
                documentNames: documentNames
            }
        });

        yield put(actions.getConsentContentSuccess(response));
    } catch (error) {
        console.log(error);
    }
}

export function* getContentByDocName(action) {
    try {
        let organizationId = action.payload.organizationId;
        let documentNames = [];
        documentNames.push(action.payload.documentName);

        const response = yield call(webapi, {
            method: "post",
            url: route.WEB_API_GET_DOC_NAME_BASED_CONTENT,
            data: {
                organizationId,
                documentNames
            }
        });

        yield put(actions.getContentByDocNameSuccess(response));
    } catch (error) {
        console.log(error);
    }
}

export function* saveConsentDocument(action) {
    yield put(startSubmit(form.ACCOUNT_TERMS));
    try {
        if (!_ip) {
            try {
                _ip = yield getIpAddress();
            } catch (err) {
            }
        }

        const data = action.payload;
        const response = yield call(webapi, {
            method: "post",
            url: route.WEB_API_SAVE_CONSENT_CONTENT,
            headers: {
                "User-Ip": _ip,
            },
            data,
        });

        let responseKyc;
        try {
            if (data.leadSequenceId) {
                let rekycUrl = route.HOTBOX_API_REKYC
                    .replace(/:organizationId/i, data.organizationId)
                    .replace(/:baseLoanId/i, data.baseLoanId)
                    .replace(/:leadSequenceId/i, data.leadSequenceId);

                responseKyc = yield call(hotboxapi, {
                    method: "get",
                    url:rekycUrl
                });
            }
        } catch (e) {
            // If exception occurs then let it go
        }

        yield put(errorActions.clearApiError(null, field.ERROR_SAVE_CONSENT_DOCUMENT));
        //If user has outstanding flex loan then reject the application. However, the information is saved for future reference.
        yield put(actions.saveConsentContentSuccess(response));
        // NKWB-4270 : account_terms_submit page is added in tracking info after submitting the welcome page
        // Document names are submitted from Alert Dialog box, which should not trigger submit account terms tracking.
        // Account terms documents are submitted by documentTypes, for which submit account terms tracking should be triggered.
        if(action.payload.documentTypes) {
            yield put(trackingActions.submitAccountTerms());
        }

        if (action.payload.haveExistingFlexLoan) {
            yield put(push(routeutility.getRejectPage(constant.REJCT_FLEX_LOAN)));
        } else if (responseKyc && responseKyc.data.kyc_result === "fail") {
            yield put(push(route.APPLICATION_REJECT_PAGE));
        } else if (window.location.pathname.startsWith(route.APPLICATION_ACCOUNT_TERMS_PAGE)) {
            let userId = yield select(state => state[field.REDUCER_NODE_AUTH][field.USERID]);
            yield put(authAction.getLandingPage(userId, window.location.pathname));
            //yield put(push(routeutility.getNextPage(route.APPLICATION_ACCOUNT_TERMS_PAGE, action.payload.isOrganic)));
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SAVE_CONSENT_DOCUMENT));
    }
    yield put(stopSubmit(form.ACCOUNT_TERMS));
}
