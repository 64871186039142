// '-----------------------[ stateinfo page ]-----------------------'
export const STATEINFO_EMAIL_ADDRESS = "x0FF";
export const STATEINFO_STATE = "x0FE";
export const STATEINFO_LOAN_AMOUNT = "x0FD";

// '--------------------[ createpassword page ]---------------------'
export const CREATEPASSWORD_PASSWORD = "x100";
// '---------------------[ personalinfo page ]----------------------'
export const PERSONALINFO_ID = "x2EF";
export const PERSONALINFO_FIRST_NAME = "x2EE";
export const PERSONALINFO_LAST_NAME = "x2ED";
export const PERSONALINFO_BIRTHDATE = "x2EC";
export const PERSONALINFO_SSN = "x2EB";
export const PERSONALINFO_UNMASKED_SSN = "x2EK";
export const PERSONALINFO_UNMASKED_SIN = "x2EK-1";
export const PERSONALINFO_SIN = "x2EB-2";
export const PERSONALINFO_PHONE_NUMBER = "x2EA";
export const PERSONALINFO_IS_MOBILE = "x2E9";
export const PERSONALINFO_IS_MOBILE__NO = "x2E9-0";
export const PERSONALINFO_IS_MOBILE__YES = "x2E9-1";
export const PERSONALINFO_RECEIVE_OFFERS = "x2E8";
export const PERSONALINFO_RECEIVE_OFFERS__NO = "x2E8-0";
export const PERSONALINFO_RECEIVE_OFFERS__YES = "x2E8-1";
export const PERSONALINFO_DM_CODE = "x2E7";
export const PERSONALINFO_DM_CODE_VALID = "x2E7-0";
export const PERSONALINFO_IS_LANDLINE_CHECKBOX = "x2E12-0";
export const PERSONALINFO_RECEIVE_OFFERS_CHECKBOX = "x2E12-1";

// '----------------------[ addressinfo page ]----------------------'
export const ADDRESSINFO_ADDRESS_AUTOCOMPLETE = "x310";
export const ADDRESSINFO_CONFIRM_STATE_CHANGE = "x311";
export const ADDRESSINFO_CONFIRM_STATE_CHANGE__NO = "x311-0";
export const ADDRESSINFO_CONFIRM_STATE_CHANGE__YES = "x311-1";
export const STREET_ADDRESS = "x320"
export const ADDRESSINFO_ADDRESS_LINE_1 = "x312";
export const ADDRESSINFO_ADDRESS_LINE_2 = "x313";
export const ADDRESSINFO_CITY = "x314";
export const ADDRESSINFO_STATE = "x315";
export const ADDRESSINFO_ZIPCODE = "x316";
export const ADDRESSINFO_POSTAL_CODE = "x317";
export const ADDRESSINFO_RESIDENCY_STATUS = "x318";
export const ADDRESSINFO_PROVINCE = "x319";


// '--------------------[ paymentschedule page ]--------------------'
export const PAYMENTSCHEDULE_PAYCHECK_AMOUNT = "x4DF";
export const PAYMENTSCHEDULE_RENT_AMOUNT = "x4DE";

// '--------------------[ livingsituation page ]--------------------'
export const LIVINGSITUATION_RENT_OR_OWN = "x500";
export const LIVINGSITUATION_RENT = "x500-1";
export const LIVINGSITUATION_OWN = "x500-2";
export const LIVINGSITUATION_LIVING_FOR_YEARS = "x501";
export const LIVINGSITUATION_LIVING_FOR_0_YEARS = "x501-0";
export const LIVINGSITUATION_LIVING_FOR_3_YEARS = "x501-3";
export const LIVINGSITUATION_LIVING_FOR_6_YEARS = "x501-6";

// '-------------------------[ login page ]-------------------------'
export const LOGIN_EMAIL_ADDRESS = "x6FF";
export const LOGIN_PASSWORD = "x6FE";
export const LOGIN_FOUR_DIGITS_SSN = "x6FD";

// '-------------------------[ email login page (FORA) ]-------------------------'

export const EMAIL_LOGIN_EMAIL_ADDRESS = "x6FG";

// '---------------------[ resetpassword page ]---------------------'
export const RESETPASSWORD_EMAIL_ADDRESS = "x7FF";

// '---------------------[ email verification page ]---------------------'
export const EMAILVERIFICATION_EMAIL_ADDRESS = "x7FE";

// '-------------------[ phoneverification page ]-------------------'
export const PHONEVERIFICATION_PHONE_NUMBER = "x8E0";
export const PHONEVERIFICATION_IS_MOBILE = "x8E1";
export const PHONEVERIFICATION_IS_MOBILE__NO = "x8E1-0";
export const PHONEVERIFICATION_IS_MOBILE__YES = "x8E1-1";
export const PHONEVERIFICATION_RECEIVE_OFFERS = "x8E2";
export const PHONEVERIFICATION_RECEIVE_OFFERS__NO = "x8E2-0";
export const PHONEVERIFICATION_RECEIVE_OFFERS__YES = "x8E2-1";

// '--------------------[ updatepassword page ]---------------------'
export const UPDATEPASSWORD_PASSWORD = "x900";
export const UPDATEPASSWORD_PASSWORD_CONFIRM = "x901";

// '----------------------[ bankinginfo page ]----------------------'
export const BANKINGINFO_ROUTING_NUMBER = "x1000";
export const BANKINGINFO_BANK_NAME = "x1001";
export const BANKINGINFO_ACCOUNT_NUMBER = "x1002";
export const BANKINGINFO_UNMASKED_ACCOUNT_NUMBER = "x1005";
export const BANKINGINFO_ACCOUNT_TYPE = "x1003";
export const BANKINGINFO_ACCOUNT_TYPE_SAVING = "x1003-1";
export const BANKINGINFO_ACCOUNT_TYPE_CHECKING = "x1003-2";
export const BANKINGINFO_DONT_SEE_MY_BANK = "x1004";
export const BANKINGINFO_BRANCHTRANSIT_NUMBER="x1006";
export const BANKINGINFO_INSTITUTION_NUMBER="x1007";

// '--------------------[ employmentinfo page ]---------------------'
export const EMPLOYMENTINFO_EMPLOYER_NAME = "x1100";
export const EMPLOYMENT_EMPLOYER_INFO = "x1108";
export const EMPLOYMENTINFO_INCOME_TYPE = "x1102";
export const EMPLOYMENTINFO_PAYMENT_TYPE = "x1103";
export const EMPLOYMENTINFO_BENEFIT_TYPE = "x1104";
export const EMPLOYMENTINFO_PAYMENT_FREQUENCY = "x1105";
export const EMPLOYMENTINFO_LAST_PAYCHECK_DATE = "x1106";
export const EMPLOYMENTINFO_NEXT_PAYCHECK_DATE = "x1107";

// '------------------------[ optout page ]-------------------------'
export const OPTOUT_FIRST_NAME = "x1200";
export const OPTOUT_LAST_NAME = "x1201";
export const OPTOUT_LAST_FOUR_SSN = "x1202";
export const OPTOUT_DOB = "x1203";
export const OPTOUT_OPTION_AFFILIATE_BUSINESS_PURPOSE = "x1204";
export const OPTOUT_OPTION_AFFILIATE_MARKETING = "x1205";
export const OPTOUT_OPTION_NONAFFILIATE_MARKETING = "x1206";

// '--------------------[ reloan page ]---------------------'
export const RELOAN_LOAN_AMOUNT = "x1300";
export const RELOAN_ECONSENT = "x1301";
export const RELOAN_DELAWARE_CONSENT = "x1302";

// '--------------------[ accountterms page ]---------------------'
export const ACCOUNTTERMS_SSA_CONSENT = "x1403";
export const ACCOUNTTERMS_DELAWARE_CONSENT = "x1402";
export const ACCOUNTTERMS_EXISTING_FLEX_LOAN = "x1401";
export const ACCOUNTTERMS_EXISTING_FLEX_LOAN__YES = "x1401-0";
export const ACCOUNTTERMS_EXISTING_FLEX_LOAN__NO = "x1401-1";
export const ACCOUNTTERMS_ECONSENT = "x1400";
export const ACCOUNTTERMS_ECONSENT_CANADA_CREDIT_REPORT = "x1404";
export const ACCOUNTTERMS_PATHWARD_ARBITRATION_CLAUSE = "x1405";
export const ACCOUNTTERMS_PATHWARD_TERMS_OF_USE = "x1406";
export const ACCOUNTTERMS_PATHWARD_PRIVACY_POLICY = "x1407";

// '---------------------[ pre-approved page ]----------------------'
export const PREAPPROVED_LOAN_AMOUNT = "x1500";

// '---------------------[ welcome page ]----------------------'
export const WELCOME_LOAN_AMOUNT = "x1600";

// '---------------------[ ral page] --------------------------'
export const RAL_LOAN_AMOUNT = "x1700";
export const RAL_LOAN_MAX_AMOUNT = "x1701";
export const RAL_LOAN_MIN_AMOUNT = "x1702";

//----------------------[cf offer page] -----------------------'
export const OFFER_PAGE_SSN_VALID = "x1800";
export const OFFER_PAGE_EMAIL_ADDRESS_VALID = "x1801";

// ---------------------[online payments page] -----------------`

export const FULL_NAME = "x1900";
export const CARD_NUMBER = "x1901";
export const CARD_EXPIRY_DATE = "x1902";
export const CARD_CVV_NUMBER = "x1903";
export const CARD_ADDRESS_ZIP = "x1904";
export const ONLINE_PAYMENT_AMOUNT = "x1905";
export const SELECTED_PAYMENT_AMOUNT = "x1906"


// ----------------------[ Webchat Page ] ----------------------

export const WEBCHAT_FIRST_NAME = "x2000";
export const WEBCHAT_LAST_NAME = "x2001";
export const WEBCHAT_EMAIL_ADDRESS = "x2002";
export const WEBCHAT_PHONE_NUMBER = "x2003";
export const WEBCHAT_HELP_OPTIONS = "x2004";

// ---------------------[account page] -----------------`

export const MARITAL_STATUS = "x2000";
export const MARITAL_STATUS_SINGLE = "x2000-1";
export const MARITAL_STATUS_LEGALLY_SEPARATED = "x2000-2";
export const MARITAL_STATUS_LEGALLY_MARRIED= "x2000-3";
export const SPOUSE_FIRST_NAME = "x2001";
export const SPOUSE_LAST_NAME = "x2002";
export const SPOUSE_EMAIL_ADDRESS = "x2003";
export const SPOUSE_ADDRESS_LINE_1 = "x2004";
export const SPOUSE_ADDRESS_LINE_2 = "x2005";
export const SPOUSE_CITY = "x2006";
export const SPOUSE_STATE = "x2007";
export const SPOUSE_ZIP_CODE = "x2008";
export const ENTER_ALTERNATE_ADDRESS = "x2009";

// ----------------------[ Feedback Form Page ] ----------------------

export const FEEDBACK_FIRST_NAME = "x3000";
export const FEEDBACK_LAST_NAME = "x3001";
export const FEEDBACK_EMAIL_ADDRESS = "x3003";
export const FEEDBACK_PHONE_NUMBER = "x3004";
export const FEEDBACK_LOAN_NUMBER = "x3005";
export const FEEDBACK_CATEGORY = "x3006";
export const FEEDBACK_SUBJECT = "x3007";
export const FEEDBACK_CONTENT = "x3008";

// ----------------------[ Statement Filter Page ] ----------------------
export const STATEMENT_FILTERS_OPTIONS = "X3100"
export const STATEMENT_FILTERS_LAST_90_DAYS = "X3100-1";
export const STATEMENT_FILTERS_YEAR = "X3100-";
export const STATEMENT_FILTERS_SELECT_DATES = "X3100-2";
export const STATEMENT_FILTERS_FROM_DATE = "X3101";
export const STATEMENT_FILTERS_TO_DATE = "X3102";

// ---------------------[OTP Form] -----------------
export const OTP_VERIFICATION_CODE = "x4000";
export const OTP_LOGIN_VERIFICATION_CODE = "x4002";
// ---------------------[Verify Phone Form] -----------------
export const VERIFY_PHONE_CODE = "x4001";

// '--------------------[ Document Upload Page ]--------------------'
export const DOCUMENT_TYPE = "x5000";
export const PROOF_OF_BANK_ACCOUNT = "x5000-1";
export const PROOF_OF_INCOME = "x5000-2";
export const PROOF_OF_IDENTIFICATION = "x5000-3";
export const PROOF_OF_RESIDENCE = "x5000-4";
export const OTHER_DOCUMENT = "x5000-5";

export const DOCUMENT_DESCRIPTION = "x6000";

export const PROOF_OF_BANK_ACCOUNT_VOID_CHECK = "x6001-1";
export const PROOF_OF_BANK_ACCOUNT_BANK_STATEMENT = "x6001-2";

export const PROOF_OF_INCOME_BANK_STATEMENT = "x6002-1";
export const PROOF_OF_INCOME_PAY_STUB = "x6002-2";
export const PROOF_OF_INCOME_EMPLOYMENT_LETTER = "x6002-3";

export const PROOF_OF_IDENTIFICATION_SSN = "x6003-1";
export const PROOF_OF_IDENTIFICATION_DRIVERS_LICENSE = "x6003-2";
export const PROOF_OF_IDENTIFICATION_PROVINCIAL_ID = "x6003-5";
export const PROOF_OF_IDENTIFICATION_PASSPORT = "x6003-3";
export const PROOF_OF_IDENTIFICATION_STATE_ID = "x6003-4";
export const PROOF_OF_IDENTIFICATION_PERMANENT_RESIDENT_CARD = "x6003-6";

export const PROOF_OF_RESIDENCE_UTILITY_BILL = "x6004-1";
export const PROOF_OF_RESIDENCE_LEASE_TENANT_AGREEMENT = "x6004-2";
export const PROOF_OF_RESIDENCE_DRIVERS_LICENSE = "x6004-3";

export const OTHER_DOCUMENT_POWER_OF_ATTORNEY = "x6005-1";
export const OTHER_DOCUMENT_POLICE_REPORT = "x6005-2";
export const OTHER_DOCUMENT_OTHER = "x6005-3";
export const OTHER_DOCUMENT_INSURANCE_CLAIM = "x6005-4";

export const UPLOAD_DOCUMENT_INPUT = "x7000";
export const OTHER_FIELD = "x8100";
// ---------------------[Verify Authentication Form] -----------------
export const VERIFY_MFA_CODE = "x8200";
// Payment Protection Plan
export const PAYMENT_PROTECTION_PLAN_ACKNOWLEDGEMENT = "x8300-1";
export const PAYMENT_PROTECTION_PLAN_DISCLOSURE_ACKNOWLEDGEMENT = "x8300-2";
export const INSURANCE_FIRST_NAME = "x8300-3";
export const INSURANCE_LAST_NAME = "x8300-4";
export const INSURANCE_EMAIL = "x8300-5";
