
import {Loan, LoanType} from "../constant/loan";
import * as constant from "../constant/constant";
import _ from 'lodash';
import moment from 'moment';

const reloanAllowedStatuses = [Loan.DEFAULT_SIF, Loan.DEFAULT_PIF, Loan.PAID_OFF, Loan.PENDING_PAID_OFF];
export const canReloan = (status, loanType) => reloanAllowedStatuses.includes(status) && loanType === LoanType.INSTALLMENT_LOAN;

const RcBlockedStates = [constant.STATE_ILLINOIS, constant.STATE_ALABAMA, constant.STATE_OHIO, constant.STATE_CALIFORNIA];
export const isBlockedRc = (orgId, loanType, state, entityId) => (RcBlockedStates.includes(state) && loanType === LoanType.INSTALLMENT_LOAN && orgId === constant.MONEYKEY_ORGANIZATION_ID);

const RalBlockedStates = [constant.STATE_ILLINOIS, constant.STATE_ALABAMA, constant.STATE_OHIO, constant.STATE_CALIFORNIA];
export const isBlockedRal = (orgId, loanType, state) => (RalBlockedStates.includes(state) && loanType === LoanType.INSTALLMENT_LOAN && orgId === constant.MONEYKEY_ORGANIZATION_ID);

const sepStatesWithFees = [constant.STATE_TEXAS];
export const isMkSepStateWithFees = (state, orgId) => (sepStatesWithFees.includes(state) && orgId === constant.MONEYKEY_ORGANIZATION_ID);

const creditFreshStatesWithDashboardRedirect = [constant.STATE_ILLINOIS, constant.STATE_WASHINGTON];
const creditFreshPlusStatesWithDashboardRedirect = [constant.STATE_WASHINGTON];
const ccFlowStatesWithDashboardRedirect = [constant.STATE_MN];
export const isOrgStateWithDashboardRedirect = (state, orgId) =>
    (creditFreshStatesWithDashboardRedirect.includes(state) && orgId === constant.CREDITFRESH_ORGANIZATION_ID) ||
    (creditFreshPlusStatesWithDashboardRedirect.includes(state) && orgId === constant.CREDITFRESH_PLUS_ORGANIZATION_ID) ||
    (ccFlowStatesWithDashboardRedirect.includes(state) && orgId === constant.MONEYKEY_PLUS_ORGANIZATION_ID)
;

export const isCAPayday = (state, entityId) => (state === constant.STATE_CALIFORNIA && parseInt(entityId) === constant.ENTITY_CA_PAYDAY);

export const getNextPaymentDisplay = (paymentScheduleItems) => {
    let nextScheduledPayment = _.find(paymentScheduleItems, function(paymentScheduleItem) {
        if (paymentScheduleItem.paymentStatus === "scheduled" || paymentScheduleItem.paymentStatus === "bypass") {
            return true;
        }
    });
    if (!nextScheduledPayment) {
        let sentPayments = _.filter(paymentScheduleItems, { paymentStatus: "SENT" });
        if (_.isEmpty(sentPayments)) {
            nextScheduledPayment = { paymentDate: "-", amount: 0 };
        }
        else {
            nextScheduledPayment = {
                paymentDate: (_.orderBy(sentPayments, sentPayment => moment(sentPayment.paymentDate), ["desc"])[0]).paymentDate,
                amount: 0
            };
        }
    }
    return nextScheduledPayment;
}
