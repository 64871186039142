const ERROR_500 = 500;
const ERROR_417 = 417;
const ERROR_404 = 404;
const ERROR_403 = 403;
const ERROR_401 = 401;
const ERROR_400 = 400;
const ERROR_406 = 406;
const ERROR_502 = 502;

export const API_ERROR_CODE = {
    ERROR_400,
    ERROR_401,
    ERROR_403,
    ERROR_404,
    ERROR_417,
    ERROR_500,
    ERROR_406,
    ERROR_502
};
