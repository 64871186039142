import React from "react";
import {connect} from "react-redux";
import {Fields, reduxForm} from "redux-form";
import * as fieldtags from "../../../shared/constant/fieldtags";
import * as form from "../../../shared/constant/form";
import * as actions from "../../../store/action/OrganizationActions";
import FeedbackFields from "./FeedbackFields";
import * as _helpers from "../../../shared/helpers";
import {validate} from "./_validate";
import * as utility from "../../../shared/utility/utility";

const formFields = [
    fieldtags.FEEDBACK_FIRST_NAME,
    fieldtags.FEEDBACK_LAST_NAME,
    fieldtags.FEEDBACK_EMAIL_ADDRESS,
    fieldtags.FEEDBACK_PHONE_NUMBER,
    fieldtags.FEEDBACK_LOAN_NUMBER,
    fieldtags.FEEDBACK_CATEGORY,
    fieldtags.FEEDBACK_SUBJECT,
    fieldtags.FEEDBACK_CONTENT
];

class FeedbackForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            submitted: false
        };

        this.submitFeedbackForm = this.submitFeedbackForm.bind(this);
    }

    submitFeedbackForm(values) {
        this.setState({
            submitting: true
        });

        let data = {};
        data["feedbackFirstName"] = values[fieldtags.FEEDBACK_FIRST_NAME];
        data["feedbackLastName"] = values[fieldtags.FEEDBACK_LAST_NAME];
        data["feedbackEmail"] = values[fieldtags.FEEDBACK_EMAIL_ADDRESS];
        data["feedbackPhoneNumber"] = values[fieldtags.FEEDBACK_PHONE_NUMBER];
        data["feedbackLoanNumber"] = values[fieldtags.FEEDBACK_LOAN_NUMBER];
        data["feedbackCategory"] = values[fieldtags.FEEDBACK_CATEGORY];
        data["feedbackSubject"] = values[fieldtags.FEEDBACK_SUBJECT];
        data["feedbackContent"] = values[fieldtags.FEEDBACK_CONTENT];
        data["organizationId"] = utility.getOrganizationId();
        
        this.props.submitFeedbackForm(data);
        this.setState({
            submitted: true
        });
    }

    render() {
        return (
            <div>
                {this.props.submitted ? (
                    ""
                ) : (
                    <form id="feedback-form" onSubmit={this.props.handleSubmit(this.submitFeedbackForm)}>
                        <Fields names={formFields} component={FeedbackFields} />

                        <div className="text-center">
                            <button
                                id="submit-feedbackform"
                                type="submit"
                                tabIndex={10}
                                className={_helpers.getButtonClassName(this.props.valid)}
                                disabled={this.state.submitting || this.props.submitting}
                            >
                                {this.props.submitting && this.state.submitting ? "Processing..." : "Submit"}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        submitFeedbackForm: data => dispatch(actions.submitFeedbackForm(data))
    };
};

FeedbackForm = reduxForm({
    form: form.FEEDBACK_FORM,
    validate,
    touchOnBlur: true,
    touchOnChange: true
})(FeedbackForm);

FeedbackForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedbackForm);

export default FeedbackForm;
