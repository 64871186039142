import React from "react";
import * as P from "prop-types";
import {fragmentizeString} from "../shared/utility/contentutility";

export default class ErrorPage extends React.Component {
    render() {
        const {Layout, errorContent, headingFrag, explanationFrag, optionalTextFrag} = this.props;

        const heading = headingFrag ? fragmentizeString(errorContent.heading, headingFrag) : errorContent.heading;
        const explanation = explanationFrag
            ? fragmentizeString(errorContent.explanation, explanationFrag)
            : errorContent.explanation;
        const optionalText =
            errorContent.optionalText && optionalTextFrag
                ? fragmentizeString(errorContent.optionalText, optionalTextFrag)
                : errorContent.optionalText;

        return (
            <Layout>
                <h1>{heading}</h1>
                <p>{explanation}</p>
                {optionalText && <p>{optionalText}</p>}
            </Layout>
        );
    }
}

ErrorPage.propTypes = {
    Layout: P.any.isRequired,
    errorContent: P.shape({
        heading: P.string.isRequired,
        explanation: P.string.isRequired,
        optionalText: P.string
    }).isRequired,
    headingFrag: P.object,
    explanationFrag: P.object,
    optionalTextFrag: P.object
};
