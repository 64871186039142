// [ store/saga ]

// '----------------------[ ApplicationSaga ]-----------------------'
export const APPLICATIONSAGA_EMAIL_ADDRESS__IS_NOT_UNIQUE = "APPLICATIONSAGA_EMAIL_ADDRESS_IS_NOT_UNIQUE";
export const APPLICATIONSAGA_EMAIL_ADDRESS__INVALID = "APPLICATIONSAGA_EMAIL_ADDRESS_INVALID";
export const APPLICATIONSAGA_NETWORK_ERROR = "APPLICATIONSAGA_NETWORK_ERROR";

export const EMAIL_ADDRESS__EMPTY = "EMAIL_ADDRESS__EMPTY";
export const EMAIL_ADDRESS__INVALID = "EMAIL_ADDRESS__INVALID";

export const STATE__EMPTY = "STATE__EMPTY";
export const STATE__INVALID = "STATE__INVALID";

export const RESIDENCY__EMPTY = "RESIDENCY__EMPTY";

export const FIRST_NAME__EMPTY = "FIRST_NAME__EMPTY";
export const FIRST_NAME__LESS_THAN_2_CHARS = "FIRST_NAME__LESS_THAN_2_CHARS";

export const LAST_NAME__EMPTY = "LAST_NAME__EMPTY";
export const LAST_NAME__LESS_THAN_2_CHARS = "LAST_NAME__LESS_THAN_2_CHARS";

export const BIRTHDATE__EMPTY = "BIRTHDATE__EMPTY";
export const BIRTHDATE__INVALID = "BIRTHDATE__INVALID";
export const BIRTHDATE__MONTH_INVALID = "BIRTHDATE__MONTH_INVALID";
export const BIRTHDATE__DAY_INVALID = "BIRTHDATE__DAY_INVALID";
export const BIRTHDATE__YEAR_INVALID = "BIRTHDATE__YEAR_INVALID";
export const BIRTHDATE__IS_IN_THE_FUTURE = "BIRTHDATE__IS_IN_THE_FUTURE";
export const BIRTHDATE__LESS_THAN_18_YEARS = "BIRTHDATE__LESS_THAN_18_YEARS";
export const BIRTHDATE__LESS_THAN_19_YEARS = "BIRTHDATE__LESS_THAN_19_YEARS";
export const BIRTHDATE__MORE_THAN_120_YEARS = "BIRTHDATE__MORE_THAN_120_YEARS";


export const SSN__EMPTY = "SSN__EMPTY";
export const SSN__INVALID = "SSN__INVALID";
export const SSN__IS_NOT_UNIQUE = "SSN__IS_NOT_UNIQUE";

export const SSN_FOUR_LAST_DIGITS__EMPTY = "SSN_FOUR_LAST_DIGITS__EMPTY";
export const SSN_FOUR_LAST_DIGITS__LESS_THAN_4_CHARS = "SSN_FOUR_LAST_DIGITS__LESS_THAN_4_CHARS";
export const SSN_FOUR_LAST_DIGITS__NO_MATCH = "SSN_FOUR_LAST_DIGITS__NO_MATCH";
export const LOAN_DOC_INITIALS__EMPTY = "LOAN_DOC_INITIALS__EMPTY";
export const LOAN_DOC_INITIALS__NO_MATCH = "LOAN_DOC_INITIALS__NO_MATCH";

export const PHONE_NUMBER__EMPTY = "PHONE_NUMBER__EMPTY";
export const PHONE_NUMBER__INVALID = "PHONE_NUMBER__INVALID";

export const APPCODE__EMPTY = "APPCODE__EMPTY";
export const APPCODE__INVALID = "APPCODE__INVALID";

export const ROUTING_NUMBER__EMPTY = "ROUTING_NUMBER__EMPTY";
export const ROUTING_NUMBER__INVALID = "ROUTING_NUMBER__INVALID";
export const ROUTING_NUMBER__ALLZEROS = "ROUTING_NUMBER__ALLZEROS";
export const ROUTING_NUMBER__INVALID_CHECKSUM = "ROUTING_NUMBER__INVALID_CHECKSUM";
export const ACCOUNT_NUMBER__EMPTY = "ACCOUNT_NUMBER__EMPTY";
export const ACCOUNT_NUMBER__INVALID = "ACCOUNT_NUMBER__INVALID";
export const ACCOUNT_NUMBER_FORA__INVALID = "ACCOUNT_NUMBER_FORA__INVALID";
export const ACCOUNT_NUMBER__ALLZEROS = "ACCOUNT_NUMBER__ALLZEROS";
export const BANK_NAME__EMPTY = "BANK_NAME__EMPTY";
export const BANK_NAME__INVALID_SHORT = "BANK_NAME__INVALID_SHORT";
export const BANK_NAME__INVALID_LONG = "BANK_NAME__INVALID_LONG";
export const BANK_NAME__INVALID_NO_CHAR = "BANK_NAME__INVALID_NO_CHAR";
export const RAL_LOAN_AMOUNT__INVALID_NO_NUMBER = "RAL_LOAN_AMOUNT__EMPTY";
export const RAL_LOAN_AMOUNT_INVALID_ONLY_NUMBERS = "RAL_LOAN_AMOUNT_ONLY_NUMBERS";
export const RAL_LOAN_AMOUNT_INVALID_GREATER = "RAL_LOAN_AMOUNT_GREATER";
export const RAL_LOAN_AMOUNT_INVALID_LESSER = "RAL_LOAN_AMOUNT_LESSER";
export const INSTITUTION_NUMBER__EMPTY="INSTITUTION_NUMBER__EMPTY";
export const INSTITUTION_NUMBER__INVALID="INSTITUTION_NUMBER__INVALID";
export const INSTITUTION_NUMBER__ALLZEROS="INSTITUTION_NUMBER__ALLZEROS";
export const BRANCHTRANSIT_NUMBER__EMPTY="BRANCHTRANSIT_NUMBER__EMPTY";
export const BRANCHTRANSIT_NUMBER__INVALID="BRANCHTRANSIT_NUMBER__INVALID";
export const BRANCHTRANSIT_NUMBER__ALLZEROS="BRANCHTRANSIT_NUMBER__ALLZEROS";


export const SPOUSE_FIRST_NAME__EMPTY = "SPOUSE_FIRST_NAME__EMPTY";
export const SPOUSE_FIRST_NAME__LESS_THAN_2_CHARS = "SPOUSE_FIRST_NAME__LESS_THAN_2_CHARS";

export const SPOUSE_LAST_NAME__EMPTY = "SPOUSE_LAST_NAME__EMPTY";
export const SPOUSE_LAST_NAME__LESS_THAN_2_CHARS = "SPOUSE_LAST_NAME__LESS_THAN_2_CHARS";

export const SPOUSE_EMAIL_ADDRESS__EMPTY = "SPOUSE_EMAIL_ADDRESS__EMPTY";
export const SPOUSE_EMAIL_ADDRESS__INVALID = "SPOUSE_EMAIL_ADDRESS__INVALID";

export const SPOUCE_CITY__EMPTY = "SPOUSE_EMAIL_ADDRESS__INVALID";
export const SPOUCE_CITY__HAS_NUMBER = "SPOUCE_CITY__HAS_NUMBER";
export const SPOUCE_CITY__HAS_CHARACTERS_ONLY = "SPOUCE_CITY__HAS_CHARACTERS_ONLY";

export const MARITAL_STATUS_NOT_SELECTED = "MARITAL_STATUS_NOT_SELECTED";

export const SPOUSE_STATE__EMPTY = "SPOUSE_STATE__EMPTY";
export const SPOUSE_STATE__INVALID = "SPOUSE_STATE__INVALID";

export const DOCUMENT_UPLOAD__INVALID_FILE = "DOCUMENT_UPLOAD__INVALID_FILE";
export const DOCUMENT_UPLOAD__INVALID_FILE_NAME = "DOCUMENT_UPLOAD__INVALID_FILE_NAME";
export const DOCUMENT_UPLOAD__INVALID_FILE_NAME_SIZE_MIN = "DOCUMENT_UPLOAD__INVALID_FILE_NAME_SIZE_MIN";
export const DOCUMENT_UPLOAD__INVALID_FILE_NAME_SIZE_MAX = "DOCUMENT_UPLOAD__INVALID_FILE_NAME_SIZE_MAX";
export const DOCUMENT_UPLOAD__INVALID_FILE_SIZE = "DOCUMENT_UPLOAD__INVALID_FILE_SIZE";
export const DOCUMENT_UPLOAD__INVALID_FILE_FORMAT = "DOCUMENT_UPLOAD__INVALID_FILE_FORMAT";
