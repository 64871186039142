import {MC_IDENTITY} from "../../shared/constant/field";
import * as actionTypes from "../action/actionTypes";
import _ from "lodash";
import * as cookieUtility from "../../shared/utility/cookieutility";
import * as constant from "../../shared/constant/constant";

export const mcIdentityLoadingState = {
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    NOT_LOADED: 'NOT_LOADED',
}

const initialState = {
    [MC_IDENTITY.MC_IDENTITY_SESSION_ID]: null,
    [MC_IDENTITY.IS_MC_IDENTITY_PACKAGE_LOADING]: mcIdentityLoadingState.NOT_LOADED,
    [MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_ENABLED]: "",
    [MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_ENABLED]: "",
    [MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_CLIENT_ID]: false,
    [MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_CLIENT_ID]: false,
    [MC_IDENTITY.MC_IDENTITY_TRACKING_PAGE]: null,
    [MC_IDENTITY.LOGIN_PAGE]: {
        [MC_IDENTITY.DETECTION_STARTED]: false,
        [MC_IDENTITY.DATA_SUBMITTED]: false
    },
    [MC_IDENTITY.BOT_DETECTION]: {
        [MC_IDENTITY.DETECTION_STARTED]: false,
        [MC_IDENTITY.DATA_SUBMITTED]: false
    }
};

const _update = (state, data) => {
    return {
        ...state,
        ...data
    };
};

export const loadMcIdentityDataFromCookie = () => {
    console.log("Loading from cookie...")
    let mcIdentityState = initialState;
    let mkWebMcIdentityData = cookieUtility.getCookie(constant.COOKIE_MK_WEB_MC_IDENTITY_DATA);

    if (!mkWebMcIdentityData) {
        return mcIdentityState;
    } else {
        mcIdentityState = {
            ...mcIdentityState,
            [MC_IDENTITY.MC_IDENTITY_SESSION_ID]: mkWebMcIdentityData[MC_IDENTITY.MC_IDENTITY_SESSION_ID],
            [MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_ENABLED]: mkWebMcIdentityData[MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_ENABLED],
            [MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_ENABLED]: mkWebMcIdentityData[MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_ENABLED],
            [MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_CLIENT_ID]: mkWebMcIdentityData[MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_CLIENT_ID],
            [MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_CLIENT_ID]: mkWebMcIdentityData[MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_CLIENT_ID]
        };
        return mcIdentityState;
    }
};

const _setMcIdentityReducerCookie = state => {
    let cookieMkWebMcIdentityData = _.cloneDeep(state);
    cookieUtility.setCookie(constant.COOKIE_MK_WEB_MC_IDENTITY_DATA, JSON.stringify(cookieMkWebMcIdentityData));
};

const resetMcIdentityReducer = () => {
    _setMcIdentityReducerCookie(_.cloneDeep(initialState));
    return _.cloneDeep(initialState);
};


export default function mcIdentityReducer(state = {...initialState}, action) {
    let updatedState = {};

    switch (action.type) {
        case actionTypes.ADD_MC_IDENTITY_SESSION_ID:
            updatedState = {
                [MC_IDENTITY.MC_IDENTITY_SESSION_ID]: action.payload.sessionId,
                [MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_CLIENT_ID]: action.payload.botDetectionClientId,
                [MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_CLIENT_ID]: action.payload.loginBehaviourClientId,
                [MC_IDENTITY.MC_IDENTITY_LOGIN_BEHAVIOUR_ENABLED]: action.payload.loginBehaviourEnabled,
                [MC_IDENTITY.MC_IDENTITY_BOT_DETECTION_ENABLED]: action.payload.botDetectionEnabled
            };
            _setMcIdentityReducerCookie(_update(state, updatedState));
            return _update(state, updatedState);

        case actionTypes.UPDATE_IS_MC_IDENTITY_PACKAGE_LOADED:
            updatedState[MC_IDENTITY.IS_MC_IDENTITY_PACKAGE_LOADING] = action.payload;
            _setMcIdentityReducerCookie(_update(state, updatedState));
            return _update(state, updatedState);

        case actionTypes.UPDATE_LOGIN_PAGE_DETECTION_STARTED:
            updatedState = {
                [MC_IDENTITY.LOGIN_PAGE]: {
                    ...state[MC_IDENTITY.LOGIN_PAGE],
                    [MC_IDENTITY.DETECTION_STARTED]: action.payload
                }
            };
            _setMcIdentityReducerCookie(_update(state, updatedState));
            return _update(state, updatedState);

        case actionTypes.UPDATE_LOGIN_PAGE_DATA_SUBMITTED:
            updatedState = {
                [MC_IDENTITY.LOGIN_PAGE]: {
                    ...state[MC_IDENTITY.LOGIN_PAGE],
                    [MC_IDENTITY.DATA_SUBMITTED]: action.payload
                }
            };
            _setMcIdentityReducerCookie(_update(state, updatedState));
            return _update(state, updatedState);

        case actionTypes.UPDATE_BOT_DETECTION_STARTED:
            updatedState = {
                [MC_IDENTITY.BOT_DETECTION]: {
                    ...state[MC_IDENTITY.BOT_DETECTION],
                    [MC_IDENTITY.DETECTION_STARTED]: action.payload
                }
            };
            _setMcIdentityReducerCookie(_update(state, updatedState));
            return _update(state, updatedState);

        case actionTypes.UPDATE_BOT_DETECTION_DATA_SUBMITTED:
            updatedState = {
                [MC_IDENTITY.BOT_DETECTION]: {
                    ...state[MC_IDENTITY.BOT_DETECTION],
                    [MC_IDENTITY.DATA_SUBMITTED]: action.payload
                }
            };
            _setMcIdentityReducerCookie(_update(state, updatedState));
            return _update(state, updatedState);

        case actionTypes.UPDATE_MC_IDENTITY_TRACKING_DATA:
            updatedState[MC_IDENTITY.MC_IDENTITY_TRACKING_PAGE] = action.payload;
            _setMcIdentityReducerCookie(_update(state, updatedState));
            return _update(state, updatedState);

        case actionTypes.RESET_MC_IDENTITY_STATE:
            return resetMcIdentityReducer();

        case actionTypes.FETCHING_MC_IDENTITY_SESSION_ID:
            updatedState[MC_IDENTITY.MC_IDENTITY_SESSION_ID] = "Fetching";
            _setMcIdentityReducerCookie(_update(state, updatedState));
            return _update(state, updatedState);

        default:
            return _update(state, updatedState);
    }
}
