/**
 * This Action file is responsible for insurance actions
 */
import * as actionTypes from './actionTypes'


export const resetInsuranceReducer = () => {
    return {
        type: actionTypes.RESET_INSURANCE_DATA,
        payload: {}
    };
};

export const submitInsuranceEnrollment = (enrollment, baseLoanId, stateCode) => {
    return {
        type: actionTypes.SUBMIT_INSURANCE_ENROLLMENT,
        payload: {
            baseLoanId,
            enrollment,
            stateCode
        }
    };
}
export const submitInsuranceEnrollmentSuccess = enrollment => {
    return {
        type: actionTypes.SUBMIT_INSURANCE_ENROLLMENT_SUCCESS,
        payload: {
            enrollment
        }
    };
}

export const updatePaymentProtectionPlanDetails = paymentProtectionPlanDetails => {
    return {
        type: actionTypes.UPDATE_PAYMENT_PROTECTION_PLAN_DETAILS,
        payload: {
            data:paymentProtectionPlanDetails
        }
    }
}

export const submitPaymentProtectionPlanDetails = paymentProtectionPlanDetails => {
    return {
        type: actionTypes.SUBMIT_PAYMENT_PROTECTION_PLAN_DETAILS,
        payload: {
            data:paymentProtectionPlanDetails
        }
    }
}

export const getInsuranceStatus = (stateCode, baseLoanId) =>{
    return {
        type: actionTypes.GET_INSURANCE_STATUS,
        payload: {
            stateCode,
            baseLoanId
        }
    }
}

export const getInsuranceStatusSuccess = (response) =>{
    return {
        type: actionTypes.GET_INSURANCE_STATUS_SUCCESS,
        payload: {
            response
        }
    }
}

export const getPaymentProtectionPlanDetails = (baseLoanId) =>{
    return {
        type: actionTypes.GET_PAYMENT_PROTECTION_PLAN_DETAILS,
        payload: {
            baseLoanId
        }
    }
}

export const getPaymentProtectionPlanDetailsSuccess = (response) =>{
    return {
        type: actionTypes.GET_PAYMENT_PROTECTION_PLAN_DETAILS_SUCCESS,
        payload: {
            response
        }
    }
}
export const updateActionItemCompleted = () =>{
    return {
        type: actionTypes.GET_ACTION_ITEM_COMPLETED_SUCCESS,
        payload: {}
    }
}


export const showInsurancePopup = (userId) => {
    return {
        type: actionTypes.SHOW_INSURANCE_POPUP,
        payload: {
            userId: userId
        }
    };
};

export const showInsurancePopupSuccess = (response) => {
    return {
        type: actionTypes.SHOW_INSURANCE_POPUP_SUCCESS,
        payload: {
            response: response
        }
    };
};

export const logInsurancePopupTime = (userId) => {
    return {
        type: actionTypes.LOG_INSURANCE_POPUP_TIME,
        payload: {
            userId: userId
        }
    };
};

export const resetInsurancePopup = () => {
    return {
        type: actionTypes.RESET_INSURANCE_POPUP,
        payload: {
        }
    };
};
