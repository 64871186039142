import * as actionTypes from "./actionTypes";

export const executeMiscAuthActions = () => {
    return {
        type: actionTypes.EXECUTE_MISC_AUTH_ACTIONS
    };
};

export const executeMiscDashboardActions = previousLocation => {
    return {
        type: actionTypes.EXECUTE_MISC_DASHBOARD_ACTIONS,
        payload: {previousLocation}
    };
};

export const executeMiscApplicationActions = (previousLocation) => {
    return {
        type: actionTypes.EXECUTE_MISC_APPLICATION_ACTIONS,
        payload: {previousLocation}
    };
};
