// Currently unused(?)
// const RECOMMENDED_FOR_APPROVAL = "recommended for approval";
// const VOID = "canceled";

const PENDING = "pending";
const APPROVE = "approve";
const PENDING_BANK_APPROVAL = "pending bank approval";
const QA_ERROR = "qa error";
const BANK_QA_ERROR = "bank qa error";
const ORIGINATED = "originated";
const DELINQUENT = "delinquent";
const DEFAULT = "default";
const VOID = "void"; //Need the reason (Rescind, Cancel Agreement, Agent Error, Systems Error)
const DEFAULT_SLD = "default-sld";
const DEFAULT_PIF = "default-pif";
const DEFAULT_SIF = "default-sif";
const WITHDRAW = "withdraw";
const CLOSED = "closed";
const PAID_OFF = "paid off";
const PENDING_PAID_OFF = "pending paid off";
const FAST_TRACKED = "fast tracked";
const DENIED = "denied";
const EXPIRED = "expired";
const PENDING_VOID = "pending void";
const CREDIT_RETURN = "credit return";
const APPROVED_AWAITING_ESIG = "approved awaiting e-sig";
const DEFAULT_BKC = "default-bkc";
const DEFAULT_FRD = "default-frd";
const DEFAULT_BKC_SLD = "default-bkc-sld";
const DECEASED = "deceased";


export const Loan = {
    PENDING,
    APPROVE,
    PENDING_BANK_APPROVAL,
    QA_ERROR,
    BANK_QA_ERROR,
    ORIGINATED,
    DELINQUENT,
    DEFAULT,
    VOID,
    DEFAULT_SLD,
    DEFAULT_PIF,
    DEFAULT_SIF,
    WITHDRAW,
    CLOSED,
    EXPIRED,
    FAST_TRACKED,
    PAID_OFF,
    PENDING_PAID_OFF,
    DENIED,
    PENDING_VOID,
    CREDIT_RETURN,
    APPROVED_AWAITING_ESIG,
    DEFAULT_BKC,
    DEFAULT_FRD,
    DEFAULT_BKC_SLD,
    DECEASED
};

export const STATUS_HEADER_FILTER = {
    [PENDING]: {historySEP: false, visibleForRC: true, historyNotClickable: false, historyLOC: false, statements: false, agreement: false},
    [APPROVE]: {historySEP: false, visibleForRC: true, historyNotClickable: false, historyLOC: false, statements: false, agreement: false},
    [PENDING_BANK_APPROVAL]: {
        historySEP: true,
        visibleForRC: true,
        historyNotClickable: true,
        historyLOC: false,
        statements: false,
        agreement: false
    },
    [QA_ERROR]: {historySEP: true, visibleForRC: true, historyNotClickable: true, historyLOC: false, statements: false, agreement: false},
    [BANK_QA_ERROR]: {
        historySEP: true,
        visibleForRC: true,
        historyNotClickable: true,
        historyLOC: false,
        statements: false,
        agreement: false
    },
    [ORIGINATED]: {historySEP: true, visibleForRC: true, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [DELINQUENT]: {historySEP: true, visibleForRC: true, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [DEFAULT]: {historySEP: true, visibleForRC: true, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [VOID]: {historySEP: true, visibleForRC: true, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [DEFAULT_SLD]: {historySEP: true, visibleForRC: true, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [DEFAULT_PIF]: {historySEP: true, visibleForRC: true, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [DEFAULT_SIF]: {historySEP: true, visibleForRC: true, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [WITHDRAW]: {historySEP: false, visibleForRC: true, historyNotClickable: true, historyLOC: false, statements: false, agreement: false},
    [CLOSED]: {historySEP: false, visibleForRC: false, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [DEFAULT_BKC]: {historySEP: true, visibleForRC: false, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [DEFAULT_BKC_SLD]: {historySEP: true, visibleForRC: false, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [DEFAULT_FRD]: {historySEP: false, visibleForRC: false, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [PAID_OFF]: {historySEP: true, visibleForRC: true, historyNotClickable: false, historyLOC: false, statements: false, agreement: false},
    [PENDING_PAID_OFF]: {
        historySEP: true,
        visibleForRC: true,
        historyNotClickable: false,
        historyLOC: false,
        statements: false,
        agreement: false
    },
    [PENDING_VOID]: {
        historySEP: true,
        visibleForRC: true,
        historyNotClickable: false,
        historyLOC: false,
        statements: false,
        agreement: false
    },
    [CREDIT_RETURN]: {historySEP: true, visibleForRC: true, historyNotClickable: false, historyLOC: true, statements: true, agreement: true},
    [APPROVED_AWAITING_ESIG]: {
        historySEP: false,
        visibleForRC: true,
        historyNotClickable: false,
        historyLOC: false,
        statements: false,
        agreement: false
    }
};

const LINE_OF_CREDIT = "loc";
const INSTALLMENT_LOAN = "install";
const PAYDAY = "payday";
export const CONTENT_LOAN_TYPE_LOC = "LOC";
export const CONTENT_LOAN_TYPE_INSTALLMENT = "SEP";
export const CONTENT_LOAN_TYPE_PAYDAY = "PAYDAY";

export const LoanType = {
    LINE_OF_CREDIT,
    INSTALLMENT_LOAN,
    PAYDAY
};

const DENY_BUSINESS = "deny (business)";
const WITHDRAWN_CUSTOMER = "withdraw (customer";

export const WithdrawnReason = {
    DENY_BUSINESS,
    WITHDRAWN_CUSTOMER
};

export const LoanStatusNotForPaymentInfo = {
    PENDING,
    APPROVE,
    PENDING_BANK_APPROVAL,
    QA_ERROR,
    BANK_QA_ERROR,
    VOID,
    WITHDRAW,
    CLOSED,
    DENIED,
    EXPIRED,
    CREDIT_RETURN,
    APPROVED_AWAITING_ESIG
}
