import * as actionTypes from "./actionTypes";

export const populateOrganizationInitialConfig = () => {
    return {
        type: actionTypes.POPULATE_ORGANIZATION_INITIAL_CONFIG,
        payload: {}
    };
};

export const setOrganizationContent = ({state = undefined, product = undefined} = {}) => {
    return {
        type: actionTypes.SET_ORGANIZATION_CONTENT,
        payload: {
            state,
            product
        }
    };
};

export const resetOrganizationContent = () => {
    return {
        type: actionTypes.RESET_ORGANIZATION_CONTENT
    };
};

export const getOrganizationConfig = organizationId => {
    return {
        type: actionTypes.GET_ORGANIZATION_CONFIG,
        payload: {organizationId}
    };
};

export const getOrganizationConfigSuccess = response => {
    return {
        type: actionTypes.GET_ORGANIZATION_CONFIG_SUCCESS,
        payload: {
            response
        }
    };
};

export const isChatActive = () => {
    return {
        type: actionTypes.IS_CHAT_ACTIVE,
        payload: {}
    };
};

export const getChatHours = () => {
    return {
        type: actionTypes.GET_CHAT_HOURS,
        payload: {}
    };
};

export const isChatActiveSuccess = value => {
    return {
        type: actionTypes.IS_CHAT_ACTIVE_SUCCESS,
        payload: {
            isChatActiveSuccess: value
        }
    };
};

export const getChatHoursSuccess = response => {
    return {
        type: actionTypes.GET_CHAT_HOURS_SUCCESS,
        payload: {
            response
        }
    };
};

export const submitFeedbackForm = data => {
    return {
        type: actionTypes.SUBMIT_FEEDBACK_FORM,
        payload: {
            data
        }
    };
};

export const submitFeedbackFormSuccess = response => {
    return {
        type: actionTypes.SUBMIT_FEEDBACK_FORM_SUCCESS,
        payload: {
            response
        }
    };
};

export const submitDebtValidation = data => {
    return {
        type: actionTypes.SUBMIT_DEBT_VALIDATION,
        payload: {
            data
        }
    };
}

export const getStateOrganizationConfig = (organizationId, stateCode) => {
    return {
        type: actionTypes.GET_STATE_ORGANIZATION_CONFIG,
        payload: {
            organizationId,
            stateCode
        }
    };
};

export const getStateOrganizationConfigSuccess = response => {
    return {
        type: actionTypes.GET_STATE_ORGANIZATION_CONFIG_SUCCESS,
        payload: {
            response
        }
    };
};

export const getServerTime = () => {
    return {
        type: actionTypes.GET_SERVER_TIME,
        payload: {
        }
    };
};

export const getServerTimeSuccess = response => {
    return {
        type: actionTypes.GET_SERVER_TIME_SUCCESS,
        payload: {
            response
        }
    };
};

export const emailOptOut = (customerId, loanId) => {
    return {
        type: actionTypes.EMAIL_OPT_OUT,
        payload: {
            customerId,
            loanId
        }
    }
};
