import React from "react";
import MaskedInput from "react-text-mask";

export default class CustomMaskedInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
    }

    handleChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    handleKeyPress(e) {
        if (this.props.onKeyPress) {
            this.props.onKeyPress(e);
        }
    }

    handlePaste(e) {
        if(this.props.onPaste) {
            this.props.onPaste(e);
        }
    }

    render() {
        return (
            <MaskedInput
                {...this.props}
                id={this.props.id}
                autoComplete={this.props.autoComplete}
                guide={this.props.guide}
                mask={this.props.mask}
                name={this.props.name}
                type={this.props.type}
                inputmode={this.props.inputmode}
                placeholder={this.props.placeholder}
                placeholderChar={this.props.placeholderChar}
                tabIndex={this.props.tabIndex}
                autoFocus={this.props.autoFocus}
                maxLength={this.props.maxLength}
                className={this.props.className}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                onPaste={this.handlePaste}
            />
        );
    }
}

CustomMaskedInput.defaultProps = {
    autoComplete: "off",
    guide: false,
    type: "text",
    placeholderChar: "\u2000"
};
